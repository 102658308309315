<script setup>

import { computed } from 'vue'
import useGetProperty from '../../hooks/useGetProperty'
import Skeleton from 'primevue/skeleton';

const props = defineProps({
    propertyId: {
        type: String,
        default: 'true',
    }

})

const pId = computed(() => props.propertyId)
const {data, isLoading} = useGetProperty(pId)
const urlSlugCreate = (val) => {
 // replace all special characters | symbols with a space
 let str = val.replace(/[`~!@#$%^&*()_\-+=\[\]{};:'"\\|\/,.<>?\s]/g, ' ').toLowerCase();

 // trim spaces at the start and end of the string
 str = str.replace(/^\s+|\s+$/gm, '');

 // replace space with dash/hyphen
 str = str.replace(/\s+/g, '-');

 return str;
};
const preview = (deal_type,primary_commodity,country,company_name_and_project_name_slug) => {
const dealTypeSlug = deal_type? deal_type == "Unknown" ?"other":urlSlugCreate(deal_type):"none";;
const commoditiSlug = primary_commodity? urlSlugCreate(primary_commodity): "none"
const countrySlug = country? urlSlugCreate(country): "none";
   window.open(`/`+ dealTypeSlug +'/'+ commoditiSlug +'/'+countrySlug +'/' + company_name_and_project_name_slug, '_blank')
 
}


</script>

<template>
    <div class="">
        <div v-if="isLoading" class="tw-flex tw-w-full tw-items-center">
            <Skeleton shape="square" size="4rem" class="mr-2"></Skeleton>
            <div style="flex: 1">
                <Skeleton width="75%" class="mb-2"></Skeleton>
                <Skeleton width="75%"></Skeleton>
            </div>
        </div>
        <!-- <div v-else class="tw-flex tw-justify-between tw-items-center"> -->
        <div v-else class="tw-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-sm-between">
            <!-- <div class="tw-flex tw-gap-2"> -->
            <div class="tw-flex flex-column flex-sm-row">
                <div class="">
                    <img :src="data.cover_image" class="tw-h-24 tw-w-32 tw-object-cover" alt="Property Image"/>
                </div>
                <!-- <div class="listing-info-inbox"> -->
                <div class="listing-info-inbox ms-sm-3 mt-2 mt-sm-0">
                    <p class="rpanel-listing tw-mb-0 me-3">{{data.name}}</p>
                    <p class="rpanel-company tw-mb-0">{{data.company_name}}</p>
                    <!-- <p class="rpanel-location"><em>{{data.street_address}}, {{data.country}}</em></p> -->
                </div>
            </div>
            <div class="mt-2 mt-sm-2">
                <a class="btn inbox-view-listings-btn" @click="preview(data.deal_type, data.primary_commodity, data.country , data.company_name_and_project_name_slug)">View Listing</a>
            </div>
        </div>
    </div>
</template>