<script setup>

import { ref } from 'vue'
const activeTab = ref(1)

</script>

<template>
    <!-- AI banner starts -->
    <div class="container-fluid AI-banner-img d-flex justify-content-center align-items-center flex-column px-3">
        <h1 class="mb-3">SearchSpace AI</h1>
        <h3 class="mb-3 mb-sm-4">Taking you closer to your Investment goals</h3>
        <div class="">
            <a>
                <p class="mb-0">We use Artificial Intelligence (AI) to analyse new-releases and other published data
                    of
                    Canadian mining companies to extract information that may be useful in research and
                    decision-making.
                    Our analysis may be helpful to mining companies, asset managers, investors and others to make
                    portfolio management, capital allocation, business risk evaluation decisions and many more. We
                    present some of the data analysis you may not have seen before.</p>
            </a>
        </div>
    </div>
    <!-- AI banner ends -->

    <!-- AI Tab section starts -->
    <div class="container-fluid background-color pb-4">
        <div class="container px-md-0 home-filer-tab d-flex">
            <div class="me-3 pt-4">
                <ul class="nav nav-pills flex-column ai-tabs" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link" :class="{'active': activeTab == 1}" @click.prevent="activeTab = 1"><img class="change-img"
                                src="../assets/img/peoples-web.png" alt="">
                            <img class="change-img-active" src="../assets/img/peoples-web-white.png" alt="">
                        </a><span class="tooltip-text">People Web</span>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" :class="{'active': activeTab == 2}"  @click.prevent="activeTab = 2"><img class="change-img"
                                src="../assets/img/people-profiles.png" alt="">
                            <img class="change-img-active" src="../assets/img/people-profiles-white.png" alt="">
                        </a><span class="tooltip-text">People Profiles</span>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" :class="{'active': activeTab == 3}"  @click.prevent="activeTab = 3"><img class="change-img"
                                src="../assets/img/investment-news-explorer.png" alt="">
                            <img class="change-img-active" src="../assets/img/investment-news-explorer-white.png"
                                alt=""></a><span class="tooltip-text">Investment News Explorer</span>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" :class="{'active': activeTab == 4}"  @click.prevent="activeTab = 4"><img class="change-img"
                                src="../assets/img/investment-network.png" alt="">
                            <img class="change-img-active" src="../assets/img/investment-network-white.png" alt=""></a>
                        <span class="tooltip-text">Investment Network</span>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" :class="{'active': activeTab == 5}"  @click.prevent="activeTab = 5"><img class="change-img"
                                src="../assets/img/sentiment-analysis.png" alt="">
                            <img class="change-img-active" src="../assets/img/sentiment-analysis-white.png" alt=""></a>
                        <span class="tooltip-text">Sentiment Analysis</span>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" :class="{'active': activeTab == 6}"  @click.prevent="activeTab = 6"><img
                                class="change-img" src="../assets/img/press-release-data-vs-stock-price.png" alt="">
                            <img class="change-img-active" src="../assets/img/press-release-data-vs-stock-price-white.png"
                                alt=""></a><span class="tooltip-text">Press Release Data vs Stock Price</span>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" :class="{'active': activeTab == 7}"  @click.prevent="activeTab = 7"><img class="change-img"
                                src="../assets/img/stock-price-comparison.png" alt="">
                            <img class="change-img-active" src="../assets/img/stock-price-comparison-white.png" alt=""></a>
                        <span class="tooltip-text">Stock Price Comparison</span>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" :class="{'active': activeTab == 8}"  @click.prevent="activeTab = 8"><img class="change-img"
                                src="../assets/img/commodity-overview.png" alt="">
                        <img class="change-img-active" src="../assets/img/commodity-overview-white.png" alt=""></a>
                    <span class="tooltip-text">Project Location & Commodity Overview</span>
                </li>
                <!-- <li class="nav-item">
                            <a class="nav-link" data-bs-toggle="pill" href="#location-analysis"><img class="change-img"
                                                                                                                src="../assets/img/location-analysis.png" alt="">
                                                                                                            <img class="change-img-active" src="../assets/img/location-analysis-white.png" alt=""></a>
                                                                                                        <span class="tooltip-text">Location Analysis</span>
                                                                                                    </li> -->
                    <li class="nav-item">
                        <a class="nav-link" :class="{'active': activeTab == 9}" style="white-space: nowrap;"  @click.prevent="activeTab = 9"><img
                                class="change-img" src="../assets/img/keyword-analysis.png" alt="">
                        <img class="change-img-active" src="../assets/img/keyword-analysis-white.png" alt=""></a>
                    <span class="tooltip-text">Keyword Analysis</span>
                </li>
                <!-- <li class="nav-item">
                            <a class="nav-link" style="white-space: nowrap;" data-bs-toggle="pill"
                                href="#article-proof-reading"><img class="change-img"
                                    src="../assets/img/article-proof-reading.png" alt="">
                                                                                                            <img class="change-img-active" src="../assets/img/article-proof-reading-white.png"
                                                                                                                alt=""></a>
                                                                                                        <span class="tooltip-text">Article Proof Reading</span>
                                                                                                    </li> -->
                </ul>
            </div>
            <!-- Tab panes -->
            <div class="tab-content d-flex ai-sub-section pt-4 px-3 pb-4">
                <div v-if="activeTab === 1" id="peoples-web" class="container active pb-1">
                    <h4 class="ai-info-tooltip">People Web <sup class=""><i class="fa fa-info-circle"
                                aria-hidden="true"></i></sup>
                        <ul class="tooltip-text ps-4">
                            <li>Type a name to search and click the 'eraser' icon to remove the filter by
                                name</li>
                            <li>Click on a name to select and click again to deselect</li>
                            <li>Use the slicer to adjust the date range</li>
                            <li>CTRL + Click the Reset filters button to remove all filters</li>
                        </ul>
                    </h4>

                    <p class="pt-2 mb-3">Here we provide you with the connections between individuals in the Mining
                        Industry
                        in Canada.
                        You could select different filters to expand the network in different ways. You can use the
                        results to research current or historical ties to a company and the people who drive them.</p>
                    <iframe title="Peoples Web"
                        src="https://app.powerbi.com/view?r=eyJrIjoiMmQxZWFjMjItODJmMC00MmU0LWE0N2UtYjRhZGFkNmI1YjdjIiwidCI6IjlhZWUyNmQ4LTk3YzItNGZhZC04OTAwLTk2NzM1ZjZkYzczZiIsImMiOjEwfQ%3D%3D"
                        frameborder="0" allowFullScreen="true"></iframe>
                </div>

                <div v-if="activeTab === 2" id="people-profiles" class="container  pb-1 active">
                    <h4 class="ai-info-tooltip">People Profiles <sup class=""><i class="fa fa-info-circle"
                                aria-hidden="true"></i></sup>
                        <ul class="tooltip-text ps-4">
                            <li>Type a name to search and click the 'eraser' icon to remove the filter by name</li>
                            <li>Use the slicer to adjust the date range</li>
                            <li>Once a name is selected, the line chart will adjust per person</li>
                        </ul>
                    </h4>

                    <p class="pt-2 mb-3">Here, we list the dominant figures in the Canadian Mining Industry, the
                        companies
                        they are affiliated with, and their mentions in various press releases. We also include a
                        summary of the mention of a person or company in press releases. A graph and table depict the
                        investment press releases and general press releases as a function of time. You can choose from
                        a variety of filters to refine your analysis. In the past, in the present, or for a given date
                        range, the advanced analysis will present the industry dominance in terms of individuals and
                        businesses in the field.</p>
                    <iframe title="People Profiles"
                        src="https://app.powerbi.com/view?r=eyJrIjoiNjY3MmE1ZWItMDIwNS00NTdkLWJhZmItZmY0YzNmNzZmMzFhIiwidCI6IjlhZWUyNmQ4LTk3YzItNGZhZC04OTAwLTk2NzM1ZjZkYzczZiIsImMiOjEwfQ%3D%3D"
                        frameborder="0" allowFullScreen="true"></iframe>
                </div>
                <div v-if="activeTab === 3" id="investment-news-explorer" class="container  pb-1">
                    <h4 class="ai-info-tooltip">Investment News Explorer <sup class=""><i class="fa fa-info-circle"
                                aria-hidden="true"></i></sup>
                        <ul class="tooltip-text ps-4">
                            <li>Type a name to search and click the 'eraser' icon to remove the filter by name</li>
                            <li>Use the slicer to adjust the date range</li>
                            <li>Click on the bar chart to filter by company name</li>
                            <li>Click bubbles on the map to select location/ click again to deselect
                                Multiple filters:</li>
                            <li>Once a filter is added, hold the CTRL key and click to add another filter</li>
                        </ul>
                    </h4>

                    <p class="pt-2 mb-3">The analysis reveals companies’ investments. Press releases regarding these
                        investments include company names are indicated. The map depicts global investments with various
                        colours representing various investment types. Upon hovering over the map, investment type and
                        releases are displayed. You can choose from a variety of filters to refine your analysis. The
                        analysis may aid investors and corporations in making investment decisions based on the
                        evolution of global investment.</p>
                    <iframe title="Investment News Explorer"
                        src="https://app.powerbi.com/view?r=eyJrIjoiNGM4NDE0NDUtNGFlNy00YTFhLThiNzItMTc1NTJmMjk5ZWM5IiwidCI6IjlhZWUyNmQ4LTk3YzItNGZhZC04OTAwLTk2NzM1ZjZkYzczZiIsImMiOjEwfQ%3D%3D"
                        frameborder="0" allowFullScreen="true"></iframe>
                </div>
                <div v-if="activeTab === 4" id="investment-network" class="container  pb-1">
                    <h4 class="ai-info-tooltip">Investment Network <sup class=""><i class="fa fa-info-circle"
                                aria-hidden="true"></i></sup>
                        <ul class="tooltip-text ps-4">
                            <li>Type a name to search and click the 'eraser' icon to remove the filter by name</li>
                            <li>Use the slicer to adjust the date range</li>
                            <li>CTRL + Click the Reset filters button to remove all filters</li>
                            <li>The network chart view can be zoomed/clicked and dragged</li>
                        </ul>
                    </h4>

                    <p class="pt-2 mb-3">The analysis shows companies’ investment networks. Press releases reveal these
                        companies’ investment amounts and types. Filters can refine your analysis. Based on current
                        investments, the analysis may help investors and corporations make investment decisions.</p>
                    <iframe title="Investment Network"
                        src="https://app.powerbi.com/view?r=eyJrIjoiYjI0NDY1MzQtMjdiOS00NmRhLWI4ZmQtOTVlZWQwZjVlZTc3IiwidCI6IjlhZWUyNmQ4LTk3YzItNGZhZC04OTAwLTk2NzM1ZjZkYzczZiIsImMiOjEwfQ%3D%3D"
                        frameborder="0" allowFullScreen="true"></iframe>
                </div>
                <div v-if="activeTab === 5" id="sentiment-analysis" class="container  pb-1">
                    <h4 class="ai-info-tooltip">Sentiment Analysis <sup class=""><i class="fa fa-info-circle"
                                aria-hidden="true"></i></sup>
                        <ul class="tooltip-text ps-4">
                            <li>Use the slicer to adjust the date range</li>
                            <li>Select an article to view sentiments</li>
                            <li>Select an emotion from the radar chart to see emotion keywords</li>
                            <p>Note: Text charts displayed are sentences with the highest negative/positive sentiments</p>
                        </ul>
                    </h4>

                    <p class="pt-2 mb-3">This analysis will assist you in deciphering the hidden meanings of corporate press
                        releases. Viewers can see the positive and negative sentiments toward the chosen company in the
                        graph. Pick a news item specifically to examine its emotional tone. Indicative of feelings and
                        thoughts are the inclusion of both positive and negative keywords. You can get a more precise
                        result by using one of many available Filters.</p>
                    <iframe title="Sentiment Analysis"
                        src="https://app.powerbi.com/view?r=eyJrIjoiMDJhODhiZjUtM2ZkYi00MTM3LWFlMWYtNjBkNDg2N2RmMWYxIiwidCI6IjlhZWUyNmQ4LTk3YzItNGZhZC04OTAwLTk2NzM1ZjZkYzczZiIsImMiOjEwfQ%3D%3D"
                        frameborder="0" allowFullScreen="true"></iframe>
                </div>
                <div v-if="activeTab === 6" id="press-release-data-vs-stock-price" class="container  pb-1">
                    <h4 class="ai-info-tooltip">Press Release Data vs Stock Price <sup class=""><i class="fa fa-info-circle"
                                aria-hidden="true"></i></sup>
                        <ul class="tooltip-text ps-4">
                            <li>Use the slicer to adjust the date range</li>
                        </ul>
                    </h4>

                    <p class="pt-2 mb-3">By analysing the impact of a press release on stock prices, investors and
                        businesses
                        can prepare for the future. The analysis considers the stock price chart over the years and the
                        company’s press releases during that time. You can read the press releases and consider their
                        potential effects. Choose the company and time frame of interest using the filters provided.</p>
                    <iframe title="Press Release Data vs Stock Price"
                        src="https://app.powerbi.com/view?r=eyJrIjoiMjcxYzk2NTQtM2JlZS00YzU0LTg0ZDItYjY0Nzg4NmJkZGZkIiwidCI6IjlhZWUyNmQ4LTk3YzItNGZhZC04OTAwLTk2NzM1ZjZkYzczZiIsImMiOjEwfQ%3D%3D"
                        frameborder="0" allowFullScreen="true"></iframe>
                </div>
                <div v-if="activeTab === 7" id="stock-price-comparison" class="container  pb-1">
                    <h4 class="ai-info-tooltip">Stock Price Comparison <sup class=""><i class="fa fa-info-circle"
                                aria-hidden="true"></i></sup>
                        <ul class="tooltip-text ps-4">
                            <li>Click and select company 1 and 2, and click again to deselect</li>
                        </ul>
                    </h4>

                    <p class="pt-2 mb-3">The dashboard provides the ability to evaluate and contrast the effect of a press
                        release on the stock prices of two different companies. Potential investors and businesses may
                        find this analysis useful in laying the groundwork for upcoming investment decisions. You can
                        learn about the outcomes by reading the press releases. It’s also possible to compare stock
                        prices of different companies over time to assess their track records over a longer period of
                        time using this analysis.</p>
                    <iframe title="Stock Price Comparison"
                        src="https://app.powerbi.com/view?r=eyJrIjoiYWU3YTNjZTYtMjc4MC00YzY3LWFlZTMtNjEzMjExOTU4YzEwIiwidCI6IjlhZWUyNmQ4LTk3YzItNGZhZC04OTAwLTk2NzM1ZjZkYzczZiIsImMiOjEwfQ%3D%3D"
                        frameborder="0" allowFullScreen="true"></iframe>
                </div>
                <div v-if="activeTab === 8" id="commodity-overview" class="container  pb-1">
                    <h4 class="ai-info-tooltip">Project Location & Commodity Overview <sup class=""><i class="fa fa-info-circle"
                                aria-hidden="true"></i></sup>
                        <ul class="tooltip-text ps-4">
                            <li>Type a name to search and click the 'eraser' icon to remove the filter by name</li>
                            <li>Click bubbles on the map to select location/ click again to deselect</li>
                            <li>Click checkboxes to select/ click again to deselect</li>
                        </ul>
                    </h4>

                    <p class="pt-2 mb-3">This analysis shows commodity distribution and related projects worldwide. Each
                        type of
                        product is represented by a specific hue. Investment-seeking projects listed on SearchSpace are
                        revealed by this analysis. The analysis also aids users in viewing the many SearchSpace projects
                        listed for a variety of commodities. A global location for the project can be found using the map
                        and Specific project information is shown on the dashboard.</p>
                    <iframe title="Project Location & Commodity Overview"
                    src="https://app.powerbi.com/view?r=eyJrIjoiNjNmMmE4MWEtZTA4MC00YTI1LWEwNDMtMzU3OGYzZDcwOTc0IiwidCI6IjlhZWUyNmQ4LTk3YzItNGZhZC04OTAwLTk2NzM1ZjZkYzczZiIsImMiOjEwfQ%3D%3D"
                    frameborder="0" allowFullScreen="true"></iframe>
            </div>
            <!-- <div id="location-analysis" class="container  pb-1">
                        <h4>Location Analysis</h4>
                        <p class="pt-2">Investment-seeking projects listed on SearchSpace are revealed by this analysis.
                                                                                                        Choose a deal or project’s development level to narrow your search. A global location for the
                                                                                                        project can be found using the map and Specific project information is shown on the dashboard.
                                                                                                    </p>
                                                                                                </div> -->
                <div v-if="activeTab === 9" id="keyword-analysis" class="container  pb-1">
                    <h4 class="ai-info-tooltip">Keyword Analysis <sup class=""><i class="fa fa-info-circle"
                                aria-hidden="true"></i></sup>
                        <ul class="tooltip-text ps-4">
                            <li>Use the slicer to adjust the date range</li>
                            <li>Click on a keyword to filter sentences related to the word/ click again to deselect</li>
                        </ul>
                    </h4>

                    <p class="pt-2 mb-3">In this dashboard, we highlight the most relevant press release keywords. Users can
                        customise their research by selecting the company they’re interested in and the time frame over
                        which they can peruse the press releases. A list of the press release’s keywords will appear.
                    </p>
                    <iframe title="Keyword Analysis"
                    src="https://app.powerbi.com/view?r=eyJrIjoiM2QxM2U4MGYtYjk4MC00NDNlLTk0ZjQtZmFjMzI5Njk3ZDBkIiwidCI6IjlhZWUyNmQ4LTk3YzItNGZhZC04OTAwLTk2NzM1ZjZkYzczZiIsImMiOjEwfQ%3D%3D"
                    frameborder="0" allowFullScreen="true"></iframe>
            </div>
            <!-- <div id="article-proof-reading" class="container  pb-1">
                        <h4>Article Proof Reading</h4>
                        <p class="pt-2">In this dashboard, we highlight the most relevant press release keywords. Users can
                                                                                                        customise their research by selecting the company they’re interested in and the time frame over
                                                                                                        which they can peruse the press releases. A list of the press release’s keywords will appear.
                                                                                                    </p>
                                                                                                </div> -->
            </div>
        </div>
    </div>
    <!-- AI Tab section ends -->
</template>