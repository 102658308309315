<script setup>
import { ref, onMounted} from 'vue'
import headComponent from "../../components/inertiaHead/head.vue"
import Nav from '../../components/layout/Nav.vue'


const props = defineProps({
  csrf_token: {
    type: String,
    required: ""
  },
  user: {
        type: Object,
        default: null,
    },
})
const currentYear = ref(null);
onMounted(() => {
    currentYear.value = new Date().getFullYear();
})
</script>

<template>

  <headComponent :description="`Understand the terms and conditions of SearchSpace AI's services. We are dedicated to maintaining a trusted platform for all users interested in mining and mining investment.`"
          :title="`Terms and Conditions`">
  </headComponent>
  <div class="container px-md-0 mt-5 pb-5 terms-n-conditions-pg">
    <h1>Terms and Conditions</h1>
    <p>01 Jan {{currentYear}}</p>
    <p class="mt-3 mb-4 custom-primary-color">Advertiser Disclosure</p>
    <p class="mb-4">SearchSpace AI (the “Site(s)”) are online information services provided by SearchSpace AI subject to
      your
      compliance with the terms and conditions set forth below. PLEASE READ THIS DOCUMENT CAREFULLY BEFORE ACCESSING OR
      USING THE SITE. BY ACCESSING OR USING THE SITE, YOU AGREE TO BE BOUND BY THE TERMS AND CONDITIONS SET FORTH BELOW.
      If you do not wish to be bound by these terms and conditions, you may not access or use the site. SearchSpace AI
      may modify this agreement at any time, and such modifications shall be effective immediately upon posting of the
      modified agreement on the site. You agree to review the agreement periodically to be aware of such modifications and
      your continued or repeated access or use of the site shall be deemed your conclusive acceptance of the modified
      agreement.</p>

    <h6 class="mb-2">1. Copyright, Licenses, Idea Submissions and Trademarks.</h6>
    <p class="mb-3">The entire contents of the Site are protected by all applicable copyright and trademark laws. The
      owner of the
      copyrights and trademarks is SearchSpace AI, its affiliates, successors, beneficiaries, or other third-party
      licensors.</p>
    <p class="mb-3">YOU MAY NOT COPY, MODIFY, REPRODUCE, REPUBLISH, POST, UPLOAD, OR DISTRIBUTE OR TRANSMIT, IN ANY
      MANNER,
      THE MATERIAL ON THE SITE, INCLUDING TEXT, GRAPHICS, CODE AND/OR SOFTWARE. </p>
    <p>You may only print and download portions
      of material from the different areas of the Site solely for your own non-commercial use on the condition that you
      agree not to change or delete any copyright or proprietary notices from the site(s). You agree to grant to
      SearchSpace AI a non-exclusive, royalty-free, worldwide, perpetual license, with the right to reproduce,
      distribute, transmit, sub-license, create derivative works of, publicly display and publicly perform any materials
      and other information (including, without limitation, ideas contained therein for new or improved products and
      services) you submit to any public areas of the Site (such as bulletin boards, forums and newsgroups) or by e-mail
      to SearchSpace AI, by all means and in any media now known or hereafter developed. You also grant to SearchSpace
      AI the right to use your name in connection with the submitted materials and other information as well as in
      connection with all advertising, marketing and promotional material related thereto. You agree that you shall have
      no recourse against SearchSpace AI for any alleged or actual infringement or misappropriation of any proprietary
      right in your communications to SearchSpace AI. Publications, products, content or services referenced herein or on
      the Site are the exclusive trademarks or service marks of SearchSpace AI. Other product and company names mentioned
      in the Site may be the trademarks of their respective owners.</p>

    <h6 class="mb-2 mt-4">2. Use of the Site and Disclaimer of all Warranties.</h6>
    <p class="mb-3">You understand that, except for information, products or services clearly identified as being supplied
      by SearchSpace AI sites, SearchSpace AI does not operate, control, or endorse any information, products, or
      services on the Internet in any way. Except for sites mentioned above, identified information, products or services,
      all information, products, and services offered through the Site or on the Internet generally are offered by third
      parties, that are not affiliated with SearchSpace AI or sites. You also understand that SearchSpace AI cannot and
      does not guarantee or warrant that file available for downloading through the Site will be free of infection or
      viruses, worms, Trojan horses, or other code that manifest contaminating or destructive properties. You are
      responsible for implementing sufficient procedures and checkpoints to satisfy your requirements for accuracy of data
      input and output, and for maintaining a means external to the Site for the reconstruction of any lost data.</p>
    <p class="mb-3">You assume total responsibility and risk for your use of the site and the internet. SearchSpace AI
      provides the site(s) and related information “as is” and does not make any express or implied warranties,
      representations or endorsements whatsoever (including without limitation warranties of title or non-infringement, or
      the implied warranties of merchantability or fitness for a particular purpose) with regard to the service, any
      merchandise information or service provided through the service or on the internet generally, and SearchSpace AI
      shall not be liable for any cost or damage arising either directly or indirectly from any such transaction. It is
      solely your responsibility to evaluate the accuracy, completeness and usefulness of all opinions, advice, services,
      merchandise, and other information provided through the service or on the internet generally. SearchSpace AI, does
      not warrant that the service will be uninterrupted or error-free or that defects in the service will be corrected.
    </p>
    <p>You understand further that the pure nature of the internet contains unedited materials. SearchSpace AI has no
      control over and accepts no responsibility whatsoever for such materials. SearchSpace AI makes no representations
      whatsoever about any other web site which you may access through this one or which may link to this site. When you
      access a non-SearchSpace AI website(s), please understand that it is independent from SearchSpace AI, and that
      SearchSpace AI has no control over the content on that website. In addition, a link to a SearchSpace AI website
      does not mean that SearchSpace AI endorses or accepts any responsibility for the content, or the use, of such
      website.</p>

    <h6 class="mb-2 mt-4">3. LIMITATION OF LIABILITY.</h6>
    <p class="mb-3">In no event will SearchSpace AI be liable for:</p>
    <p class="mb-3">(i) Any incidental, consequential, or indirect damages (including, but not limited to, damages for
      loss of profits, business interruption, loss of programs or information, and the like) arising out of the use of or
      inability to use the service, or any information, or transactions provided on the service, or downloaded from the
      service, or any delay of such information or service. Even if SearchSpace AI or its authorized representatives have
      been advised of the possibility of such damages, or</p>
    <p>(ii) Any claim attributable to errors, omissions, or other inaccuracies in the service and/or materials or
      information downloaded through the site(s). Because some states do not allow the exclusion or limitation of
      liability for consequential or incidental damages, the above limitation may not apply to you. In such states,
      SearchSpace AI liability is limited to the greatest extent permitted by law.</p>

    <h6 class="mb-2 mt-4">4. Indemnification.</h6>
    <p>You agree to indemnify, defend and hold harmless SearchSpace AI, its affiliates, successors, joint
      venturers, partners, officers, directors, employees, agents, licensors, suppliers and any third party information
      providers to the Service from and against all losses, expenses, damages and costs, including reasonable attorneys’
      fees, resulting from any violation of this Agreement (including but not limited to negligent or wrongful conduct) by
      you or any other person accessing the Service.</p>

    <h6 class="mb-2 mt-4">5. Third Party Rights.</h6>
    <p>The provisions of paragraphs 2 (Use of the Service), and 3 (Indemnification) are for the benefit of SearchSpace AI
      and its officers, directors, employees, agents, licensors, suppliers, and any third-party information providers to
      the Service. Each of these individuals or entities shall have the right to assert and enforce those provisions
      directly against you on its own behalf.</p>

    <h6 class="mb-2 mt-4">6. Term Termination.</h6>
    <p>This Agreement may be terminated by either party without notice at any time for any reason. The provisions of
      paragraphs 1 (Copyright, Licenses, and Idea Submissions), 2 (Use of the Service), 3 (Indemnification), 4 (Third
      Party Rights) and 6 (Miscellaneous) shall survive termination of this Agreement.</p>

    <h6 class="mb-2 mt-4">7. Advertiser Disclosure.</h6>
    <p>SearchSpace AI is an independent, advertising-supported comparison service. The card and financial service offers
      that appear on this site are from companies from which SearchSpace AI receives compensation. This compensation may
      impact how and where products appear on this site (including, for example, the order in which they appear).
      SearchSpace AI does not include all card or financial service companies, or all card or financial service offers
      available in the marketplace. SearchSpace AI may use other proprietary factors to impact card or financial service
      offer listings on the website such as consumer selection or the likelihood of the applicant’s credit approval.</p>

    <h6 class="mb-2 mt-4">8. Miscellaneous.</h6>
    <p class="mb-3">This Agreement shall all be governed and construed in accordance with the laws of British Columbia,
      Canada. You
      agree that any legal action or proceeding between SearchSpace AI and you for any purpose concerning this Agreement
      or the parties’ obligations hereunder shall be brought exclusively in a federal or state court of competent
      jurisdiction sitting in the Province of British Columbia, Canada. Any cause of action or claim you may have with
      respect to the Service must be commenced within one (1) year after the claim or cause of action arises or such claim
      or cause of action is barred. SearchSpace AI’s failure to insist upon or enforce strict performance of any
      provision of this Agreement shall not be construed as a waiver of any provision or right. Neither the course of
      conduct between the parties nor trade practice shall act to modify any provision of this Agreement. SearchSpace AI
      may assign its rights and duties under this Agreement to any party at any time without notice to you.</p>
    <p class="mb-3">Any rights of SearchSpace AI not expressly granted herein are reserved.</p>
    <p class="mb-3">If you have any questions about these terms of use statement, please contact us at:</p>
    <a href="mailto:support@searchspaceai.com"><p class="mb-3 custom-primary-color">support@searchspaceai.com</p></a>
    <p class="mb-3">You acknowledge and accept that your use of the SearchSpace AI website indicates your acceptance of
      the SearchSpace AI website terms and conditions.</p>
  </div>

        <a href="#" class="top-to-scroll">
            <i class="fa fa-chevron-up" aria-hidden="true"></i>
        </a>

</template>