
import { useQuery } from "vue-query";
import { reactive } from 'vue'
import axios from 'axios'

const fetchData = async (date) => {
    const response = await axios.get(`/api/v1/press-releases/news_releases`, {
      params: {
        current_date: date,
      }
    });

    return response.data;
};

  export default function useGetTodaysPressReleases(date) {
    // const queryKey = reactive(["property", id ]);
    const queryKey = ["todaysPressReleases", date];
    return useQuery(
        queryKey,
        () => fetchData(date.value),
        {
          refetchOnWindowFocus: false,
          //   placeholderData: [],
            staleTime: Infinity,
            keepPreviousData: true,
          onError: (error) => {
            // store.commit("errorsModal/openModal", error.response.data.errors);
            console.log('Error....', error)
          },
        }
    );
}