<script setup>
import { ref, computed } from 'vue'
import { router } from '@inertiajs/vue3'
import BsModal from '../BsModal.vue';

import { VueRecaptcha } from 'vue-recaptcha';

const props = defineProps({
    csrf_token: {
        type: String,
        required: ""
    },
    isVisible: {
        type: Boolean,
        default: true
    },
    exists: {
        type: Boolean,
        default: false
    },
    errors: {
        type: Object,
    },
    captchaSiteKey: {
        type: String,
    },
})

const reCaptchaToken = ref('')

const emit = defineEmits(["onClose"]);
const visible = computed(() => props.isVisible)
const regName = ref('')
const regNameError = ref(false)
const regNameErrorMsg = ref('')
const regEmail = ref('')
const regEmailError = ref(false)
const regEmailErrorMsg = ref('')
const regPassword = ref('')
const regPasswordError = ref(false)
const regPasswordErrorMsg = ref('')
const regConfirmPassword = ref('')
const regConfirmPasswordError = ref(false)
const regConfirmPasswordErrorMsg = ref('')
const regTermsCondition = ref(false)
const regTermsConditionError = ref(false)
const regTermsConditionErrorMsg = ref('')


const closeModal = () => {
    emit("onClose");
}
const isProcessing = ref(false)

const signUp = async () => {
    if (regPassword.value === regConfirmPassword.value) {
        router.post('/create_account', {
            user: {
                'name': regName.value,
                'email': regEmail.value,
                'password': regPassword.value                
            },
            'reCaptchaToken': JSON.parse(JSON.stringify(reCaptchaToken))._value,
            'authenticity_token': props.csrf_token,
        }, {
            onStart: () => {
                isProcessing.value = true
            },
            onFinish: () => {
                isProcessing.value = false
            }
        })
    } else {
        regConfirmPasswordError.value = true
        regPasswordError.value = true
        regConfirmPasswordErrorMsg.value = "Your passwords do not match."
    }
}



const verifyMethod = (response) => {
    reCaptchaToken.value = response
}

const expiredMethod = () => {
    reCaptchaToken.value = ""
}


</script>

<template>
    <BsModal :show="visible" :blurBackdrop="true">
        <template #modalHeader>
            <h4 class="modal-title">Sign Up</h4>
            <button class="close-button" @click="closeModal">
                <svg class="tw-w-6 tw-h-6" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
            </button>
        </template>

        <template #modalBody>
            <h1 class="form-caption mb-3">Join SearchSpace AI | Mining Projects & Investments Network</h1>

            <form @submit.prevent="signUp">


                <!-- authenticity token -->
                <input type="hidden" name="authenticity_token" :value="csrf_token">

                <!-- Name -->
                <div class="form-floating mb-3">
                    <input type="text" :class="{ 'form-control': true, 'is-invalid': regNameError }" name="user[name]"
                        id="regName" v-model="regName" placeholder="Name" required>
                    <label for="regName">Name</label>
                    <div v-if="regNameError" class="validation-msg">
                        {{ regNameErrorMsg }}
                    </div>
                </div>

                <!-- Email -->
                <div class="form-floating mb-3">
                    <input type="email" :class="{ 'form-control': true, 'is-invalid': regEmailError }" name="user[email]"
                        id="regEmail" v-model="regEmail" placeholder="name@example.com" required>
                    <label for="regEmail">Email</label>
                    <div v-if="regEmailError" class="validation-msg">
                        {{ regEmailErrorMsg }}
                    </div>
                </div>

                <!-- Password -->
                <div class="form-floating mb-3">
                    <input type="password" :class="{ 'form-control': true, 'is-invalid': regPasswordError }"
                        name="user[password]" id="regPassword" v-model="regPassword" placeholder="Password" required>
                    <label for="password">Password</label>
                    <div v-if="regPasswordError" class="validation-msg">
                        {{ regPasswordErrorMsg }}
                    </div>
                </div>

                <!-- Re-Password -->
                <div class="form-floating mb-3">
                    <input type="password" :class="{ 'form-control': true, 'is-invalid': regConfirmPasswordError }"
                        id="regConfirmPassword" v-model="regConfirmPassword" placeholder="Confirm Password" required>
                    <label for="rePassword">Confirm Password</label>
                    <div v-if="regConfirmPasswordError" class="validation-msg">
                        {{ regConfirmPasswordErrorMsg }}
                    </div>
                </div>

                <!-- Terms and Conditions -->
                <div class="form-check mb-3 px-4 tnm-wrapper">
                    <input type="checkbox" class="form-check-input" id="TermsAndConditions" v-model="regTermsCondition"
                        required>
                    <label class="form-check-label" for="TermsAndConditions">
                        Accept
                        <span>
                            <a href="/terms-and-conditions">
                                Terms and Conditions
                            </a>
                        </span>
                    </label>
                    <div v-if="regTermsConditionError" class="validation-msg">
                        {{ regTermsConditionErrorMsg }}
                    </div>
                </div>

                <vue-recaptcha ref="recaptcha" :sitekey="props.captchaSiteKey" @verify="verifyMethod"
                    @expired="expiredMethod"></vue-recaptcha>


                <div class="validation-msg">
                    {{ errors?.message }}
                </div>

                <div class="d-grid gap-2 mt-20">
                    <button :disabled="isProcessing || reCaptchaToken === ''" type="submit"
                        class="btn btn-primary btn-sign-in">
                        {{ isProcessing ? 'Please wait...' : 'Sign Up' }}
                    </button>
                </div>

            </form>

            <div class="sign-up-link-wrapper">
                <p> Already have an account? <span><a href="signin"> Sign In </a></span> </p>
            </div>
        </template>
        <template #modalFooterWrapper>
            <span></span>
        </template>
    </BsModal>
</template>

<style>
:root {
    --black: #222222;
    --link-blue: #3882FF;
    --error: #ff1a1a;
}
</style>

<style scoped>
:deep(.modal-dialog) {
    justify-content: center;
}

:deep(.modal-content) {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    width: 500px;
    display: flex;
    flex-direction: column;
}

:deep(.modal-header) {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #eee;
}

:deep(.modal-title) {
    font-size: 17px;
}

:deep(.modal-body) {
    padding: 1.25rem;
    flex: 1;
}


.close-button {
    background: none;
    border: none;
}

.close-button-img {
    width: 14px;
}


.mt-20 {
    margin-top: 20px;
}

.form-caption {
    text-align: left;
    font: normal normal medium 22px/27px Poppins;
    letter-spacing: 0px;
    color: var(--black);
    font-size: 20px;
    font-weight: 600;
}

.tnm-wrapper input {
    border: 1px solid #000;
}

.tnm-wrapper label {
    font-size: 13px;
    font-weight: 500;
    margin-top: 1px;
}

.tnm-wrapper a {
    text-decoration: none;
    color: var(--link-blue) !important;
}


.btn-sign-in {
    height: 56px;
    font-weight: 600;
    color: #fff !important;
}



.devider-2 {
    display: flex;
}

.devider-2 hr {
    border: 1px;
    width: 46%;
}

.devider-2 p {
    color: var(--black);
    margin: 3px 10px 0 10px;
    font-size: 16px;
    font-weight: 500;
}

.sso-login-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 30px 0 30px 0;
}

.sso-btn {
    background: none;
    border: none;
    cursor: pointer;
}

.sso-btn-img {
    width: 21px;
}

.sign-up-link-wrapper {
    display: flex;
    justify-content: center;
    margin: 30px 0 0 0;
}

.sign-up-link-wrapper span {
    margin-left: 3px;
}

.sign-up-link-wrapper p {
    font-size: 13px;
    font-weight: 500;
}

.sign-up-link-wrapper a {
    color: var(--link-blue) !important;
    text-decoration: none;
}

.validation-msg {
    color: var(--error);
    margin: 2px 0 0px 2px;
    font-size: 13px;
    font-weight: 500;
}
</style>