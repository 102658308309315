
import { useQuery, useInfiniteQuery } from "vue-query";
import axios from "axios";
import _ from 'lodash'

const fetchData = async ({ pageParam = 1, queryKey }, conversationId) => {
    const response = await axios.get(
      `/api/v1/conversations/${conversationId}/messages`,
      {
        params: {
          page: pageParam
        }
      }
    );
   // return response.data.data //.map(x => ({conversation_name: x.conversation_name, members: [], property_id: x.property_id}));
    return {
      data: _.orderBy( response.data.data, ['created_at'], ['asc']).map(x => {
        x['sentDate'] = new Date(x.created_at).toLocaleDateString('en-gb')
        x['sentTime'] = Intl.DateTimeFormat('en', { hour: "numeric", minute: "numeric", hour12: true }).format(new Date(x.created_at)) 
        return x
      }),
      // data: response.data.data,
      pagination: response.data.pagination,
    }
};

export default function useGetMessagesForConversation(conversationId) {
    return useInfiniteQuery(
        ["conversation-messages", conversationId],
        (params) => fetchData(params, conversationId.value),
        {
          getNextPageParam: (lastPage, pages) => {
            const { pagination } = lastPage;
            return !pagination.has_more_pages
              ? undefined
              : Number(pagination.current_page) + 1;
          },
          select: data => ({
            pages: [...data.pages].reverse(),
            pageParams: [...data.pageParams].reverse(),
          }),
          refetchOnWindowFocus: true,
          //   placeholderData: [],
          //   staleTime: Infinity,
          onError: (error) => {
            // store.commit("errorsModal/openModal", error.response.data.errors);
          },
        }
    );
}