<script setup>
import { ref, onMounted } from 'vue';
import AnnouncementComponent from '../../components/advertisements/AnnouncementComponent.vue'
import headComponent from "../../components/inertiaHead/head.vue"

const props = defineProps({
  title: {
    type: String,
    default: ""
  },
  html_page: {
    type: String,
    default: ""
  },
  pdf: Boolean,
  back: {
    type: String,
    default: ""
  }
})

const frame = ref();
const frameHeight = ref(1400);
const framePageCount = ref(1);

onMounted(() => {
  document.getElementById('pr-frame').contentWindow.document.body.innerHTML = '<div style="text-align:center;margin-top:50px"><img style="pointer-events:none;draggable:false" src="/img/loading.svg" width="100"></div>';

  frame.value.onload = () => {
    if (props.pdf) {
      framePageCount.value = frame.value.contentWindow.document.getElementsByName('page-count')[0].getAttribute('content');
      frameHeight.value = frameHeight.value * framePageCount.value + 50;

    } else {
      frameHeight.value = frame.value.contentWindow.document.body.scrollHeight + 50;
    }
  }
})

</script> 

<template>
  <headComponent
      :description="'Stay updated with mining industry and mining projects. Discover press releases on gold, silver, copper, junior mining, and more mining investment projects.'"
      :title="'Latest Mining News'">
  </headComponent>
  <!-- News releases banner starts -->
    <!-- News releases toggle section -->
    <div class="container  tw-left-0 tw-right-0 tw-z-[1] tw-pointer-events-none sm:tw-flex" style="max-width: 1500px;">
      <div id="announcement-dt"
          class="tw-pointer-events-auto tw-w-[1358px] tw-mr-4 tw-mt-2 tw-mb-1 tw-hidden sm:tw-block">
          <AnnouncementComponent type="desktop" :view="'NewsReleaseListView'" :user="user" />
      </div>
  </div>
  <div id="announcement-mobile" class="sm:tw-hidden  tw-left-0 tw-w-full tw-z-10 tw-p-3" style="height:16%">
      <AnnouncementComponent type="mobile" :view="'NewsReleaseListView'" :user="user" />
  </div>

  <div class="container mt-2 px-md-0">
    <div class="container">
      <a class="link" :href="props.back"><< Back</a>
      <div id="press-release-html">
        <h2 v-if="pdf==false" class="title">{{ title }}</h2>
        <iframe id="pr-frame" ref="frame" width="100%" :src="html_page" scrolling="no" frameBorder="0" :style="`height:${frameHeight}px`"></iframe>
      </div>
    </div>
  </div>
</template>

<style>
  #press-release-html {
    max-width:1024px;
    margin: 0 auto;
  }
  #press-release-html .title {
    margin-bottom: 5px;
  }
  #press-release-html .html-body p {
    margin-bottom: 10px;
  }
  a.link, #press-release-html .html-body a:link {
    text-decoration: none;
    color: #1976d2 !important;
  }
  #press-release-html .html-body a:visited {
    text-decoration: none;
  }
  #press-release-html .html-body a:hover {
    text-decoration: underline;
  }
  #press-release-html .html-body a:active {
    text-decoration: underline;
  }
</style>