<script setup>

</script>

<template><!-- News releases banner starts -->
    <div class="container-fluid news-releases-banner-img d-flex justify-content-center align-items-center flex-column px-3">
        <h1 class="mb-3">The Latest News and Insights on Mining Industry </h1>
        <h3>Over 39745 News Releases</h3>
    </div>
    <!-- News releases banner ends -->

    <!-- News releases toggle section -->
    <div class="container px-md-0 pt-4 news-releases d-flex justify-content-between align-items-center">
        <div class="d-flex  align-items-center">
            <h4 class="mb-0 me-2">News Releases</h4>
            <button class="btn subscribe-btn"><img src="../assets/img/notification.png" alt="">
                Subscribe
            </button>
        </div>
        <div class="d-flex">
            <div class="d-flex list-map">
                <button type="button" class="btn list active"><i class="fa fa-th me-2"></i>List</button>
                <button type="button" class="btn map"><i class="fa fa-map me-2"></i>Map</button>
            </div>
            <button type="button" class="btn filter-section" data-bs-toggle="modal" data-bs-target="#filter-modal">
            <i class="fa fa-sliders me-2" aria-hidden="true"></i>Filters</button>
        </div>
    </div>
    <!-- News releases toggle section ends -->

    <!-- Tab panes -->
    <div class="tab-content pt-3 pb-3">
        <!-- News Releases starts -->
        <div id="news-releases-list" class="container tab-pane active news-releases-list p-0"><br />
            <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex flex-column">
                    <h6>
                        <a href="">ALX Resources Corp. Stakes Crystal Lithium Project, Northern Saskatchewan, Canada</a>
                    </h6>
                    <div class="d-flex date-company">
                        <p>Date<span>2023-01-31</span></p>
                        <p>Company<span>ALX Resources Corp.</span></p>
                    </div>
                </div>
                <div class="d-flex">
                    <button class="btn custom-primary-btn">Read More</button>
                </div>
            </div>
            <hr class="my-4" />
            <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex flex-column">
                    <h6><a href="">B2Gold Corp. Announces Acquisition Of Sabina Gold & Silver Corp</a></h6>
                    <div class="d-flex date-company">
                        <p>Date<span>2023-01-31</span></p>
                        <p>Company<span>B2Gold Corp.</span></p>
                    </div>
                </div>
                <div class="d-flex">
                    <button class="btn custom-primary-btn">Read More</button>
                </div>
            </div>
            <hr class="my-4" />
            <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex flex-column">
                    <h6><a href="">Blue Lagoon Encounters Additional Significant Intersections From Drilling Along Strike
                            West Of The Boulder Vein Resource</a></h6>
                    <div class="d-flex date-company">
                        <p>Date<span>2023-01-31</span></p>
                        <p>Company<span>Blue Lagoon Resources Inc.</span></p>
                    </div>
                </div>
                <div class="d-flex">
                    <button class="btn custom-primary-btn">Read More</button>
                </div>
            </div>
            <hr class="my-4" />
            <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex flex-column">
                    <h6><a href="">Bravo Discovers New Nickel-Rich Zone In Luanga’s Central Sector And Extends Footprint Of
                            Nickel/Copper-Rich Zone In Luanga’s North Sector</a></h6>
                    <div class="d-flex date-company">
                        <p>Date<span>2023-01-31</span></p>
                        <p>Company<span>Bravo Mining Corp.</span></p>
                    </div>
                </div>
                <div class="d-flex">
                    <button class="btn custom-primary-btn">Read More</button>
                </div>
            </div>
            <hr class="my-4" />
            <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex flex-column">
                    <h6><a href="">Bunker Hill Announces Filing Of Amended Pre-Effective U.S. Registration Statement And
                            Updates On Equity Financing</a></h6>
                    <div class="d-flex date-company">
                        <p>Date<span>2023-01-31</span></p>
                        <p>Company<span>Bravo Mining Corp.</span></p>
                    </div>
                </div>
                <div class="d-flex">
                    <button class="btn custom-primary-btn">Read More</button>
                </div>
            </div>
            <hr class="my-4" />
            <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex flex-column">
                    <h6><a href="">Cordoba Minerals Provides An Update On Recent Activities</a></h6>
                    <div class="d-flex date-company">
                        <p>Date<span>2023-01-31</span></p>
                        <p>Company<span>Cordoba Minerals Corp.</span></p>
                    </div>
                </div>
                <div class="d-flex">
                    <button class="btn custom-primary-btn">Read More</button>
                </div>
            </div>
            <hr class="my-4" />
            <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex flex-column">
                    <h6><a href="">Defense Metals Variability Flotation Tests Yield High Rare Earth Recoveries To High Grade
                            Concentrates</a></h6>
                    <div class="d-flex date-company">
                        <p>Date<span>2023-01-31</span></p>
                        <p>Company<span>Defense Metals Corp.</span></p>
                    </div>
                </div>
                <div class="d-flex">
                    <button class="btn custom-primary-btn">Read More</button>
                </div>
            </div>
            <hr class="my-4" />
            <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex flex-column">
                    <h6><a href="">Drilling Expands Dimensions Of Gold Zones Of East Chimo Mine Sector</a></h6>
                    <div class="d-flex date-company">
                        <p>Date<span>2023-01-31</span></p>
                        <p>Company<span>Cartier Resources Inc.</span></p>
                    </div>
                </div>
                <div class="d-flex">
                    <button class="btn custom-primary-btn">Read More</button>
                </div>
            </div>
            <hr class="my-4" />
            <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex flex-column">
                    <h6><a href="">Freeman Reports On Final Drill Results From 2021-2022 Drill Program</a></h6>
                    <div class="d-flex date-company">
                        <p>Date<span>2023-01-31</span></p>
                        <p>Company<span>Freeman Gold Corp.</span></p>
                    </div>
                </div>
                <div class="d-flex">
                    <button class="btn custom-primary-btn">Read More</button>
                </div>
            </div>
            <hr class="my-4" />
            <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex flex-column">
                    <h6><a href="">G2 Drills Multiple High-Grade Intercepts</a></h6>
                    <div class="d-flex date-company">
                        <p>Date<span>2023-01-31</span></p>
                        <p>Company<span>G2 Drills Multiple High-Grade Intercepts</span></p>
                    </div>
                </div>
                <div class="d-flex">
                    <button class="btn custom-primary-btn">Read More</button>
                </div>
            </div>
            <div class="d-flex justify-content-end my-4">
                <h3>Pagination here</h3>
            </div>
        </div>
        <!-- News Releases ends -->

        <!-- Map View starts -->
        <div id="news-releases-list-map" class="container tab-pane fade p-0"><br>

        </div>
        <!-- Map View Ends -->
    </div>
    <!-- Tab panes ends -->

    <!-- Filter Modal starts -->
    <div class="modal" id="filter-modal" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable pt-5">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Filters</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row g-2">
                        <div class="col-md">
                            <div class="form-floating">
                                <input type="text" class="form-control" id="floatingInputGrid" placeholder="Title Keyword"
                                    value="">
                                <label for="floatingInputGrid">Title Keyword</label>
                            </div>
                        </div>
                    </div>
                    <div class="row g-2 mt-0 mt-md-3">
                        <div class="col-md">
                            <div class="form-floating">
                                <input type="date" class="form-control" id="floatingInputGrid" placeholder="Start Date"
                                    value="">
                                <label for="floatingInputGrid">Start Date</label>
                            </div>
                        </div>
                        <div class="col-md">
                            <div class="form-floating">
                                <input type="date" class="form-control" id="floatingInputGrid" placeholder="Start Date"
                                    value="">
                                <label for="floatingInputGrid">End Date</label>
                            </div>
                        </div>
                    </div>
                    <div class="row g-2 mt-0 mt-md-3">
                        <div class="col-md">
                            <div class="form-floating">
                                <select class="form-select" id="floatingSelectGrid">
                                    <option selected>Select</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                                <label for="floatingInputGrid">Country</label>
                            </div>
                        </div>
                        <div class="col-md">
                            <div class="form-floating">
                                <select class="form-select" id="floatingSelectGrid">
                                    <option selected>Select</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                                <label for="floatingSelectGrid">Commodity</label>
                            </div>
                        </div>
                    </div>
                    <div class="row g-2 mt-0 mt-md-3">
                        <div class="col-md">
                            <div class="form-floating">
                                <select class="form-select" id="floatingSelectGrid">
                                    <option selected>Select</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                                <label for="floatingInputGrid">Company Name</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn clear-btn">Reset</button>
                    <button type="button" class="btn apply-btn custom-primary-btn">Apply</button>
                </div>
            </div>
        </div>
    </div>
<!-- Filter Modal ends --></template>