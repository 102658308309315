<script setup>
import { computed, ref } from 'vue'
import { mapStore } from '../../../components/map/mapStore'
import ProjectInfoBox from '../../../components/map/map_view_for_news_releases/ProjectInfoBox.vue'
import useGetStockInfo from '../../../../src/hooks/map/useGetStockInfo'

const props = defineProps({
    project: {
        type: Object,
    },
    externalProject: Boolean,
    id: {
        type: String,
    },
    lat: {
        type: Number,
    },
    lng: {
        type: Number,
    },
    baseImageUrl : {
        type: String,
        defult: ""
    } ,
    all_internal_comapnies_with_slug:{
      type: Array,
      defult: []
    }
})

const fetchStockInfo = ref(false)

const opened = computed(() => {
    return mapStore.clickedMarker === props.id
}) 

const handleMarkerClick = () => {
    fetchStockInfo.value = true
    mapStore.clickedMarker = props.id == mapStore.clickedMarker? null: props.id
}

const onCloseMarker = () => {
    // mapStore.clickedMarker = null
}

const handleMouseHover = () => {
    fetchStockInfo.value = true
    console.log('Mouse hover........')
}


const symbol = computed(() => props.project.symbol)

const { data, isSuccess, isLoading } = useGetStockInfo(symbol.value, fetchStockInfo)

const icon = computed(() => {
    if(props.externalProject) return "/img/New_bule_5.png"

    return props.project.press_relases_info.length? '/img/New_purpel_1.png':  '/img/map_location_new.png'
})

const title = computed(() => props.project.name ? props.project.name : props.project.project_title);

const label = computed(() => {
    if(!props.project.press_relases_info.length) return null

    return  {
        text:props.project.press_relases_info[0].title.toString().slice(0,40)+'...',
        fontSize: '10px',
        className:'map_marker_label'                                            
    }
})

</script>


<template>
    <GMapMarker
        :position="{lat: lat, lng: lng}"
        :icon= "{
            url: icon,
            labelOrigin: { x: 12, y: -10}
        }"
        :title="title"
        @click="handleMarkerClick"
        :clickable="true"
        @mouseover="handleMouseHover"
        :label="label"
     >
        <GMapInfoWindow 
            :opened="opened"
            :closeclick="true"
            @closeclick="onCloseMarker"
        >
            <!-- <ExternalProjectInfoBox v-if="externalProject" :project="project" :stockData="data" :isSuccess="isSuccess" :isLoading="isLoading"/> -->
            <ProjectInfoBox :externalProject="externalProject" :project="project" :stockData="data" :isSuccess="isSuccess" :isLoading="isLoading" :baseImageUrl="baseImageUrl" :all_internal_comapnies_with_slug="all_internal_comapnies_with_slug"/>

        </GMapInfoWindow>
    </GMapMarker>
</template>