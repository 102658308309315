<script setup>
import { computed, defineAsyncComponent, ref, onMounted, watch, nextTick } from 'vue'
import _ from 'lodash'
import PropertyInfo from './PropertyInfo.vue'
import useSendMessage from './../../hooks/useSendMessage'
import useGetMessagesForConversation from './../../hooks/useGetMessagesForConversation'
import useDeleteConversation from './../../hooks/useDeleteConversation'
import ScrollPanel from 'primevue/scrollpanel';
import Skeleton from 'primevue/skeleton';
// import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";

// import useGetProperty from '@/hooks/useGetProperty'
// const {isLoading} = useGetProperty(props.conversation.property_id)


// const PropertyInfoVue = defineAsyncComponent(() =>
//   import('./PropertyInfo.vue')
// )

// import PropertyInfoVue from './PropertyInfo.vue'

const props = defineProps({
    conversation: {
        type: Object,
        required: true
    },
    scrollHeightClass: {
        type: String,
        default: ''
    },
    loggedInUserEmail: {
        type: String,
        default: ''
    }
})
const conversationId = computed(() => props.conversation._id.$oid)
const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } = useGetMessagesForConversation(conversationId)
const { sendMessage, sendMessageStatus } = useSendMessage()
const { deleteConversation, deleteConversationStatus } = useDeleteConversation()

const items = computed(() => {
    const resultArray = [];
    for (let page in (data.value || []).pages) {
        // totalCustomers.value = data.value.pages[page]?.pagination?.total
        resultArray.push(...data.value.pages[page].data);
    }
    return _.groupBy(resultArray, 'sentDate');
    //   return resultArray;
});

const scrollToBottom = () => {
    document.querySelector('#scrollpanelMessage .p-scrollpanel-content')?.scrollTo(0, 10000000000)
}

onMounted(() => {
    scrollToBottom()
})

const newMessage = ref('')

const send = async () => {
    const text = newMessage.value
    newMessage.value = ""
    if (text.trim() == '') return
    await sendMessage({
        conversationId: conversationId.value,
        message: text
    })
    nextTick(() => {
        scrollToBottom()
    })
}

watch([items, conversationId], (currentValue, oldValue) => {
    if ((_.isEmpty(oldValue[0]) && !_.isEmpty(currentValue[0])) || (currentValue[1] !== oldValue[1])) {
        nextTick(() => {
            scrollToBottom()
        })
    }
})

const deleteMe = async () => {
    await deleteConversation({ conversationId: conversationId.value })
}

const initiatedUserName = computed(() => {
    return props.conversation.members.filter(x => x.email === props.conversation?.initiated_by_user_email).name
})


// const startInfiniteLoading = async ($state) => {
//       await fetchNextPage.value()
//       $state.loaded();
//       if(!hasNextPage) {
//         $state.complete();
//       }
//   };


</script>

<template>
    <div class="tw-flex tw-flex-col tw-h-full">

        <PropertyInfo :property-id="conversation.property_id"></PropertyInfo>

        <div class="row inbox-right-body tw-flex-1">
            <ScrollPanel style="width: 100%" :class="scrollHeightClass" id="scrollpanelMessage"
                class="custombar1 tw-min-h-full tw-h-5 tw-bg-gray-50">
                <template v-if="isLoading">
                    <div class="tw-flex tw-my-4 tw-pl-4" v-for="i in 2" :key="i">
                        <Skeleton shape="circle" size="4rem" class="mr-2"></Skeleton>
                        <div style="flex: 1">
                            <Skeleton width="100%" class="mb-2"></Skeleton>
                            <Skeleton width="75%"></Skeleton>
                        </div>
                    </div>
                </template>
                <div v-else class="tw-flex tw-flex-col tw-pl-4">
                    <div v-if="hasNextPage" @click="fetchNextPage" :class="{ 'tw-animate-pulse': isFetchingNextPage }"
                        class="tw-flex tw-gap-2 tw-items-center tw-cursor-pointer tw-justify-center">
                        <i class="pi pi-cloud-download"></i> <span>Load more</span>
                    </div>

                    <template v-if="isFetchingNextPage">
                        <div class="tw-flex tw-my-2" v-for="i in 1" :key="i">
                            <Skeleton shape="circle" size="2rem" class="mr-2"></Skeleton>
                            <div style="flex: 1">
                                <Skeleton width="100%" class="mb-2"></Skeleton>
                                <Skeleton width="75%"></Skeleton>
                            </div>
                        </div>
                    </template>

                    <div class="col-sm-12- pb-2">

                        <template v-for="(data, key) in items" :key="key">
                            <div class="inbox-date">{{ key }}</div>
                            <div v-for="m in data" :key="m._id.$oid" :class="m.is_owner ? 'msg-sender-outer' : ''">
                                <div :class="[m.is_owner ? 'msg-sender' : 'msg-receiver']">
                                    <span :class="m.error ? 'tw-text-red-500' : ''" class="message">
                                        {{ m.message }}
                                    </span>
                                    <span class="timestamp">
                                        {{ m.sentTime }}
                                    </span>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </ScrollPanel>
        </div>
        <div class="row inbox-right-footer">
            <div class="col-sm-12">
                <div class="inbox-right-footer-inner">
                    <form class="p-3" @submit.prevent="send">
                        <div class="form-group">
                            <input class="form-control" placeholder="Type your message" v-model="newMessage" />
                            <div class="tw-cursor-pointer ico-sent-inbox tw-relative" @click="send"></div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
::v-deep .p-scrollpanel-content {
    padding-bottom: 0;
    /* padding-right: 0px; */
}

::v-deep(.p-scrollpanel) {
    p {
        padding: .5rem;
        line-height: 1.5;
        margin: 0;
    }

    &.custombar1 {
        .p-scrollpanel-wrapper {
            border-right: 9px solid var(--surface-ground);
        }

        .p-scrollpanel-bar {
            background-color: var(--gray-400);
            ;
            opacity: 1;
            transition: background-color .2s;

            &:hover {
                background-color: #007ad9;
            }
        }
    }

    &.custombar2 {
        .p-scrollpanel-wrapper {
            border-right: 9px solid var(--surface-border);
            border-bottom: 9px solid var(--surface-border);
        }

        .p-scrollpanel-bar {
            background-color: var(--surface-ground);
            border-radius: 0;
            opacity: 1;
            transition: background-color .2s;
        }
    }
}</style>