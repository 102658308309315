<script setup>
import { ref, reactive, computed, onMounted, onUpdated, watch } from "vue";
import { router, useForm } from "@inertiajs/vue3";
import VueMultiselectCustom from "../../components/dropdownSelector/VueMultiselectCustom.vue";
import VueMultiselectCustomWithLabel from "../../components/dropdownSelector/VueMultiselectCustomWithLabel.vue";
import ProjectImageUploader from "../../components/imageUploader/ProjectImageUploader.vue";
import uploadedDocumentLocal from "../../components/imageUploader/ProjectDocumentUploader.vue";
import BsModal from '../../components/modals/ConatcUsModal.vue'
import ScreenLoader from '../../components/ScreenLoader.vue'
import Nav from '../../components/layout/Nav.vue'

const props = defineProps({
    csrf_token: {
        type: String,
        required: "",
    },
    errors: {
        type: Object,
    },
    baseImageUrl: {
        type: String,
    },
    all_commodities: {
        type: Array,
    },
    all_countries: {
        type: Array,
    },
    all_stages: {
        type: Array,
    },
    all_deals: {
        type: Array,
    },
    user: {
        type: Object,
        default: null,
    },
    listingObject: {
        type: Object,
        default: null
    },
    is_edit: {
        type: Boolean,
        default: false
    }
});
const showListingModal = ref(false)
const activeTab = ref("Company_Details");//Project_Details  Company_Details
const imageUploaderRef = ref(null)
const documnetUploaderRef = ref(null)

const all_Claim_Size_Unit = [
    "Acres",
    "Hectares",
    "kms",
    "sq kms",
    "Meters",
    "Miles",
];

const dealValuesArray = ref([])

const active = ref(props.listingObject.active);
const company_id = ref(props.listingObject.company_id);
const plan = ref(props.listingObject.plan);
const deposit_type = ref(props.listingObject.deposit_type);
const total_views = ref(props.listingObject.total_views);
const owner = computed(() => props.user ? props.user.email : '')

const poCompanyName = ref(props.listingObject.company_name ? props.listingObject.company_name : '');
const poCompanyNameErrorMsg = ref("");

const poStreetAddress = ref(props.listingObject.street_address);
const poStreetAddressErrorMsg = ref("");

const poCity = ref(props.listingObject.city);
const poCityErrorMsg = ref("");

const poState = ref(props.listingObject.state);
const poStateErrorMsg = ref("");

const poCountry = ref(props.listingObject.company_country);
const poCountryErrorMsg = ref("");

const poPostalCode = ref(props.listingObject.postal_code);
const poPostalCodeErrorMsg = ref("");

const poPhone = ref(props.listingObject.phone);
const poPhoneErrorMsg = ref("");

const poStockExchange = ref(props.listingObject.exchange);
const poStockExchangeErrorMsg = ref("");

const poSymbol = ref(props.listingObject.symbol);
const poSymbolErrorMsg = ref("");

const poCompanyDiscription = ref(props.listingObject.company_description);
const poCompanyDiscriptionErrorMsg = ref("");

const poCashPosition = ref("");//temporarily not use
const poCashPositionErrorMsg = ref("");

const poDate = ref("");//temporarily not use
const poDateErrorMsg = ref("");

const cdName = ref(props.listingObject.listing_contact_name);
const cdNameErrorMsg = ref("");

const cdMobileNo = ref(props.listingObject.listing_contact_mobile_number);
const cdMobileNoErrorMsg = ref("");

const cdEmail = ref(props.listingObject.listing_contact_email_address);
const cdEmailErrorMsg = ref("");

const cdPublish = ref(props.listingObject.listing_contact_should_publish);
const cdPublishErrorMsg = ref("");


const webDetailUrl = ref(props.listingObject.website);
const webDetailUrlErrorMsg = ref("");

const showLoader = ref(false)

const addedCompanyVideos = ref([]);//temporarily not use
const companyVideo = ref('')
const companyVideoErrorMsg = ref('')
const addVideoLink = () => {
    addedCompanyVideos.value.push(companyVideo.value)
    companyVideo.value = ''
}
const removeVideoLink = (index) => {
    addedCompanyVideos.value.splice(index, 1);
}

const companyDetailSectionCancel = () => {
    poCompanyName.value = "";
    poCompanyNameErrorMsg.value = "";
    poStreetAddress.value = "";
    poStreetAddressErrorMsg.value = "";
    poCity.value = "";
    poCityErrorMsg.value = "";
    poState.value = "";
    poStateErrorMsg.value = "";
    poCountry.value = null;
    poCountryErrorMsg.value = "";
    poPostalCode.value = "";
    poPostalCodeErrorMsg.value = "";
    poPhone.value = "";
    poPhoneErrorMsg.value = "";
    poStockExchange.value = "";
    poStockExchangeErrorMsg.value = "";
    poSymbol.value = "";
    poSymbolErrorMsg.value = "";
    poCompanyDiscription.value = "";
    poCompanyDiscriptionErrorMsg.value = "";
    poCashPosition.value = "";
    poCashPositionErrorMsg.value = "";
    poDate.value = "";
    poDateErrorMsg.value = "";
    cdName.value = "";
    cdNameErrorMsg.value = "";
    cdMobileNo.value = "";
    cdMobileNoErrorMsg.value = "";
    cdEmail.value = "";
    cdEmailErrorMsg.value = "";
    cdPublish.value = false;
    cdPublishErrorMsg.value = "";
    webDetailUrl.value = "";
    webDetailUrlErrorMsg.value = "";
    addedCompanyVideos.value = [];
    companyVideoErrorMsg.value = "";
};

const companyDetailsValdation = () => {

    if (poCompanyName.value === "") {
        poCompanyNameErrorMsg.value = "Company name required. ";
    } else if (poCompanyName.value.length < 4) {
        poCompanyNameErrorMsg.value = "Company name can't be short than 4 characters.";
    } else {
        poCompanyNameErrorMsg.value = "";
    }

    if (poStreetAddress.value === "") {
        poStreetAddressErrorMsg.value = "Street address required. ";
    } else if (poStreetAddress.value.length < 10) {
        poStreetAddressErrorMsg.value = "Street address can't be short than 10 characters.";
    } else {
        poStreetAddressErrorMsg.value = "";
    }

    if (poCity.value === "") {
        poCityErrorMsg.value = "Company city required. ";
    } else if (poCity.value.length < 3) {
        poCityErrorMsg.value = "City can't be short than 3 characters.";
    } else {
        poCityErrorMsg.value = "";
    }

    if (poCompanyDiscription.value === "") {
        poCompanyDiscriptionErrorMsg.value = "Company description required. ";
    } else if (poCompanyDiscription.value.length < 100) {
        poCompanyDiscriptionErrorMsg.value = "Company name can't be short than 100 characters.";
    } else {
        poCompanyDiscriptionErrorMsg.value = "";
    }
    if(cdPublish.value === true && (cdName.value  === "" || cdEmail.value === "" || cdMobileNo.value === ""))
    {

    if (cdName.value === "") {
        cdNameErrorMsg.value = "Contact name required. ";
    } else {
        cdNameErrorMsg.value = "";
    }

    if (cdMobileNo.value === "") {
        cdMobileNoErrorMsg.value = "Contact number required. ";
    } else {
        cdMobileNoErrorMsg.value = "";
    }


    // let regExp = new RegExp("([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\"\(\[\]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])");
    // var regExp = /^[A-Za-z][\w$.]+@[\w]+\.\w+$/;
    if (cdEmail.value === "") {
        cdEmailErrorMsg.value = "Contact Email Address required. ";
    }
    // } else if (regExp.test(cdEmail.value)) {
    //     cdEmailErrorMsg.value = "";
    // } else {
    //     cdEmailErrorMsg.value = "Invalid email address ";
    // }
    // console.log("email validation ", regExp.test(cdEmail.value));
    // }
    }
    var regExp = /^[A-Za-z][\w$.]+@[\w]+\.\w+$/;

    if (cdEmail.value != "" &&  cdPublish.value === true ) {
        console.log("email validation11 ", regExp.test(cdEmail.value))
        if (regExp.test(cdEmail.value) === true){
            cdEmailErrorMsg.value = "";
        }
        else{
            cdEmailErrorMsg.value = "Invalid email address";
        }
    }
    if (  cdPublish.value === false ) {
       if (cdEmail.value != ""){
                console.log("email validation 222", regExp.test(cdEmail.value))
            if (regExp.test(cdEmail.value) === true){
                cdEmailErrorMsg.value = "";
            }
            else{
                cdEmailErrorMsg.value = "Invalid email address";
            }
         }
         else{
            cdEmailErrorMsg.value = "";
         }
        cdNameErrorMsg.value = "";
        cdMobileNoErrorMsg.value = "";
    }

    if (cdMobileNo.value != "") {
        cdMobileNoErrorMsg.value = "";
    }
    if (cdName.value != "") {
        cdNameErrorMsg.value = "";
    }
    // if (cdEmail.value != "") {
    //     cdEmailErrorMsg.value = "";
    // }
    // if (cdPublish.value === false) {
    //     cdEmailErrorMsg.value = "";
    //     cdNameErrorMsg.value = "";
    //     cdMobileNoErrorMsg.value = "";
    // }
    


    if (poCompanyNameErrorMsg.value != "" ||
        poCompanyDiscriptionErrorMsg.value != '' ||
        poStreetAddressErrorMsg.value != '' ||
        poCityErrorMsg.value != '' ||
        cdNameErrorMsg.value != "" ||
        cdMobileNoErrorMsg.value != '' ||
        cdEmailErrorMsg.value != "") {
        return false;
    } else {
        return true;
    }
};

const tabClick = (tabName) => {

    if (tabName === 'Project_Details') {
        if (props.user) {
            activeTab.value = companyDetailsValdation() ? tabName : activeTab.value
        } else {
            activeTab.value = tabName
        }
    }
    else {
        activeTab.value = tabName
    }
};

const goToNext = () => {

    if (props.user) {
        activeTab.value = companyDetailsValdation() ? 'Project_Details' : activeTab.value
    } else {
        activeTab.value = 'Project_Details'
    }
    window.scrollTo(0, 0);
};

const goToBack = () => {
    activeTab.value = "Company_Details";
    window.scrollTo(0, 0);
};

// ================================================================================================================================================
// ================================================================================================================================================
// ================================================================================================================================================


const listingName = ref(props.listingObject.name ? props.listingObject.name : '');
const listingNameErrorMsg = ref("");

const listingDescription = ref(props.listingObject.description ? props.listingObject.description : '');
const listingDescriptionErrorMsg = ref("");

const listingProjectUrl = ref(props.listingObject.project_page);
const listingProjectUrlErrorMsg = ref("");

const projectClaimSize = ref(props.listingObject.claim_size);
const projectClaimSizeErrorMsg = ref("");

const projectClaimSizeUnit = ref(props.listingObject.claim_unit_size);
const projectClaimSizeUnitErrorMsg = ref("");

const projectStage = ref(props.listingObject.stage);
const projectStageErrorMsg = ref("");

const projectPrimaryCommodity = ref(props.listingObject.primary_commodity);
const projectPrimaryCommodityErrorMsg = ref("");

const projectSecondaryCommodity = ref(props.listingObject.secondary_commodity);
const projectSecondaryCommodityErrorMsg = ref("");

const projectCountry = ref(props.listingObject.country);
const projectCountryErrorMsg = ref("");

const projectDealType = ref(props.all_deals.find(item => item.type === props.listingObject.deal_type));
const projectDealTypeErrorMsg = ref("");

const projectImage = ref([])
const projectImageErrorMsg = ref('')

const addedProjectVideos = ref(props.listingObject.videos);
const projectVideo = ref('')
const projectVideoErrorMsg = ref('')
const addProjectVideoLink = () => {

    var regExp = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    if (regExp.test(projectVideo.value)) {
        addedProjectVideos.value.push(projectVideo.value);
        projectVideo.value = "";
        projectVideoErrorMsg.value = "";
    } else {
        projectVideoErrorMsg.value = "Invalid YouTube url."
    }



};
const removeProjectVideoLink = (index) => {
    addedProjectVideos.value.splice(index, 1);
};

const plCountry = ref(props.listingObject.location ? (props.listingObject.location.split(","))[2]?.trim() : '');
const plCountryErrorMsg = ref("");

const plRegion = ref(props.listingObject.location ? (props.listingObject.location.split(","))[1]?.trim() : '');
const plRegionErrorMsg = ref("");

const plCity = ref(props.listingObject.location ? (props.listingObject.location.split(","))[0] : '');
const plCityErrorMsg = ref("");

const plLatitude = ref(props.listingObject.latitude);
const plLatitudeErrorMsg = ref("");

const plLongitude = ref(props.listingObject.longitude);
const plLongitudeErrorMsg = ref("");

// const originalImages = ref(props.listingObject.images_s3 ? props.listingObject.images_s3 : []);
const imageValidationMessage = ref("");

const projectDetailsValidation = () => {

    if (listingName.value === "") {
        listingNameErrorMsg.value = "Name required. ";
    } else if (listingName.value.length < 4) {
        listingNameErrorMsg.value = "Name can't be short than 4 characters.";
    } else {
        listingNameErrorMsg.value = "";
    }

    if (listingDescription.value === "") {
        listingDescriptionErrorMsg.value = "Description required. ";
    } else if (listingDescription.value.length < 100) {
        listingDescriptionErrorMsg.value = "Description can't be short than 100 characters.";
    } else {
        listingDescriptionErrorMsg.value = "";
    }

    if (finalImagesTobeUploaded.value.length < 1) {
        imageValidationMessage.value = "Project images Upload at least one image.";
    } else {
        imageValidationMessage.value = "";
    }

    if (plCountry.value === "") {
        plCountryErrorMsg.value = "Country required. ";
    } else {
        plCountryErrorMsg.value = "";
    }

    if (plRegion.value === "") {
        plRegionErrorMsg.value = "Region required. ";
    } else {
        plRegionErrorMsg.value = "";
    }

    if (plCity.value === "") {
        plCityErrorMsg.value = "City required. ";
    } else {
        plCityErrorMsg.value = "";
    }

    if (plLatitude.value === "") {
        plLatitudeErrorMsg.value = "Latitude required. ";
    } else {
        plLatitudeErrorMsg.value = "";
    }

    if (plLongitude.value === "") {
        plLongitudeErrorMsg.value = "Longitude required. ";
    } else {
        plLongitudeErrorMsg.value = "";
    }

    if (listingNameErrorMsg.value != "" ||
        listingDescriptionErrorMsg.value != "" ||
        plCountryErrorMsg.value != "" ||
        plRegionErrorMsg.value != "" ||
        plCityErrorMsg.value != "" ||
        plLatitudeErrorMsg.value != "" ||
        plLongitudeErrorMsg.value != "" ||
        imageValidationMessage.value != '') {
        return false;
    } else {
        return true;
    }
};
const projectCancelGoBack = () => {
    if (props.user != null) {
        //     router.get('/geo_listings', {
        //     onBefore: visit => { confirm('Are you sure?') },
        // })
        router.visit('/geo_listings', {
            method: 'get',
            onBefore: () => {
                return confirm('Are you sure?')
            }
        })
    }
    else {
        router.visit('/', {
            method: 'get',
            onBefore: () => {
                return confirm('Are you sure?')
            }
        })
        // router.get('/', {
        //     onBefore: visit => { confirm('Are you sure?') },
        // })
    }
}

const projectDetailSectionCancel = () => {

    listingName.value = ''
    listingNameErrorMsg.value = ''
    listingDescription.value = ''
    listingDescriptionErrorMsg.value = ''
    listingProjectUrl.value = ''
    listingProjectUrlErrorMsg.value = ''
    projectClaimSize.value = ''
    projectClaimSizeErrorMsg.value = ''
    projectClaimSizeUnit.value = ''
    projectClaimSizeUnitErrorMsg.value = ''
    projectStage.value = ''
    projectStageErrorMsg.value = ''
    projectPrimaryCommodity.value = ''
    projectPrimaryCommodityErrorMsg.value = ''
    projectSecondaryCommodity.value = ''
    projectSecondaryCommodityErrorMsg.value = ''
    projectCountry.value = ''
    projectCountryErrorMsg.value = ''
    projectDealType.value = []
    projectDealTypeErrorMsg.value = ''
    projectImage.value = []
    projectImageErrorMsg.value = ''
    plCountry.value = ''
    plCountryErrorMsg.value = ''
    plRegion.value = ''
    plRegionErrorMsg.value = ''
    plCity.value = ''
    plCityErrorMsg.value = ''
    plLatitude.value = ''
    plLatitudeErrorMsg.value = ''
    plLongitude.value = ''
    plLongitudeErrorMsg.value = ''
    // originalImages.value = ''
    imageValidationMessage.value = ''
    dealValuesArray.value = []

};

// const UpolodImagesUpadte = (newImgs, savedImgs) => {

//     new_images.splice(0, new_images.length)
//     if (newImgs.length > 0) {
//         newImgs.forEach(element => {
//             new_images.push(element.img)
//         });

//     }

//     new_images_uploaded.splice(0, new_images_uploaded.length)
//     if (savedImgs.length > 0) {
//         savedImgs.forEach(element => {
//             new_images_uploaded.push(element.img)
//         });

//     }
//     var final_images = [...new_images, ...new_images_uploaded];
//     originalImages.value = final_images
// }

const addDealValus = () => {
    let valseRow = {}

    projectDealType.value.values.forEach((item) => {
        valseRow[item.field] = document.getElementById(item.field).value
        document.getElementById(item.field).value = '';
    })

    dealValuesArray.value.push(valseRow)
}

const deleteDealValus = (index) => {
    dealValuesArray.value.splice(index, 1);
}

const finalImagesTobeUploaded = computed(() => {
    return [
        ...imageUploaderRef.value.uploadedImagesLocal.map(x => x.img),
        ...imageUploaderRef.value.newlyUploadedFiles.map(x => x.img)
    ]
})
const finalDocumnetsTobeUploaded = computed(() => {
    return [
        ...documnetUploaderRef.value.uploadedDocumentLocal.map(x => x.img),
        ...documnetUploaderRef.value.newlyUploadedDocument.map(x => x.img)
    ]
})

// uploadedDocumentLocal newlyUploadedDocument

const submitListing = () => {

    const data = {
        authenticity_token: props.csrf_token,
        company_name: poCompanyName.value,
        street_address: poStreetAddress.value,
        city: poCity.value,
        state: poState.value,
        company_country: poCountry.value,
        postal_code: poPostalCode.value,
        phone: poPhone.value,
        exchange: poStockExchange.value,
        symbol: poSymbol.value,
        company_description: poCompanyDiscription.value,
        listing_contact_name: cdName.value,
        listing_contact_mobile_number: cdMobileNo.value,
        listing_contact_email_address: cdEmail.value,
        listing_contact_should_publish: cdPublish.value,
        website: webDetailUrl.value,
        'name': listingName.value,
        description: listingDescription.value,
        project_page: listingProjectUrl.value,
        claim_size: projectClaimSize.value,
        claim_unit_size: projectClaimSizeUnit.value,
        stage: projectStage.value,
        primary_commodity: projectPrimaryCommodity.value,
        secondary_commodity: projectSecondaryCommodity.value,
        country: projectCountry.value,
        deal_type: projectDealType.value.type,
        images_s3: finalImagesTobeUploaded.value,
        attachement_s3: finalDocumnetsTobeUploaded.value,
        videos: addedProjectVideos.value,
        location: `${plCity.value}, ${plRegion.value}, ${plCountry.value}`,
        latitude: plLatitude.value,
        longitude: plLongitude.value,
        active: active.value,
        company_id: company_id.value,
        plan: plan.value,
        deposit_type: deposit_type.value,
        total_views: total_views.value,
        owner: owner.value,
    }

    if (projectDealType.value.values.length > 0) {

        if (projectDealType.value.type != 'Joint-Venture' && projectDealType.value.type != 'Option') {

            let valseRow = {}
            projectDealType.value.values.forEach((item) => {
                valseRow[item.field] = document.getElementById(item.field).value
            })

            dealValuesArray.value = []
            dealValuesArray.value.push(valseRow)
        }
    }

    projectDealType.value.type != 'Unknown' ? data[projectDealType.value.model] = dealValuesArray.value : null


    if (props.user) {
        if (projectDetailsValidation()) {
            if (props.is_edit == true) {

                router.patch(
                    '/my-listings/' + props.listingObject._id.$oid + '/edit',
                    data,
                    {
                        onStart: () => {
                            showLoader.value = true
                        },
                        onFinish: () => {
                            showLoader.value = false
                        }
                    }
                )
            }
            else {

                router.post(
                    '/my-listings/new',
                    data,
                    {
                        onStart: () => {
                            showLoader.value = true
                        },
                        onFinish: () => {
                            showLoader.value = false
                        }
                    }
                )
            }

        } else {
            console.log("Validation error ... !");
        }
    } else {
        router.post(
            '/my-listings/create_listing',
            data,
            {
                onStart: () => {
                    showLoader.value = true
                    activeTab.value = "Company_Details";
                },
                onFinish: () => {
                    showLoader.value = false
                  
                }
            }
        )
    }
};


const NumbersOnly = (evt) => {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    console.log(charCode)
    if ((charCode !== 45 && charCode !== 43 ) &&(charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
    } else {
        return true;
    }
}

watch(projectDealType, () => {
    dealValuesArray.value = []

    // document.getElementsByClassName("dealValueInputs")[0].value = "";

    // projectDealType.value.values.forEach((item, index) => {
    //     document.getElementsByClassName("dealValueInputs")[index].value = "";
    // })

})

onMounted(() => {

    if (projectDealType.value.type != 'Unknown') {
        dealValuesArray.value = []
        dealValuesArray.value.push(...props.listingObject[projectDealType.value.model])
    }

    if (projectDealType.value.type != "Joint-Venture" && projectDealType.value.type != "Option") {

        if (dealValuesArray.value.length > 0) {
            projectDealType.value.values.forEach((item) => {
                document.getElementById(item.field).value = dealValuesArray.value[0][item.field];
            })
        }
    }

})
</script>

<template>
    <div class="container createlisting">
        <div class="d-flex align-items-center my-5">
            <div v-if="is_edit == true">
                <a class="btn custom-primary-btn-outline me-3" :href="'/my-listings'"><i class="fa fa-arrow-left me-2"
                        aria-hidden="true"></i>Back</a>
            </div>
            <h4 class="">Create Project Listing</h4>
        </div>
        <ul class="nav nav-tabs mb-3" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
                <button :class="{ 'nav-link': true, active: activeTab === 'Company_Details' }" type="button" role="tab"
                    @click="tabClick('Company_Details')">
                    Company Details
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button :class="{ 'nav-link': true, active: activeTab === 'Project_Details' }" type="button" role="tab"
                    @click="tabClick('Project_Details')">
                    Project Details
                </button>
            </li>
        </ul>

        <div class="tab-content" id="myTabContent">
            <!--------------------------------------------------------------------------------------------------------------->
            <!-- Company Details Section  -->
            <!---------------------------------------------------------------------------------------------------------------->
            <div :class="{ 'tab-pane fade': true, 'show active': activeTab === 'Company_Details', }" role="tabpanel">


                <!------------------------------ Project Owner --------------------------------------------->
                <div class="row g-3 listing-form">
                    <h5 class="mt-5">Project Owner</h5>
                    <div class="col-md-4">
                        <label class="form-label">Company Name <span v-if="user" class="required-star">*</span></label>
                        <input type="text" class="form-control " v-model="poCompanyName" />
                        <div v-if="poCompanyNameErrorMsg" class="validation-msg">
                            {{ poCompanyNameErrorMsg }}
                        </div>
                    </div>

                    <div class="col-md-4">
                        <label class="form-label">Street Address <span v-if="user" class="required-star">*</span></label>
                        <input type="text" class="form-control" v-model="poStreetAddress" />
                        <div v-if="poStreetAddressErrorMsg" class="validation-msg">
                            {{ poStreetAddressErrorMsg }}
                        </div>
                    </div>

                    <div class="col-md-4">
                        <label class="form-label">City <span v-if="user" class="required-star">*</span></label>
                        <input type="text" class="form-control" v-model="poCity" />
                        <div v-if="poCityErrorMsg" class="validation-msg">
                            {{ poCityErrorMsg }}
                        </div>
                    </div>


                    <div class="col-md-4">
                        <label class="form-label">State </label>
                        <input type="text" class="form-control" v-model="poState" />
                        <div v-if="poStateErrorMsg" class="validation-msg">
                            {{ poStateErrorMsg }}
                        </div>
                    </div>

                    <div class="col-md-4">
                        <label class="form-label">Country </label>
                        <VueMultiselectCustom placeholder="All Countries" :options="all_countries" :modelValue="poCountry"
                            :multiple="false" @update:modelValue="poCountry = $event" class="notranslate">
                        </VueMultiselectCustom>
                        <div v-if="poCountryErrorMsg" class="validation-msg">
                            {{ poCountryErrorMsg }}
                        </div>
                    </div>

                    <div class="col-md-4">
                        <label class="form-label">Postal Code </label>
                        <input type="text" class="form-control" v-model="poPostalCode" />
                        <div v-if="poPostalCodeErrorMsg" class="validation-msg">
                            {{ poPostalCodeErrorMsg }}
                        </div>
                    </div>

                    <div class="col-md-4">
                        <label class="form-label">Phone </label>
                        <input type="text" class="form-control" v-model="poPhone" @keypress="NumbersOnly" />
                        <div v-if="poPhoneErrorMsg" class="validation-msg">
                            {{ poPhoneErrorMsg }}
                        </div>
                    </div>

                    <div class="col-md-4">
                        <label class="form-label">Stock Exchange </label>
                        <input type="text" class="form-control" v-model="poStockExchange" />
                        <div v-if="poStockExchangeErrorMsg" class="validation-msg">
                            {{ poStockExchangeErrorMsg }}
                        </div>
                    </div>

                    <div class="col-md-4">
                        <label class="form-label">Symbol </label>
                        <input type="text" class="form-control" v-model="poSymbol" />
                        <div v-if="poSymbolErrorMsg" class="validation-msg">
                            {{ poSymbolErrorMsg }}
                        </div>
                    </div>

                    <div class="col-md-12">
                        <label class="form-label">Company Description <span v-if="user"
                                class="required-star">*</span></label>
                        <textarea class="form-control" rows="5" v-model="poCompanyDiscription"></textarea>
                        <div v-if="poCompanyDiscriptionErrorMsg" class="validation-msg">
                            {{ poCompanyDiscriptionErrorMsg }}
                        </div>
                    </div>

                    <!-- <div class="col-md-4">
                                                                                                                                                                                                                                                    <label class="form-label">Cash Position</label>
                                                                                                                                                                                                                                                    <input type="text" class="form-control" v-model="poCashPosition">
                                                                                                                                                                                                                                                    <div v-if="poCashPositionErrorMsg" class="validation-msg">
                                                                                                                                                                                                                                                        {{ poCashPositionErrorMsg }}
                                                                                                                                                                                                                                                    </div>
                                                                                                                                                                                                                                                </div> -->

                    <!-- <div class="col-md-4">
                                                                                                                                                                                                                                                 <label class="form-label">As of Date</label>
                                                                                                                                                                                                                                                 <input type="date" class="form-control" v-model="poDate" onkeydown="return false">
                                                                                                                                                                                                                                                 <div v-if="poDateErrorMsg" class="validation-msg">
                                                                                                                                                                                                                                                     {{ poDateErrorMsg }}
                                                                                                                                                                                                                                                 </div>
                                                                                                                                                                                                                                             </div> -->
                </div>

                <!------------------------------------ Contact Details  ---------------------------------------->
                <div class="row g-3 listing-form mt-3">
                    <h5 class="mt-5">Contact Details</h5>

                    <div class="col-md-4">
                        <label class="form-label">Name <span v-if="user" class="required-star">*</span></label>
                        <input type="text" class="form-control" v-model="cdName" />
                        <div v-if="cdNameErrorMsg" class="validation-msg">
                            {{ cdNameErrorMsg }}
                        </div>
                    </div>

                    <div class="col-md-4">
                        <label class="form-label">Contact Number <span v-if="user" class="required-star">*</span></label>
                        <input type="text" class="form-control" v-model="cdMobileNo" @keypress="NumbersOnly" />
                        <div v-if="cdMobileNoErrorMsg" class="validation-msg">
                            {{ cdMobileNoErrorMsg }}
                        </div>
                    </div>

                    <div class="col-md-4">
                        <label class="form-label">Email Address <span v-if="user" class="required-star">*</span></label>
                        <input type="email" class="form-control" v-model="cdEmail" />
                        <div v-if="cdEmailErrorMsg" class="validation-msg">
                            {{ cdEmailErrorMsg }}
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-check">
                            <input id="isPublish" class="form-check-input" type="checkbox" v-model="cdPublish" />
                            <label for="isPublish" class="form-check-label"> Publish contact details </label>
                            <div v-if="cdPublishErrorMsg" class="validation-msg">
                                {{ cdPublishErrorMsg }}
                            </div>
                        </div>
                    </div>
                </div>

                <!------------------------------------ Web Details  ---------------------------------------->
                <div class="row g-3 listing-form mt-1">
                    <h5 class="mt-5">Web Details</h5>
                    <div class="col-md-10">
                        <label class="form-label">Website</label>
                        <input type="text" class="form-control" v-model="webDetailUrl" />
                    </div>
                    <div class="col-md-2 d-flex align-items-end">
                        <a :href="webDetailUrl ? webDetailUrl : '#'" target="_blank"
                            class="btn custom-primary-btn-link-outline w-100 p10">Preview</a>
                    </div>
                </div>

                <!------------------------------------Company Documents  ---------------------------------------->
                <!-- <div class="row g-3 listing-form mt-3">
                            <h5 class="mt-5">Company Documents</h5>
                            <uploadedDocumentLocal 
                                ref="documnetUploaderRef" 
                                :csrf_token="csrf_token" 
                                :remoteBaseUrl="baseImageUrl"
                                :uploadedImages="listingObject?.attachement_s3 ?? []"  
                                :config="{restrictions: {
                                    allowedFileTypes: ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'],
                                    maxFileSize: 15 * 1024 * 1024}}">

                                <template #trigger="triggerProps" class="row g-3 listing-form mt-3">
                                    <div class="col-md-10">
                                        <div class="text-image">
                                            <p>
                                                File types :.PNG, .JPG, .PDF <span v-if="user" class="required-star">*</span>
                                            </p>
                                            <p>Max Size : 15MB</p>
                                        </div>
                                    </div>
                                    <button @click="triggerProps.showUploadModal" type="button"
                                        class="col-md-2 btn custom-primary-btn-outline">
                                        Upload
                                    </button>
                                </template>

                                <template #default="slotProps">
                                    <input v-for="file in slotProps.uploadedDocumentLocal" :key="file.id" :value="file.img"
                                        :modelValue="file.img" id="geo_listing_images_attachement_s3" type="hidden" />

                                    <input v-for="file in slotProps.newlyUploadedDocument" :key="file.id" :value="file.img"
                                        :modelValue="file.img" type="hidden" id="geo_listing_attachement_s3" />

                                </template>
                            </uploadedDocumentLocal>
                        </div> -->

                <!------------------------------------Company Videos  ---------------------------------------->
                <!-- <div class="row g-3 listing-form mt-3">
                                                                                                                                                                                                                                                                                                                                         <h5 class="mt-5">Company Videos</h5>
                                                                                                                                                                           <label class="form-label">Video URL</label>

                                                                                                                                                                           <div class="m-0" v-for="item, index in addedCompanyVideos">
                                                                                                                                                                               <div class="row mt-3" :key="index">
                                                                                                                                                                                   <div class="col-md-10 ">
                                                                                                                                                                                       <input type="text" class="form-control" :value="item">
                                                                                                                                                                                   </div>
                                                                                                                                                                                   <div class="col-md-2 d-flex align-items-end">
                                                                                                                                                                                       <button type="submit" class="btn custom-danger-btn-outline w-100"
                                                                                                                                                                                           @click="removeVideoLink(index)">Remove</button>
                                                                                                                                                                                   </div>
                                                                                                                                                                               </div>
                                                                                                                                                                           </div>

                                                                                                                                                                           <div class="m-0">
                                                                                                                                                                               <div class="row mt-3">
                                                                                                                                                                                   <div class="col-md-10">
                                                                                                                                                                                       <input type="text" class="form-control" v-model="companyVideo">
                                                                                                                                                                                   </div>
                                                                                                                                                                                   <div class="col-md-2 d-flex align-items-end">
                                                                                                                                                                                       <button type="submit" class="btn custom-primary-btn-outline w-100"
                                                                                                                                                                                           @click="addVideoLink">Add</button>
                                                                                                                                                                                   </div>
                                                                                                                                                                               </div>
                                                                                                                                                                           </div>
                                                                                                                                                                       </div> -->

                <div class="row g-3 listing-form mt-4 pb-5 d-flex justify-content-end">
                    <div class="col-md-2">
                        <button type="submit" class="btn custom-secondary-btn-outline w-100" @click="projectCancelGoBack">
                            Cancel
                        </button>
                    </div>
                    <div class="col-md-2">
                        <button type="submit" class="btn custom-primary-btn w-100" @click="goToNext">
                            Next
                        </button>
                    </div>
                </div>
            </div>

            <!--------------------------------------------------------------------------------------------------------------->
            <!-- Project Details Section  -->
            <!---------------------------------------------------------------------------------------------------------------->
            <div :class="{ 'tab-pane fade': true, 'show active': activeTab === 'Project_Details', }" role="tabpanel">
                <!------------------------------------ Listing Details ---------------------------------------->

                <div class="row g-3 listing-form">
                    <h5 class="mt-5">Listing Details</h5>
                    <div class="col-md-12">
                        <label class="form-label">Name <span v-if="user" class="required-star">*</span></label>
                        <input type="text" class="form-control" v-model="listingName" />
                        <div v-if="listingNameErrorMsg" class="validation-msg">
                            {{ listingNameErrorMsg }}
                        </div>
                    </div>
                    <div class="col-md-12">
                        <label class="form-label">Description <span v-if="user" class="required-star">*</span></label>
                        <textarea class="form-control" rows="5" v-model="listingDescription"></textarea>
                        <div v-if="listingDescriptionErrorMsg" class="validation-msg">
                            {{ listingDescriptionErrorMsg }}
                        </div>
                    </div>
                    <div class="col-md-10">
                        <label class="form-label">Project Home Page Link</label>
                        <input type="text" class="form-control" v-model="listingProjectUrl" />
                    </div>
                    <div class="col-md-2 d-flex align-items-end">
                        <a :href="listingProjectUrl ? listingProjectUrl : '#'" target="_blank"
                            class="btn custom-primary-btn-link-outline w-100 p10">Preview</a>
                    </div>
                </div>

                <!------------------------------------------- Project Details  --------------------------------------------->
                <div class="row g-3 listing-form mt-3">
                    <h5 class="mt-5">Project Details</h5>
                    <div class="col-md-4">
                        <label class="form-label">Claim Size</label>
                        <input type="text" class="form-control" v-model="projectClaimSize" @keypress="NumbersOnly" />
                        <div v-if="projectClaimSizeErrorMsg" class="validation-msg">
                            {{ projectClaimSizeErrorMsg }}

                        </div>
                    </div>
                    <div class="col-md-4">
                        <label class="form-label">Claim Size Unit</label>
                        <VueMultiselectCustom placeholder="Select" :options="all_Claim_Size_Unit"
                            :modelValue="projectClaimSizeUnit" :multiple="false"
                            @update:modelValue="projectClaimSizeUnit = $event" class="notranslate">
                        </VueMultiselectCustom>
                        <div v-if="projectClaimSizeUnitErrorMsg" class="validation-msg">
                            {{ projectClaimSizeUnitErrorMsg }}
                        </div>
                    </div>
                    <div class="col-md-4">
                        <label class="form-label">Stage</label>
                        <VueMultiselectCustom placeholder="Select" :options="all_stages" :modelValue="projectStage"
                            :multiple="false" @update:modelValue="projectStage = $event" class="notranslate">
                        </VueMultiselectCustom>
                        <div v-if="projectStageErrorMsg" class="validation-msg">
                            {{ projectStageErrorMsg }}
                        </div>
                    </div>
                    <div class="col-md-4">
                        <label class="form-label">Primary Commodity</label>
                        <VueMultiselectCustom placeholder="Select" :options="all_commodities"
                            :modelValue="projectPrimaryCommodity" :multiple="false"
                            @update:modelValue="projectPrimaryCommodity = $event" class="notranslate">
                        </VueMultiselectCustom>
                        <div v-if="projectPrimaryCommodityErrorMsg" class="validation-msg">
                            {{ projectPrimaryCommodityErrorMsg }}
                        </div>
                    </div>
                    <div class="col-md-4">
                        <label class="form-label">Secondary Commodity</label>
                        <VueMultiselectCustom placeholder="Select" :options="all_commodities"
                            :modelValue="projectSecondaryCommodity" :multiple="false"
                            @update:modelValue="projectSecondaryCommodity = $event" class="notranslate">
                        </VueMultiselectCustom>
                        <div v-if="projectSecondaryCommodityErrorMsg" class="validation-msg">
                            {{ projectSecondaryCommodityErrorMsg }}
                        </div>
                    </div>
                    <div class="col-md-4">
                        <label class="form-label">Country</label>
                        <VueMultiselectCustom placeholder="Select" :options="all_countries" :modelValue="projectCountry"
                            :multiple="false" @update:modelValue="projectCountry = $event" class="notranslate">
                        </VueMultiselectCustom>
                        <div v-if="projectCountryErrorMsg" class="validation-msg">
                            {{ projectCountryErrorMsg }}
                        </div>
                    </div>
                </div>

                <!------------------------------------------- Deal Details  --------------------------------------------->
                <div class="row g-3 listing-form mt-3">
                    <h5 class="mt-5">Deal Details</h5>
                    <div class="col-md-4">
                        <label class="form-label">Preferred Deal Type</label>
                        <VueMultiselectCustomWithLabel placeholder="Select" :options="all_deals"
                            :modelValue="projectDealType" :multiple="false" @update:modelValue="projectDealType = $event"
                            class="notranslate">
                        </VueMultiselectCustomWithLabel>
                        <div v-if="projectDealTypeErrorMsg" class="validation-msg">
                            {{ projectDealTypeErrorMsg }}
                        </div>
                    </div>
                </div>

                <!-- Deal values of --| Joint-Venture, Option |-->
                <div v-if="projectDealType?.type === 'Joint-Venture' || projectDealType?.type === 'Option'">

                    <div class="row g-3 listing-form" v-for="item, index in dealValuesArray">

                        <div class="col-md-3" v-for="value, name, index in item">
                            <label class="form-label">{{ item.label }}</label>
                            <input type="text" class="form-control dealValueInputs" :value="value" readonly />
                        </div>
                        <div class="col-md-3 d-flex align-items-end">
                            <button type="button" class="btn custom-danger-btn-outline w-100"
                                @click="deleteDealValus(index)"> Remove </button>
                        </div>


                    </div>

                    <div class="row g-3 listing-form mt-3">
                        <div class="col-md-3" v-for="item, index in projectDealType?.values">
                            <label class="form-label">{{ item.label }}</label>
                            <input :id="item.field" type="text" class="form-control dealValueInputs" placeholder="" />
                        </div>
                        <div class="col-md-3 d-flex align-items-end">
                            <button type="button" class="btn custom-primary-btn-outline w-100" @click="addDealValus"> Add
                            </button>
                        </div>

                    </div>
                </div>

                <!-- Deal values of --| Sale, Lease, Investment |-->
                <div v-if="projectDealType?.type != 'Joint-Venture' && projectDealType?.type != 'Option'"
                    class="row g-3 listing-form mt-3">
                    <div class="col-md-3" v-for="item in projectDealType?.values">
                        <label class="form-label">{{ item.label }}</label>
                        <input :id="item.field" type="text" class="form-control dealValueInputs" />
                    </div>

                </div>

                <!-------------------------------------------   project IMages   ---------------------------------------->
                <div class="row g-3 listing-form mt-3">
                    <h5 class="mt-5">Project Images</h5>

                    <ProjectImageUploader ref="imageUploaderRef" :csrf_token="csrf_token" :remoteBaseUrl="baseImageUrl"
                        :uploadedImages="listingObject?.images_s3 ?? []">
                        <template #trigger="triggerProps" class="row g-3 listing-form mt-3">
                            <div class="col-md-10">
                                <div class="text-image">
                                    <p>
                                        File types :.PNG, .JPG, .JPEG, .gif <span v-if="user" class="required-star">*</span>
                                    </p>
                                    <p>Max Size : 5MB</p>
                                </div>
                            </div>
                            <button @click="triggerProps.showUploadModal" type="button"
                                class="col-md-2 btn custom-primary-btn-outline">
                                Upload
                            </button>
                        </template>

                        <template #default="slotProps">
                            <input v-for="file in slotProps.uploadedImagesLocal" :key="file.id" :value="file.img"
                                :modelValue="file.img" id="geo_listing_images_s3dd" type="hidden" />

                            <input v-for="file in slotProps.newlyUploadedFiles" :key="file.id" :value="file.img"
                                :modelValue="file.img" type="hidden" id="geo_listing_images_s3ss" />

                            <!--  <input
                                            :modelValue="UpolodImagesUpadte(slotProps.newlyUploadedFiles, slotProps.uploadedImagesLocal)"
                                            type="hidden" id="geo_listing_images_s3ss" /> -->
                        </template>
                    </ProjectImageUploader>

                    <div v-if="imageValidationMessage" class="validation-msg">
                        {{ imageValidationMessage }}
                    </div>
                </div>

                <!------------------------------------     Project Videos    ---------------------------------------->
                <div class="row g-3 listing-form mt-3">
                    <h5 class="mt-5">Project Videos</h5>
                    <label class="form-label">Video URL</label>

                    <div class="m-0" v-for="(item, index) in addedProjectVideos">
                        <div class="row mt-3" :key="index">
                            <div class="col-md-10">
                                <input type="text" class="form-control" :value="item" readonly />
                            </div>
                            <div class="col-md-2 d-flex align-items-end">
                                <button type="submit" class="btn custom-danger-btn-outline w-100"
                                    @click="removeProjectVideoLink(index)">
                                    Remove
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="m-0" v-if="addedProjectVideos.length < 5">
                        <div class="row mt-3">
                            <div class="col-md-10">
                                <input type="text" class="form-control" v-model="projectVideo" />
                            </div>
                            <div class="col-md-2 d-flex align-items-end">
                                <button type="submit" class="btn custom-primary-btn-outline w-100"
                                    @click="addProjectVideoLink">
                                    Add
                                </button>
                            </div>
                            <div class="col-md-2" v-if="projectVideoErrorMsg">
                                <div class="validation-msg">
                                    {{ projectVideoErrorMsg }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!------------------------------------Project Documents  ---------------------------------------->
                <div class="row g-3 listing-form mt-3">
                    <h5 class="mt-5">Project Documents</h5>
                    <uploadedDocumentLocal ref="documnetUploaderRef" :csrf_token="csrf_token" :remoteBaseUrl="baseImageUrl"
                        :uploadedImages="listingObject?.attachement_s3 ?? []">

                        <template #trigger="triggerProps" class="row g-3 listing-form mt-3">
                            <div class="col-md-10">
                                <div class="text-image">
                                    <p>
                                        File types :.PNG, .JPG, .PDF, .DOC, .DOCX <span v-if="user" class="required-star">*</span>
                                    </p>
                                    <p>Max Size : 15MB</p>
                                </div>
                            </div>
                            <button @click="triggerProps.showUploadModal" type="button"
                                class="col-md-2 btn custom-primary-btn-outline">
                                Upload
                            </button>
                        </template>

                        <template #default="slotProps">
                            <input v-for="file in slotProps.uploadedDocumentLocal" :key="file.id" :value="file.img"
                                :modelValue="file.img" id="geo_listing_images_attachement_s3" type="hidden" />

                            <input v-for="file in slotProps.newlyUploadedDocument" :key="file.id" :value="file.img"
                                :modelValue="file.img" type="hidden" id="geo_listing_attachement_s3" />

                        </template>
                    </uploadedDocumentLocal>
                </div>

                <!---------------------------------------    Project Locations   ------------------------------------------->
                <div class="row g-3 listing-form mt-3">
                    <h5 class="mt-5">Project Location</h5>

                    <div class="col-md-4">
                        <label class="form-label">Country <span v-if="user" class="required-star">*</span></label>
                        <input type="text" class="form-control" v-model="plCountry" />
                        <div v-if="true" class="validation-msg">
                            {{ plCountryErrorMsg }}
                        </div>
                    </div>

                    <div class="col-md-4">
                        <label class="form-label">Region <span v-if="user" class="required-star">*</span></label>
                        <input type="text" class="form-control" v-model="plRegion" />
                        <div v-if="plRegionErrorMsg" class="validation-msg">
                            {{ plRegionErrorMsg }}
                        </div>
                    </div>

                    <div class="col-md-4">
                        <label class="form-label">City <span v-if="user" class="required-star">*</span></label>
                        <input type="text" class="form-control" v-model="plCity" />
                        <div v-if="plCityErrorMsg" class="validation-msg">
                            {{ plCityErrorMsg }}
                        </div>
                    </div>

                    <div class="col-md-4">
                        <label class="form-label">Latitude <span v-if="user" class="required-star">*</span></label>
                        <input type="text" class="form-control" v-model="plLatitude" @keypress="NumbersOnly" />
                        <div v-if="plLatitudeErrorMsg" class="validation-msg">
                            {{ plLatitudeErrorMsg }}
                        </div>
                    </div>

                    <div class="col-md-4">
                        <label class="form-label">Longitude <span v-if="user" class="required-star">*</span></label>
                        <input type="text" class="form-control" v-model="plLongitude" @keypress="NumbersOnly" />
                        <div v-if="plLongitudeErrorMsg" class="validation-msg">
                            {{ plLongitudeErrorMsg }}
                        </div>
                    </div>
                </div>

                <!----------------------------------------------- Button section  ------------------------------------------->

                <div class="row g-3 listing-form mt-4 pb-5 d-flex justify-content-end align-items-center">
                    <div class="col-md-8">
                        <a class="text-primary" @click="goToBack" style="cursor: pointer">Back</a>
                    </div>
                    <div class="col-md-2">
                        <button type="submit" class="btn custom-secondary-btn-outline w-100" @click="projectCancelGoBack">
                            Cancel
                        </button>
                    </div>
                    <div class="col-md-2" v-if="user">
                        <button type="button" class="btn custom-primary-btn w-100" @click="submitListing" id="submitbutton"
                            name="submitbutton">
                            {{ is_edit ? 'Update Listing' : 'Create Listing' }}
                        </button>
                    </div>
                    <div class="col-md-2" v-else>
                        <button type="button" class="btn custom-primary-btn w-100" id="submitbutton" name="submitbutton"
                            @click="showListingModal = !showListingModal">
                            {{ is_edit ? 'Update Listing' : 'Create Listing' }}

                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <BsModal :show="showListingModal" :modal_name="'Listing'">
        <template #modalHeader>
            <h5 class="modal-title" id="exampleModalLabel">Register or Login to Continue!</h5>
            <button type="button" class="btn-close" aria-label="Close" @click="showListingModal = false"></button>
        </template>
        <template #modalBody>
            <div>
                Dear User,
                <p class="mb-0">Please Register or Login to continue using SearchSpace AI and avail the many benefits it
                    entails.</p>

            </div>
        </template>
        <template #modalFooter>
            <button type="submit" class="btn apply-btn custom-primary-btn w-100"
                @click="showListingModal = false; submitListing()">OK</button>
        </template>

    </BsModal>

    <ScreenLoader :show="showLoader" message="Processing, please wait..." />
</template>

<style scoped lang="scss">
.custom-primary-btn {
  --bs-btn-disabled-bg: #3882ff;
  padding: 5px;
}
</style>