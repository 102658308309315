
import { useQuery } from "vue-query";
import { reactive } from 'vue'
import axios from 'axios'

const fetchData = async (symbol) => {
  console.log('FETCHINGGGGGGGGGG', symbol)
  const encodedString =  btoa(symbol);
    const response = await axios.get(
      // `https://query1.finance.yahoo.com/v7/finance/quote?symbols=${symbol}`
      `/api/v1/stockinfo/${encodedString}`
    );

    console.log('RES.......', response)
    return response.data;
};

export default function useGetStockInfo(symbol, fetch) {
    // const queryKey = reactive(["property", id ]);

    const queryKey = ["symbol", symbol ];
    return useQuery(
        queryKey,
        () => fetchData(symbol),
        {
          enabled: fetch,
          retry: false,
          refetchOnWindowFocus: false,
          //   placeholderData: [],
            staleTime: Infinity,
          onError: (error) => {
            // store.commit("errorsModal/openModal", error.response.data.errors);
            console.log('Error....', error)
          },
        }
    );
}