// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

// import '../packs/inertia'
// import '../frontend/assets/css/style.css'
import axios from 'axios'
// import Vue from 'vue'
// import { createApp } from 'vue'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import { VueQueryPlugin } from "vue-query";
import PrimeVue from 'primevue/config';
import  VueGoogleMaps from '@fawmi/vue-google-maps'
import ToastService from 'primevue/toastservice';
import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import Layout from '../frontend/components/layout/Layout.vue'
import { Head } from '@inertiajs/vue3'

// import { app, plugin } from '@inertiajs/inertia-vue'
// import { InertiaProgress } from '@inertiajs/progress'
// import '@packs/assets/css/style.css'

// import '../../assets/stylesheets/application.tailwind.css'
// import './application.css'
// TODO: import './application.css' and import '../frontend/assets/css/style.css' added in layout
document.addEventListener('DOMContentLoaded', () => {
  const csrfToken = document.querySelector('meta[name=csrf-token]').content
  axios.defaults.headers.common['X-CSRF-Token'] = csrfToken


const pages = import.meta.globEagerDefault('../frontend/Pages/**/*.vue');

createInertiaApp({
  resolve: (name) => {
    const component = pages[`../frontend/Pages/${name}.vue`];
    if (!component)
      throw new Error(
        `Unknown page ${name}. Is it located under Pages with a .vue extension?`,
      );

      if(component.layout === undefined){
        component.layout = name.startsWith('Public/') ? undefined : Layout
      }
    
    return component;
  },
  title: (title) => (title ? `${title} | SearchSpace AI` : 'SearchSpace AI'),

  setup({ el, App, props, plugin }) {
    const vueApp = createApp({
      render: () => h(App, props),
    });
    // vueApp.config.globalProperties.$routes = Routes;
    vueApp.use(VueGoogleMaps, {
      load: {
        key: 'AIzaSyCyTktXbFDcMvWQQKUJM9x-BJnPO5LFz20',
        libraries: ["places", "geometry", "visualization"]
      },
    });
    vueApp.component('InertiaHead', Head)
    vueApp.use(VueQueryPlugin);
    vueApp.use(PrimeVue);
    vueApp.use(ToastService);
    vueApp.use(plugin).mount(el);
  },
});
})

