<script setup>
import { api as viewerApi } from "v-viewer"
// import 'viewerjs/dist/viewer.css'

const props = defineProps({
    images: {
        type: Array,
        required: true
    }
})

const preview = (index) => {
    console.log('Viewing new.....', index)

    viewerApi({
        images: props.images,
        options: {
            initialViewIndex: index
        },
    })
}

</script>

<template>
    <slot :preview="preview"> </slot>
</template>