<script setup>
import Paginator from "primevue/paginator";
import { computed, ref, watch, onMounted } from "vue";
import _ from 'lodash'
import useGetPressReleases from "../../components/hooks/useGetPressReleases";
import { router, usePage } from '@inertiajs/vue3'
import PdfModal from "./../../components/modals/PdfModal.vue";
import VueMultiselectCustom from '../../components/dropdownSelector/VueMultiselectCustom.vue'
import BsModal from '../../components/BsModal.vue'
import ScreenLoader from '../../components/ScreenLoader.vue'
import AnnouncementComponent from '../../components/advertisements/AnnouncementComponent.vue'
import headComponent from "../../components/inertiaHead/head.vue"

const props = defineProps({
    request_href: {
        type: String,
    },
    current_user: {
        type: Object,
        default: "",
    },
    baseImageUrl: {
        type: String,
    },
    is_authenticated: {
        type: Boolean,
        default: false,
    },
    recordsPerPage: {
        type: Number,
        default: 20,
    },
    user_details: {
        type: Object,
        default: "",
    },
    all_commodities: {
        type: Array,
        default: []
    },
    all_countries: {
        type: Array,
        default: []
    },
    all_companies: {
        type: Array,
        default: []
    },
    user: {
        type: Object
    },
    all_internal_comapnies_with_slug: {
        type: Array,
        default: []
    },
    provider: {
        type: String,
        default: undefined
    },
    reference: {
        type: String,
        default: ""
    },
    total_count: {
        type: Number,
        default: 0
    }
});

const currentUrl = computed(() => {
    return new URL(props.request_href);
})

const newsReleaseUrl = new URLSearchParams(currentUrl.value.search);
const pressReleasId = newsReleaseUrl.get("id");
const selectedCompany = newsReleaseUrl.get('companyName')
const selectedProvider = newsReleaseUrl.get('providerName')
const selectedStartDate = newsReleaseUrl.get('startDate')
const page = ref(1);
const filterSelectedCommpany = ref(selectedCompany)
const filterSelectedProviders = ref(selectedProvider)
const filterSelectedCommodities = ref(null)
const filterSelectedCountries = ref(null)
const filterSelectedStartDate = ref(selectedStartDate)
const filterSelectedEndDate = ref(selectedStartDate)
const filterKeyWord = ref('')
const selectedIdLocal = ref(pressReleasId)
const recordsPerPageCount = ref(20)
const showFilter = ref(false)

const companyNames = computed(() => {
    return _.uniq(_.sortBy(props.all_companies.map(company => company.company_name), (o) => o.toLowerCase()))
})

const all_providers = ["Accesswire", "GlobeNewswire", "PRNewswire", "Newsfilecorp"];

const cleanFilterOptions = () => {
    document.location = "/press-releases"
}
const params = computed(() => {
    return {
        companyName: filterSelectedCommpany.value || undefined,
        providerName: filterSelectedProviders.value || props.provider,
        commoditiesName: filterSelectedCommodities.value || undefined,
        countryName: filterSelectedCountries.value || undefined,
        keyword: filterKeyWord.value || undefined,
        startDate: filterSelectedStartDate.value || undefined,
        endDate: filterSelectedEndDate.value || undefined,
        id: selectedIdLocal.value || undefined,
        page: page.value,
        recordsPerPage: recordsPerPageCount.value,
    };
});
const filters = computed(() =>
    filterSelectedCommpany.value,
    filterSelectedProviders.value,
    filterSelectedCommodities.value,
    filterSelectedCountries.value,
    filterKeyWord.value,
    filterSelectedStartDate.value,
    filterSelectedEndDate.value,
    selectedIdLocal.value,
    page.value,
    recordsPerPageCount.value,
)

watch(filters, () => {
    page.value = 1
}, { deep: true })

const offSet = computed(() => {
    return page.value === 1 ? 0 : page.value * 20 - 1;
});
const s3BaseUrl = computed(() => {
    return props.baseImageUrl;
});
const {
    data: pressReleaseData,
    isLoading: pressReleaseLoading,
    isSuccess: pressReleaseSuccess,
    refetch
} = useGetPressReleases(params, currentUrl.value.origin);

if (typeof window === 'undefined') {
    await refetch.value()
}


onMounted(() => {
    try {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let datetime_str = new Date().toLocaleString("en-US", { timeZone: "" + timezone + "" });
        const current = new Date(datetime_str);
        const date = `${current.getFullYear()}-${((current.getMonth() + 1) > 9 ? '' : '0') + (current.getMonth() + 1)}-${(current.getDate() > 9 ? '' : '0') + current.getDate()}`;
        document.cookie = "currentDate=" + date + ";";
    }
    catch (e) {
        // TODO: Jeashan to check
        console.log('Error', e)
    }

    filterSelectedProviders.value = providerLabel(props.provider);
});

const vistCompanyProfile = (name) => {
    const company_slug = computed(() => {
        const company = props.all_internal_comapnies_with_slug.find(obj => obj.company_name === name);
        return company ? company.slug : null;
    });
    if (company_slug.value != null) {

        window.open(`/company/${company_slug.value}`, '_blank')
    }
    else {
        alert("Company profile not found.")

    }
}

const providerLabel = (name) => {
    if (name != null) {
        const providersList = all_providers.map(provider => provider.toLocaleLowerCase())
        const index = providersList.indexOf(name.toLowerCase());
        return all_providers[index];
    } else {
        return '';
    }
}

// <-- To be deleted -->
// const getFirstPointValue = (summeryArray) => {
//     if (summeryArray.length > 0) {
//         const firstData = summeryArray[0];
//         let firstPoint = firstData.summary_points.split('\n-').filter(p => p.trim() !== '')[0];
//         firstPoint = firstPoint.replace(/^-\s*/, '');
//         return firstPoint || '';
//     }
//     return '';
// }
// const isExpanded = ref([]);

// const toggleCollapse = (index) => {
//     isExpanded.value[index] = !isExpanded.value[index];
// };

</script>

<template>
    <headComponent
        :description="'Stay updated with mining industry and mining projects. Discover press releases on gold, silver, copper, junior mining, and more mining investment projects.'"
        :title="'Latest Mining News'">
    </headComponent>
    <!-- News releases banner starts -->
    <div class="container-fluid news-releases-banner-img d-flex justify-content-center align-items-center flex-column px-3">
        <h1 class="mb-3">Press Releases | Latest Mining News Across a Range of Minerals Including Gold, Platinum, Diamonds,
            Copper and Ferrous Metals</h1>
        <h3 v-if="pressReleaseSuccess">
            <template v-if="pressReleaseData.pagination.count == 1">
                {{ pressReleaseData.pagination.count }} News Release
            </template>
            <template v-else>
                {{ props.total_count }} News Releases
            </template>
        </h3>
    </div>
    <!-- News releases banner ends -->
    <a href="#" class="top-to-scroll">
        <i class="fa fa-chevron-up" aria-hidden="true"></i>
    </a>
    <!-- News releases toggle section -->
    <div class="container  tw-left-0 tw-right-0 tw-z-[1] tw-pointer-events-none sm:tw-flex" style="max-width: 1500px;">
        <div id="announcement-dt"
            class="tw-pointer-events-auto tw-w-[1358px] tw-mr-4 tw-mt-2 tw-mb-1 tw-hidden sm:tw-block">
            <AnnouncementComponent type="desktop" :view="'NewsReleaseListView'" :user="user" />
        </div>
    </div>
    <div id="announcement-mobile" class="sm:tw-hidden  tw-left-0 tw-w-full tw-z-10 tw-p-3" style="height:16%">
        <AnnouncementComponent type="mobile" :view="'NewsReleaseListView'" :user="user" />
    </div>
    <div
        class="container px-sm-0 pt-4 news-releases d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center">
        <div class="d-flex align-items-center">
            <h4 class="mb-2 mb-sm-0 me-2">News Releases</h4>
            <div v-if="is_authenticated">
                <a class="btn subscribe-btn" v-if="user_details.news_letters === false"
                    :href="'/press-releases-subscription'">

                    <img src="../../assets/img/notification.png" alt="News Releases Subscribe" />
                    Subscribe
                </a>
            </div>
            <div v-if="!is_authenticated">
                <a class="btn subscribe-btn"
                    :href="'https://cdn.forms-content.sg-form.com/22e82734-a8e9-11ed-a3be-064bee5bcfa5'" target="_blank">
                    <img src="../../assets/img/notification.png" alt="News Releases Subscribe" />
                    Subscribe
                </a>
            </div>
        </div>
        <div class="d-flex">
            <div class="d-flex list-map">
                <a type="button" class="btn list active p-2" :href="'/view-press-releases'">
                    <i class="fa fa-th me-2"></i>List
                </a>
                <a type="button" class="btn map p-2" :href="'/map_view_listings'">
                    <i class="fa fa-map me-2"></i>Map
                </a>
            </div>
            <button type="button" class="btn filter-section" @click="showFilter = !showFilter"><i class="fa fa-sliders me-2"
                    aria-hidden="true"></i>Filters</button>

        </div>
    </div>

    <!-- News releases toggle section ends -->

    <!-- Tab panes -->
    <div class="container tab-content px-sm-0 pt-3 pb-3">
        <h5 v-if="filterSelectedProviders || props.provider"><span>By: {{ providerLabel(filterSelectedProviders || props.provider) }}</span></h5>

        <!-- News Releases starts -->
        <div id="news-releases-list" class="container tab-pane active news-releases-list p-0" v-if="!pressReleaseLoading">

            <div class="row mt-4">
                <div class="col-md-8 col-lg-9">
                    <div v-if="pressReleaseSuccess" v-for="(valueRow, index) in pressReleaseData.data" :key="JSON.stringify(index)">
                        <div
                            class="d-flex flex-column flex-sm-row justify-content-start justify-content-sm-between align-items-start align-items-sm-center">
                            <div class="d-flex flex-column w-100">
                                <div class="d-flex flex-column flex-sm-row date-company">
                                    <p>
                                        Date<span>{{ valueRow.publish_date }}</span>
                                    </p>
                                    <p>
                                        Company<a @click="vistCompanyProfile(valueRow.company_name)" style="cursor: pointer;"><span>{{ valueRow.company_name }}</span></a>
                                    </p>
                                    <p>
                                        PR Provider <a :href="`/press-releases/${valueRow.provider}`"><span>{{  providerLabel(valueRow.provider) }}</span></a>
                                    </p>
                                </div>
                                <h6 class="mb-1 mt-1">
                                    <a v-if="valueRow.body_len>0" :href="`/press-releases/${valueRow.provider}/${valueRow.slug || valueRow._id.$oid}?ref=${props.reference}`">{{ valueRow.title || "No title" }}</a>
                                    <!-- Backward compatibility to view PDF Popup, if HTML is not available -->
                                    <PdfModal v-else :link="`${s3BaseUrl}/${valueRow.link_s3}`" :PressReleaseOpen="valueRow._id.$oid === pressReleasId ? true : false">
                                        <a @click.prevent="" href="#" target="_blank">{{ valueRow.title || "No title" }}</a>
                                    </PdfModal>
                                    
                                </h6>
                                <div class="expand-for-news" v-if="valueRow.summery.length > 0">
                                    <!--To be deleted -->
                                    <!-- <div class="card-header">
                                        <ul class="p-0 mb-0 d-flex justify-content-between align-items-center">
                                            <li class="header-collapse" style="font-size: 12px;margin-left: 10px">{{ getFirstPointValue(valueRow.summery) }}</li>
                                            <i class="fa fa-chevron-down" :class="{ 'rotate-icon': isExpanded[index] }"
                                                aria-hidden="true"  v-if="valueRow.summery.length > 0 && valueRow.summery[0].summary_points.split('\n-').filter(p => p.trim() !== '').length > 1"
                                                @click="toggleCollapse(index)" style="cursor: pointer"></i>
                                        </ul>
                                    </div> -->
                                    <!-- <div class="collapse" :id="'collapseExample' + index" :class="{ 'show': isExpanded[index] }"> -->
                                        <div class="card card-body border border-0">
                                            <div v-for="(data, dataIndex) in valueRow.summery" :key="dataIndex" style="margin-bottom: -15px;">
                                                <div v-if="data.summary_points">
                                                    <ul class="p-0">
                                                        <div v-for="(point, pointIndex) in data.summary_points.split('\n-').filter(p => p.trim() !== '')"
                                                            :key="pointIndex">

                                                            <!-- <div v-if="dataIndex !== 0 || pointIndex !== 0"> -->
                                                                <li class="pb-1">
                                                                    {{ point.replace(/^-\s*/, '') }}
                                                                </li>
                                                            <!-- </div> -->
                                                        </div>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    <!-- </div> -->
                                </div>
                            </div>
                        </div>
                        <hr class="my-4" />
                    </div>
                </div>
                <div class="col-md-4 col-lg-3">
                    <div id="pr-news-ad-1" class="my-2"><a href="https://jlflabs.com/mint-hrm-case-study.html" target="_blank"><img class="img-responsive" width="100%" src="../../assets/img/ads/mint_hrm.jpg" /></a></div>
                    <div id="pr-news-ad-2" class="my-2"><a href="https://jlflabs.com/index.html" target="_blank"><img class="img-responsive" width="100%" src="../../assets/img/ads/JLF.jpg" /></a></div>
                </div>
            </div>

            <div class="d-flex justify-content-end my-4">
                <hr style="border-color: #ebebeb; margin: 15px 0px" />
                <template v-if="pressReleaseSuccess">
                    <Paginator :rows="recordsPerPage" :totalRecords="pressReleaseData.pagination.count"
                        @page="(e) => (page = e.page + 1)" :first="offSet"
                        template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink  CurrentPageReport JumpToPageInput">
                    </Paginator>
                </template>
            </div>
        </div>
        <div v-else class="container tab-pane active news-releases-list p-0">
            <ScreenLoader :show="true" />
        </div>
        <!-- News Releases ends -->

        <!-- Map View starts -->
        <div id="news-releases-list-map" class="container tab-pane fade p-0">
            <br />
        </div>
        <!-- Map View Ends -->
    </div>
    <!-- Tab panes ends -->

    <!-- Filter Modal starts -->
    <BsModal :show="showFilter">
        <template #modalHeader>
            <h5 class="modal-title" id="exampleModalLabel">Filters</h5>
            <button type="button" class="btn-close" aria-label="Close" @click="showFilter = false"></button>
        </template>
        <template #modalBody>
            <div class="row g-2 g-2 home-filter-modal">
                <div class="col-md">
                    <!-- <div class="form-floating">
                                <VueMultiselectCustom placeholder="All Countries" :options="all_countries" -->
                    <div class="">
                        <label class="form-label">PR Provider</label>
                        <VueMultiselectCustom placeholder="Select" :options="all_providers" 
                            :modelValue="filterSelectedProviders" :multiple="true" :max="1"
                            @select="$event => filterSelectedProviders = $event"
                            @remove="$event => filterSelectedProviders = null" class="notranslate">
                        </VueMultiselectCustom>
                    </div>
                </div>
            </div>

            <div class="row g-2 mt-0 mt-md-3 home-filter-modal">
                <div class="col-md">
                    <div class="">
                        <label class="form-label">Title Keyword</label>
                        <input type="text" class="form-control" placeholder=""
                            @input="$event => filterKeyWord = $event.target.value" />
                    </div>
                    <!-- <div class="form-floating">
                                <input type="text" class="form-control" id="floatingInputGrid" placeholder="Title Keyword"
                                    @input="$event=>filterKeyWord= $event.target.value"/>
                                <label for="floatingInputGrid">Title Keyword</label>
                            </div> -->
                </div>
            </div>
            <div class="row g-2 mt-0 mt-md-3 home-filter-modal">
                <div class="col-md">
                    <div class="">
                        <label class="form-label">Start Date</label>
                        <input type="date" class="form-control" placeholder=""
                            @input="$event => filterSelectedStartDate = $event.target.value" />
                    </div>
                    <!-- <div class="form-floating">
                                <input type="date" class="form-control" id="floatingInputGrid" placeholder="Start Date"
                                @input="$event=>filterSelectedStartDate=$event.target.value" />
                                <label for="floatingInputGrid">Start Date</label>
                            </div> -->
                </div>
                <div class="col-md">
                    <div class="">
                        <label class="form-label">End Date</label>
                        <input type="date" class="form-control" placeholder=""
                            @input="$event => filterSelectedEndDate = $event.target.value" />
                    </div>
                    <!-- <div class="form-floating">
                                <input type="date" class="form-control" id="floatingInputGrid" placeholder="End Date"
                                @input="$event=>filterSelectedEndDate=$event.target.value" />
                                <label for="floatingInputGrid">End Date</label>
                            </div> -->
                </div>
            </div>
            <div class="row g-2 mt-0 mt-md-3">
                <div class="col-md">
                    <!-- <div class="form-floating">
                                <VueMultiselectCustom placeholder="All Countries" :options="all_countries" -->
                    <div class="">
                        <label class="form-label">All Countries</label>
                        <VueMultiselectCustom placeholder="Select" :options="all_countries"
                            :modelValue="filterSelectedCountries" :multiple="true" :max="1"
                            @select="$event => filterSelectedCountries = $event"
                            @remove="$event => filterSelectedCountries = null" class="notranslate">
                        </VueMultiselectCustom>
                    </div>
                </div>
                <div class="col-md">
                    <!-- <div class="form-floating">
                                <VueMultiselectCustom placeholder="All Commodities" :options="all_commodities" -->
                    <div class="">
                        <label class="form-label">All Commodities</label>
                        <VueMultiselectCustom placeholder="Select" :options="all_commodities"
                            :modelValue="filterSelectedCommodities" :multiple="true" :max="1"
                            @select="$event => filterSelectedCommodities = $event"
                            @remove="$event => filterSelectedCommodities = null" class="notranslate">
                        </VueMultiselectCustom>
                    </div>
                </div>
            </div>
            <div class="row g-2 mt-0 mt-md-3">
                <div class="col-md">
                    <!-- <div class="form-floating">
                                <VueMultiselectCustom placeholder="All Companies" :options="companyNames" -->
                    <div class="">
                        <label class="form-label">All Companies</label>
                        <VueMultiselectCustom placeholder="Select" :options="companyNames"
                            :modelValue="filterSelectedCommpany" :multiple="true" :max="1"
                            @select="$event => filterSelectedCommpany = $event"
                            @remove="$event => filterSelectedCommpany = null" class="notranslate">
                        </VueMultiselectCustom>

                    </div>
                </div>
            </div>
        </template>

        <template #modalFooter>
            <button type="button" class="btn clear-btn" @click="showFilter = false; cleanFilterOptions()">Clear</button>
            <button class="btn apply-btn custom-primary-btn" type="submit" @click="showFilter = false">Apply</button>
        </template>
    </BsModal>
</template>
