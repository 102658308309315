<script setup>
import { ref, computed } from 'vue'
import { router } from '@inertiajs/vue3'
import _ from 'lodash'
import VueMultiselectCustom from '../dropdownSelector/VueMultiselectCustom.vue'
import { onClickOutside } from '@vueuse/core'
import socialMediaBar from '../socialMediaBar/socialMediaBar.vue'

const props = defineProps({

  all_commodities: {
    type: Array,
    default: []
  },
  all_countries: {
    type: Array,
    default: []
  },
  user: {
    type: Object
  },
  isTopSearchShow: {
    type: Boolean,
    default: true
  },
  isCompanyFilter: {
    type: Boolean,
    default: false
  },
  all_companies: {
      type: Array,
      default: []
  },
  all_internal_comapnies_with_slug: {
    type: Array,
    default: []
  },
  selectedTopSearchCommoditiesLocal: {
    type: String,
    default: ''
  },
  selectedTopSearchKeywordLocal: {
    type: String,
    default: ''
  },
  selectedTopSearchCountryLocal: {
    type: String,
    default: ''
  },
  selected_company_name: {
    type: String,
    default: null
  },
  is_commodity_slug: {
    type: Boolean,
    default: false
  },
  is_country_slug: {
    type: Boolean,
    default: false
  }

})

const showLogin = () => {
  // router.remember('window.location.href','closeLoginBack')
  router.visit('/signin', { data: { backUrl: window.location.href } })
}

const showRegister = () => {
  // router.remember('window.location.href','closeLoginBack')
  router.visit('/signup', { data: { backUrl: window.location.href } })
}

const is_authenticated = computed(() => { return props.user != null })

const searchKeyWord = ref(props.selectedTopSearchKeywordLocal)
const selectedCommoditiesLocal = ref(props.selectedTopSearchCommoditiesLocal)
const selectedInternalCompanyLocal = ref(props.selected_company_name)
const selectedCountriesLocal = ref(props.selectedTopSearchCountryLocal)

const clickglobelSearch = () => {
  if (props.isCompanyFilter == true) {
    if (selectedInternalCompanyLocal.value != null) {
        router.visit(`/company/${comapny_slug.value}`, {
        method: 'get',
        replace: true,
        preserveScroll: true,
      }
      )
    }
  }
  else {
    if (searchKeyWord.value != "" || selectedCommoditiesLocal.value != "" || selectedCountriesLocal.value != "") {
      let searshParam = {}
      Object.assign(searshParam, searchKeyWord.value ? { keyword: searchKeyWord.value } : null);
      Object.assign(searshParam, selectedCommoditiesLocal.value ? { commodity: selectedCommoditiesLocal.value } : null);
      Object.assign(searshParam, selectedCountriesLocal.value ? { country: selectedCountriesLocal.value } : null);

      router.visit('/projects', {
        method: 'get',
        data: { ...searshParam },
        replace: true,
        preserveScroll: true

      })

    }
  }

}
const comapny_slug = computed(() => {
  const company = props.all_internal_comapnies_with_slug.find(obj => obj.company_name === selectedInternalCompanyLocal.value);
  return company ? company.slug : null;
});

const internalCompanyNames = computed(() => {
  return _.uniq(_.sortBy(props.all_internal_comapnies_with_slug.map(company => company.company_name), (o) => o.toLowerCase()))
})

const companyNames = computed(() => {
  return _.uniq(_.sortBy(props.all_internal_comapnies_with_slug.map(company => company.company_name), (o) => o.toLowerCase()))
  // const companies = _.uniq(_.sortBy(props.all_internal_comapnies_with_slug.map(company => [company.company_name, company.slug])))
})

const mobileManuShow = ref(false)
const targetx = ref(null)

const toggleMobileManu = () => {
  mobileManuShow.value = !mobileManuShow.value
}

onClickOutside(targetx, (event) => {
  if (event.target.parentNode.parentElement && event.target.parentNode.parentElement.getAttribute('class') == 'company-select' || 
      event.target.parentNode.parentElement && event.target.parentNode.parentElement.getAttribute('aria-owns') == 'listbox-companyDropdown' ) {
    showCompanyDropdown.value = true;
  } else {
    showCompanyDropdown.value = false;
  }

  if (event.target.parentElement.id != 'collapseManu' && 
      event.target.getAttribute('id') !='companies-menu-link' && 
      event.target.parentNode.getAttribute('aria-owns') !='listbox-company-select-dropdown') {
    mobileManuShow.value = false;
  }
})

const showCompanyDropdown = ref()

const toggleCompanyDropdownShow = () => {
  showCompanyDropdown.value = !showCompanyDropdown.value
}

const openSelectedCompanyPage = (selectedOption, id) => {
  const company = props.all_internal_comapnies_with_slug.find(obj => obj.company_name === selectedOption);
  const slug = company ? company.slug : ''
  window.location.href = '/company/' + slug
}

</script>

<!-- Nav bar starts -->
<template>
  <nav class="navbar navbar-expand-lg navbar-light header-nav navbar-mobile-view">
    <div class="container px-sm-0">
      <a id="navbar-logo-top" class="logo" href="/">
        <img src="./SSAI.png" alt="SSAI Mobile Logo"></a>
      <ul class="navbar-nav m-auto search-bar-mobile-ul">
        <li class="nav-item">
        </li>
      </ul>
      <button ref="targetx" @click="toggleMobileManu" class="navbar-toggler" type="button">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div id="collapseManu" :class="{ 'collapse navbar-collapse': true, 'show': mobileManuShow }">
        <ul class="navbar-nav m-auto mb-2 mb-lg-0 search-bar-web-ul" style="padding-left: 20px;">
          <li class="nav-item d-sm-none d-lg-block"></li>
        </ul>
        <div class="d-flex justify-content-end justify-content-lg-center align-items-start align-items-lg-center">
          <ul class="navbar-nav mb-2 mb-lg-0 mt-3 mt-lg-0 d-flex align-items-end align-items-lg-center nav-main-links">
            <li class="nav-item" style="text-align: center;">
              <a class="nav-link px-3" id="companies-menu-link" href="#" @click="toggleCompanyDropdownShow">Companies</a>
              <div class="company-select" v-show="showCompanyDropdown">
              <VueMultiselectCustom id="company-select-dropdown"
                  placeholder="Search for Company Profiles"
                  :options="internalCompanyNames"
                  :modelValue="selectedInternalCompanyLocal" :multiple="false"
                  @select="openSelectedCompanyPage"
                  :showNoResults="false"
                  class="notranslate">
                </VueMultiselectCustom>
            </div>
            </li>
            <li class="nav-item" style="text-align: center;">
              <a class="nav-link px-3" :href="'/people-web'">People</a>
            </li>
            <li class="nav-item" style="text-align: center;">
              <a class="nav-link px-3" :href="'/projects'">Projects</a>
            </li>
            <li class="nav-item" style="text-align: center;">
              <a class="nav-link px-3 ps-lg-0" :href="'/press-releases'">Newsroom</a>
            </li>
            <li class="nav-item" style="text-align: center;">
              <a class="nav-link px-3 ps-lg-0" :href="'/searchspace-ai'"><img alt="AI Analytics"
                  src="../../assets/img/AI-Analytics2.png">AI Dashboard</a>
            </li>
            <template class="d-xs-none d-sm-block">
              <li class="d-md-none d-xl-block" style="width:150px">&nbsp;</li>
              <li class="d-lg-none d-xl-block" style="width:100px">&nbsp;</li>
            </template>
            <li class="nav-item px-3 mt-2">
              <a href="/my-listings/new" class="btn custom-primary-btn small"><i class="fa fa-plus fa-border me-2" aria-hidden="true" style="font-size:0.7em;border-radius:3px;padding:5px"></i>Add Projects</a>
            </li>
            <!-- ======================== Mobile links ================== -->
            <div class="mobile-post-signin" v-if="is_authenticated">
              <!-- <hr class="dropdown-divider"> -->
              <div class="horizontal-seperator"></div>
              <li class="nav-item"><a class="dropdown-item"
                  style="font-weight: bold;padding: 0.25rem 1rem;cursor: none;">My Account</a></li>
              <li class="nav-item"><a class="dropdown-item px-3 py-1" href="/my-account">My Subscription</a></li>
              <li class="nav-item"><a class="dropdown-item px-3 py-1" href="/my-listings">My Listings</a></li>
              <li class="nav-item"><a class="dropdown-item px-3 py-1" href="/press-releases-subscription">My News
                  Preferences</a>
              </li>
              <li class="nav-item"><a class="dropdown-item px-3 py-1" href="/inbox">My Messages</a></li>
              <li class="nav-item" v-if="user.admin == true"><a class="dropdown-item px-3 py-1"
                  href="/press_release/manage_press_release">Manage
                  News Releases</a></li>
              <!-- <hr class="dropdown-divider"> -->
              <div class="horizontal-seperator"></div>
              <li class="nav-item"><a class="dropdown-item px-3" href="/logout">Sign Out</a></li>
            </div>
            
            <div class="mobile-pre-signin" v-else>
              <li class="nav-item"><a class="dropdown-item px-3 py-1 ps-lg-0" href="/login"
                  @click.prevent="showLogin">Sign In</a></li>
              <li class="nav-item"><a class="dropdown-item px-3 py-1 ps-lg-0" href="/signup"
                  @click.prevent="showRegister">Sign Up</a></li>
            </div>
            <!-- ======================== Mobile links ends ================== -->

            <button class="nav-item dropdown ms-lg-3 profile-dd">
              <a :key="is_authenticated" class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                data-bs-toggle="dropdown" aria-expanded="active false">
                <div class="d-flex justify-content-center align-items-center">
                  <i class="fa fa-bars me-2" aria-hidden="true"></i>
                  <i class="fa fa-user-circle" aria-hidden="true"></i>
                </div>
              </a>
              <div v-if="is_authenticated">
                <ul class="dropdown-menu pre-signing-dd" aria-labelledby="navbarDropdown">
                  <li><a style="font-weight: bold;padding: 0.25rem 1rem;cursor: none;">My Account</a></li>
                  <li><a class="dropdown-item" href="/my-account">My Subscription</a></li>
                  <li><a class="dropdown-item" href="/my-listings">My Listings</a></li>
                  <li><a class="dropdown-item" href="/press-releases-subscription">My News Preferences</a></li>
                  <li><a class="dropdown-item" href="/inbox">My Messages</a></li>
                  <li v-if="user.admin == true"><a class="dropdown-item" href="/press_release/manage_press_release">Manage
                      News Releases</a></li>
                  <hr class="dropdown-divider">
                  <li><a class="dropdown-item" href="/logout">Sign Out</a></li>
                </ul>
              </div>
              <div v-else>
                <ul class="dropdown-menu pre-signing-dd" aria-labelledby="navbarDropdown">
                  <li><a class="dropdown-item" href="/login" @click.prevent="showLogin">Sign In</a></li>
                  <li><a class="dropdown-item" href="/signup" @click.prevent="showRegister">Sign Up</a></li>
                </ul>
              </div>
            </button>
          </ul>
        </div>
      </div>



    </div>
  </nav>



  <!-- Nav bar ends -->
  <div class="nav-above-space"></div>

  <!-- Social Media bar -->
  <socialMediaBar />
  <!-- Social Media bar ends -->

</template>

<style>
.commodity-drop-down,
.country-drop-down {
  width: 190px;
  margin-right: -10px;
}
.navbar-nav .nav-link.active {
  color: #7296ac !important;
}
</style>