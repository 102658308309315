<script setup>
import { ref, computed, onMounted} from 'vue'
import { router, Link } from '@inertiajs/vue3'
import BsModal from '../../components/BsModal.vue'
import headComponent from "../../components/inertiaHead/head.vue"
const props = defineProps({

    is_plan_current_paln: {
        type: Boolean

    },
    user: {
        type: Object
    },
    total_active: {
        type: Number,
        defult: 0
    },
    listings: {
        type: Array,
        defult: []
    },
    max_limit_lisitngs: {
        type: Number
    },
    messages: {
        type: String,
        defult: ""
    }


})
const showContactusmodal = ref(false)
const listingsLocal = computed(() => props.listings);
const to_acttive = (id) => {
    router.get(
        '/my-listings/activate?id=' + id,
    )
}
const to_deacttive = (id) => {
    router.get(
        '/my-listings/deactivate?id=' + id,
    )
}
// const go_to_my_account = () => {
//     router.visit(
//         '/my_account',
//     )
//     router.reload
// }
const remove = (id) => {
    router.get('/my-listings/' + id + '/destroy', {
        onBefore: visit => { confirm('Are you sure?') },
    })
}

onMounted(() => {
    if (props.is_plan_current_paln == true) {
        showContactusmodal.value = true
    }

})
const urlSlugCreate = (val) => {
    // replace all special characters | symbols with a space
    let str = val.replace(/[`~!@#$%^&*()_\-+=\[\]{};:'"\\|\/,.<>?\s]/g, ' ').toLowerCase();

    // trim spaces at the start and end of the string
    str = str.replace(/^\s+|\s+$/gm, '');

    // replace space with dash/hyphen
    str = str.replace(/\s+/g, '-');

    return str;
};
const preview = (deal_type, primary_commodity, country, company_name_and_project_name_slug) => {

    const dealTypeSlug = deal_type ? deal_type == "Unknown" ? "other" : urlSlugCreate(deal_type) : "none";
    const commoditiSlug = primary_commodity ? urlSlugCreate(primary_commodity) : "none"
    const countrySlug = country ? urlSlugCreate(country) : "none";

    router.visit(
        '/' + dealTypeSlug + '/' + commoditiSlug + '/' + countrySlug + '/' + company_name_and_project_name_slug,
    )
}

</script>

<template>
    <headComponent :description="'View, edit and manage your listings on SearchSpace AI. Your personalized source for mining, gold stocks, and more.'"
          :title="'My Listings | Personalized Mining Listings and Investments'">
    </headComponent>
    <div class="container my-listings-pg mt-5 pb-3">
        <div
            class="d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center title-section">
            <div class="d-flex flex-column mb-2 mb-sm-0">
                <template v-if="user.plan == 'none' && listingsLocal.length > 0">
                    <div class="mt-2 alert alert-warning" role="alert">
                        <p class="text-center"> You will need to upgrade to a <a href="/my-account"><u>paid plan</u></a> in
                            order to activate your listing. </p>
                    </div>
                </template>
                <h1 class="h5">My Listings | Personalized Mining Listings and Investments </h1>
                <div v-if="user.plan == 'none'">
                    <p>Create, edit and choose which listings to publish.</p>
                </div>
                <div v-else>
                    <p>You are subscribed with the <b>{{ user.plan }}</b> plan.</p>
                </div>
            </div>
            <p id="message">{{ messages }}</p>
            <!-- <div v-if="user.plan == 'none' && listingsLocal.length > 0">
                <p class="text-center mt-2 mb-5">
                    You will need to upgrade to a <a href="/my_account"><u>paid plan</u></a> in order to activate your
                    listing.
                </p>
            </div> -->
            <div class="d-flex">
                <Link class="btn custom-primary-btn py-2 px-4" :href="'/my-listings/new'">Create Listing</Link>
            </div>
        </div>
        <div v-if="listingsLocal.length > 0">
            <div class="d-flex flex-wrap sub-title-section mt-5">
                <h6 class="me-5">My Projects</h6>
                <p>{{ total_active }} / {{ max_limit_lisitngs }} active listings</p>
            </div>
            <div class="horizontal-seperator"></div>
            <div class="my-lisitngs-section" :key="index" v-for="(listing, index) in listingsLocal">
                <div class="d-flex flex-column justify-content-between flex-sm-row align-items-sm-center">
                    <h6>{{ listing.company_name }} - <span>{{ listing.name }}</span></h6>
                    <div class="form-check form-switch ps-0 mt-2 mt-sm-0">
                        <div v-if="user.plan == 'none'">
                            <template v-if="listing.active == false">
                                <a :href="'/my-account'">
                                    <input class="form-check-input me-2 ms-0 mt-0" type="button" role="switch"
                                        id="flexSwitchCheckChecked"></a>
                                <span class="active-deactive">Deactivated</span>
                            </template>
                            <template v-else>
                                <input class="form-check-input me-2 ms-0 mt-0" type="checkbox" role="switch"
                                    id="flexSwitchCheckChecked" checked @click="to_deacttive(listing._id.$oid)"><span
                                    class="active-deactive">Activated</span>
                            </template>

                            <!-- <input class="form-check-input me-2 ms-0 mt-0" type="button" @click="showContactusmodal = !showContactusmodal"><span
                                                 class="active-deactive">Deactivate</span> -->
                        </div>
                        <div v-else>
                            <div v-if="listing.active == false">
                                <div v-if="is_plan_current_paln">
                                    <!-- <button type="button" class="btn btn-outline-success" data-toggle="modal" data-target="#trial_notification">
                                                        Activate @click="showContactusmodal = !showContactusmodal"
                                                </button> -->
                                    <input class="form-check-input me-2 ms-0 mt-0" type="button"
                                        @click="showContactusmodal = !showContactusmodal"><span
                                        class="active-deactive">Deactivated</span>
                                    <!-- <input class="form-check-input " type="checkbox" role="switch" disabled  id="flexSwitchCheckCheckedsdss" > -->
                                </div>
                                <div class="d-flex align-items-center" v-else>
                                    <input class="form-check-input me-2 ms-0 mt-0" type="checkbox" role="switch"
                                        id="flexSwitchCheckChecked" @click="to_acttive(listing._id.$oid)"><span
                                        class="active-deactive">Deactivated</span>
                                </div>
                            </div>
                            <div class="d-flex align-items-center" v-else>
                                <input class="form-check-input me-2 ms-0 mt-0" type="checkbox" role="switch"
                                    id="flexSwitchCheckChecked" checked @click="to_deacttive(listing._id.$oid)"><span
                                    class="active-deactive">Activated</span>
                            </div>
                        </div>

                    </div>
                </div>
                <div
                    class="d-flex align-items-start mt-2 flex-column flex-lg-row align-items-lg-center justify-content-lg-between">
                    <p class="me-lg-2">{{ listing.company_description.slice(0, 200) }}…</p>
                    <div class="d-flex flex-wrap flex-lg-nowrap mt-1 mt-lg-0">
                        <a class="btn custom-primary-btn-outline me-2 mt-1 mt-lg-0"
                            @click="remove(listing._id.$oid)">Remove</a>
                        <Link class="btn custom-primary-btn-outline me-2 mt-1 mt-lg-0"
                            :href="'/my-listings/' + listing._id.$oid + '/edit'">Edit</Link>
                        <Link class="btn custom-primary-btn-outline me-2 mt-1 mt-lg-0"
                            :href="'/my-listings/new?copy_from=' + listing._id.$oid">Duplicate</Link>
                        <a class="btn custom-primary-btn-outline mt-1 mt-lg-0"
                            @click="preview(listing.deal_type, listing.primary_commodity, listing.country, listing.company_name_and_project_name_slug)">Preview</a>
                    </div>
                </div>
                <div class="horizontal-seperator"></div>
            </div>
        </div>
        <div v-else><br>
            <div class="get-started-outer mt-lg-3">
                <div class="get-started-header mb-3">
                    <h5 class="mb-1">Get Started</h5>
                    <p>Welcome to SearchSpace AI.</p>
                </div>
                <div class="">
                    <p>Searchspace AI allows mine operators to easily list their projects to find potential investors and
                        partners.</p>
                    <p class="mt-3">To get started, the first thing you will need to do is create a listing for your mining
                        project.
                        After creating your listing you can choose a plan to activate and promote your listing.</p>
                </div>
            </div>
        </div>
    </div>

    <BsModal :show="showContactusmodal">
        <template #modalHeader>
            <h5 class="modal-title" id="exampleModalLabel">Trial expired!</h5>
            <button type="button" class="btn-close" aria-label="Close" @click="showContactusmodal = false"></button>
        </template>
        <template #modalBody>
            Dear {{ user.name }} ,
            <br>
            <p class="mb-0">Your trial period has expired. Please select a pricing plan to continue using SearchSpace AI and
                avail the many benefits it entails.</p>

        </template>
        <template #modalFooter>
            <a href="/my-account" class="btn btn-primary">OK</a>
        </template>
    </BsModal>

    <a href="#" class="top-to-scroll">
    <i class="fa fa-chevron-up" aria-hidden="true"></i>
</a></template>