<script setup>
import { ref, computed } from "vue";
import ListingCard from "../../components/listingCard/ListingCard.vue";
import YouTubeVideoGallery from "../../../src/components/YouTubeVideoGallery.vue";
import property_conversation from "../../../src/components/conversation/PropertyConversation.vue";
import Gmap from '../../components/map/map.vue';
import slider from "../../components/slider/slider.vue"
import DocumentThumbnail from "../../components/documentThumbnail/DocumentThumbnail.vue";
import AnnouncementComponent from '../../components/advertisements/AnnouncementComponent.vue'
import { Link } from '@inertiajs/vue3'
import headComponent from "../../components/inertiaHead/head.vue"

const props = defineProps({
    request_href: {
        type: String,
    },
    similar_listings: {
        type: Array,
    },
    listings: {
        type: Array,
    },
    images: {
        type: Array,
    },
    current_user: {
        type: String,
        default: ""
    },
    baseImageUrl: {
        type: String,
    },
    is_authenticated:
    {
        type: Boolean,
        default: false
    },
    heat_map_data: {
        type: Array
    },
    related_properties: {
        type: Array,
        default: []
    },
    user: {
        type: Object
    },
    listings_id: {
        type: String
    },
    all_commodities: {
        type: Array,
    },
    all_countries: {
        type: Array
    },
});

const currentUrl = computed(() => {
    return new URL(props.request_href);
})

const selectedTopSearchCommoditiesLocal = ref('')
const selectedTopSearchCountryLocal = ref('')
const selectedTopSearchKeywordLocal = ref('')

const loginUrl = `/signin?backUrl=${currentUrl.value.href}`
const is_all_videos_empty = computed(() => {
    let truecount = []
    props.listings.videos.forEach((value, index) => {
        if (props.listings.videos[index] == "") {
            truecount.push(true)
        }
    });
    return truecount.length == props.listings.videos.length
})

let queryObject = {}
// const urlLocation = window.location
const urlString = currentUrl.value.href // urlLocation.href
queryObject.value = [...new URLSearchParams(urlString.split('?')[1])].reduce((a, [k, v]) => ((a[k] = v), a), {})

selectedTopSearchCommoditiesLocal.value = queryObject.value.commodity
selectedTopSearchKeywordLocal.value = queryObject.value.keyword
selectedTopSearchCountryLocal.value = queryObject.value.country

const urlSlugCreate = (data) => {
    // replace all special characters | symbols with a space
    let str = data.replace(/[`~!@#$%^&*()_\-+=\[\]{};:'"\\|\/,.<>?\s]/g, ' ').toLowerCase();

    // trim spaces at the start and end of the string
    str = str.replace(/^\s+|\s+$/gm, '');

    // replace space with dash/hyphen
    str = str.replace(/\s+/g, '-');

    return str;
};

const dealTypeSlug = props.listings.deal_type ? props.listings.deal_type == "Unknown" ? "other" : urlSlugCreate(props.listings.deal_type) : "none";
const commoditiSlug = props.listings.primary_commodity ? urlSlugCreate(props.listings.primary_commodity) : "none"
const countrySlug = props.listings.country ? urlSlugCreate(props.listings.country) : "none";

const visitProjectPage = (data) => {

    if (!data) {
        window.open(``, '_blank')
    }
    else {
        if (data.includes("https://") || data.includes("http://")) {
            window.location = data
            window.open(data, '_blank')

        } else {
            const newURL = "https://" + data
            window.open(newURL, '_blank')

        }
    }
}
const headTitleCountryName = props.listings.country ? 'in'+ props.listings.country : "";
</script>

<template>
    <headComponent :description="listings.description"
          :title="`${ listings.name },mining ${listings.primary_commodity} & ${listings.secondary_commodity} in ${listings.country}`">
    </headComponent>

    <div class="container  tw-left-0 tw-right-0 tw-z-[1] tw-pointer-events-none sm:tw-flex"
        style="max-width: 1500px;margin-bottom: 15px; margin-top: 1.5rem;">
        <div id="announcement-dt"
            class="tw-pointer-events-auto tw-w-[1358px] tw-mr-4 tw-mt-2 tw-mb-1 tw-hidden sm:tw-block">
            <AnnouncementComponent type="desktop" :view="'InnerListingView'" :user="user" :listings_id="listings_id"
                :companyName="listings.company_name" :listings_company_name_slug="listings.slug"
                :baseImageUrl="baseImageUrl" />
        </div>
    </div>
    <div id="announcement-mobile" class="sm:tw-hidden  tw-left-0 tw-w-full tw-z-10 tw-p-3" style="height:16%;">
        <AnnouncementComponent type="mobile" :view="'InnerListingView'" :user="user" :listings_id="listings_id"
            :companyName="listings.company_name" :listings_company_name_slug="listings.slug" :baseImageUrl="baseImageUrl" />
    </div>

    <div class="container mt-4 px-md-0 addvertiment-banners">
        <h1 class="h5">{{ listings.name }}, mining {{listings.primary_commodity}} <span v-if="listings.secondary_commodity" class="h5">& {{ listings.secondary_commodity }} </span><span v-if="listings.country" class="h5"> in {{ listings.country }}</span></h1>
        <div class="inner-listing-top-section d-flex justify-content-between align-items-end">
            <div class="project-summary d-flex align-items-start align-items-lg-center flex-column flex-lg-row">
                <div class="d-flex align-items-center me-3 mt-2 mt-lg-0">
                    <img class="me-2" src="../../assets/img/commodities.png" alt="Listing commodity" />
                    <p>
                        {{ listings.primary_commodity }}
                        <span v-if="listings.secondary_commodity">|&nbsp;</span>{{ listings.secondary_commodity }}
                    </p>
                </div>
                <div class="d-flex align-items-center me-4 mt-1 mt-lg-0">
                    <img class="me-2" src="../../assets/img/address.png" alt="Lisitng location" />
                    <p>{{ listings.location }}</p>
                </div>
                <div class="d-flex align-items-center me-4 mt-1 mt-lg-0">
                    <img class="me-2" src="../../assets/img/claim-size.png" alt="Lisitng claim Size" />
                    <p>{{ listings.claim_size }} {{ listings.claim_unit_size }}</p>
                </div>
                <div class="d-flex align-items-center me-4 mt-1 mt-lg-0">
                    <img class="me-2" src="../../assets/img/project-stage.png" alt="Lisitng Stage" />
                    <p>{{ listings.stage }}</p>
                </div>
                <div class="d-flex align-items-center me-4 mt-1 mt-lg-0">
                    <img class="me-2" src="../../assets/img/website.png" alt="Lisitng Project Website" />
                    <a @click="visitProjectPage(listings.project_page)">
                        <p class="project-website">Project Website</p>
                    </a>
                </div>
            </div>
            <div class="message-owner d-flex">
                <template v-if="is_authenticated && listings.owner">
                    <template v-if="listings.owner != current_user.email">
                        <property_conversation :property-id="listings._id.$oid" :deal_type_slug="dealTypeSlug"
                            :commoditiy_slug="commoditiSlug" :country_slug="countrySlug"
                            :company_name_and_project_name_slug="listings.company_name_and_project_name_slug" />
                    </template>
                </template>
                <Link v-if="!is_authenticated" :href="loginUrl">
                <button class="btn">
                    <img src="../../assets/img/message-icon.png" alt="Massage With Owner" class="msg-owner-icon">
                    <!-- <img src="../../assets/img/message-icon-white.png" alt="" class="msg-owner-icon-white d-none"> -->
                    Message Owner
                </button>
                </Link>
            </div>
        </div>
    </div>

    <div class="container mt-4 px-md-0">
        <div class="row">
            <div class="col-lg-8 col-sm-12 pe-xl-5">
                <!-- =================== Carousel Starts ================================================== -->
                <div v-if="images.length > 0">
                    <slider :imageUrl="images" :baseImageUrl="baseImageUrl"></slider>

                </div>
                <!-- =================== Carousel Ends ================================================== -->

                <div class="about-project">
                    <h5 class="mt-5 mb-3">About the Project</h5>
                    <p>
                        {{ listings.description }}
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-12 ps-3 mt-1 mt-lg-0 right-section">
                <a class="d-flex mt-4 mt-lg-0" :href="'/company/' + listings.slug">
                    <h4>{{ listings.company_name }}</h4>
                    <i class="fa fa-external-link ms-1" aria-hidden="true"></i>
                </a>
                <div class="d-flex mt-1 ticker-symbols">
                    <p>{{ listings.exchange }}-{{ listings.symbol }}</p>
                    <!-- <h5>NYCE.V:EMX</h5> -->
                </div>
                <div class="d-flex align-items-center company-summary mt-3">
                    <img class="me-2" src="../../assets/img/address.png" alt="Lisitng Street Address" />
                    <p>{{ listings.street_address }}</p>
                </div>
                <div class="d-flex align-items-center company-summary mt-2">
                    <img class="me-2" src="../../assets/img/phone.png" alt="Lisitng Phone Number" />
                    <p>{{ listings.phone }}</p>
                </div>
                <div class="d-flex align-items-center company-summary mt-2">
                    <img class="me-2" src="../../assets/img/website.png" alt="Lisitng Company Website" />
                    <a class="company-website" @click="visitProjectPage(listings.website)">{{ listings.website }}</a>
                </div>
                <div class="deal-details mt-5">
                    <h5 class="mb-3">Deal Details</h5>
                    <div class="d-flex mb-2">
                        <p class="me-5">Preferred Deal Type</p>
                        <p>{{ listings.deal_type }}</p>
                    </div>
                    <div class="d-flex mb-2" v-if="listings.deal_type == 'Sale'">
                        <p class="me-5">Expected value (USD)</p>
                        <p v-if="listings.deal_sale_value">{{ listings.deal_sale_value["expectedValue"] }}</p>
                        <p v-else>N/A</p>
                    </div>
                    <table class="table table-striped">
                        <thead v-if="listings.deal_type == 'Option'">
                            <tr v-if="listings.deal_option_value">
                                <th scope="col">Options</th>
                                <th scope="col">Total equity per stage</th>
                                <th scope="col">% of project per stage</th>
                            </tr>
                        </thead>
                        <thead v-if="listings.deal_type == 'Joint-Venture'">
                            <tr v-if="listings.deal_joint_venture_value">
                                <th scope="col">Ventures</th>
                                <th scope="col">Total equity per stage</th>
                                <th scope="col">% of project per stage</th>
                            </tr>
                        </thead>
                        <tbody v-if="listings.deal_type == 'Joint-Venture'">
                            <tr v-for="(valueRow, index) in listings.deal_joint_venture_value">
                                <td v-if="valueRow.optionStage">
                                    {{ valueRow["optionStage"] }}
                                </td>
                                <td v-else>N/A</td>
                                <td v-if="valueRow.totalEquityPerStage">
                                    {{ valueRow["totalEquityPerStage"] }}
                                </td>
                                <td v-else>N/A</td>
                                <td v-if="valueRow.pecentageOfProjectPerStage">
                                    {{ valueRow["pecentageOfProjectPerStage"] }}
                                </td>
                                <td v-else>N/A</td>
                            </tr>
                        </tbody>
                        <tbody v-if="listings.deal_type == 'Option'">
                            <tr v-for="(valueRow, index) in listings.deal_option_value">
                                <td v-if="valueRow.optionStage">
                                    {{ valueRow["optionStage"] }}
                                </td>
                                <td v-else>N/A</td>
                                <td v-if="valueRow.totalEquityPerStage">
                                    {{ valueRow["totalEquityPerStage"] }}
                                </td>
                                <td v-else>N/A</td>
                                <td v-if="valueRow.pecentageOfProjectPerStage">
                                    {{ valueRow["pecentageOfProjectPerStage"] }}
                                </td>
                                <td v-else>N/A</td>
                            </tr>
                        </tbody>
                        <thead v-if="listings.deal_type == 'Lease'">
                            <tr v-if="listings.deal_lease_value">
                                <th scope="col">Expected value (USD)</th>
                                <th scope="col">Lease term</th>
                            </tr>
                        </thead>
                        <thead v-if="listings.deal_type == 'Investment'">
                            <tr v-if="listings.deal_investment_value">
                                <th scope="col">Expected value (USD)</th>
                                <th scope="col">Investment equity (%)</th>
                            </tr>
                        </thead>
                        <tbody v-if="listings.deal_type == 'Lease'">
                            <tr v-for="(valueRow, index) in listings.deal_lease_value">
                                <td v-if="valueRow.expectedValue">
                                    {{ valueRow["expectedValue"] }}
                                </td>
                                <td v-else>N/A</td>
                                <td v-if="valueRow.leaseTerm">{{ valueRow["leaseTerm"] }}</td>
                                <td v-else>N/A</td>
                            </tr>
                        </tbody>
                        <tbody v-if="listings.deal_type == 'Investment'">
                            <tr v-for="(valueRow, index) in listings.deal_investment_value">
                                <td v-if="valueRow.expectedValue">
                                    {{ valueRow["expectedValue"] }}
                                </td>
                                <td v-else>N/A</td>
                                <td v-if="valueRow.investmentEquity">
                                    {{ valueRow["investmentEquity"] }}
                                </td>
                                <td v-else>N/A</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="project-owner-details mt-5" v-if="listings.listing_contact_should_publish == true">
                    <h5 class="mb-3">Project Owner Details</h5>
                    <div class="row mb-2">
                        <div class="col-2">
                            <p>Name</p>
                        </div>
                        <div class="col-10">
                            <p>{{ listings.listing_contact_name }}</p>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-2">
                            <p>Email</p>
                        </div>
                        <div class="col-10">
                            <p>{{ listings.listing_contact_email_address }}</p>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-2">
                            <p>Phone</p>
                        </div>
                        <div class="col-10">
                            <p>{{ listings.listing_contact_mobile_number }}</p>
                        </div>
                    </div>
                </div>

                <div class="project-videos mt-5">
                    <h5 class="mb-3">Project Videos</h5>
                    <div class="videos row gx-3 gx-xxl-1 gy-3 gy-xxl-2"
                        v-if="listings.videos.length > 0 && is_all_videos_empty == false">
                        <YouTubeVideoGallery :links="listings.videos"></YouTubeVideoGallery>
                    </div>
                    <div class="videos row gx-3 gx-xxl-1 gy-3 gy-xxl-2" v-else>
                        <div class="col-xxl-4 col-md-6 col-sm-12">
                            <p>No videos added.</p>
                        </div>
                    </div>
                </div>

                <!-- |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| -->


                <div class="project-documents mt-5">
                    <h5 class="mb-3">Project Documents</h5>
                    <div class="d-flex flex-wrap flex-wrap-column-gap">
                        <template v-if="listings.attachement_s3.length > 0">
                            <div v-for="item, index in listings.attachement_s3" class="thumbnail-wrapper">

                                <DocumentThumbnail :key="index" :document="item" :baseImageUrl="baseImageUrl"
                                    :user="user" />
                            </div>
                        </template>
                        <template v-else>
                            <p>No documents added.</p>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container mt-5 px-md-0">
        <h5 class="mb-3">Project Location</h5>
        <Gmap :heat_map_data="heat_map_data" :listings="similar_listings" :baseImageUrl="baseImageUrl"
            :current_listing="listings" :map_view_current_page="'InnerPage'" :related_properties="related_properties" />
        <div class="d-flex flex-column flex-md-row mt-3 inner-listings-map-legend">
            <div class="d-flex align-items-center me-4 mt-2 mt-md-0">
                <img class="me-2" src="../../assets/img/New_red_6.png" alt="Current Lisitng " />
                <p>Current Listing</p>
            </div>
            <div class="d-flex align-items-center me-4 mt-2 mt-md-0">
                <img class="me-2" src="../../assets/img/New_purpel_1.png" alt="Related Properties" />
                <p>Related Properties</p>
            </div>
            <div class="d-flex align-items-center me-4 mt-2 mt-md-0">
                <img class="me-2" src="../../assets/img/New_bule_5.png" alt="Other Properties" />
                <p>Other Properties</p>
            </div>
            <div class="d-flex align-items-center me-4 mt-2 mt-md-0">
                <img class="me-2" src="../../assets/img/project-density.png" alt="Project Density" />
                <p>Project Density</p>
            </div>
        </div>
    </div>

    <div class="container mt-5 pb-4 px-md-0">
        <h5 class="mb-3">Similar Listings</h5>
        <div class="row gx-4 gy-3">
            <div class="col-xl-2 col-lg-3 col-md-4 col-sm-12 listing-card"
                v-for="(valueRow, index) in similar_listings.slice(0, 6)">
                <ListingCard :item="valueRow" :baseImageUrl="baseImageUrl" />
            </div>
        </div>
    </div>
</template>

<style>
.slider {
    width: 100%;
    height: 500px;
    overflow: hidden;
}

.slider img {
    width: 100%;
    height: 100%;
    display: none;
}

.slider img:first-child {
    display: block;
}

.thumbnail-wrapper {
    cursor: pointer;
    width: 21%;
    margin: 10px 10px 0 0;
}

.flex-wrap-column-gap {
    column-gap: 54px;
}
</style>