
import { useQuery } from "vue-query";
import { reactive } from 'vue'
import axios from 'axios'

const fetchData = async (params) => {
    const response = await axios.get(`/api/v1/press-releases`, {
      params: {
        company_name: params.companyName,
        commodities_name: params.commoditiesName,
        country_name: params.countryName,
        page: params.page,
        keyword: params.keyword,
        startDate: params.startDate,
        id: params.id,
        endDate: params.endDate,
        recordsPerPage: params.recordsPerPage || 5,
      }
    });
    return response.data;
};

  export default function useGetPressReleases(params) {
    // const queryKey = reactive(["property", id ]);
    const queryKey = ["pressreleases", params];
    return useQuery(
        queryKey,
        () => fetchData(params.value),
        {
          refetchOnWindowFocus: false,
          //   placeholderData: [],
            staleTime: Infinity,
            keepPreviousData: true,
          onError: (error) => {
            // store.commit("errorsModal/openModal", error.response.data.errors);
            console.log('Error....', error)
          },
        }
    );
}