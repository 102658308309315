<script setup>
import { ref, onMounted } from "vue";
import Gmap from "../../components/map/map.vue";
import _ from 'lodash'
import ScreenLoader from '../../components/ScreenLoader.vue'

const props = defineProps({
    csrf_token: {
        type: String,
        required: "",
    },
    baseImageUrl: {
        type: String,
    },
    company_names: {
        type: Array,
        default: [],
    },
    is_authenticated: {
        type: Boolean,
        default: false,
    },
    availableLastPressReleasDateRange: {
        type: String,
        default: "",
    },
    news_releases_count_for_external_internal_projects: {
        type: Number,
    },
    active_internal_listings: {
        type: Array,
        default: [],
    },
    external_projects: {
        type: Array,
        default: [],
    },
    heat_map_data: {
        type: Array,
        default: [],
    },
    location: {
        type: Array,
        default: [],
    },
    all_commodities: {
        type: Array,
    },
    all_countries: {
        type: Array
    },
    date_range_selector: {
        type: String,
        default: ''
    },
    name: {
        type: String,
        default: ''
    },
    current_date_for_zone: {
        type: String,
        default: ''
    },
    user: {
        type: Object
    },
    all_internal_comapnies_with_slug: {
        type: Array,
        default: [] 
    }
});

const filterSelectedCommodities = ref(null)
const filterSelectedCountries = ref(null)
const filterKeyWord = ref('')
const filterComProName = ref(null)
const current_day_local = ref()
const one_month_local = ref()

const getQueryFilters = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    filterKeyWord.value = urlParams.get('title-keyword') ? urlParams.get('title-keyword') : ''
    filterComProName.value = urlParams.get('company_name_on_press_release') ? urlParams.get('company_name_on_press_release').split(",") : []
    filterSelectedCommodities.value = urlParams.get('commodity') ? urlParams.get('commodity').split(",") : []
    filterSelectedCountries.value = urlParams.get('country') ? urlParams.get('country').split(",") : []
    current_day_local.value = urlParams.get('press-date-range') == '1d' ? true : ''
    one_month_local.value = urlParams.get('press-date-range') == '1m' ? true : ''
}

const is_visible = ref(false);
const is_visible_loader = ref(true);
const toggle = () => {
    is_visible.value = true;
    is_visible_loader.value = false;
};
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
let datetime_str = new Date().toLocaleString("en-US", {
    timeZone: "" + timezone + "",
});
const current = new Date(datetime_str);
const today_date = `${current.getFullYear()}-${(current.getMonth() + 1 > 9 ? "" : "0") + (current.getMonth() + 1)
    }-${(current.getDate() > 9 ? "" : "0") + current.getDate()}`;

if (props.current_date_for_zone.toString() != today_date.toString()) {

    setTimeout(toggle, 9500);
} else {
    is_visible.value = true;
    is_visible_loader.value = false;
}


onMounted(() => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let datetime_str = new Date().toLocaleString("en-US", { timeZone: "" + timezone + "" });
    const current = new Date(datetime_str);
    const date = `${current.getFullYear()}-${((current.getMonth() + 1) > 9 ? '' : '0') + (current.getMonth() + 1)}-${(current.getDate() > 9 ? '' : '0') + current.getDate()}`;
    document.cookie = "currentDate=" + date + ";";

    getQueryFilters()
});

</script>

<template>
    <base href="/" target="_PARENT">

    <!-- News releases banner starts -->
    <div id="loader" v-show="is_visible_loader" class="map_view_fade_loader">
        <!-- <img  src="../../../frontend/assets/img/spinerGif.gif"> -->
        <ScreenLoader :show="is_visible_loader" />
    </div>
    <div id="MapView" style="margin-top:0" v-show="is_visible">
        <!-- News releases banner ends -->

        <!-- News releases toggle section -->
        <!-- News releases toggle section ends -->

        <!-- Tab panes -->
        <div class="py-2 px-3">
        <h4>News Map</h4>
        <p>Navigate the latest mining news geographically with our News Map, providing insights into industry developments categorized by company, commodity, and projects.</p>
        </div>
        
        <div class="tab-content">
            <Gmap :heat_map_data="heat_map_data" :projects="active_internal_listings" :baseImageUrl="baseImageUrl"
                :location="location" :current_date_for_zone="current_date_for_zone" :externalProjects="external_projects"
                :map_view_current_page="'NewsReleasesMapView'" :user="user" :all_internal_comapnies_with_slug="all_internal_comapnies_with_slug"/>
        </div>
        <!-- Map View starts -->
        <!-- <div id="news-releases-list-map" class="container tab-pane fade p-0">
               
            </div> -->
        <!-- Map View Ends -->
        <!-- </div> -->
    </div>
</template>