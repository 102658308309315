<script setup>
import { ref, computed } from 'vue'
import { router, useForm } from "@inertiajs/vue3";
import _ from 'lodash'
import VueMultiselectCustom from "../../components/dropdownSelector/VueMultiselectCustom.vue";
import useCreatePressReleaseSubscription from '../../components/hooks/useCreatePressReleaseSubscription'
import useUpdatePressReleaseSubscription from '../../components/hooks/useUpdatePressReleaseSubscription'
import { useToast } from "primevue/usetoast";
import headComponent from "../../components/inertiaHead/head.vue"
import Nav from '../../components/layout/Nav.vue'



const props = defineProps({
    csrf_token: {
        type: String,
        required: "",
    },
    all_commodities: {
        type: Array,
    },
    all_countries: {
        type: Array,
    },
    user: {
        type: Object,
        default: null,
    },
    internal_external_companies: {
        type: Array,
        default: []
    },
    selected_companies: {
        type: Array,
        default: []
    },
    selected_commodities: {
        type: Array,
        default: []
    },
    company_selected_countries: {
        type: Array,
        default: []
    },
    is_subscribed: {
        type: Boolean,
        default: false
    }
});
const toast = useToast();

const selectedcompany = ref(props.selected_companies)
const selectedCommoditiesLocal = ref(props.selected_commodities)
const selectedCompanyCountriesLocal = ref(props.company_selected_countries)
const internal_external_companies_options = _.uniq(props.internal_external_companies.map(company => company.company_name))

const { createPressReleaseSubscription, createPressReleaseSubscriptionStatus } = useCreatePressReleaseSubscription()
const { updatePressReleaseSubscription, updatePressReleaseSubscriptionStatus } = useUpdatePressReleaseSubscription()

let buttonType = '';

const handleSubmit = (button) => {
    buttonType = button;
}

const submitForm = async () => {

    if (buttonType === "unsubscribe") {

        await updatePressReleaseSubscription({
            company_name: selectedcompany.value,
            commodity: selectedCommoditiesLocal.value,
            country: selectedCompanyCountriesLocal.value,
            subscription_owner_email: props.user.email,
            is_subscribed: false
        }).then(res => {
            toast.add({ severity: 'success', detail: 'Press release subscription update successfully.', life: 3000 });
            window.location.reload()
        }).catch(err => {
            window.scrollTo(0, 0)
            toast.add({ severity: 'error', summary: 'Error Message', detail: 'We could not save the press release subscription.', life: 3000 });
        })

    } else if (buttonType === "updatepreference") {

        await updatePressReleaseSubscription({
            company_name: selectedcompany.value,
            commodity: selectedCommoditiesLocal.value,
            country: selectedCompanyCountriesLocal.value,
            subscription_owner_email: props.user.email,
            is_subscribed: true
        }).then(res => {
            toast.add({ severity: 'success', detail: 'Press release subscription update successfully.', life: 3000 });
            window.location.reload()
        }).catch(err => {
            window.scrollTo(0, 0)
            console.log(err)
            toast.add({ severity: 'error', summary: 'Error Message', detail: 'We could not save the press release subscription.', life: 3000 });
        })


    } else if (buttonType === "subscribe") {

        await createPressReleaseSubscription({
            company_name: selectedcompany.value,
            commodity: selectedCommoditiesLocal.value,
            country: selectedCompanyCountriesLocal.value,
            subscription_owner_email: props.user.email,
            is_subscribed: true
        }).then(res => {
            toast.add({ severity: 'success', detail: 'Press release subscription saved successfully.', life: 3000 });
            window.location.reload()

        }).catch(err => {
            window.scrollTo(0, 0)
            toast.add({ severity: 'error', summary: 'Error Message', detail: 'We could not save the press release subscription.', life: 3000 });
        })
    } else {
        toast.add({ severity: 'error', summary: 'Error Message', detail: 'We could not save the press release subscription.', life: 3000 });
    }

}
</script>

<template>
     <headComponent :description="'Stay up-to-date with the latest mining news personalized for you. press releases based on your preferences junior mining, and more.'"
          :title="'My News | Personalized Mining Press Release and News'">
      </headComponent>
      <form v-cloak method="POST" @submit.prevent="submitForm">
    <div class="container subscription-pages">
        <!------------------------------ News Release Preferences --------------------------------------------->
      
        <div class="row g-3 subscription-form mt-lg-2">
            <h1 class="mt-5 mb-0 h5">My News | Personalized Mining Press Release and News</h1>
            <p class="mt-1 mb-0 ps-3 ms-1">Please select one or more options from the following to receive daily press
                releases based on your preferences.</p>
            <template v-if="createPressReleaseSubscriptionStatus.status === 'error'">
                <div class="tw-mb-2">
                    <p class="error-link text-danger tw-mb-2 tw-font-bold">Could not save the press release subscription.
                    </p>

                    <p v-for="(e, index) in createPressReleaseSubscriptionStatus.error.response.data.errors" :key="index"
                        class="error-link text-danger tw-mb-0">
                        {{ index }}: {{ e[0] }}
                    </p>
                </div>
            </template>
            <template v-if="updatePressReleaseSubscriptionStatus.status === 'error'">
                <div class="tw-mb-2">
                    <p class="error-link text-danger tw-mb-2 tw-font-bold">Could not update the press release subscription.
                    </p>

                    <p v-for="(e, index) in updatePressReleaseSubscriptionStatus.error.response.data.errors" :key="index"
                        class="error-link text-danger tw-mb-0">
                        {{ index }}: {{ e[0] }}
                    </p>
                </div>
            </template>
            <div class="col-md-12">
                <label class="form-label">Companies</label>
                <VueMultiselectCustom placeholder="Select" :options="internal_external_companies_options"
                    :modelValue="selectedcompany" :multiple="true" :max="10" @update:modelValue="selectedcompany = $event"
                    class="notranslate">
                </VueMultiselectCustom>
            </div>
            <div class="col-md-12">
                <label class="form-label">Project commodities associated with companies</label>
                <VueMultiselectCustom placeholder="Select" :options="all_commodities" :modelValue="selectedCommoditiesLocal"
                    :multiple="true" :max="5" @update:modelValue="selectedCommoditiesLocal = $event" class="notranslate">
                </VueMultiselectCustom>
            </div>
            <div class="col-md-12">
                <label class="form-label">Countries</label>
                <VueMultiselectCustom placeholder="Select" :options="all_countries"
                    :modelValue="selectedCompanyCountriesLocal" :multiple="true" :max="5"
                    @update:modelValue="selectedCompanyCountriesLocal = $event" class="notranslate">
                </VueMultiselectCustom>
            </div>
        </div>

        <div class="row g-3 listing-form mt-4 pb-5 d-flex justify-content-end">
            <!-- To Subscribe Preferences -->
            <div class="col-lg-2"  v-if="!(props.is_subscribed)">
                <button type="submit" class="btn custom-primary-btn w-100"
                @click="handleSubmit('subscribe')">Subscribe</button>
            </div>
            <!-- To Unsubscribe Preferences -->
            <div class="col-lg-2"  v-if="(props.is_subscribed)">
                <button type="submit" class="btn custom-danger-btn w-100"    @click="handleSubmit('unsubscribe')">Unsubscribe</button>
            </div>
            <div class="col-lg-3"  v-if="(props.is_subscribed)">
                <button type="submit" class="btn custom-primary-btn w-100"   @click="handleSubmit('updatepreference')">Update My Preferences</button>
            </div>
        </div>
    </div>
</form>
</template>