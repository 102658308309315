<script setup>
import { computed, ref } from 'vue'
import { mapStore } from "./mapStore"
import ProjectInfoBox from './ProjectInfoBox.vue'



const props = defineProps({
    project: {
        type: Object,
    },
    id: {
        type: String,
    },
    lat: {
        type: Number,
    },
    lng: {
        type: Number,
    },
    baseImageUrl: {
        type: String,
    },
})


const opened = computed(() => {
    return mapStore.clickedMarker === props.id
}) 

const handleMarkerClick = () => { 
    setProjectImage();
    mapStore.clickedMarker = props.id == mapStore.clickedMarker? null: props.id
}

const onCloseMarker = () => {
  
}

const handleMouseHover = () => {
    
    console.log('Mouse hover........')
}
const icon = computed(() => {
    
 return  '/img/New_bule_5.png'
})

const title = computed(() => props.project.name? props.project.name :props.project.project_title.toString().replace(/[^\w\s]/gi, ''))

const projectImage = ref(null);

const setProjectImage = () => {
    let url = ''

    if (props.project.images_s3) {
        url = `${props.baseImageUrl}/${props.project.images_s3[0]}`
    }
    else {
        url = props.project.image_one
    }

    projectImage.value = url
}

</script>


<template>
    <GMapMarker
        :position="{lat: lat, lng: lng}"
        :icon= "icon"
        :title="title"
        @click="handleMarkerClick"
        :clickable="true"
        @mouseover="handleMouseHover"
        
     >
        <GMapInfoWindow 
            :opened="opened"
            :closeclick="true"
            @closeclick="onCloseMarker"
        >
            <!-- <ExternalProjectInfoBox v-if="externalProject" :project="project" :stockData="data" :isSuccess="isSuccess" :isLoading="isLoading"/> -->
            <ProjectInfoBox :project="project" :baseImageUrl="baseImageUrl" :projectImage="projectImage" />

        </GMapInfoWindow>
    </GMapMarker>
</template>