<template>
  <a href="/people-web" target="_parent">
    <div class="py-2 px-3">
      <h4>PeopleWeb</h4>
      <p>Explore the network of connections between mining professionals and companies with PeopleWeb.</p>
    </div>
    <img class="d-sm-block d-md-none object-fit-cover w-100" src='./../../assets/img/Peoples-web-360x670.jpg' >
    <img class="d-none d-md-block object-fit-cover" src='./../../assets/img/Peoples-web.jpg' >
  </a>
</template>

