
import { useQuery } from "vue-query";
import axios from 'axios'

const fetchData = async (params, url) => {
      const response = await axios.get(url, {
      params: {
        provider: params.providerName,
        company_name: params.companyName,
        commodities_name: params.commoditiesName,
        country_name: params.countryName,
        page: params.page,
        keyword: params.keyword,
        startDate: params.startDate,
        id: params.id,
        endDate: params.endDate,
        recordsPerPage: params.recordsPerPage || 5,
      }
    });
    return response.data;
};

  export default function useGetPressReleases(params, origin) {
    const apiUrl = '/api/v1/press-releases'
    const url = origin? `${origin}/${apiUrl}`: apiUrl


    // const queryKey = reactive(["property", id ]);
    const queryKey = ["pressreleases", params];
    return useQuery(
        queryKey,
        () => fetchData(params.value, url),
        {
          refetchOnWindowFocus: false,
          //   placeholderData: [],
            staleTime: Infinity,
            keepPreviousData: true,
          onError: (error) => {
            // store.commit("errorsModal/openModal", error.response.data.errors);
            console.log('Error....', error)
          },
        }
    );
}