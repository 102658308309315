<template>
  <a href="/projects?map-view=true" target="_parent">
    <div class="py-2 px-3">
      <h4>Projects Map</h4>
      <p>Visualize exploration opportunities worldwide with our Projects Map, offering a comprehensive view of exploration projects by stage, minerals, location, and deal type.</p>
    </div>
    <img class="d-sm-block d-md-none object-fit-cover w-100" src='./../../assets/img/Projects-Map-360x670.jpg' >
    <img class="d-none d-md-block object-fit-cover" src='./../../assets/img/Projects-Map.jpg' >
  </a>
</template>
