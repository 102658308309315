<script setup>
import { computed } from 'vue'
import _ from 'lodash'

const props = defineProps({
    conversation: {
        type: Object,
        required: true,
    },
    owner: {
        type: String,
        required: true,
    },
    activeConversation: {
        type: Object,
        default: null
    }
})

const userNameText = computed(() => {
    return props.conversation?.members?.filter(x => x.email !== props.owner).map(i => i.name).join(',')
})

const isActive = computed(() => {
    return props.conversation["_id"]["$oid"] === _.get(props, 'activeConversation._id.$oid', undefined)
})

const latestMessage = computed(() => {
    const message = _.get(props.conversation,'latest_message[0].message','')
    const result = message.substring(0, 100)
    return message.length > 100? `${result}...`: result
})

</script>

<template>
    <div class="col-sm-12- py-3- tw-p-4 tw-cursor-pointer" :class="{'tw-bg-gray-300': isActive}">
        <p class="lpanel-listing tw-mb-0">{{userNameText}}
            <span>{{  conversation.lastUpdatedDate }}</span>
        </p>
        <p class="lpanel-company tw-mb-0">{{conversation.conversation_name}}</p>
        <p class="lpanel-body tw-mb-0">{{latestMessage}}</p>
    </div>
</template>