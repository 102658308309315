<script setup>
import { onMounted, computed } from 'vue'
import Skeleton from 'primevue/skeleton';
import _ from 'lodash'
import { mapStore } from '../../map/mapStore'
import PdfModal from '../../modals/PdfModal.vue'
import { router, usePage, Head, Link } from '@inertiajs/vue3'

const props = defineProps({
    project: {
        type: Object
    },
    externalProject: Boolean,
    stockData: {
        type: Object,
    },
    baseImageUrl: {
        type: String,
        default: ""
    },
    isLoading: Boolean,
    isSuccess: Boolean,
    all_internal_comapnies_with_slug: {
        type: Array,
        defult: []
    }
})

const volume = computed(() => new Intl.NumberFormat().format(props.stockData?.data.volume))
const price = computed(() => new Intl.NumberFormat('en-us', { style: 'currency', currency: 'USD' }).format(props.stockData?.data.price))
const change = computed(() => new Intl.NumberFormat('en-us', { style: 'decimal', minimumFractionDigits: 2 }).format(props.stockData?.data.change))
// const changePercentage = computed(() => new Intl.NumberFormat('en-us', { style: 'decimal', minimumFractionDigits: 2 }).format(props.stockData?.data.change_percentage) )

const pressReleases = computed(() => _.get(props.project, 'press_relases_info', []))
const s3BaseUrl = computed(() => { return props.baseImageUrl })

onMounted(() => {
    // console.log('ONMOUNTEDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD')
})

const showOtherProjects = computed(() => _.get(props.project, 'ohter_internal_lisitng_info.length', false))
const projectName = computed(() => props.externalProject ? props.project.project_title : props.project.name)
const moveToProject = (p) => {
    const id = p._id.$oid
    console.log('Move to ........', p)
    console.log('Move to ........', p._id.$oid)
    mapStore.clickedMarker = id
}
const vistCompanyProfile = (name) => {
    const company_slug = computed(() => {
        const company = props.all_internal_comapnies_with_slug.find(obj => obj.company_name === name);
        return company ? company.slug : null;
    });
    if (company_slug.value != null) {

        window.open(`/company/${company_slug.value}`, '_blank')
    }
    else {
        alert("Company profile not found.")

    }

}
const urlSlugCreate = (val) => {
    // replace all special characters | symbols with a space
    let str = val.replace(/[`~!@#$%^&*()_\-+=\[\]{};:'"\\|\/,.<>?\s]/g, ' ').toLowerCase();

    // trim spaces at the start and end of the string
    str = str.replace(/^\s+|\s+$/gm, '');

    // replace space with dash/hyphen
    str = str.replace(/\s+/g, '-');

    return str;
};
const preview = (deal_type, primary_commodity, country, company_name_and_project_name_slug) => {
    const dealTypeSlug =  deal_type? deal_type == "Unknown" ?"other":urlSlugCreate(deal_type):"none";
    const commoditiSlug = primary_commodity ? urlSlugCreate(primary_commodity) : "none"
    const countrySlug = country ? urlSlugCreate(country) : "none";


    window.open(`/` + dealTypeSlug + '/' + commoditiSlug + '/' + countrySlug + '/' + company_name_and_project_name_slug, '_blank')

}


</script>

<template>
    <!-- Map Cards Starts -->
    <div class="news-release-map-card p-3">
        <div>
            <div v-if="externalProject == false">
                <a  style="cursor: pointer;" @click="preview(project.deal_type, project.primary_commodity, project.country, project.company_name_and_project_name_slug)">
                    <h6 class="project-name">{{ projectName }}</h6>
                </a>
            </div>
            <div v-else>
                <h6 class="project-name">{{ projectName}}</h6>
            </div>
            <div class="d-flex align-items-center company-name-ticker mb-3">
                <h6 class="me-2"><a @click="vistCompanyProfile(project.company_name)" style="cursor: pointer;">{{
                    project.company_name }}</a></h6>
                <span>{{ project.exchange }}:{{ project.symbol }}</span>
            </div>
            <div v-if="isLoading" class="tw-mt-2" style="flex: 1">
                <Skeleton width="100%" class="mb-2"></Skeleton>
                <Skeleton width="75%"></Skeleton>
            </div>
            <template v-if="isSuccess">
                <div class="d-flex news-release-stock-info mb-3">
                    <div class="d-flex flex-column me-4" v-if="stockData.data.price != null">
                        <p>Stock Price</p>
                        <h6 class="news-release-stock-info"
                            :class="{ 'color-minus-price-value': change < 0, 'color-plus-price-value': change > 0 }">{{ price
                            }}</h6>
                    </div>
                    <div class="d-flex flex-column me-4" v-if="stockData.data.volume != null">
                        <p>Volume</p>
                        <h6>{{ volume }}</h6>
                    </div>
                    <div class="d-flex flex-column me-4" v-if="stockData.data.change_percentage != null">
                        <p>Chg %</p>
                        <h6 class="news-release-stock-info"
                            :class="{ 'color-minus-value': change < 0, 'color-plus-value': change > 0 }">{{
                                stockData.data.change_percentage }}</h6>
                    </div>
                    <div class="d-flex flex-column me-4" v-if="stockData.data.change != null">
                        <p>Chg $</p>
                        <h6 class="news-release-stock-info"
                            :class="{ 'color-minus-value': change < 0, 'color-plus-value': change > 0 }">{{ change }}</h6>
                    </div>
                </div>
            </template>
            <ul class="nav nav-pills flex-nowrap map-card-tabs" role="tablist">
                <li class="nav-item" v-if="pressReleases.length">
                    <a :class="{ 'active': pressReleases.length }" class="nav-link active me-4" data-bs-toggle="pill"
                        href="#news-releases">News Releases</a>
                </li>
                <li class="nav-item" v-if="!externalProject">
                    <a :class="{ 'active': !pressReleases.length }" class="nav-link me-4" data-bs-toggle="pill"
                        href="#project-details">Project Details</a>
                </li>
                <li class="nav-item" v-if="showOtherProjects && !externalProject">
                    <a class="nav-link" data-bs-toggle="pill" href="#other-projects">Other Projects</a>
                </li>
            </ul>
        </div>

        <div class="tab-content pt-0">
            <div id="news-releases" class="tab-pane active pt-3" v-if="pressReleases.length"
                :class="{ 'active show': pressReleases.length }">
                <div class="news-releases-info">
                    <div class="d-flex" v-for="(pressRelease, index) in pressReleases" :key="index">
                        <img src="../../../assets/img/news-releases.png" alt="News Releases">
                        <div class="d-flex flex-column ms-2">
                            <!-- Backward compatibility to view PDF Popup, if HTML is not available -->
                            <PdfModal v-if="pressRelease.body==''" :link="`${s3BaseUrl}/${pressRelease.link_s3}`">
                                <h6><a href="#" target="_blank"
                                        @click.prevent="">{{ pressRelease.title.toString().replace(/[^\w\s]/gi, '') }}</a>
                                </h6>
                            </PdfModal>
                            <h6><a v-if="pressRelease.body" :href="`/press-releases/${pressRelease.provider}/${(pressRelease._slugs && pressRelease._slugs[0]) || pressRelease._id.$oid}?ref=map`">{{ pressRelease.title.toString().replace(/[^\w\s]/gi, '') }}</a></h6>
                            <p>{{ new Intl.DateTimeFormat('fr-CA', {
                                year: 'numeric', month: 'numeric', day: 'numeric',
                                hour12: true,
                                timeZone: 'UTC'
                            }).format(new Date(pressRelease.publish_date)) }}</p>

                        </div>
                    </div>
                    <h6 class="mt-3 mb-0"><a :href="'/press-releases?companyName=' + project.company_name">More News
                            Releases</a></h6>
                </div>
            </div>
            <div id="project-details" class="tab-pane pt-3" v-if="!externalProject"
                :class="{ 'active show': !pressReleases.length }">
                <div class="project-details-info">
                    <p>{{ project.description.toString().replace(/[^\w\s]/gi, '').slice(0, 120) }}</p>
                    <h6 class="my-2">Advanced Exploration</h6>
                    <div class="d-flex align-items-center pt-1 commodities-section">
                        <span class="me-2">{{ project.primary_commodity }}</span>
                        <span class="me-2" v-if="project.secondary_commodity">{{ project.secondary_commodity }}</span>
                        <img src="../../../assets/img/claim-size-map.png" alt="Internal Project" class="me-1">
                        <h6>{{project.claim_size}} {{project.claim_unit_size}}</h6>
                </div>
            </div>
        </div>
        <div id="other-projects" class="tab-pane pt-3" v-if="showOtherProjects">
            <div class="other-projects-info">
                <div class="d-flex align-items-center" v-for="(p, index) in project.ohter_internal_lisitng_info"
                    :key="index">
                    <img src="../../../assets/img/New_purpel_2.png" alt="External Project"><br>
                    <h6 class="ms-2"><a target="_blank"  style="cursor: pointer;"
                            @click="preview(p.deal_type, p.primary_commodity, p.country , p.company_name_and_project_name_slug)">
                            {{p.name}}</a></h6>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Map Cards ends --></template>