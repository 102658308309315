<script setup>
import { ref, computed } from "vue";
import { useForm } from "@inertiajs/vue3";
import BsModal from "../../components/BsModal.vue";

const props = defineProps({
  csrf_token: {
    type: String,
    default: "",
  },
  isVisible: {
    type: Boolean,
    default: true,
  },
  errors: {
    type: Object,
  },
});

const loginEmailError = ref(false);
const loginEmailErrorMsg = ref("");
const loginPasswordError = ref(false);
const loginPasswordErrorMsg = ref("");

const emit = defineEmits(["onClose"]);

const closeModal = () => {

  emit("onClose");
};

const form = useForm({
  email: null,
  password: null,
  authenticity_token: props.csrf_token,
})

const submit = (e) => {
  form.post('/signin', {
    preserveState: false, onSuccess: () => {

      console.log('on successs...')
    }
  })
};

const sh = computed(() => props.isVisible)
</script>

<template>
  <BsModal :show="sh" :blurBackdrop="true">
    <template #modalHeader>
      <h4 class="modal-title">Sign In</h4>
      <button class="close-button" @click="closeModal">
        <svg class="tw-w-6 tw-h-6" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
        </svg>
      </button>
    </template>

    <template #modalBody>
      <h1 class="form-caption mb-3">Welcome to SearchSpace AI | Login to Your Account</h1>

      <form :action="`/signin`" method="POST" @submit.prevent="submit">
        <!-- authenticity token -->

        <input type="hidden" name="authenticity_token" :value="csrf_token" />

        <!-- email -->
        <div class="form-floating mb-3">
          <input type="email" :class="{
            'form-control': true,
            'is-invalid': loginEmailError,
          }" name="email" id="login_email" v-model="form.email" placeholder="name@example.com" required />
          <label for="login_email">Email</label>
          <div v-if="loginEmailError" class="validation-msg">
            {{ loginEmailErrorMsg }}
          </div>
        </div>

        <!-- password -->
        <div class="form-floating">
          <input type="password" :class="{
            'form-control': true,
            'is-invalid': loginPasswordError,
          }" name="password" id="login_password" v-model="form.password" placeholder="Password" required />
          <label for="login_password">Password</label>
          <div v-if="loginPasswordError" class="validation-msg">
            {{ loginPasswordErrorMsg }}
          </div>
        </div>

        <div class="validation-msg">
          {{ errors?.login }}
        </div>

        <!-- sign in button -->
        <div class="d-grid gap-2 mt-20">
          <button type="submit" class="btn btn-primary btn-sign-in"> {{ form.processing ? 'Please wait...' : 'Sign In' }}
          </button>
        </div>
      </form>

      <!-- fogot password -->
      <div class="Forgot-Password-wrapper">
        <p class="Forgot-Password_Text"><a href="/forgot-password" class="Forgot-Password">Forgot Password?</a></p>
      </div>

      <!-- to sign up -->
      <div class="sign-up-link-wrapper">
        <p>
          Don't have an account?
          <span><a href="/signup"> Sign Up </a></span>
        </p>
      </div>
    </template>

    <template #modalFooterWrapper>
      <span></span>
    </template>
  </BsModal>
</template>

<style scoped>
:deep(.modal-dialog) {
  justify-content: center;
}

:deep(.modal-content) {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  width: 500px;
  display: flex;
  flex-direction: column;
}

:deep(.modal-header) {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #eee;
}

:deep(.modal-title) {
  font-size: 17px;
}

:deep(.modal-body) {
  padding: 1.25rem;
  flex: 1;
}

.close-button {
  background: none;
  border: none;
}

.close-button-img {
  width: 14px;
}

.mt-20 {
  margin-top: 20px;
}

.form-caption {
  text-align: left;
  font: normal normal medium 22px/27px Poppins;
  letter-spacing: 0px;
  color: #222222;
  font-size: 20px;
  font-weight: 600;
}

.btn-sign-in {
  height: 56px;
  font-weight: 600;
  color: #fff !important;
}

.Forgot-Password-wrapper {
  width: 100%;
  margin: 15px 0 30px 0;
}

.Forgot-Password {
  font-size: 13px;
  color: #3882ff !important;
  cursor: pointer;
  font-weight: 500;
}

.Forgot-Password_Text {
  text-align: right;
}

.devider-2 {
  display: flex;
}

.devider-2 hr {
  border: 1px;
  width: 46%;
}

.devider-2 p {
  color: #222222;
  margin: 3px 10px 0 10px;
  font-size: 16px;
  font-weight: 500;
}

.sso-login-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 30px 0 30px 0;
}

.sso-btn {
  background: none;
  border: none;
  cursor: pointer;
}

.sso-btn-img {
  width: 21px;
}

.sign-up-link-wrapper {
  display: flex;
  justify-content: center;
}

.sign-up-link-wrapper span {
  margin-left: 3px;
}

.sign-up-link-wrapper p {
  font-size: 13px;
  font-weight: 500;
}

.sign-up-link-wrapper a {
  color: #3882ff !important;
  text-decoration: none;
}

.validation-msg {
  color: #ff1a1a;
  margin: 2px 0 0px 2px;
  font-size: 13px;
  font-weight: 500;
}
</style>
