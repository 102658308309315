<script setup>
import { onMounted, computed } from "vue";
import { router, usePage, Head, Link } from '@inertiajs/vue3'
import Skeleton from "primevue/skeleton";
import _ from "lodash";

const props = defineProps({
  project: {
    type: Object,
  },
  baseImageUrl: {
     type: String,
  },
  projectImage: {
    type: String
  }
});

// const imageUrlComputed = computed(() => {
//     let url = ''

//     if (props.project.images_s3) {
//         url = `${props.baseImageUrl}/${props.project.images_s3[0]}`
//     }
//     else {
//         url = props.project.image_one
//     }
//     return url
//     // return 'https://searchspaceai-dev.s3.ca-central-1.amazonaws.com/public/images/property/6531d8ff30514b00021d8f3c/1697765573_bugow-2200x200.jpg'
// })

const dealTypeValue = computed(() => {
  const fieldName = `deal_${props.project.deal_type.replace('-', '_').toLowerCase()}_value`
  let index = props.project[fieldName] && props.project[fieldName].length
  let value = ''
  if (index != 0 && index != undefined) {
    if (fieldName === 'deal_sale_value', fieldName === 'deal_investment_value', fieldName === 'deal_lease_value') {

      value = props.project[fieldName][0].expectedValue
    }
    else {
      value = props.project[fieldName][index - 1].pecentageOfProjectPerStage
    }
  }
  return value
})
onMounted(() => { });

const urlSlugCreate = (val) => {
 // replace all special characters | symbols with a space
 let str = val.replace(/[`~!@#$%^&*()_\-+=\[\]{};:'"\\|\/,.<>?\s]/g, ' ').toLowerCase();

 // trim spaces at the start and end of the string
 str = str.replace(/^\s+|\s+$/gm, '');

 // replace space with dash/hyphen
 str = str.replace(/\s+/g, '-');

 return str;
};
const preview = (deal_type,primary_commodity,country,company_name_and_project_name_slug) => {
const dealTypeSlug = deal_type? deal_type == "Unknown" ?"other":urlSlugCreate(deal_type):"none";
const commoditiSlug = primary_commodity? urlSlugCreate(primary_commodity): "none"
const countrySlug = country? urlSlugCreate(country): "none";

    // router.visit(
    //     '/'+ dealTypeSlug +'/'+ commoditiSlug +'/'+countrySlug +'/' + company_name_and_project_name_slug,
    // )
    window.open(`/` + dealTypeSlug + '/' + commoditiSlug + '/' + countrySlug + '/' + company_name_and_project_name_slug, '_blank')
}

</script>

<template>
  <div class="card map-cards">
    <a  style="text-decoration:none;cursor: pointer; "  @click="preview(project.deal_type, project.primary_commodity, project.country , project.company_name_and_project_name_slug)" >
    <div class="card-body p-0">
      <div class="card-img" v-bind:style="{ 'background-image': 'url(' + projectImage + ')' }"></div>

      <div class="px-2">
        <h6 class="">{{ project.name }}</h6>
        <h5 class="">{{ project.company_name }}</h5>
        <h5 class="mb-2">{{ project.country }}</h5>
        <div class="d-flex justify-content-between">
          <p class="">{{ project.deal_type }}</p>&nbsp;
          <p>{{ dealTypeValue }} </p>
        </div>
        <div class="d-flex justify-content-between">
          <p class="">{{ project.stage }}</p>&nbsp;
          <p class="">
            {{ project.claim_size }} {{ project.claim_unit_size }}
          </p>
        </div>
        <p class="commodities">
          {{ project.primary_commodity }}
          <span v-if="project.secondary_commodity">|</span>
          {{ project.secondary_commodity }}
        </p>
      </div>
    </div>
  </a>
  </div>
</template>
