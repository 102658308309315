import { useMutation, useQueryClient } from "vue-query";
import axios from "axios";
import { ref, toRefs, computed, reactive } from "@vue/reactivity";
import _ from 'lodash'

const action = async (payload) => {
  const { company_name, publish_date, title, link_s3, primary_commodity = '', secondary_commodity = '',created_by = '' } = payload


  await axios.post(`/api/v1/press-releases`, {
    company_name, 
    publish_date, 
    title, 
    link_s3,
    created_by,
    primary_commodity,
    secondary_commodity
  }, {
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      'X-CSRF-Token': document.querySelector('[name=authenticity_token]').value,
    }
  });
};
// ["conversation-messages", conversationId]
export default function useCreatePressRelease() {
  const cache = useQueryClient();
  
  const mutation = useMutation(
    (payload) => action(payload),
    {
      
      onSuccess: (data, variables, context) => {
        cache.invalidateQueries('pressreleases')
      },
      onError: (err, newMessageItem, context) => {
        console.error('Error while deleting the conversation: ', err)

      },
      onSettled: (data, error, variables, context) => {},
    }
  );

  return reactive({
    createPressRelease: mutation.mutateAsync,
    createPressReleaseStatus: mutation,
  });
}
