<script setup>
import { ref, onMounted} from 'vue'
import headComponent from "../../components/inertiaHead/head.vue"
import Nav from '../../components/layout/Nav.vue'


const props = defineProps({
  csrf_token: {
    type: String,
    required: ""
  },
  user: {
        type: Object,
        default: null,
    },
})
const currentYear = ref(null);
onMounted(() => {
    currentYear.value = new Date().getFullYear();
})
</script>

<template>
    
  <headComponent :description="`Your privacy is important to us. Learn how SearchSpace AI protects your information as you explore the mining, mining projects and mining investment industry with us.`"
          :title="`Privacy Policy`">
  </headComponent>
    <div class="container px-md-0 mt-5 pb-5 privacy">
    <h1>Privacy Policy</h1>
    <p>01 Jan {{currentYear}}</p>

    <h6 class="mt-4 mb-2">SearchSpace AI recognizes the importance of protecting your privacy and abides by the Canadian
      Privacy Principles.</h6>
    <p class="mb-3">This privacy policy explains how we collect personal information and how we maintain, use and disclose
      that
      information. It also provides some detail about your privacy rights along with our general rights and
      obligations, and our policy in relation to the personal information we keep on record. By using SearchSpace
      AI’s website and/or accepting SearchSpace AI’s Privacy Policy, you are consenting to the matters set out
      in it.</p>
    <p class="mb-3">This privacy policy does not apply to parties that may be linked or associated with our website and to
      whom
      you disclose personal information. Those website owners are responsible for the privacy of the information
      they collect and should be contacted directly for details of their privacy policies.</p>
    <p>When we refer to our ‘website’, we are referring to the SearchSpace AI website on any device, social media
      sites, emails we send, SearchSpace AI apps, and any new technology we implement on any of these platforms.
    </p>

    <h6 class="mt-4 mb-2">What information do we collect?</h6>
    <p class="mb-3">Because we strive to provide our website users with personalized services showing the information most
      relevant to them, from time to time we request and store some private information about you.&nbsp; We will
      only use that information in accordance with this policy.</p>
    <p class="mb-3">We collect and hold information about you when you access and use our services through our
      website.&nbsp;
      The types of information we keep on record will depend on the type of product or service requested by you.
    </p>
    <p>We may keep a record of the following types of information about you:</p>
    <ul>
      <li><i class="fa fa-check-circle" aria-hidden="true"></i>Personal information that you give us when you use the
        website, such as your name, email address,
        address, and postcode;</li>
      <li><i class="fa fa-check-circle" aria-hidden="true"></i>If you provide them to us, the name of your product
        provider, credit provider or product details for
        specific products that you hold;</li>
      <li class="d-none"><i class="fa fa-check-circle" aria-hidden="true"></i>The Facebook/Twitter/LinkedIn profile
        information identified in the ‘Login’ form, if you
        choose to log in or sign up to our website using these accounts;</li>
      <li><i class="fa fa-check-circle" aria-hidden="true"></i>Pages of the website that you have visited; Your IP address
        for your interaction with various parts of
        our website. Your IP address is the identifier for your computer when you are using the internet;</li>
      <li><i class="fa fa-check-circle" aria-hidden="true"></i>Other information voluntarily provided by you;</li>
      <li><i class="fa fa-check-circle" aria-hidden="true"></i>Any other information we need to enable us to deliver
        services to you;</li>
      <li class="d-none"><i class="fa fa-check-circle" aria-hidden="true"></i>and</li>
      <li><i class="fa fa-check-circle" aria-hidden="true"></i>Personal information that you give us when you visit the
        SearchSpace AI office, such as photographs we
        take of you with your permission, and physical or digital signatures.</li>
    </ul>
    <p class="mb-3">We do not seek to collect sensitive information (for example health information or information about
      your
      racial or ethnic origin or any criminal record), unless it is necessary for our business purposes. If we do
      have to collect sensitive information, we will do so in accordance with the Canadian Privacy Principles.</p>
    <p class="mb-3">During our dealings with you, we may gather information about you relating to all products and
      services
      that we or our business partners provide to you. This information is used to help us decide how we can
      improve our service delivery to you, or to provide you with information that we think may be of interest to
      you.</p>
    <p class="mb-3">We also collect some statistical information about visitors to our website and their attributes by use
      of
      cookies, local storage, use of de-identified or aggregated data, data caches and tracking tags (for example,
      the number of visitors, pages viewed, types of transactions conducted, time online and information
      accessed).&nbsp; Other than for statistical information and user behavior on our website, we do not collect
      any information about you through our website unless you voluntarily provide the information to us.</p>
    <p class="mb-3">Your web browser may automatically accept cookies and local storage on your computer.</p>
    <p class="mb-3">SearchSpace AI also works with selected third parties in providing you services that we think may be
      of
      interest to you. SearchSpace AI may attach a cookie to your browser during your visit to our websites.
      These cookies may be used to provide relevant advertising to you by us or third parties, or to determine
      what marketing or advertising is delivered to you on our website and on other sites.</p>
    <p class="mb-3">You can usually modify your settings to decline cookies and/or local storage if you wish. Note that if
      you
      do that, this might stop you from being able to use all the services on our website. &nbsp;It is usually
      possible to stop your browser accepting cookies, disable local storage or to stop it accepting cookies from
      a particular website. Each browser is different, so check the ‘Help’ menu of your browser to learn how to
      change your preferences. You need not give us any of the personal information that we may request. However,
      without that information, we may not be able to fulfill your request or provide you with the services you
      want.</p>
    <p class="mb-3">If you use a link on our website that takes you to a third-party website,&nbsp;this policy will no
      longer
      operate.&nbsp; Third-party websites are not operated by or endorsed by SearchSpace AI, and the privacy
      policy&nbsp;of that website will apply to any information you give while visiting it.</p>

    <h6 class="mt-4 mb-2">Information collected for particular services</h6>
    <p class="mb-3">We use your personal information to:</p>
    <ul>
      <li><i class="fa fa-check-circle" aria-hidden="true"></i>Respond to your requests and directions;</li>
      <li><i class="fa fa-check-circle" aria-hidden="true"></i>Provide products and services to you and, where possible,
        to provide information customized for you;
      </li>
      <li><i class="fa fa-check-circle" aria-hidden="true"></i>Improve our website, products and services;</li>
      <li><i class="fa fa-check-circle" aria-hidden="true"></i>Contact you from time to time by telephone or email about
        new products, services or offers;</li>
      <li><i class="fa fa-check-circle" aria-hidden="true"></i>Update you about what we’re doing that you may find of
        interest;</li>
      <li><i class="fa fa-check-circle" aria-hidden="true"></i>Deal with complaints or issues you may have in relation to
        our services;</li>
      <li><i class="fa fa-check-circle" aria-hidden="true"></i>Review your dealings with us, including the products and
        services you use, your potential needs, and new
        products that might be of interest to you or other customers;</li>
      <li><i class="fa fa-check-circle" aria-hidden="true"></i>Maintain our internal records, such as billing;</li>
      <li><i class="fa fa-check-circle" aria-hidden="true"></i>Ensure our internal business operations are running
        smoothly which may include fulfilling legal
        requirements and conducting confidential systems maintenance and testing;</li>
      <li><i class="fa fa-check-circle" aria-hidden="true"></i>Help preserve the personal safety and security of our
        customers, website users, staff and the public;
      </li>
      <li><i class="fa fa-check-circle" aria-hidden="true"></i>Provide you with relevant and timely advertising</li>
    </ul>
    <p>Information collected by cookies or tracking tags on our website may be disclosed in a non-identified or
      aggregated format to third parties including website analytics services (e.g., Google Analytics),&nbsp;data
      management platform operators, advertisers, referral partners and media agencies. &nbsp;We do not share your
      personal information with our partners unless it is required for the provision of a particular website
      service, and we have disclosed this to you, and you have consented.</p>

    <h6 class="mt-4 mb-2">Where we share your personal information</h6>
    <p class="mb-3">We must get your consent before we disclose your personal information. You can give us your consent
      expressly or it may be implied by your conduct.</p>
    <p class="mb-3">By using our services, you are deemed to have consented to our use, disclosure and other matters in
      relation to your personal information, as set out in this policy.</p>
    <p class="mb-3">We may share your personal information:</p>
    <ul>
      <li><i class="fa fa-check-circle" aria-hidden="true"></i>To any organization which you request us to, including a
        financial institution, financial adviser,
        service provider, product specialist, broker, solicitor, or accountant;</li>
      <li><i class="fa fa-check-circle" aria-hidden="true"></i>To the cloud, as part of our normal business operations
        (e.g., backing up our systems, operating a
        third-party app on our website);</li>
      <li><i class="fa fa-check-circle" aria-hidden="true"></i>To our external service providers such as IT service
        providers (e.g., a provider of web hosting
        services). Some of these may be located overseas. SearchSpace AI may store your personal information on
        servers in overseas web hosting facilities;</li>
      <li><i class="fa fa-check-circle" aria-hidden="true"></i>To our related companies and other organizations where
        reasonable to do so in relation to the operation
        of our business;</li>
      <li><i class="fa fa-check-circle" aria-hidden="true"></i>To protect and defend our rights or property;</li>
      <li><i class="fa fa-check-circle" aria-hidden="true"></i>If we consider it necessary to do so in order to enforce or
        apply the terms of any of our agreements
        with you;</li>
      <li><i class="fa fa-check-circle" aria-hidden="true"></i>To contractors providing licensed collections services,
        credit card payment processing and CRM services,
        who comply with our privacy and security policies;</li>
      <li><i class="fa fa-check-circle" aria-hidden="true"></i>If we sell our business or part of it;</li>
      <li><i class="fa fa-check-circle" aria-hidden="true"></i>In extreme circumstances, to protect the personal safety of
        our customers, website users, staff or the
        public;</li>
      <li><i class="fa fa-check-circle" aria-hidden="true"></i>To law enforcement, regulatory and government bodies,
        government agencies and courts, if we are required
        by law to do so.</li>
    </ul>
    <p>We take reasonable steps to protect your personal information from misuse, loss, unauthorized access,
      modification, or disclosure.</p>

    <h6 class="mt-4 mb-2">How do we store personal information?</h6>
    <p class="mb-3">We provide a robust level of security when you use our website or when you sign in as a visitor to our
      office. We have physical, electronic and procedural safeguards to protect your information which is held by
      us.</p>
    <p class="mb-3">Access to information stored electronically is restricted to staff and contractors whose job purpose
      requires access. We use secure methods to destroy or de-identify any personal information as soon as the law
      permits, provided the information is no longer needed by us for any purpose.&nbsp;Data is retained for up to
      7 years as required by law. We continuously monitor and update our website security to minimize the risk of
      hacking.</p>
    <p class="mb-3">Data used and stored by SearchSpace AI is mostly hosted in Canada. From time to time SearchSpace AI
      may
      store (disclose) your personal information on servers in overseas web hosting facilities.</p>
    <p class="mb-3">The Canadian Privacy Principles require that we take reasonable steps, dependent on the circumstances
      of
      that personal information, to ensure that the overseas service provider to whom personal information is
      disclosed does not breach Canadian privacy laws (the “Requirement”).</p>
    <p class="mb-3">When you provide us with your personal information you consent to the disclosure of your information
      to an
      overseas provider. &nbsp;&nbsp;Your consent to this disclosure means that the Requirement does not apply and
      SearchSpace AI will not be held accountable under the Privacy Act for any breaches of the Privacy Act by
      the overseas provider.</p>
    <p>Where commercially feasible, SearchSpace AI will contract with hosting providers using services in Canada,
      or who agree to comply with the Canadian Privacy Principles.</p>

    <h6 class="mt-4 mb-2">Amendment of this policy</h6>
    <p class="mb-3">We may amend this policy from time to time. Not all changes to our privacy policy will require your
      consent, for example where office security procedures are changed. We will notify you of any change to our
      information handling policy that requires your consent before being implemented.</p>
    <p>If you do not wish to receive information about our products and services, then please tell us. You can do
      this at any time by writing to us or telephoning us at the contact details listed below.</p>

    <h6 class="mt-4 mb-2">Access to your information</h6>
  <p>You can request access at any time to personal information we hold about you by using the contact details
    below. We will process your request within a reasonable time, usually 14 days for a straightforward request.
    More time may be needed, depending on the nature of the request. There is no fee for requesting access to
    your information; however, we may charge you the reasonable cost of processing your request. Sometimes we
    are not required to provide you with access – for example, if the law says we can deny access. If we refuse
    you access to your personal information, we will tell you the reason why.</p>

  <h6 class="mt-4 mb-2">Correction of information</h6>
  <p class="mb-3">We try to ensure that all information we hold about you which we collect, use or disclose is accurate,
    complete and up to date. You must promptly notify us if there are any changes to your personal information.
    You may ask us at any time to correct personal information held by us about you, which you believe is
    incorrect or out of date. We will deal with your request within a reasonable time</p>
  <p>In this policy, reference to “we/us” means SearchSpace AI</p>

  <h6 class="mt-4 mb-2">Contact Details and Complaints</h6>
  <p class="mb-3">If you have any questions, concerns or complaints about our privacy policy or practices please <span
      class="custom-primary-color">contact us</span></p>
  <p class="mb-3">By email: <a href="mailto:support@searchspaceai.com"><span
        class="custom-primary-color">support@searchspaceai.com</span></a></p>
  <p>You acknowledge and accept that your use of the SearchSpace AI website indicates your acceptance of the
    SearchSpace AI website Privacy Policy.</p>
</div>

        <a href="#" class="top-to-scroll">
            <i class="fa fa-chevron-up" aria-hidden="true"></i>
        </a>

</template>