<script setup>

import Nav from './Nav.vue'
import Footer from './Footer.vue'
import LoginModal from '../modals/LoginModal.vue'
import { ref, watch, computed } from 'vue'
import { router } from '@inertiajs/vue3'
import Toast from 'primevue/toast';
import { useToast } from "primevue/usetoast";
import RegistrationModal from '../modals/RegistrationModal.vue'

import '../../assets/css/style.css'
import '../../../../../app/javascript/entrypoints/application.css'

const toast = useToast();

const props = defineProps({
    csrf_token: {
        type: String,
        required: ""
    },
    all_commodities: {
        type: Array,
        default: []
    },
    all_countries: {
        type: Array,
        default: []
    },
    showLoginmodal: {
        type: Boolean
    },
    showSignupModal: {
        type: Boolean
    },
    errors: Object,
    user: {
        type: Object
    },
    flash: {
        type: Object
    },
    captchaSiteKey: {
        type: String
    },
    env: {
        type: String
    },
    isTopSearchShow: {
        type: Boolean,
        default: true
    },
    is_home_page: {
        type: Boolean,
        default: true
    },
    all_internal_comapnies_with_slug: {
        type: Array,
        default: []
    },
    selectedTopSearchCommoditiesLocal: {
        type: String,
        default: ''
    },
    selectedTopSearchKeywordLocal: {
        type: String,
        default: ''
    },
    selectedTopSearchCountryLocal: {
        type: String,
        default: ''
    },
    is_country_slug: {
        type: Boolean,
        default: false
    }
})

const envComp = computed(() => props.env)

watch(envComp, val => console.log("ENV: ", val), { deep: true, immediate: true })

const flashComputed = computed(() => props.flash)

watch(flashComputed, (val) => {
    if (val?.success) {
        toast.add({ severity: 'success', detail: val.success, life: 5000 });
    }

    if (val?.alert) {
        toast.add({ severity: 'error', detail: val.alert, life: 5000 });
    }

}, { deep: true, immediate: true })

const showLoginUi = ref(false)

const onCloseLogin = () => {
    let params = new URLSearchParams(document.location.search);
    let url = params.get("backUrl");

    if (url) {
        history.back();
    } else {
        router.visit('/')
    }
}
</script>

<template>
    <link rel="stylesheet" href="/css/multiselect.css">

    <div>
        <section v-if="is_home_page">
            <Nav :user="user" :isTopSearchShow="true"
            :all_commodities="all_commodities" 
            :all_countries="all_countries"
            :selectedTopSearchCommoditiesLocal="selectedTopSearchCommoditiesLocal"
            :selectedTopSearchKeywordLocal="selectedTopSearchKeywordLocal"
            :selectedTopSearchCountryLocal="selectedTopSearchCountryLocal" 
            :is_commodity_slug="is_commodity_slug"
            :all_internal_comapnies_with_slug="all_internal_comapnies_with_slug"
            :is_country_slug="is_country_slug"></Nav>
        </section>

        <KeepAlive>
            <Suspense>
                <slot />
                <template #fallback>
                    Loading....
                </template>
            </Suspense>
        </KeepAlive>
        <Footer :csrf_token="csrf_token" :close_pop="close_pop" :captchaSiteKey="props.captchaSiteKey" :is_home_page="is_home_page"/>

        <LoginModal :is-visible="showLoginmodal" :errors="errors" :csrf_token="csrf_token" :user="user"
            @onClose="onCloseLogin" />
        <RegistrationModal :is-visible="showSignupModal" :errors="errors" :csrf_token="csrf_token" @onClose="onCloseLogin"
            :captchaSiteKey="props.captchaSiteKey" />

        <Teleport to="body">
            <Toast position="bottom-left" />
        </Teleport>
    </div>
</template>