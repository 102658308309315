<script setup>
import { ref, computed, onMounted, watch } from "vue";
import { useForm } from "@inertiajs/vue3";
import { useToast } from "primevue/usetoast";
import useSubscribeNewsletter from "./../../../src/hooks/useSubscribeNewsletter";
import useGetTodaysPressReleases from "../../../src/hooks/press-releases/useGetTodaysPressReleases";
import { FilterMatchMode, FilterService } from 'primevue/api';
import AutoComplete from 'primevue/autocomplete';
import BsContactModal from '../../components/modals/ConatcUsModal.vue'
import { VueRecaptcha } from 'vue-recaptcha';
import axios from "axios";

const props = defineProps({
  csrf_token: {
    type: String,
    required: ""
  },
  captcha_sitekey: {
    type: String,
  },
  errors: {
    type: Object,
  },
  country_name_in_slug:{
    type: String,
    required: ""
  },
  stage_name_in_slug:{
    type: String,
    required: ""
  },
  commodity_name_in_slug:{
    type: String,
    required: ""
  },
  deal_type_name_in_slug:{
    type: String,
    required: ""
  },
  is_deal_type_slug: {
    type: Boolean,
    default: false
  },
  is_country_slug: {
    type: Boolean,
    default: false
  },
  is_stage_slug: {
    type: Boolean,
    default: false
  },
  is_commodity_slug: {
    type: Boolean,
    default: false
  },
});

const toast = useToast();
const subscriptionEmail = ref("");
const { subscribeNewsletter } = useSubscribeNewsletter();


const today = computed(() => {
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;
  return today;
})


const { data: newsReleases } = useGetTodaysPressReleases(today)

const releasesLength = computed(() => {
  return newsReleases.value?.length ?? 0
})


const isSubscribing = ref(false);

const clickSubscription = async () => {
  isSubscribing.value = true;
  subscribeNewsletter({
    email: subscriptionEmail.value,
    name: subscriptionEmail.value,
    csrf_token: props.csrf_token,
  })
    .then((res) => {
      if (res.status === 202) {
        subscriptionEmail.value = "";
        isSubscribing.value = false;
        toast.add({
          severity: "success",
          detail: "Thank you for subscribing!",
          life: 5000,
        });
      }
    })
    .catch((err) => {
      isSubscribing.value = false;
      console.log("Error while subscribing", err);
      toast.add({
        severity: "error",
        detail: "Something went wrong.",
        life: 5000,
      });
    });
};

// ===| this is use for the slide new preas releases title |=========================
let releasesIndex = 0;
const relShowIndex = ref(1);

setInterval(() => {
  if (releasesLength.value > releasesIndex) {
    relShowIndex.value = releasesIndex;

    releasesIndex++;
  } else {
    releasesIndex = 0;
  }
}, 4000);
// ==================================================================================

const searchPath = ref('/projects');

const enableSearch = ref(false);
const selectedName = ref();
const filteredNames = ref();
const groupedNames = ref([]);

const search = async (event) => {
    let query = event.query;
    let newFilteredNames = [];

    const response = await axios.get(`api/v1/search`, {
        params: {"keyword": event.query }
    });

    groupedNames.value = response.data;

    for (let gpname of groupedNames.value) {
        let filteredItems = FilterService.filter(gpname.items, ['label'], query, FilterMatchMode.CONTAINS);
        if (filteredItems && filteredItems.length) {
            newFilteredNames.push({...gpname, ...{items: filteredItems}});
        }
    }

    filteredNames.value = newFilteredNames;
}

const checkSearchContent = (event) => {
  enableSearch.value = event.target.value.length > 1
}

const focusSearch = () => {
  document.getElementById('main-search').focus();
}

const goToSearchPage = (event) => {
  searchPath.value = event.value.path;
  window.location.href = searchPath.value;
}

const showContactusmodal = ref(false);
const showModalboday = ref(true);
const showThankyouMsg = ref(false)

const form = useForm({
  email_contact: null,
  name_contact: null,
  message_contact: null,
  authenticity_token: props.csrf_token,
  reCaptchaToken: null
});

const submit = (e) => {
  form.post('/contact/process_contact_form', {
    preserveState: true, onSuccess: () => {
      showModalboday.value = false;
      showThankyouMsg.value = true;
      form.reset();

    }
  })
}

watch(showThankyouMsg, (val) => {
  if (val == true) {
    setTimeout(() => {

      showThankyouMsg.value = false;
      showContactusmodal.value = false;
    }, 5000);
  } else {
    setTimeout(() => {
      form.reset();
      showContactusmodal.value = false;
    }, 1000);
  }
});

const verifyMethod = (response) => {
  form.reCaptchaToken = response;
}

const expiredMethod = () => {
  form.reCaptchaToken = ""
}

const openStats = (stat) => {
  switch (stat) {
    case ('company-profiles'):
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      document.getElementById('companies-menu-link').click();
      document.getElementById('collapseManu').className = 'collapse navbar-collapse show';
      break;
    case ('industry-people'):
      window.location.href = '/people-web';break;
    case ('projects'):
      window.location.href = '/projects';break;
    case ('news-releases'):
      window.location.href = '/press-releases';break;
  }
}

</script>

<template>
  <!-- Announcements -->
  <template v-if="releasesLength > 0">
    <div class="site-announcements">
      <marquee>
        <template v-for="(item, index) in newsReleases">
          <span style="display:inline-block; width: 300px"></span>
          {{ `${item.title} ` }}
        </template>
      </marquee>
    </div>
  </template>
  <!-- Announcements end -->

  <!-- Home banner starts -->
  <div class="container-fluid banner-img tw-bg-gray-400 d-flex justify-content-center align-items-center flex-column px-3">
    <h1 class="mb-2 mt-2">
      Welcome to the Largest Mining Database
    </h1>
    <h4 class="mb-3 mb-sm-4">
      The only all-in-one hub of Junior Mining Industry; Companies, People, Projects, News, and Insights
    </h4>
    <div class="container">
      <div class="d-flex flex-column mt-3 mb-3 align-items-center ">
        <div class="mt-2 search-projects align-items-center">
          <div class="d-flex align-items-center">
            <AutoComplete inputId="main-search" v-model="selectedName"
              @complete="search"
              @item-select="goToSearchPage"
              @focus="checkSearchContent"
              :suggestions="filteredNames"
              optionLabel="label"
              optionGroupLabel="label"
              optionGroupChildren="items"
              minLength="2"
              delay="600"
              :completeOnFocus="enableSearch"
              placeholder="Search for Junior Mining: Companies, People or Projects"
            >
            </AutoComplete>
            <button class="btn btn-outline-secondary" type="submit" id="button-addon" v-on:click.prevent="focusSearch">
              {{ isSubscribing ? 'Please wait...' : 'Search' }}
            </button>
          </div>
          <p class="mt-2 add-project">Not finding yours? 
            <a href="#" class="help-link" data-bs-target="#contactModal" @click="showContactusmodal = !showContactusmodal">
              Learn how to add it here.
            </a>
          </p>
        </div>
      </div>
    </div>

    <div class="mt-4 mb-3 site-stats d-flex justify-content-center">
      <div class="site-stat" @click="openStats('company-profiles')">
        <div class="stat-count">3,000+</div>
        <div class="stat-title">Company Profiles</div>
      </div>
      <div class="site-stat" @click="openStats('industry-people')">
        <div class="stat-count">5,000+</div>
        <div class="stat-title">Industry People</div>
      </div>
      <div class="site-stat" @click="openStats('projects')">
        <div class="stat-count">10,000+</div>
        <div class="stat-title">Projects</div>
      </div>
      <div class="site-stat" @click="openStats('news-releases')">
        <div class="stat-count">80,000+</div>
        <div class="stat-title">News Releases</div>
      </div>
    </div>
  </div>
  <!-- Home banner ends -->

  <!-- Contact Modal -->
  <form :action="`/contact/process_contact_form`" method="POST" @submit.prevent="submit" class="mb-0">
    <BsContactModal :show="showContactusmodal" :modal_name="'ContactUS'">
      <template #modalHeader>
        <h5 class="modal-title" id="exampleModalLabel">Contact Us</h5>
        <button type="button" class="btn-close" aria-label="Close" @click="showContactusmodal = false"></button>
      </template>
      <template #modalBody>
        <div v-show="showModalboday">
          <h6 class="mt-1 mb-3">Send us a message</h6>
          <div class="mb-3">
            <label class="form-label">Name</label>
            <input type="text" class="form-control" v-model="form.name_contact" required>
          </div>
          <div class="mb-3">
            <label class="form-label">Email</label>
            <input type="email" class="form-control" v-model="form.email_contact" required>
          </div>
          <div class="mb-3">
            <label class="form-label">Message</label>
            <textarea class="form-control" v-model="form.message_contact" required></textarea>
          </div>

          <div class="mb-3">
            <vue-recaptcha ref="recaptcha" :sitekey="props.captcha_sitekey" @verify="verifyMethod"
              @expired="expiredMethod"></vue-recaptcha>

            <input type="hidden" class="form-control" v-model="form.reCaptchaToken" required>

            <div class="validation-msg">
              {{ errors?.message }}
            </div>
          </div>

          <button :disabled="form.reCaptchaToken === null" type="submit" class="btn apply-btn custom-primary-btn w-100">{{
            form.processing ? 'Please wait...' :
            'Submit' }} </button>
        </div>
        <div class="Thank-u-msg text-center mt-4" v-show="showThankyouMsg">
          <div class="success-icon"><i class="fa fa-check" aria-hidden="true"></i>
          </div>
          <h6 class="mb-2">Thank you!</h6>
          <p class="">Your message was delivered successfully. <br /> We will get back to you as quick as possible.</p>
        </div>
      </template>
      <template #modalFooter>
        <div class="d-flex align-items-center mb-2">
          <img src="../../assets/img/email.png" alt="Support Email" class="me-2">
          <p>support@searchspaceai.com</p>
        </div>
        <div class="d-flex align-items-center mb-2">
          <img src="../../assets/img/phone.png" alt="Support Phone Number" class="me-2">
          <p>+1 604 283 7679</p>
        </div>
        <div class="d-flex align-items-center mb-2">
          <img src="../../assets/img/address.png" alt="Support Address" class="me-2">
          <p>595 Burrard Street, Suit 1723, Vancouver, BC V7X 1J1.</p>
        </div>
      </template>
    </BsContactModal>
  </form>
  <!-- Contact Modal End-->

</template>

<style scoped lang="scss">
.apply-btn {
  --bs-btn-disabled-bg: #3882ff;
  padding: 5px;
}
</style>