
import { useQuery } from "vue-query";
import axios from "axios";

const fetchData = async () => {
    const response = await axios.get(
      `api/v1/conversations`
    );
    return response.data.data.map(x => {
      x.lastUpdatedDate =  new Date(x.updated_at).toLocaleDateString('en-gb')
      return x
    }) //.map(x => ({conversation_name: x.conversation_name, members: [], property_id: x.property_id}));
};

export default function useGetConversations() {
    return useQuery(
        ["conversations"],
        () => fetchData(),
        {
          refetchOnWindowFocus: true,
          //   placeholderData: [],
          //   staleTime: Infinity,
          onError: (error) => {
            // store.commit("errorsModal/openModal", error.response.data.errors);
          },
        }
    );
}