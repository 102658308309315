<script setup>

import { ref } from 'vue'
import Dialog from 'primevue/dialog';



const props = defineProps({
    link: {
        type: String,
        required: true
    },
    PressReleaseOpen: {
        type: Boolean,
        default: false
    },
    user: {
        type: Object
    },
    downloadBtn: {
        type: Boolean,
        default: false
    }
})

const open = ref(props.PressReleaseOpen)
const openModal = () => {
    open.value = true
    setTimeout(() => {
        let iFrame = document.getElementById('pdfIframe');
        if (iFrame!==null && iFrame.contentDocument) {
            iFrame.src = null
            iFrame.src = "https://drive.google.com/viewerng/viewer?embedded=true&url=" + props.link
            console.log("PDF is loading....")
            openModal()
        }
    }, 3600)
}

const closeModal = () => {
    if (props.PressReleaseOpen) {
        window.location = '/press-releases'
    }
    open.value = false
}
</script>

<template>
    <span @click="openModal" style="padding-left: 0px;">
        <slot>
            Click here to open
        </slot>
    </span>

    <Dialog :visible="open" header=" " :modal="true" :closable="true" @update:visible="closeModal" :baseZIndex="100124001"
        class="pdf-modal">
        <div style=" display: flex; justify-content: space-between;">
            <img style="width: 200px; margin: 1px  0 28px 28px" src="../../assets/img/SSAI.png"  alt="SSAI Logo"/>

            <!-- <a v-if="!user && downloadBtn" class="download-link" href="/login" @click.prevent="showLogin">Download</a>
            <a v-if="user && downloadBtn" class="download-link" :href="link" target="_blank" download>Download</a> -->
        </div>

        <div style="width: 90vw; height: 90vh;" class="tw-bg-white-">
            <iframe
                sandbox="allow-scripts allow-same-origin"
                id="pdfIframe" 
                :src="`https://drive.google.com/viewerng/viewer?embedded=true&url=${link}`"
                style="width: 100%; height: 100%; min-height: 500px;" class="iframe-placeholder">
            </iframe>


        </div>
    </Dialog>

</template>

<style lang="scss">
.download-btn {
    padding: 10px 15px;
    background-color: #3882ff;
    height: 42px;
    border-radius: 5px;
    color: #fff !important;
    margin-right: 10px;
    font-size: 16px !important;
    cursor: pointer;
}

.download-link {
    padding: 10px 15px;
    background-color: #3882ff;
    height: 42px;
    border-radius: 5px;
    color: #fff !important;
    margin-right: 10px;
    font-size: 16px !important;
    cursor: pointer;
}

.pdf-modal.p-dialog {
    box-shadow: none;

    .p-dialog-header {
        padding: 0;
        margin-top: 50px;
     }

    .p-dialog-content:last-of-type {
        padding: 0;
        // overflow: hidden;
    }
    
}

.iframe-placeholder {
    background: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 100% 100%"><text fill="%23d0e1e1" x="50%" y="50%" font-family="\'Lucida Grande\', sans-serif" font-size="20" text-anchor="middle">Loading Please Wait...</text></svg>') 0px 0px no-repeat;
}
</style>