<script setup>

</script>

<template>
    <div class="container createlisting">
        <h4 class="my-5">Create Project Listing</h4>
        <ul class="nav nav-tabs mb-3" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
                <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button"
                    role="tab" aria-controls="home" aria-selected="true">Company Details</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button"
                    role="tab" aria-controls="profile" aria-selected="false">Project Details</button>
            </li>
        </ul>

        <!------------------------------ Company Details Section --------------------------------------------->
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">

                <!------------------------------ Project Owner --------------------------------------------->
                <div class="row g-3 listing-form">
                    <h5 class="mt-5">Project Owner</h5>
                    <div class="col-md-4">
                        <label class="form-label">Company Name</label>
                        <input type="text" class="form-control">
                    </div>
                    <div class="col-md-4">
                        <label class="form-label">Street Address</label>
                        <input type="text" class="form-control">
                    </div>
                    <div class="col-md-4">
                        <label class="form-label">City</label>
                        <input type="text" class="form-control">
                    </div>
                    <div class="col-md-4">
                        <label class="form-label">State</label>
                        <input type="text" class="form-control">
                    </div>
                    <div class="col-md-4">
                        <label class="form-label">Country</label>
                        <select class="form-select">
                            <option selected>Select...</option>
                            <option>...</option>
                            <option>...</option>
                            <option>...</option>
                        </select>
                    </div>
                    <div class="col-md-4">
                        <label class="form-label">Postal Code</label>
                        <input type="text" class="form-control">
                    </div>
                    <div class="col-md-4">
                        <label class="form-label">Phone</label>
                        <input type="text" class="form-control">
                    </div>
                    <div class="col-md-4">
                        <label class="form-label">Stock Exchange</label>
                        <input type="text" class="form-control">
                    </div>
                    <div class="col-md-4">
                        <label class="form-label">Symbol</label>
                        <input type="text" class="form-control">
                    </div>
                    <div class="col-md-12">
                        <label class="form-label">Company Description</label>
                        <textarea class="form-control" rows="3"></textarea>
                    </div>
                    <div class="col-md-4">
                        <label class="form-label">Cash Position</label>
                        <input type="text" class="form-control">
                    </div>
                    <div class="col-md-4">
                        <label class="form-label">As of Date</label>
                        <input type="date" class="form-control">
                    </div>
                </div>

                <!------------------------------------ Contact Details  ---------------------------------------->
                <div class="row g-3 listing-form mt-3">
                    <h5 class="mt-5">Contact Details</h5>
                    <div class="col-md-4">
                        <label class="form-label">Name</label>
                        <input type="text" class="form-control">
                    </div>
                    <div class="col-md-4">
                        <label class="form-label">Mobile Number</label>
                        <input type="text" class="form-control">
                    </div>
                    <div class="col-md-4">
                        <label class="form-label">Email Address</label>
                        <input type="email" class="form-control">
                    </div>
                    <div class="col-12">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox">
                            <label class="form-check-label">
                                Publish contact details
                            </label>
                        </div>
                    </div>
                </div>

                <!------------------------------------ Web Details  ---------------------------------------->
                <div class="row g-3 listing-form mt-1">
                    <h5 class="mt-5">Web Details</h5>
                    <div class="col-md-10">
                        <label class="form-label">Website</label>
                        <input type="text" class="form-control">
                    </div>
                    <div class="col-md-2 d-flex align-items-end">
                        <button type="submit" class="btn custom-primary-btn-outline w-100">Preview</button>
                    </div>
                </div>


                <!------------------------------------Company Documents  ---------------------------------------->
                <div class="row g-3 listing-form mt-3">
                    <h5 class="mt-5">Company Documents</h5>
                    <div class="col-md-10">
                        <div class="text-image">
                            <p>File types : .PDF, .DOCS, .PPTX, .PNG, .JGP, .JPEG, .gif</p>
                            <p>Max Size : 5MB</p>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <button type="submit" class="btn custom-primary-btn-outline w-100">Upload</button>
                    </div>
                    <div class="col-md-12">
                        <div class="d-flex justify-content-center align-items-center upload-file">
                            <img src="../assets/img/upload-file.png" alt="">
                        </div>
                    </div>
                </div>


                <!------------------------------------Company Videos  ---------------------------------------->
                <div class="row g-3 listing-form mt-3">
                    <h5 class="mt-5">Company Videos</h5>
                    <div class="col-md-10">
                        <label class="form-label">Video URL</label>
                        <input type="text" class="form-control">
                    </div>
                    <div class="col-md-2 d-flex align-items-end">
                        <button type="submit" class="btn custom-primary-btn-outline w-100">Add</button>
                    </div>
                </div>

                <div class="row g-3 listing-form mt-4 pb-5 d-flex justify-content-end">
                    <div class="col-md-2">
                        <button type="submit" class="btn custom-secondary-btn-outline w-100">Cancel</button>
                    </div>
                    <div class="col-md-2">
                        <button type="submit" class="btn custom-primary-btn w-100">Next</button>
                    </div>
                </div>
            </div>


            <!------------------------------------------------------------------------------------------------------------- 
                                                         Project Details Section 
                ------------------------------------------------------------------------------------------------------------->
            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">

                <!------------------------------------ Listing Details ---------------------------------------->

                <div class="row g-3 listing-form">
                    <h5 class="mt-5">Listing Details</h5>
                    <div class="col-md-12">
                        <label class="form-label">Name</label>
                        <input type="text" class="form-control">
                    </div>
                    <div class="col-md-12">
                        <label class="form-label">Description</label>
                        <textarea class="form-control" rows="3"></textarea>
                    </div>
                    <div class="col-md-10">
                        <label class="form-label">Project Home Page Link</label>
                        <input type="text" class="form-control">
                    </div>
                    <div class="col-md-2 d-flex align-items-end">
                        <button type="submit" class="btn custom-primary-btn-outline w-100">Preview</button>
                    </div>
                </div>


                <!------------------------------------------- Project Details  --------------------------------------------->
                <div class="row g-3 listing-form mt-3">
                    <h5 class="mt-5">Project Details</h5>
                    <div class="col-md-4">
                        <label class="form-label">Name</label>
                        <input type="text" class="form-control">
                    </div>
                    <div class="col-md-4">
                        <label class="form-label">Claim Size Unit</label>
                        <select class="form-select">
                            <option selected>Select...</option>
                            <option>...</option>
                            <option>...</option>
                            <option>...</option>
                        </select>
                    </div>
                    <div class="col-md-4">
                        <label class="form-label">Stage</label>
                        <select class="form-select">
                            <option selected>Select...</option>
                            <option>...</option>
                            <option>...</option>
                            <option>...</option>
                        </select>
                    </div>
                    <div class="col-md-4">
                        <label class="form-label">Primary Commodity</label>
                        <select class="form-select">
                            <option selected>Select...</option>
                            <option>...</option>
                            <option>...</option>
                            <option>...</option>
                        </select>
                    </div>
                    <div class="col-md-4">
                        <label class="form-label">Secondary Commodity</label>
                        <select class="form-select">
                            <option selected>Select...</option>
                            <option>...</option>
                            <option>...</option>
                            <option>...</option>
                        </select>
                    </div>
                    <div class="col-md-4">
                        <label class="form-label">Country</label>
                        <select class="form-select">
                            <option selected>Select...</option>
                            <option>...</option>
                            <option>...</option>
                            <option>...</option>
                        </select>
                    </div>
                </div>

                <!------------------------------------------- Deal Details  --------------------------------------------->
                <div class="row g-3 listing-form mt-3">
                    <h5 class="mt-5">Deal Details</h5>
                    <div class="col-md-4">
                        <label class="form-label">Preferred Deal Type</label>
                        <select class="form-select">
                            <option selected>Select...</option>
                            <option>...</option>
                            <option>...</option>
                            <option>...</option>
                        </select>
                    </div>
                </div>

                <!-------------------------------------------   project IMages   ---------------------------------------->
                <div class="row g-3 listing-form mt-3">
                    <h5 class="mt-5">Project Images </h5>
                    <div class="col-md-10">
                        <div class="text-image">
                            <p>File types : .PDF, .DOCS, .PPTX, .PNG, .JGP, .JPEG, .gif</p>
                            <p>Max Size : 5MB</p>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <button type="submit" class="btn custom-primary-btn-outline w-100">Upload</button>
                    </div>
                    <div class="col-md-12">
                        <div class="d-flex justify-content-center align-items-center upload-file">
                            <img src="../assets/img/upload-images.png" alt="">
                        </div>
                    </div>
                </div>

                <!------------------------------------     Project Videos    ---------------------------------------->
                <div class="row g-3 listing-form mt-3">
                    <h5 class="mt-5">Project Videos</h5>
                    <div class="col-md-10">
                        <label class="form-label">Video URL</label>
                        <input type="text" class="form-control">
                    </div>
                    <div class="col-md-2 d-flex align-items-end">
                        <button type="submit" class="btn custom-primary-btn-outline w-100">Add</button>
                    </div>
                </div>

                <!------------------------------------Project Documents  ---------------------------------------->
                <div class="row g-3 listing-form mt-3">
                    <h5 class="mt-5">Project Documents</h5>
                    <div class="col-md-10">
                        <div class="text-image">
                            <p>File types : .PDF only</p>
                            <p>Max Size : 5MB</p>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <button type="submit" class="btn custom-primary-btn-outline w-100">Upload</button>
                    </div>
                    <div class="col-md-12">
                        <div class="d-flex justify-content-center align-items-center upload-file">
                            <img src="../assets/img/upload-file.png" alt="">
                        </div>
                    </div>
                </div>

                <!---------------------------------------    Project Locations   ------------------------------------------->
                <div class="row g-3 listing-form mt-3">
                    <h5 class="mt-5">Project Location</h5>
                    <div class="col-md-4">
                        <label class="form-label">Country</label>
                        <input type="text" class="form-control">
                    </div>
                    <div class="col-md-4">
                        <label class="form-label">Region</label>
                        <input type="text" class="form-control">
                    </div>
                    <div class="col-md-4">
                        <label class="form-label">City</label>
                        <input type="text" class="form-control">
                    </div>
                    <div class="col-md-4">
                        <label class="form-label">Latitude</label>
                        <input type="text" class="form-control">
                    </div>
                    <div class="col-md-4">
                        <label class="form-label">Longitude</label>
                        <input type="text" class="form-control">
                    </div>
                </div>

                <!----------------------------------------------- Button section  ------------------------------------------->

                <div class="row g-3 listing-form mt-4 pb-5 d-flex justify-content-end align-items-center">
                    <div class="col-md-8">
                        <a class="text-primary" href="#">Back</a>
                    </div>
                    <div class="col-md-2">
                        <button type="submit" class="btn custom-secondary-btn-outline w-100">Cancel</button>
                    </div>
                    <div class="col-md-2">
                        <button type="submit" class="btn custom-primary-btn w-100">Create Listing</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>