<script setup>
import { ref,onMounted} from 'vue'
import { router, usePage, Head } from '@inertiajs/vue3'
import Nav from '../../components/layout/Nav.vue'
import headComponent from "../../components/inertiaHead/head.vue"

import ScreenLoader from '../../components/ScreenLoader.vue'

const props = defineProps({

    is_current_plan_expired: {
        type: Boolean,
        defult: false
    },
    user: {
        type: Object
    },
    strip_key: {
        type: String
    }

})

var stripe = ""
onMounted(() => {
        if ( Stripe !== 'undefined') {
            stripe = Stripe(props.strip_key);
        }
    })
const isLoading = ref(false)

function getStripSession(plan) {
    isLoading.value = true
    fetch('/api/stripe/get_session?plan=' + plan)
        .then(function(res) {
            return res.json()
        })
        .then(function(res) {
            isLoading.value = false
            stripe.redirectToCheckout({
                sessionId: res.session.id
            })
            .then(function(res) {
                console.log(res)
            })
        })
}

const cancelSubscription = () => {
    router.visit('/cancel_subscription', {
        method: 'get',
        onStart: () => {
            isLoading.value = true

        },
        onFinish: () => {
            isLoading.value = false

        }
    })
}

// console.log(stripe)
// $('#btn-basic').click(getStripSession('basic'))
</script>

<template>
    <headComponent :description="'Access your account settings at SearchSpace AI. Customize your experience as you explore the world of mining and mining stocks.'"
          :title="'Manage SearchSpace AI Account'">
    </headComponent>
    <ScreenLoader :show="isLoading"/>
    <div class="container my-account-pg pb-5">
        <div class="tw-text-center">
            <h1 class="mt-5 mb-0 h5">Manage Your Account | SearchSpace AI</h1>
            <h6 class="mt-1 mb-0 text-center">Manage your SearchSpace AI subscription</h6>
        </div>
        <div v-if="(user.plan == 'none' || user.plan == 'trial' || user.plan == 'free')">
            <div class="d-flex align-items-center flex-column my-account-titles">
                <!-- <h5 class="mt-5 mb-0">My Account</h5>
                <h6 class="mt-1 mb-0 text-center">Manage your SearchSpaceGeo subscription</h6> -->
                <!-- <p class="mt-1 mb-0 text-center">Upgrade your trial account to a paid account by selecting a pricing plan</p> -->
                <p class="mt-1 mb-0 text-center">
                <div v-if="user.plan == 'trial' && !is_current_plan_expired">
                    Upgrade your trial account to a paid account by selecting a pricing plan.
                </div>
                <div class="mt-3 alert alert-warning" role="alert" v-else-if="is_current_plan_expired">
                    <span>
                        Your trial period has expired. Please upgrade to a pricing plan to activate your listings.
                    </span>
                </div>
                <div v-else>
                    You will need to upgrade to a paid plan in order to activate your listing.
                </div>
                </p>
            </div>
            <div class="row gx-5 gy-5 gy-lg-1 mt-4 d-flex justify-content-center align-items-end">
                <div class="col-md-4 pricing-plan">
                    <div class="card pricing-plan-cards pt-3">
                        <h5 class="text-center">Basic</h5>
                        <div class="horizontal-seperator"></div>
                        <div class="card-body pt-0">
                            <h1 class="text-center"><sup>$</sup>100<sub>/Mo</sub></h1>
                            <div class="d-flex align-items-center mt-3">
                                <img src="../../assets/img/access-true.png" alt="1 Lisitng Include">
                                <p class="ms-2">Includes <b>1</b> listing</p>
                            </div>
                            <div class="horizontal-seperator"></div>
                            <div class="d-flex align-items-center">
                                <img src="../../assets/img/access-true.png" alt="Create & Edit Listings For Basic Plan">
                                <p class="ms-2"><b>Create</b> and <b>Edit</b> your listing</p>
                            </div>
                            <div class="horizontal-seperator"></div>
                            <div class="d-flex align-items-center">
                                <img src="../../assets/img/access-true.png" alt="Featured Listings For Basic Plan">
                                <p class="ms-2"><b>Featured</b> listing</p>
                            </div>
                            <div class="horizontal-seperator"></div>
                            <div class="d-flex align-items-center">
                                <img src="../../assets/img/access-false.png" alt="Viewer analytics Locked For Basic Plan">
                                <p class="ms-2"><b>Viewer analytics</b></p>
                            </div>
                            <div class="horizontal-seperator"></div>
                            <div class="d-flex justify-content-center">
                                <button @click="() => getStripSession('basic')" class="btn custom-primary-btn w-100" id="btn-basic">Select</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 pricing-plan">
                    <div class="popular-plan">
                        <h5 class="text-center">POPULAR PLAN</h5>
                    </div>
                    <div class="card pricing-plan-cards popular-plan-section pt-3">
                        <h5 class="text-center">Operator</h5>
                        <div class="horizontal-seperator"></div>
                        <div class="card-body pt-0">
                            <h1 class="text-center"><sup>$</sup>250<sub>/Mo</sub></h1>
                            <div class="d-flex align-items-center mt-3">
                                <img src="../../assets/img/access-true.png" alt="3 Lisitng Includes">
                                <p class="ms-2">Includes up to <b>3</b> listings</p>
                            </div>
                            <div class="horizontal-seperator"></div>
                            <div class="d-flex align-items-center">
                                <img src="../../assets/img/access-true.png" alt="Create & Edit Listings For Operator Plan">
                                <p class="ms-2"><b>Create</b> and <b>Edit</b> your listings</p>
                            </div>
                            <div class="horizontal-seperator"></div>
                            <div class="d-flex align-items-center">
                                <img src="../../assets/img/access-true.png" alt="Featured Listings For Operator Plan">
                                <p class="ms-2"><b>Featured</b> listings</p>
                            </div>
                            <div class="horizontal-seperator"></div>
                            <div class="d-flex align-items-center">
                                <img src="../../assets/img/access-false.png" alt="Viewer analytics Locked For Operator Plan">
                                <p class="ms-2"><b>Viewer analytics</b></p>
                            </div>
                            <div class="horizontal-seperator"></div>
                            <div class="d-flex justify-content-center">
                                <button @click="() => getStripSession('operator')" class="btn custom-primary-btn w-100">Select</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 pricing-plan">
                    <div class="card pricing-plan-cards pt-3">
                        <h5 class="text-center">Company</h5>
                        <div class="horizontal-seperator"></div>
                        <div class="card-body pt-0">
                            <h1 class="text-center"><sup>$</sup>500<sub>/Mo</sub></h1>
                            <div class="d-flex align-items-center mt-3">
                                <img src="../../assets/img/access-true.png" alt="15 Lisitng Includes">
                                <p class="ms-2">Includes up to <b>15</b> listings</p>
                            </div>
                            <div class="horizontal-seperator"></div>
                            <div class="d-flex align-items-center">
                                <img src="../../assets/img/access-true.png" alt="Create & Edit Listings For Company Plan">
                                <p class="ms-2"><b>Create</b> and <b>Edit</b> your listings</p>
                            </div>
                            <div class="horizontal-seperator"></div>
                            <div class="d-flex align-items-center">
                                <img src="../../assets/img/access-true.png" alt="Featured Listings For Company Plan">
                                <p class="ms-2"><b>Featured</b> listings</p>
                            </div>
                            <div class="horizontal-seperator"></div>
                            <div class="d-flex align-items-center">
                                <img src="../../assets/img/access-true.png" alt="Viewer analytics Unlocked For Company Plan">
                                <p class="ms-2"><b>Viewer analytics</b></p>
                            </div>
                            <div class="horizontal-seperator"></div>
                            <div class="d-flex justify-content-center">
                                <button @click="() => getStripSession('company')"  class="btn custom-primary-btn w-100">Select</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tw-mt-4" v-else>
            <div v-if="(user.plan != 'none' && user.plan != 'trial' && user.plan != 'free')">
                <div class="d-flex align-items-center flex-column my-account-titles-">
                    <p class="text-center">You are currently <b>subscribed</b> to the <b>{{user.plan }} paid plan</b>.</p>
                    <p class="text-center mb-5">
                        If you choose to cancel, your listings will not be removed from your account.
                        <br />
                        However, they will be deactivated and will not be promoted in search results.
                        <br />
                        <br />
                        <!-- <a href="/cancel_subscription"> -->
                        <button @click="cancelSubscription" class=" btn custom-primary-btn px-3 py-2" id='btn-cancel'> Cancel Subscription </button>
                        <!-- </a> -->
                    </p>
                </div>
           </div>
        </div>
    </div>
</template>