<template>
  <div>
    <div class="slider">
      <!-- <div class="inner-listings-img d-flex justify-content-center align-items-end"> -->
      <div v-for="(image, index) in images" :key="index" v-show="currentIndex === index" style="height: 100%;">
        <GalleryImagePreview :images="images" v-slot="slotProps">
          <div v-show="images.length == 1" style="height: 100%;">
            <img :src="image" @click="slotProps.preview(index)" :style="'cursor: pointer;'" :alt="'Project Image'" />
          </div>
          <div v-show="images.length > 1" style="height: 100%;">
            <img :src="image" :alt="'Project Images'"/>
          </div>
          <div class="d-flex justify-content-end slider-view-more-btn" v-if="images.length > 1">
            <button @click="slotProps.preview(index)"><i class="fa fa-th me-2" aria-hidden="true"></i>View More</button>
          </div>
        </GalleryImagePreview>
      </div>
      <!-- </div> -->
    </div>
    <!-- <div class="slider-nav">
          <button @click="prevImage">&lt;</button>
          <button @click="nextImage">&gt;</button>
        </div> -->
    <!-- <div id="carouselExampleControls" class="carousel slide" data-bs-interval="false">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <div class="row d-flex gx-1 gx-md-3 pb-3 justify-content-center carousel-imgs">
            <div class="col-4" v-for="(item, index) in images">
              <img :src="item" class="w-100" alt="..." />
            </div>

          </div>
        </div>
      </div>
      <button class="carousel-control-prev pb-3" type="button"  data-bs-target="#carouselExampleControls"
        @click="prevImage" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" v-bind:style="{ 'background-color': 'black' , 'border': '2px solid #909090', 'border-radius': '20px'}" aria-hidden="true">
                                            </span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next pb-3" type="button" data-bs-target="#carouselExampleControls"
        @click="nextImage" data-bs-slide="next">
        <span class="carousel-control-next-icon" v-bind:style="{ 'background-color': 'black', 'border': '2px solid #909090', 'border-radius': '20px' }" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div> -->
  </div>
</template>

<script>
import GalleryImagePreview from '../../../src/components/GalleryImagePreview.vue';

export default {
  props: {
    imageUrl: {
      type: Array,
      default: []
    },
    baseImageUrl: {
      type: String,
    }
  },

  data() {
    return {
      images: [],
      currentIndex: 0
    };
  },
  mounted() {
    this.images = this.imageUrl
  },
  methods: {
    prevImage() {
      if (this.currentIndex === 0) {
        this.currentIndex = this.images.length - 1;
      } else {
        this.currentIndex--;
      }
    },
    nextImage() {
      if (this.currentIndex === this.images.length - 1) {
        this.currentIndex = 0;
      } else {
        this.currentIndex++;
      }
    }
  },
  components: {
    GalleryImagePreview,
  }
};
</script>

<style>
.slider {
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;
  border: 2px solid #49505733;
  /* border: 2px solid #909090; */
  border-radius: 10px;
}

.slider-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  transition: transform 0.5s ease;
}

.slider-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.slider-nav button {
  border: none;
  background: transparent;
  font-size: 2em;
  color: white;
  cursor: pointer;
}
</style>
