<script setup>
import { router, useForm } from "@inertiajs/vue3";
import Nav from '../../components/layout/Nav.vue'

const props = defineProps({
    csrf_token: {
        type: String,
        default: ''
    },
    id: {
        type: String,
        default: ''
    }

})
const form = useForm({
    password: null,
    id: props.id,
    authenticity_token: props.csrf_token,
})

const submit = (e) => {
    form.post('/lost-password-reset', {
        preserveState: true, onSuccess: () => {
       }
    })

};
</script>

<template>
    <div class="container px-sm-0 d-flex justify-content-center mt-5">
        <div class="lost-pw">
            <h4 class="text-center">Password Reset</h4>
            <p class="text-center mb-4">Enter your new password</p>
            <div class="row justify-content-center">
                <div class="col">
                    <form :action="`/lost-password-reset`" method="POST" @submit.prevent="submit">
                    <div class="form-group">
                        <input required autofocus="autofocus" autocomplete="off" type="password" name="reset_password[password]" id="reset_password"
                        v-model="form.password"   class="form-control" placeholder="New Password*">
                    </div>
                    <div class="horizontal-seperator"></div>
                    <button class="btn custom-primary-btn w-100" type="submit"> {{ form.processing ? 'Please wait...' : 'Reset'
                }}</button>
                </form>
                </div>
            </div>
        </div>
    </div>
</template>