<script setup>
import { ref, computed, reactive } from "vue";
import { router, useForm } from "@inertiajs/vue3";
import Nav from '../../components/layout/Nav.vue'
const props = defineProps({
    csrf_token: {
        type: String,
        default: "",
    },
    errormessage: {
        type: String,
        default: "",
    }
});
const form = useForm({
    email: null,
    authenticity_token: props.csrf_token,
})

const submit = (e) => {
    form.post('/verify_account_send', {
        preserveState: true, onSuccess: () => {

            console.log('on successs...')
        }
    })

};
</script>

<template>
    <div class="container px-sm-0 d-flex justify-content-center mt-5 verify-acc">
        <div>
            <h4 class="text-center mb-4">Verify your email</h4>
            <p class="text-center">Enter your email</p>

            <form :action="`/verify_account_send`" method="POST" @submit.prevent="submit"><br>

                <input required autofocus="autofocus" v-model="form.email" name="email" id="email" autocomplete="off"
                    type="text" class="form-control" placeholder="Email">
               <br>
                    <button type="submit" class="btn custom-primary-btn w-100"> {{ form.processing ? 'Please wait...' : 'Submit'
                }} </button>
                
               

            </form>
                <p class='text-center text-danger' v-if="errormessage != ''">
                    {{ errormessage }}
                </p>
            
        </div>
    </div>
</template>