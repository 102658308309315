import { useMutation, useQueryClient } from "vue-query";
import axios from "axios";
import { ref, toRefs, computed, reactive } from "@vue/reactivity";
import _ from 'lodash'

const action = async (id) => {

  await axios.delete(`/api/v1/press-releases/${id}`, {
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      'X-CSRF-Token': document.querySelector('[name=authenticity_token]').value,
    }
  });
};

export default function useDeletePressRelease() {
  const cache = useQueryClient();
  
  const mutation = useMutation(
    (id) => action(id),
    {
      
      onSuccess: (data, variables, context) => {
        cache.invalidateQueries('pressreleases')
      },
      onError: (err, newMessageItem, context) => {
        console.error('Error while deleting the press release: ', err)

      },
      onSettled: (data, error, variables, context) => {},
    }
  );

  return reactive({
    deletePressRelease: mutation.mutateAsync,
    deletePressReleaseStatus: mutation,
  });
}
