<script setup>
import { ref, computed } from 'vue'
import VueMultiselect from 'vue-multiselect'

const props = defineProps({
    name: {
        type: String,
        default: 'NA',
    },
    multiple: {
        type: Boolean,
        default: true,
    },
    modelValue: {
        required: true,
    },
    options: {
        required: true
    }
})

const emit = defineEmits(['update:modelValue'])

const optionsLocal = ref(props.options)

const searchOption = (query) => {
    return optionsLocal.value = props.options.filter(i => i.toLowerCase().startsWith(query.toLowerCase()))
}

const update = (val, id) => {
    console.log('Update.', val)
    console.log('Update.iddd', id)
}
const isfilterted = computed(() => {
    return optionsLocal.value.length != props.options.length

})
</script>

<template>
    <VueMultiselect 
        placeholder="Select" 
        :class="{ 'is_filtered': isfilterted }" 
        :show-labels="false" 
        :multiple="multiple"
        :modelValue="modelValue" 
        @update:modelValue="emit('update:modelValue', $event)" 
        :searchable="true"
        :options="optionsLocal" 
        v-on:search-change="searchOption">
    </VueMultiselect>
</template>