<script setup>
import { ref, onBeforeUnmount, computed } from 'vue'
import _ from 'lodash'
// import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'
import Dialog from 'primevue/dialog';
import useGetConversationForProperty from './../../hooks/useGetConversationForProperty'
import useDeleteConversation from './../../hooks/useDeleteConversation'
import Conversation from './Conversation.vue'
import Skeleton from 'primevue/skeleton';

import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css';

const props = defineProps({
  propertyId: {
    type: String,
    required: true,
  }
})

const emits = defineEmits(['close'])

const {data, isLoading, isSuccess} = useGetConversationForProperty(props.propertyId)
const { deleteConversation, deleteConversationStatus } = useDeleteConversation()


const showModal = ref(true)

const close = (e) => {
  emits('close')
}
const conversationId = computed(() => _.get(data.value, '_id.$oid', 'undefined') )

onBeforeUnmount(() => {
  const count = _.get(data.value, 'total_messages', '1')  
  if (count == 0) deleteConversation({conversationId: conversationId.value})
})

</script>

<template>
  <Dialog :visible="true" header="Contact" :modal="true" :closable="true" @update:visible="close" :baseZIndex="100124001">
      <div class="tw-w-[22rem] xs:tw-w-[30rem] sm:tw-w-[40rem]">
        <template v-if="isLoading">          
          <div class="tw-flex tw-my-2" v-for="i in 2" :key="i">
              <Skeleton shape="circle" size="4rem" class="mr-2"></Skeleton>
              <div style="flex: 1">
                  <Skeleton width="100%" class="mb-2"></Skeleton>
                  <Skeleton width="75%"></Skeleton>
              </div>
          </div>          
        </template>

        <template v-if="isSuccess && data !== null">
            <div class="tw-min-h-[70vh] tw-grid">
              <Conversation :conversation="data"/>
            </div>
        </template>

        <div v-if="isSuccess && data == null">
          No conversation found
        </div>
      </div>
  </Dialog>


</template>

<style scoped lang="scss">
</style>
