<script setup>
import { computed, watch, onMounted, ref } from 'vue'

const props = defineProps({
  captchaSiteKey: {
    type: String,
  },
    show: Boolean,
    blurBackdrop: false,
})

const isVisible = computed(() => props.show)

const modalRef = ref(null)
let thisModalObj = null;

watch(isVisible, (val) => {
    if (val) {
        thisModalObj.show();
    } else {
        thisModalObj.hide();
    }

})

onMounted(() => {

    if (typeof window !== 'undefined') {
        import('bootstrap').then((bootstrap) => {
            const { Modal } = bootstrap;
            thisModalObj = new Modal(modalRef.value);

        });
    }
})


</script>

<template>
     <div ref="modalRef" class="modal fade" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable pt-5">
                <div class="modal-content">
                    <div class="modal-header">
                        <slot name="modalHeader"> </slot>
                    </div>
                    <div class="modal-body contact-modal-body">
                        <slot name="modalBody"> </slot>
                        <div class="contact-info p-3 mt-3">
                            <slot name="modalFooter"> </slot>
                        </div>
                    </div>
                </div>
            </div>
        </div>
   
</template>