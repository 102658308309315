<script setup>

import Skeleton from 'primevue/skeleton';
import ScrollPanel from 'primevue/scrollpanel';

import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css';

// import { useQueryProvider } from "vue-query";

// useQueryProvider();
// import { VueQueryDevTools } from "vue-query/devtools";

import { defineAsyncComponent, computed, ref, watch } from 'vue'
import useGetConversations from '../../hooks/useGetConversations'
// const Conversation = defineAsyncComponent(() =>
//   import('../../components/conversation/Conversation.vue')
// )

import Conversation from '../../components/conversation/Conversation.vue'
import ConversationListItem from '../../components/conversation/ConversationListItem.vue'

const { data: conversations, isLoading, isSuccess } = useGetConversations()
const props = defineProps({
     loggedInUserEmail: {
          type: String,
          required: true,
     }
})

const activeConversation = ref(null)


watch(conversations, (val, oldVal) => {
     if (val && val.length) {
          activeConversation.value = val[0]
     }
}, { deep: true })

</script>

<template>
     <!-- <VueQueryDevTools /> -->
     <div class=" bg_color_1">
          <div class="container margin_80_55">
               <h1 class="h5">My Messages | SearchSpace AI Communication</h1>

               <div v-if="isSuccess && conversations.length" class="md:tw-flex tw-shadow-md-- message-inbox-outer">
                    <!-- Left -->
                    <div class="tw-pt-4- md:tw-w-1/3 tw-border-0 md:tw-border-r md:tw-border-solid md:tw-border-gray-200 p-3">
                         <div class="tw-flex tw-flex-col tw-gap-4-">
                              <template v-if="isLoading">
                                   <div v-for="x in 3" :key="x" class="tw-flex tw-w-full tw-items-start tw-py-2 tw-px-4">
                                        <Skeleton shape="square" size="3rem" class="mr-2"></Skeleton>
                                        <div style="flex: 1"
                                             class="tw-flex tw-items tw-flex-col tw-self-stretch tw-justify-around tw-h-auto tw-space-y-0">
                                             <Skeleton v-for="i in 3" :key="i" height="10px" width="75%" class="mb-2">
                                             </Skeleton>

                                        </div>
                                   </div>
                              </template>
                              <template v-else>
                                   <div class="tw-flex-1 message-scroll tw-min-h-[23vh] md:tw-min-h-[70vh] tw-grid">
                                        <ScrollPanel style="width: 100%" id="scrollpanel"
                                             class="custombar1 tw-min-h-full tw-h-5 tw-bg-gray-50 pb-3">
                                             <div v-for="(item, i) in conversations" :key="i"
                                                  @click="activeConversation = item" class="tw-shadow">
                                                  <ConversationListItem :activeConversation="activeConversation"
                                                       :conversation="item" :owner="loggedInUserEmail" />
                                             </div>
                                        </ScrollPanel>
                                   </div>
                              </template>
                         </div>
                    </div>
                    <!-- Right -->
                    <div class="tw-flex-1 tw-min-h-[70vh] tw-grid">
                         <div v-if="activeConversation !== null"
                              class="col-sm-9- tw-px-4 tw-pt-4- inbox-right-outer-- tw-h-full pt-3">
                              <Conversation :conversation="activeConversation" :loggedInUserEmail="loggedInUserEmail" />
                         </div>
                         <div v-else class="tw-flex tw-justify-center tw-items-center">
                              <p class="tw-mb-0 tw-text-gray-200 tw-font-normal tw-text-xl tw-max-w-md tw-text-center">Please
                                   select a conversation to see the messages.</p>
                         </div>
                    </div>
               </div>

               <div v-else class="tw-flex tw-h-[40vh] tw-justify-center tw-items-center tw-text-xl">
                    <div v-if="isLoading" class="">
                         <span>Loading...</span>
                    </div>
                    <div v-if="isSuccess && !conversations.length" class="">
                         <span>You have no messages.</span>
                    </div>
               </div>
          </div>
     </div>
</template>

<style scoped lang="scss">
::v-deep(.p-scrollpanel) {

     //    p {
     //        padding: .5rem;
     //        line-height: 1.5;
     //        margin: 0;
     //    }
     /* padding: 15px; */
     .p-scrollpanel-content {
          padding-bottom: 16px;
          /* padding-right: 0px; */
          // padding-right: 0;
     }

     &.custombar1 {
          .p-scrollpanel-wrapper {
               /* border-right: 9px solid var(--surface-ground); */
          }

          .p-scrollpanel-bar {
               background-color: var(--gray-400);
               ;
               opacity: 1;
               transition: background-color .2s;

               &:hover {
                    background-color: #007ad9;
               }
          }

          /* .p-scrollpanel-bar-y {
               right: -413px !important;
          } */
     }

     &.custombar2 {
          .p-scrollpanel-wrapper {
               border-right: 9px solid var(--surface-border);
               border-bottom: 9px solid var(--surface-border);
          }

          .p-scrollpanel-bar {
               background-color: var(--surface-ground);
               border-radius: 0;
               opacity: 1;
               transition: background-color .2s;
          }
     }
}</style>