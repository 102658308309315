import { reactive } from 'vue'
import _ from 'lodash'

export const mapStore  = reactive({
    clickedMarker: null,
    projects: [],
    externalProjects: [],

    fillProjects(projectsArray) {
        let companies = _.groupBy(projectsArray, 'company_name');
        
        for(let company in companies) {
            let projects = companies[company]

            for(let i = 0; i < projects.length; i++) {
                projects[i]['companyOtherProjects'] = []

                let nextP =  _.get(projects, `[${i+1}]`, undefined)
                let prevP =  _.get(projects, `[${i-1}]`, undefined)

                if(nextP && prevP) {
                    projects[i]['companyOtherProjects'][0] = prevP
                    projects[i]['companyOtherProjects'][1] = nextP
                    continue
                }


                if(!prevP && projects[projects.length - 1]._id.$oid !== projects[i]._id.$oid) {
                    prevP = projects[projects.length - 1]
                }

                if(!nextP && projects[0]._id.$oid !== projects[i]._id.$oid) {
                    nextP = projects[0]
                }

                if((nextP && prevP) && nextP._id.$oid !== prevP._id.$oid) {
                    projects[i]['companyOtherProjects'][0] = prevP
                    projects[i]['companyOtherProjects'][1] = nextP
                    continue
                }

                if(!nextP && !prevP) continue

                projects[i]['companyOtherProjects'][0] = prevP || nextP
                
            }
        }
        this.projects = projectsArray

    },
})