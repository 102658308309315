import { useMutation, useQueryClient } from "vue-query";
import axios from "axios";
import { ref, toRefs, computed, reactive } from "@vue/reactivity";
import _ from 'lodash'

const action = async (payload) => {
  const { company_name , commodity , country , is_subscribed, subscription_owner_email = ''} = payload


  await axios.post(`/api/v1/press-releases-subscription`, {
    company_name, 
    commodity,
    country,
    subscription_owner_email,
    is_subscribed
  }, {
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      'X-CSRF-Token': document.querySelector('[name=authenticity_token]').value,
    }
  });
};

export default function useCreatePressRelease() {
  const cache = useQueryClient();
  
  const mutation = useMutation(
    (payload) => action(payload),
    {
      
      onSuccess: (data, variables, context) => {
        cache.invalidateQueries('pressreleasesSubscription')
      },
      onError: (err, newMessageItem, context) => {
        console.error('Error while creating the subscription: ', err)

      },
      onSettled: (data, error, variables, context) => {},
    }
  );

  return reactive({
    createPressReleaseSubscription: mutation.mutateAsync,
    createPressReleaseSubscriptionStatus: mutation,
  });
}
