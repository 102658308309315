<template>
  <div class="tw-relative" v-if="map_view_current_page == 'NewsReleasesMapView'">

    <div id="announcement-mobile" class="tw-hidden tw-fixed tw-bottom-0 tw-left-0 tw-w-full tw-z-10 tw-p-4" style="bottom: 100px;" :style="{ 'display': shouldShowDt }">
      <AnnouncementComponent type="mobile" :view="'MapViewForNewsReleases'" :user="user" :is_banner_closed="true"/>

    </div>
    <div class="container sm:tw-absolute tw-left-0 tw-right-0 tw-z-[1] tw-pointer-events-none sm:tw-flex"
      style="max-width: 1500px;">

      <div id="announcement-dt"
        class="tw-pointer-events-auto tw-max-w-[160px] tw-mr-4 tw-h-[640px] tw-max-h-[68vh] tw-mt-3 tw-hidden sm:tw-block" :style="{ 'display': 'none' }">
        <AnnouncementComponent type="desktop" :view="'MapViewForNewsReleases'" :user="user" :is_banner_closed="true" />
      </div>
    </div>
    <GMapMap :center="centerLocation" class="gmap tw-relative" :options="options" ref="myMapRef" :zoom="4"
      map-type-id="terrain" style="width: 100vw; height: 900px">

      <ProjectMarkerForNewsReleases :id="project._id.$oid" :externalProject="false" :project="project"
        v-for="(project, index) in mapStore.projects" :key="index" :lat="parseFloat(project.latitude)"
        :lng="parseFloat(project.longitude)" :baseImageUrl="baseImageUrl" :all_internal_comapnies_with_slug="all_internal_comapnies_with_slug" >

      </ProjectMarkerForNewsReleases>

      <ProjectMarkerForNewsReleases :id="project._id.$oid" :externalProject="true" :project="project"
        v-for="(project, index) in externalProjects" :key="index" :lat="parseFloat(project.lat)"
        :lng="parseFloat(project.long)" :baseImageUrl="baseImageUrl" :all_internal_comapnies_with_slug="all_internal_comapnies_with_slug">

      </ProjectMarkerForNewsReleases>
    </GMapMap>
  </div>
  <div class="tw-relative" v-if="map_view_current_page == 'HomePage'">
    <GMapMap :center="centerLocation" class="gmap tw-relative" :options="options" ref="myMapRef" :zoom="3"
      map-type-id="terrain" style="width: 100vw; height: 900px">
      <ProjectMarker :id="project._id.$oid" :project="project" v-for="(project, index) in lisingsLoacal" :key="index"
        :lat="parseFloat(project.latitude)" :lng="parseFloat(project.longitude)" :baseImageUrl="baseImageUrl">
      </ProjectMarker>
    </GMapMap>
  </div>
  <div class="tw-relative" v-if="map_view_current_page == 'InnerPage'">
    <GMapMap :center="centerLocation" class="gmap tw-relative" :options="options" ref="myMapRef" :zoom="5"
      map-type-id="terrain" style="width: 100vw; height: 500px">
      <ProjectMarker :id="project._id.$oid" :project="project" v-for="(project, index) in lisingsLoacal" :key="index"
        :lat="parseFloat(project.latitude)" :lng="parseFloat(project.longitude)" :baseImageUrl="baseImageUrl">
      </ProjectMarker>
      <ProjectMarker :id="current_listing._id.$oid" :project="current_listing" :lat="parseFloat(current_listing.latitude)"
        :lng="parseFloat(current_listing.longitude)" :baseImageUrl="baseImageUrl" :icon="'/img/New_red_6.png'">
      </ProjectMarker>
      <ProjectMarker :id="project._id.$oid" :project="project" v-for="(project, index) in related_properties" :key="index"
        :lat="parseFloat(project.latitude)" :lng="parseFloat(project.longitude)" :baseImageUrl="baseImageUrl"
        :icon="'/img/New_purpel_1.png'">
      </ProjectMarker>
    </GMapMap>
  </div>

  <div class="tw-relative" v-if="map_view_current_page == 'Cmpany_Profile'">
    <GMapMap :center="centerLocation" class="gmap tw-relative" :options="options" ref="myMapRef" :zoom="3"
      map-type-id="terrain" style="width: 100vw; height: 500px">
      <companyProfileMarker :id="project._id.$oid" :project="project" v-for="(project, index) in companylistings"
        :key="index" :lat="parseFloat(project.latitude)" :lng="parseFloat(project.longitude)" :baseImageUrl="baseImageUrl"
        :icon="'/img/New_purpel_1.png'">
      </companyProfileMarker>
    </GMapMap>
  </div>
</template>
<script>
import ProjectMarker from "./Marker.vue";
import ProjectMarkerForNewsReleases from "../../components/map/map_view_for_news_releases/Marker.vue";
import companyProfileMarker from "../../components/map/company_profile/Marker.vue";
import AnnouncementComponent from '../../components/advertisements/AnnouncementComponent.vue'
import { mapStore } from "./mapStore";
import { ref, watch, computed } from "vue";


export default {
  name: "google-map",
  setup(props) {
    const myMapRef = ref();
    const heatmap = ref(null);

    const setHeatmapRadious = () => {
      myMapRef.value.$mapPromise.then((map) => {
        let zoom = parseInt(map.zoom);
        heatmap.value.set("radius", zoom >= 5 ? 0.5 : 0.6);
      });
    };

    const mapLoaded = (map) => {
      map.addListener("click", () => {
        mapStore.clickedMarker = null;
      });

      map.addListener("zoom_changed", () => {
        setHeatmapRadious();
      });
    };
    const lisingsLoacal = computed(() => {
      return props.listings
    })
    const isAlreadyBanerCloedDT = computed(() => {

      if(props.user == null)
        {
            return !!window.sessionStorage.getItem('MapViewForNewsReleases-banner-dt')
        }
        else{
            return !!window.sessionStorage.getItem('MapViewForNewsReleases-banner-dt-user')
        }
    })
    const isAlreadyBanerCloedMobile = computed(() => {
      if(props.user == null)
        {
            return !!window.sessionStorage.getItem('MapViewForNewsReleases-banner-mobile')
        }
        else{
            return !!window.sessionStorage.getItem('MapViewForNewsReleases-banner-mobile-user')
        }
    })

    const shouldShowDt = computed(() => {
      if (isAlreadyBanerCloedDT.value == true) {
        return 'none'
      }
    })
    const shouldShowMobile = computed(() => {
      if (isAlreadyBanerCloedMobile.value == true) {
        return 'none'
      }

    })
    watch(myMapRef, (googleMap) => {
      if (googleMap) {
        googleMap.$mapPromise.then((map) => {
          const heatmapData = [];
          props.heat_map_data.forEach((project) => {
            try {
              let lat = parseFloat(project.latitude);
              let long = parseFloat(project.longitude);

              if (lat && long) {
                const latLng = new google.maps.LatLng(lat, long);
                heatmapData.push(latLng);
              }
            } catch (err) { }
          });

          heatmap.value = new google.maps.visualization.HeatmapLayer({
            data: heatmapData,
            dissipating: false,
            // radius: 0.5,
          });

          heatmap.value.setMap(map);
          setHeatmapRadious();

          map.setOptions({
            mapTypeControlOptions: {
              style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
              position: google.maps.ControlPosition.TOP_RIGHT,
            },
          });
          mapLoaded(map);
        });
      }
    });

    return {
      myMapRef,
      mapStore,
      lisingsLoacal
    };
  },
  props: {
    current_listing: {
      type: Array,
      default: []
    },
    map_view_current_page: {
      type: String,
      default: ""
    },
    related_properties: {
      type: Array,
      default: []
    },
    listings: {
      type: Array,
      default: []
    },
    location: {
      type: Array,
      default: []
    },
    current_date_for_zone: {
      type: String,
      default: ''
    },
    companylistings: {
      type: Array,
      default: []
    }
    ,
    user: {
        type: Object
    },
    heat_map_data: {
      type: Array,
      default: [],
    },
    baseImageUrl: String,
    externalProjects: Array,
    projects: Array,
    all_internal_comapnies_with_slug: {
      type: Array,
      default: []
    }

  },
  mounted() {
    // this.centerLocation = { lat: 39.744137, lng: -104.95005 };

    if (this.map_view_current_page == "NewsReleasesMapView") {

      this.centerLocation = this.location ? this.location.length == 1 && this.location.length ? { lat: parseFloat(this.location[0].lat), lng: parseFloat(this.location[0].lng) } : { lat: 0.000000, lng: 0.000000 } : { lat: 39.744137, lng: -104.950050 }
      this.options.zoom = this.location === "1m" ? this.location.length ? this.location.length == 1 && this.location.length ? 5 : 3 : 8 : 4
      mapStore.fillProjects(this.projects);
    }
    if (this.map_view_current_page == "InnerPage") {
      this.centerLocation = { lat: parseFloat(this.current_listing.latitude), lng: parseFloat(this.current_listing.longitude) };
      this.options.zoom = 6
    }
    if (this.map_view_current_page == "Cmpany_Profile") {

      this.centerLocation = { lat: parseFloat(this.companylistings[0].latitude), lng: parseFloat(this.companylistings[0].longitude) }
      this.options.zoom = this.companylistings.length > 1 ? 6 : 4
    }

    if (this.map_view_current_page == "HomePage") {
      //  this.centerLocation = { lat: 39.744137, lng: -104.95005 };
      this.centerLocation = this.location ? this.location.length == 1 && this.location.length ? { lat: parseFloat(this.location[0].lat), lng: parseFloat(this.location[0].lng) } : { lat: 0.000000, lng: 0.000000 } : { lat: 39.744137, lng: -104.950050 }
      // this.options.zoom = 2.5
    }
  },
  created() {
    const current = new Date();
    const date = `${current.getFullYear()}-${((current.getMonth() + 1) > 9 ? '' : '0') + (current.getMonth() + 1)}-${(current.getDate() > 9 ? '' : '0') + current.getDate()}`;
    if (this.map_view_current_page == "NewsReleasesMapView") {
      if (localStorage.getItem('reloaded')) {
        localStorage.removeItem('reloaded');
      }
      else {
        if (this.current_date_for_zone.toString() != date.toString()) {
          const newsReleaseUrl = new URLSearchParams(window.location.search);
          const current_date = newsReleaseUrl.get("Date");
          if (current_date == null || current_date.toString() != date.toString()) {
            let url = `/map_view_listings?Date=${date.toString()}`
            document.location = url
            localStorage.setItem('reloaded', '1');
          }

        }


      }

    }

  },

  data: function () {
    return {
      centerLocation: { lat: 39.744137, lng: -104.95005 },
      mapName: "google-map",
      markerCoordinates: [],
      map: null,
      bounds: null,
      markers: [],

      options: {
        mapTypeControl: true,
        zoom: 3,
        styles: [
          {
            elementType: "geometry",
            stylers: [
              {
                color: "#1d2c4d",
              },
            ],
          },
          {
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#8ec3b9",
              },
            ],
          },
          {
            elementType: "labels.text.stroke",
            stylers: [
              {
                color: "#1a3646",
              },
            ],
          },
          {
            featureType: "administrative",
            elementType: "geometry",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "administrative.country",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#4b6878",
              },
            ],
          },
          {
            featureType: "administrative.land_parcel",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "administrative.land_parcel",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#64779e",
              },
            ],
          },
          {
            featureType: "administrative.neighborhood",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "administrative.province",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#4b6878",
              },
            ],
          },
          {
            featureType: "landscape.man_made",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#334e87",
              },
            ],
          },
          {
            featureType: "landscape.natural",
            elementType: "geometry",
            stylers: [
              {
                color: "#023e58",
              },
            ],
          },
          {
            featureType: "poi",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "geometry",
            stylers: [
              {
                color: "#283d6a",
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "labels.text",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#6f9ba5",
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "labels.text.stroke",
            stylers: [
              {
                color: "#1d2c4d",
              },
            ],
          },
          {
            featureType: "poi.park",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#023e58",
              },
            ],
          },
          {
            featureType: "poi.park",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#3C7680",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [
              {
                color: "#304a7d",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "labels",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#98a5be",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "labels.text.stroke",
            stylers: [
              {
                color: "#1d2c4d",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "geometry",
            stylers: [
              {
                color: "#2c6675",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#255763",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#b0d5ce",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "labels.text.stroke",
            stylers: [
              {
                color: "#023e58",
              },
            ],
          },
          {
            featureType: "transit",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "transit",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#98a5be",
              },
            ],
          },
          {
            featureType: "transit",
            elementType: "labels.text.stroke",
            stylers: [
              {
                color: "#1d2c4d",
              },
            ],
          },
          {
            featureType: "transit.line",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#283d6a",
              },
            ],
          },
          {
            featureType: "transit.station",
            elementType: "geometry",
            stylers: [
              {
                color: "#3a4762",
              },
            ],
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [
              {
                color: "#0e1626",
              },
            ],
          },
          {
            featureType: "water",
            elementType: "labels.text",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#4e6d70",
              },
            ],
          },
        ],
        fullscreenControl: false,
        streetViewControl: false,
      },
    };
  },
  components: {
    ProjectMarker,
    ProjectMarkerForNewsReleases,
    companyProfileMarker,
    AnnouncementComponent
  },
};
</script>

<style lang="scss" scoped>
:deep .vue-map {
  width: 100% !important;
}

h5.mx-auto {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 16px;
}
</style>

