
import { useQuery } from "vue-query";
import { reactive } from 'vue'
import axios from 'axios'

const fetchData = async (id) => {
    const response = await axios.get(
      `/api/v1/conversations/property/${id}`
    );
    return response.data.data;
};

export default function useGetConversationForProperty(id) {
    // const queryKey = reactive(["property", id ]);
    const queryKey = ["conversation-property", id ];
    return useQuery(
        queryKey,
        () => fetchData(id),
        {
          refetchOnWindowFocus: false,
          //   placeholderData: [],
            // staleTime: Infinity,
          onError: (error) => {
            // store.commit("errorsModal/openModal", error.response.data.errors);
            console.log('Error....', error)
          },
        }
    );
}