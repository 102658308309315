<script setup>

</script>

<template>
    <div class="container subscription-pages">
        <!------------------------------ News Release Preferences --------------------------------------------->
        <div class="row g-3 subscription-form mt-lg-2">
            <h5 class="mt-5 mb-0">News Release Preferences</h5>
            <p class="mt-1 mb-0 ps-3 ms-1">Please select one or more options from the following to receive daily press
                releases based on your preferences.</p>
            <div class="col-md-12">
                <label class="form-label">Companies</label>
                <select class="form-select">
                    <option selected>Select</option>
                    <option>...</option>
                    <option>...</option>
                    <option>...</option>
                </select>
            </div>
            <div class="col-md-12">
                <label class="form-label">Project commodities associated with companies</label>
                <select class="form-select">
                    <option selected>Select</option>
                    <option>...</option>
                    <option>...</option>
                    <option>...</option>
                </select>
            </div>
            <div class="col-md-12">
                <label class="form-label">Countries</label>
                <select class="form-select">
                    <option selected>Select</option>
                    <option>...</option>
                    <option>...</option>
                    <option>...</option>
                </select>
            </div>
        </div>

        <div class="row g-3 listing-form mt-4 pb-5 d-flex justify-content-end">
            <!-- To Subscribe Preferences -->
            <div class="col-lg-2">
                <button type="submit" class="btn custom-primary-btn w-100">Subscribe</button>
            </div>
            <!-- To Unsubscribe Preferences -->
            <div class="col-lg-2">
                <button type="submit" class="btn custom-danger-btn w-100">Unsubscribe</button>
            </div>
            <div class="col-lg-3">
                <button type="submit" class="btn custom-primary-btn w-100">Update My Preferences</button>
            </div>
        </div>
    </div>
</template>