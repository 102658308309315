<template>
  <div>
    <div class="slider-s">
      <!-- <div class="inner-listings-img d-flex justify-content-center align-items-end"> -->
      <div v-for="(image, index) in images" :key="index" v-show="currentIndex === index">
        <GalleryImagePreview :images="images" v-slot="slotProps">
          <div v-show="images.length == 1">
            <img :src="image" @click="slotProps.preview(index)"
              :style="`cursor: pointer; height: 100%; width: 100%; object-fit: contain;`" />
          </div>
          <div v-show="images.length > 1">
            <img :src="image" />
          </div>
          <div class="d-flex justify-content-end slider-view-more-btn" v-if="images.length > 1">
            <button @click="slotProps.preview(index)"><i class="fa fa-th me-2" aria-hidden="true"></i>View More</button>
          </div>
        </GalleryImagePreview>
      </div>
      <!-- </div> -->
    </div>

  </div>
</template>

<script>
import GalleryImagePreview from '../../../src/components/GalleryImagePreview.vue';

export default {
  props: {
    imageUrl: {
      type: Array,
      default: []
    },
    baseImageUrl: {
      type: String,
    }
  },

  data() {
    return {
      images: [],
      currentIndex: 0
    };
  },
  mounted() {
    this.images = this.imageUrl
  },
  methods: {
    prevImage() {
      if (this.currentIndex === 0) {
        this.currentIndex = this.images.length - 1;
      } else {
        this.currentIndex--;
      }
    },
    nextImage() {
      if (this.currentIndex === this.images.length - 1) {
        this.currentIndex = 0;
      } else {
        this.currentIndex++;
      }
    }
  },
  components: {
    GalleryImagePreview,
  }
};
</script>

<style>
.slider-s {
  position: relative;
  width: 100%;
  height: 80px !important;
  overflow: hidden;
  border: 2px solid #49505733;
  /* border: 2px solid #909090; */
  border-radius: 10px;
}

.slider-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  /* height: 100%; */
  transition: transform 0.5s ease;
}

.slider-wrapper img {
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
}

.slider-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  /* width: 100%; */
  display: flex;
  justify-content: space-between;
}

.slider-nav button {
  border: none;
  background: transparent;
  font-size: 2em;
  color: white;
  cursor: pointer;
}
</style>