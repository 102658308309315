<script setup>
import { ref, reactive, computed, onMounted, onUpdated } from "vue";
import Gmap from "../../components/map/map.vue";
import { router, usePage } from '@inertiajs/vue3'
import _ from 'lodash'
import VueMultiselectCustom from '../../components/dropdownSelector/VueMultiselectCustom.vue'
import BsModal from '../../../frontend/components/BsModal.vue'
import ScreenLoader from '../../components/ScreenLoader.vue'
import Nav from '../../components/layout/Nav.vue'

const props = defineProps({
    csrf_token: {
        type: String,
        required: "",
    },
    baseImageUrl: {
        type: String,
    },
    company_names: {
        type: Array,
        default: [],
    },
    is_authenticated: {
        type: Boolean,
        default: false,
    },
    availableLastPressReleasDateRange: {
        type: String,
        default: "",
    },
    news_releases_count_for_external_internal_projects: {
        type: Number,
    },
    active_internal_listings: {
        type: Array,
        default: [],
    },
    external_projects: {
        type: Array,
        default: [],
    },
    heat_map_data: {
        type: Array,
        default: [],
    },
    location: {
        type: Array,
        default: [],
    },
    all_commodities: {
        type: Array,
    },
    all_countries: {
        type: Array
    },
    date_range_selector: {
        type: String,
        default: ''
    },
    name: {
        type: String,
        default: ''
    },
    current_date_for_zone: {
        type: String,
        default: ''
    },
    user: {
        type: Object
    },
    all_internal_comapnies_with_slug: {
        type: Array,
        default: [] 
    }
});
const comapnyNamas = computed(() => {
    return _.uniq(_.sortBy(props.company_names.map(company => company.company_name), (o) => o.toLowerCase()))
})
const showFilter = ref(false);


const filterSelectedCommodities = ref(null)
const filterSelectedCountries = ref(null)
const filterKeyWord = ref('')
const filterComProName = ref(null)
const current_day_local = ref()
const one_month_local = ref()
const changeStatusInTodayDateCheckBox = (e) => {
    if (current_day_local.value == true) {
        current_day_local.value = false
    }
    else {
        current_day_local.value = true
        one_month_local.value = false
    }
}
const changeStatusInOneMonthCheckBox = (e) => {
    if (one_month_local.value == true) {
        one_month_local.value = false
    }
    else {
        one_month_local.value = true
        current_day_local.value = false
    }
}
const getQueryFilters = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    filterKeyWord.value = urlParams.get('title-keyword') ? urlParams.get('title-keyword') : ''
    filterComProName.value = urlParams.get('company_name_on_press_release') ? urlParams.get('company_name_on_press_release').split(",") : []
    filterSelectedCommodities.value = urlParams.get('commodity') ? urlParams.get('commodity').split(",") : []
    filterSelectedCountries.value = urlParams.get('country') ? urlParams.get('country').split(",") : []
    current_day_local.value = urlParams.get('press-date-range') == '1d' ? true : ''
    one_month_local.value = urlParams.get('press-date-range') == '1m' ? true : ''
}

const internalCompanyNames = computed(()=>{
  return  _.uniq(_.sortBy(props.all_internal_comapnies.map(company => company.company_name),(o) => o.toLowerCase()))
})

const submitFilter = (e) => {
    e.preventDefault()
    const queryParams = {
        ...filterSelectedCountries.value.length ? { country: filterSelectedCountries.value.join(",") } : {},
        ...filterSelectedCommodities.value.length ? { commodity: filterSelectedCommodities.value.join(",") } : {},
        ...current_day_local.value ? { 'press-date-range': '1d' } : {},
        ...one_month_local.value ? { 'press-date-range': '1m' } : {},
        ...filterComProName.value.length ? { company_name_on_press_release: filterComProName.value.join(",") } : {},
        ...filterKeyWord.value.trim().length ? { 'title-keyword': filterKeyWord.value.trim() } : {},


    }

    const searchParams = new URLSearchParams(queryParams);
    const current = new Date(datetime_str);
    const today_date = `${current.getFullYear()}-${(current.getMonth() + 1 > 9 ? "" : "0") + (current.getMonth() + 1)}-${(current.getDate() > 9 ? "" : "0") + current.getDate()}`;
    let url = `/map_view_listings?Date=${today_date.toString()}&${searchParams.toString()}`
    // url = encodeURI(url)
    document.location = url
    return
}
const clearFilters = () => {
    const current = new Date(datetime_str);
    const today_date = `${current.getFullYear()}-${(current.getMonth() + 1 > 9 ? "" : "0") + (current.getMonth() + 1)}-${(current.getDate() > 9 ? "" : "0") + current.getDate()}`;
    document.location = `/map_view_listings?Date=${today_date.toString()}`

}
const is_visible = ref(false);
const is_visible_loader = ref(true);
const toggle = () => {
    is_visible.value = true;
    is_visible_loader.value = false;
};
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
let datetime_str = new Date().toLocaleString("en-US", {
    timeZone: "" + timezone + "",
});
const current = new Date(datetime_str);
const today_date = `${current.getFullYear()}-${(current.getMonth() + 1 > 9 ? "" : "0") + (current.getMonth() + 1)
    }-${(current.getDate() > 9 ? "" : "0") + current.getDate()}`;

if (props.current_date_for_zone.toString() != today_date.toString()) {

    setTimeout(toggle, 9500);
} else {
    is_visible.value = true;
    is_visible_loader.value = false;
}


onMounted(() => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let datetime_str = new Date().toLocaleString("en-US", { timeZone: "" + timezone + "" });
    const current = new Date(datetime_str);
    const date = `${current.getFullYear()}-${((current.getMonth() + 1) > 9 ? '' : '0') + (current.getMonth() + 1)}-${(current.getDate() > 9 ? '' : '0') + current.getDate()}`;
    document.cookie = "currentDate=" + date + ";";

    getQueryFilters()
});

</script>

<template>
    <!-- News releases banner starts -->
    <div id="loader" v-show="is_visible_loader" class="map_view_fade_loader">
        <!-- <img  src="../../../frontend/assets/img/spinerGif.gif"> -->
        <ScreenLoader :show="is_visible_loader" />
    </div>
    <div id="MapView" v-show="is_visible">
        <div
            class="container-fluid news-releases-banner-img d-flex justify-content-center align-items-center flex-column px-3">
            <h1 class="mb-3">The Latest News and Insights on Mining Industry</h1>
            <h3 v-if="
                news_releases_count_for_external_internal_projects == 1 &&
                availableLastPressReleasDateRange == null
            ">
                {{ news_releases_count_for_external_internal_projects }} News Release
            </h3>
            <h3 v-if="
                news_releases_count_for_external_internal_projects > 1 &&
                availableLastPressReleasDateRange == null
            ">
                {{ news_releases_count_for_external_internal_projects }} News Releases
            </h3>
            <h3 v-if="
                news_releases_count_for_external_internal_projects > 1 &&
                availableLastPressReleasDateRange != null
            ">
                {{ news_releases_count_for_external_internal_projects }} News Releases
                found as of {{ availableLastPressReleasDateRange }}
            </h3>
            <h3 v-if="
                news_releases_count_for_external_internal_projects == 1 &&
                availableLastPressReleasDateRange != null
            ">
                {{ news_releases_count_for_external_internal_projects }} News Release
                found as of {{ availableLastPressReleasDateRange }}
            </h3>
            <h3 v-if="news_releases_count_for_external_internal_projects == 0">
                No news releases for mining project companies found.
            </h3>
        </div>
        <!-- News releases banner ends -->

        <!-- News releases toggle section -->
        <div
            class="container px-md-0 pt-3 map-news-releases d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center">
            <div class="d-flex news-release-map-legend align-items-start align-items-lg-center flex-column flex-lg-row">
                <h6 class="mb-0">Legend</h6>
                <div class="verticle-line mx-3"></div>
                <div class="d-flex flex-wrap-nowrap mt-2 mt-lg-0">
                    <img src="../../assets/img/New_purpel_1.png" alt="Available Projects With News" />
                    <p class="ms-1 me-3">Available Projects with News</p>
                </div>
                <div class="d-flex flex-wrap-nowrap mt-2 mt-lg-0">
                    <img src="../../assets/img/New_bule_5.png" alt="News Releases" />
                    <p class="ms-1 me-3">News Releases</p>
                </div>
                <div class="d-flex flex-wrap-nowrap mt-2 mt-lg-0">
                    <img src="../../assets/img/project-density.png" alt="Project Density" />
                    <p class="ms-1">Project Density</p>
                </div>
            </div>
            <div class="d-flex mt-3 mt-sm-0">
                <div class="d-flex list-map">
                    <a type="button" class="btn list p-2" :href="'/press-releases'"><i class="fa fa-th me-2"></i>List</a>
                    <a type="button" class="btn map p-2 active"><i class="fa fa-map me-2"></i>Map</a>
                </div>
                <button type="button" class="btn filter-section" @click="showFilter = !showFilter"><i
                        class="fa fa-sliders me-2" aria-hidden="true"></i>Filters</button>
            </div>
        </div>
        <!-- News releases toggle section ends -->

        <!-- Tab panes -->

        <div class="tab-content pt-3">
            <Gmap :heat_map_data="heat_map_data" :projects="active_internal_listings" :baseImageUrl="baseImageUrl"
                :location="location" :current_date_for_zone="current_date_for_zone" :externalProjects="external_projects"
                :map_view_current_page="'NewsReleasesMapView'" :user="user" :all_internal_comapnies_with_slug="all_internal_comapnies_with_slug"/>
        </div>
        <!-- Map View starts -->
        <!-- <div id="news-releases-list-map" class="container tab-pane fade p-0">
               
                                </div> -->
        <!-- Map View Ends -->
        <!-- </div> -->
        <!-- Tab panes ends -->
        <!-- Filter Modal starts -->
        <!-- <form @submit.prevent="applyFilter"> -->
        <BsModal :show="showFilter">
            <template #modalHeader>
                <h5 class="modal-title" id="exampleModalLabel">Filters</h5>
                <button type="button" class="btn-close" aria-label="Close" @click="showFilter = false"></button>
            </template>
            <!-- <div class="modal" id="filter-modal" tabindex="-1">
                            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable pt-5">
                                <div class="modal-content"> -->
            <div class="modal-header">
                <h5 class="modal-title">Filters</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <template #modalBody>
                <!-- <div class="row g-2 map-date-range-filter">
                                            <h6>Press Release Date Range</h6>
                                            <div class="d-flex my-3">
                                                <label class="date_label mr-2 notranslate"><input type="checkbox" id="date_filter" name="day" v-model="current_day_local"  @click="changeStatusInTodayDateCheckBox(e)"><span class="date_type_label" > 1D </span></label>
                                                 <label class="date_label mr-2 notranslate"><input type="checkbox" id="date_filter" name="one_month" v-model="one_month_local" @click="changeStatusInOneMonthCheckBox(e)"><span class="date_type_label"> 1M </span></label>
                                                <a href=""><h5 class="me-3">1D</h5></a>
                                                <a href=""><h5>1M</h5></a>
                                            </div>
                                        </div> -->
                <div class="row g-2 map-date-range-filter">
                    <h6>News Release Date Range</h6>
                    <div class="d-flex my-3">
                        <input id="lists[Travel]" type="checkbox" name="day" v-model="current_day_local"
                            @click="changeStatusInTodayDateCheckBox(e)" />
                        <label for="lists[Travel]">1D</label>
                        <input class="checkbox" id="lists[new]" type="checkbox" name="one_month" v-model="one_month_local"
                            @click="changeStatusInOneMonthCheckBox(e)" />
                        <label for="lists[new]">1M</label>
                    </div>
                </div>
                <div class="row g-2 home-filter-modal">
                    <div class="col-md">
                        <div class="">
                            <label class="form-label">Title Keyword</label>
                            <input type="text" class="form-control" placeholder=""
                                v-model="filterKeyWord" />
                        </div>
                        <!-- <div class="form-floating">
                            <input type="text" class="form-control" id="floatingInputGrid" placeholder="Title Keyword"
                                v-model="filterKeyWord" />
                            <label for="floatingInputGrid">Title Keyword</label>
                        </div> -->
                    </div>
                </div>
                <!-- <div class="row g-2 mt-0 mt-md-3">
                                            <div class="col-md">
                                                <div class="form-floating">
                                                    <input type="date" class="form-control" id="floatingInputGrid" placeholder="Start Date"
                                                        value="" />
                                                    <label for="floatingInputGrid">Start Date</label>
                                                </div>
                                            </div>
                                            <div class="col-md">
                                                <div class="form-floating">
                                                    <input type="date" class="form-control" id="floatingInputGrid" placeholder="Start Date"
                                                        value="" />
                                                    <label for="floatingInputGrid">End Date</label>
                                                </div>
                                            </div>
                                        </div> -->
                <div class="row g-2 mt-0 mt-md-3">
                    <div class="col-md">
                        <!-- <div class="form-floating">
                            <VueMultiselectCustom placeholder="All Countries" :options="all_countries" -->
                        <div class="">
                            <label class="form-label">All Countries</label>
                            <VueMultiselectCustom placeholder="Select" :options="all_countries"
                                :modelValue="filterSelectedCountries" :multiple="true"
                                @update:modelValue="filterSelectedCountries = $event">
                            </VueMultiselectCustom>
                            <!-- <label for="floatingInputGrid">Country</label> -->
                        </div>
                    </div>
                    <div class="col-md">
                        <!-- <div class="form-floating">
                            <VueMultiselectCustom placeholder="All Commodities" :options="all_commodities" -->
                        <div class="">
                            <label class="form-label">All Commodities</label>
                            <VueMultiselectCustom placeholder="Select" :options="all_commodities"
                                :modelValue="filterSelectedCommodities" :multiple="true"
                                @update:modelValue="filterSelectedCommodities = $event">
                            </VueMultiselectCustom>
                            <!-- <label for="floatingSelectGrid">Commodity</label> -->
                        </div>
                    </div>
                </div>
                <div class="row g-2 mt-0 mt-md-3">
                    <div class="col-md">
                        <!-- <div class="form-floating">
                            <VueMultiselectCustom placeholder="All Companies" :options="comapnyNamas" -->
                        <div class="">
                            <label class="form-label">All Companies</label>
                            <VueMultiselectCustom placeholder="Select" :options="comapnyNamas"
                                :modelValue="filterComProName" :multiple="true"
                                @update:modelValue="filterComProName = $event">
                            </VueMultiselectCustom>
                            <!-- <label for="floatingInputGrid">Company Name</label> -->
                        </div>
                    </div>
                </div>
            </template>
            <template #modalFooter>
                <button type="button" class="btn btn-light clear-btn"
                    @click="clearFilters(); showFilter = false">Clear</button>
                <button class="btn apply-btn custom-primary-btn" type="submit" @click="submitFilter">Apply</button>
            </template>
            <!-- </div> -->

            <!-- </div> -->
            <!-- </div> -->
        </BsModal>
        <!-- </form> -->
        <!-- Filter Modal ends -->
    </div>
</template>