<script setup>
import { ref, computed } from 'vue'
import singleImageSlider from '../slider/singleImageSlider.vue';
import PdfModal from '../modals/PdfModal.vue';

const props = defineProps({
    document: {
        type: String,
        default: {}
    },
    baseImageUrl: {
        type: String,
    },
    user: {
        type: Object
    }
})

const documentType = computed(() => {
    let x = props.document.split('.')
    let y = x[x.length - 1]

    return y //'pdf' //'img' //
})

const documentName = computed(() => {
    let x = props.document.split('_')
    let y = x.splice(1).join('_')
    
    return y //'pdf' //'img' //
})

const documentUrl = computed(() => {
    return `${props.baseImageUrl}/${props.document}`
})

</script>

<template>
    <PdfModal v-if="documentType === ('pdf')" :link="documentUrl" :user="props.user" :downloadBtn="true">
        <img src="../../assets/img/icon-file.svg" alt="PDF Documnet">
    </PdfModal>
    <PdfModal v-if="documentType === ('dot')" :link="documentUrl" :user="props.user" :downloadBtn="true">
        <img src="../../assets/img/icon-file.svg"  alt="Dot Documnet">
    </PdfModal>
    <PdfModal v-if="documentType === ('doc')" :link="documentUrl" :user="props.user" :downloadBtn="true">
        <img src="../../assets/img/icon-file.svg"  alt="Doc Documnet">
    </PdfModal>
    <PdfModal v-if="documentType === ('docx')" :link="documentUrl" :user="props.user" :downloadBtn="true">
        <img src="../../assets/img/icon-file.svg"  alt="Docx Documnet">
    </PdfModal>
   <singleImageSlider 
        v-if="documentType === 'jpg'" 
        class="project-documents-thumbnail" 
        :imageUrl="[documentUrl]"
        :baseImageUrl="baseImageUrl">
    </singleImageSlider>
    <singleImageSlider 
        v-if="documentType === 'jpeg'" 
        class="project-documents-thumbnail" 
        :imageUrl="[documentUrl]"
        :baseImageUrl="baseImageUrl">
    </singleImageSlider>
    <singleImageSlider 
        v-if="documentType === 'png'" 
        class="project-documents-thumbnail" 
        :imageUrl="[documentUrl]"
        :baseImageUrl="baseImageUrl">
    </singleImageSlider>

    <p class="thumbnail-name">{{ documentName }}</p>
</template>

<style>
.thumbnail-name {
    width: 142px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@media (max-width: 420px) {
    .thumbnail-name{
        width: 100px;
    }
}

@media (max-width:1190px) and (min-width:900px) {
    .thumbnail-name{
        width: 100px;
    }
}

@media (max-width:1369px) and (min-width:1180px) {
    .thumbnail-name{
        width: 113px;
    }
}
</style>