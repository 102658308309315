<script setup>
import { router } from '@inertiajs/vue3'
import Nav from '../../components/layout/Nav.vue'
import headComponent from "../../components/inertiaHead/head.vue"


const props = defineProps({
    csrf_token: {
        type: String,
        required: ""
    }, user: {
        type: Object,
        default: null,
    },
    all_countries: {
        type: Array,
    },
    user: {
        type: Object,
        default: null,
    },
    captchaSiteKey: {
        type: String
    },
})
const visitPage = (data) => {
 // replace all special characters | symbols with a space
 let str = data.replace(/[`~!@#$%^&*()_\-+=\[\]{};:'"\\|\/,.<>?\s]/g, ' ').toLowerCase();

 // trim spaces at the start and end of the string
 str = str.replace(/^\s+|\s+$/gm, '');

 // replace space with dash/hyphen
 str = str.replace(/\s+/g, '-');
 router.visit('/countries/'+ str,)
}
</script>

<template>
       <headComponent :description="'Discover mining opportunities around the world. SearchSpace AI provides a comprehensive list of countries with potential mining sites, offering insights into global mining destinations. Explore various mining prospects and investment possibilities in different countries.'"
          :title="'Countries with Mining Opportunities | Global Mining Destinations'">
      </headComponent>
    <div class="category-types country-banner d-flex flex-column justify-content-center align-items-center" >
        <h1 class="text-white mb-3">Countries with Potential Mining Sites</h1>
        <h3 class="text-white">Select a country to view listings based on your selection</h3>
    </div>
    <div class="container text-left my-3 py-2"></div>
    <div class="container mb-5">
        <div class="row row-cols-2 row-cols-md-3 row-cols-lg-5 g-4 country-card-section">
            <template v-for="(data, index) in all_countries" :key="index">
                <div class="col">
                    <div class="card h-100 border-0 country-card">
                        <a @click="visitPage(data)" style="cursor: pointer;" >
                            <div class="card-body">
                                <h6 class="card-title language-content">{{ data }}</h6>
                            </div>
                        </a>
                    </div>
                </div>
            </template>
        </div>
    </div>

    <!-- /container -->
    <a href="#" class="top-to-scroll">
        <i class="fa fa-chevron-up" aria-hidden="true"></i>
    </a>
</template>