<script setup>
import { router } from '@inertiajs/vue3'
import LoginModal from '../../components/modals/LoginModal.vue';

const props = defineProps({
    csrf_token: {
        type: String,
        default: ''
    },
    error: {
        type: String
    },
    user: {
        type: Object,
        default: null,
    }
    
})

if (props.user) {
    router.visit('/', { method: 'get' })
}
</script>

<template>
    <div class="tw-relative">
        <!-- <img :src="img" class="tw-w-full tw-h-[600px] tw-object-cover"/> -->
    <!-- <LoginModal :error="error" :csrf_token="csrf_token" :user="user" /> -->
    </div>
</template>