import { useMutation, useQueryClient } from "vue-query";
import axios from "axios";
import { ref, toRefs, computed, reactive } from "@vue/reactivity";
import _ from 'lodash'

const action = async (conversationId) => {
  await axios.delete(
    `/api/v1/conversations/${conversationId}`
  );
};
// ["conversation-messages", conversationId]
export default function useDeleteConversation() {
  const cache = useQueryClient();
  
  const mutation = useMutation(
    ({ conversationId }) => action(conversationId),
    {
      
      onSuccess: (data, variables, context) => {
        cache.invalidateQueries('conversations')
      },
      onError: (err, newMessageItem, context) => {
        console.error('Error while deleting the conversation: ', err)

      },
      onSettled: (data, error, variables, context) => {},
    }
  );

  return reactive({
    deleteConversation: mutation.mutateAsync,
    deleteConversationStatus: mutation,
  });
}
