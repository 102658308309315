<template>
    <headComponent
        :description="'Experience the power of SearchSpace AI. Leveraging Artificial Intelligence to provide the latest and best data on gold mining, junior mining stocks, and more. Join us as we redefine mining.'"
        :title="'Mining investment analysis AI'">
    </headComponent>
    <div
        class="container-fluid peoples-web-banner-img d-flex justify-content-center align-items-center flex-column px-3">
        <h1 class="mb-3">People Web</h1>
        <!-- <h3 class="mb-3 mb-sm-4">Taking you closer to your Investment goals</h3> -->
        <div class="">
            <a>
                <p class="mb-0">Investigate the complex web of relationships and affiliations within the mining
                    industry,
                    exploring the interconnections that unite influential individuals and companies in this sector.</p>
            </a>
        </div>
    </div>
    <!-- AI banner ends -->
    <div class="p-0 top-image-banner-ai">
        <div class="container tw-left-0 tw-right-0 tw-z-[1] tw-pointer-events-none sm:tw-flex px-0" 
            style="max-width: 1500px;">
            <div id="announcement-dt"
                class="tw-pointer-events-auto tw-w-[1358px] tw-mt-2 tw-mb-1 tw-hidden sm:tw-block">
                <AnnouncementComponent type="desktop" :view="'AIpage'" :user="user" />
            </div>
        </div>
        <div id="announcement-mobile" class="sm:tw-hidden  tw-left-0 tw-w-full tw-z-10 tw-p-3" style="height:16%;">
            <AnnouncementComponent type="mobile" :view="'AIpage'" :user="user" />
        </div>
    </div>
    <!-- AI Tab section starts -->
    <div class="banner-peoples-web">
        <section style="position: absolute; width: 100%;">
            <div class="new-people-page-1 px-0" >
                <div class="test-class-sample">
                    <div class="container-fluid background-color px-0">
                        <div class="container px-md-0 home-filer-tab d-flex">

                            <div class="tab-content d-flex ai-sub-section pt-4 px-0 pb-3 new-people-page">
                                <div id="people-profiles" class="container content-section pb-1 active px-0">
                                    <h4 class="ai-info-tooltip">People Web</h4>
                                    <p class="pt-2 mb-3">Here we provide you with the connections between individuals in
                                        the
                                        Mining
                                        Industry in Canada.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div ref="frameSection"></div>
                    <div class="filter-section-top-fixed">
                        <div class="container new-people-page-right d-flex justify-content-between align-items-center">
                            <div class="searchbar-people">
                                <div class="search">
                                    <i class="fa fa-search"></i>
                                    <AutoComplete inputId="main-search" class="input-search" v-model="selectedName"
                                        @complete="autocompleteSearch"
                                        @item-select="goToSearchPage"
                                        @focus="checkSearchContent"
                                        :suggestions="filteredNames"
                                        optionLabel="label"
                                        optionGroupLabel="label"
                                        optionGroupChildren="items"
                                        minLength="2"
                                        delay="600"
                                        :completeOnFocus="enableSearch"
                                        placeholder="Search Prominent People or Companies"
                                        >
                                    </AutoComplete>
                                </div>
                                <div class="duration for-mobile-duration">
                                    <p>Duration: {{ duration[0] }} <strong>-</strong> {{ duration[1] }}</p>
                                </div>
                            </div>
                            <div class="right-side-option d-flex">
                                <div class="duration">
                                    <p>Duration: {{ duration[0] }} <strong>-</strong> {{ duration[1] }}</p>
                                </div>
                                <div class="filter">
                                    <button class="btn" data-bs-toggle="modal" data-bs-target="#resetfilter">
                                        Filter</button>
                                </div>
                                <div class="reset-filter">
                                    <button class="btn btn-light clear-btn" @click="resetFilter" type="button">
                                        Reset Filter</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="container section-for-iframe" style="width: 100%; height:100vh;" v-if="nodesData.length > 0">
                        <div class="frame-section"
                            :style="{ background: `url(${img})`, backgroundSize: '300px', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }">
                            <svg id="svg" :width="width" :height="height" class="container-border" ref="mySvg"
                                style="width: 100%;height: 100%;"></svg>
                        </div>
                        <!---------------------------- Scroll to bottom section ----------------------->
                        <div class="scroll-to-bottom-container">
                            <!-- <div class="scroll-text" v-if="scrollDirection === 'down' && isCloseScroll">
                                <span class="flag-text">Prominent People Mentioned</span>
                                <i class="fa fa-times-circle close-icon" @click="closeScroll"></i>
                            </div>
                            <div class="scroll-text"  v-else-if="isCloseScroll">
                                <span class="flag-text">People Web</span>
                                <i class="fa fa-times-circle close-icon" @click="closeScroll"></i>
                            </div> -->
                            <div id="bottomScroll" class="scroll-to-bottom" @click="toggleScroll">
                                <div v-if="scrollDirection === 'down' && isCloseScroll">
                                    <p>Prominent People Mentioned</p>
                                </div>
                                <div v-else-if="isCloseScroll">
                                    <p>People Web</p>
                                </div>
                                <i :class="scrollDirection === 'down' ? 'fa fa-angle-down' : 'fa fa-angle-up'"
                                    aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-center company-profile"
                        v-if="nodesData.length == 0">
                        <div class="text-center company-profile-not" style="margin-bottom: 15rem;">
                            <img class="info-img-company" src="../../assets/icons/info-svg.svg" alt="Info Image">
                            <p class="mt-2">Data not available.</p>
                            <a @click="goBack" class="btn btn-primary text-white text-center  mt-2">Go Back</a>
                        </div>
                    </div>


                    <!-- Prominent page section -->
                    <div class="container-fluid background-color pb-4 px-0" v-if="nodesData.length > 0">
                        <div class="container px-md-0 home-filer-tab d-flex">
                            <div class="prominent-page mt-5 w-100">
                                <h4>Prominent People</h4>
                                <p>Based on their mentions in press releases</p>
                                <div class="prominent-search-table">

                                    <input type="text" id="searchInput" class="prominent-search-table-input"
                                        v-model="searchQuery" @input="handleSearch"
                                        placeholder="Search Prominent People">
                                </div>
                                <div class="prominent-table mt-3">
                                    <table class="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="table-header">Person</th>
                                                <th scope="col " class="table-header left-table">Number of Associated
                                                    Companies
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="data in paginatedNameCountArray" :key="data.target">
                                                <td scope="row" style="cursor: pointer;"
                                                    @click="updateQueryParam(data.target.name)">{{ data.target.name }}
                                                </td>
                                                <td class="left-table">{{ data.numberOfSources }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <Paginator v-model="currentPage" :totalRecords="totalItems" :rows="itemsPerPage"
                                        class="pagination" @page="(e) => (currentPage = e.page + 1)" :first="offSet"
                                        template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink">
                                    </Paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div id="loader" v-show="is_visible_loader" class="map_view_fade_loader">
        <ScreenLoader :show="is_visible_loader" />
    </div>

    <section>
        <a href="#" class="top-to-scroll scroll-on-top" @click="goToTop()">
        <i class="fa fa-chevron-up" aria-hidden="true"></i>
        </a>
    </section>

    <!--=============================================================================================
                                                    Modal - filter
        ============================================================================================== -->
    <div class="modal fade people-page-modal-filter" id="resetfilter" data-bs-backdrop="static" data-bs-keyboard="false"
        tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Duration Filter</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body new-people-page-modal">
                    <ul class="nav nav-pills mb-5" id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link nav-link-people-left active" data-bs-toggle="pill"
                                data-bs-target="#pills-year" type="button" role="tab" aria-selected="true">Year</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" data-bs-toggle="pill" data-bs-target="#pills-quarter" type="button"
                                role="tab" aria-selected="false">Quarter</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" data-bs-toggle="pill" data-bs-target="#pills-month" type="button"
                                role="tab" aria-selected="false">Month</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" data-bs-toggle="pill" data-bs-target="#pills-week" type="button"
                                role="tab" aria-selected="false">Week</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link nav-link-people-right" data-bs-toggle="pill"
                                data-bs-target="#pills-day" type="button" role="tab" aria-selected="false">Day</button>
                        </li>
                    </ul>
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active peoples-web" id="pills-year">
                            <Slider v-model="selectedYears" :min="baseYear" :max="currentYear" :merge="1"
                                class="slider-blue" />
                            <div class="modal-footer">
                                <br>
                                <button type="button" class="btn btn-primary text-light" data-bs-dismiss="modal"
                                    @click="applyYearFilter">Apply
                                    Filter</button>
                            </div>
                        </div>
                        <div class="tab-pane fade peoples-web" id="pills-quarter">

                            <Slider v-model="selectedQuarters" :min="0" :max="quarters.length - 1" :format="format"
                                :merge="isMobile ? 15 : 4" class="slider-blue" />
                            <div class="modal-footer">
                                <br>
                                <button type="button" class="btn btn-primary text-light" data-bs-dismiss="modal"
                                    @click="applyQuartersFilter(selectedQuarters)">Apply
                                    Filter</button>
                            </div>
                        </div>
                        <div class="tab-pane fade peoples-web" id="pills-month">

                            <Slider v-model="selectedMonths" :min="0" :max="months.length - 1" :format="formatMonth"
                                :merge="isMobile ? 60 : 24" class="slider-blue" />
                            <div class="modal-footer">
                                <br>
                                <button type="button" class="btn btn-primary text-light" data-bs-dismiss="modal"
                                    @click="applyMonthFilter">Apply
                                    Filter
                                </button>
                            </div>
                        </div>

                        <div class="tab-pane fade peoples-web" id="pills-week">

                            <Slider v-model="selectedWeeks" :min="0" :max="totalWeeks - 1" :format="formatWeek"
                                :merge="isMobile ? 300 : 107" class="slider-blue" />
                            <div class="modal-footer">
                                <br>
                                <button type="button" class="btn btn-primary text-light" data-bs-dismiss="modal"
                                    @click="applyWeekFilter(selectedWeeks)">Apply
                                    Filter
                                </button>
                            </div>
                        </div>
                        <div class="tab-pane fade peoples-web" id="pills-day">

                            <Slider v-model="selectedDates" :min="0" :max="totalDays - 1" :format="formatDate"
                                :merge="isMobile ? 2500 : 852" class="slider-blue" />
                            <div class="modal-footer">
                                <br>
                                <button type="button" class="btn btn-primary text-light" data-bs-dismiss="modal"
                                    @click="applyDateFilter">Apply Filter
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--=============================================================================================
                                        Modal - filter endd
        ============================================================================================== -->

    <!-- AI Tab section ends -->
</template>

<script setup>
import { ref, onMounted } from "vue";
import { FilterMatchMode, FilterService } from 'primevue/api';
import AutoComplete from 'primevue/autocomplete';
import Paginator from "primevue/paginator";
import axios from "axios";

const searchPath = ref('/people-web');
const enableSearch = ref(false);
const selectedName = ref();
const filteredNames = ref();
const groupedNames = ref([]);

const checkSearchContent = (event) => {
    enableSearch.value = event.target.value.length > 1
}
const goToSearchPage = (event) => {
    searchPath.value = event.value.path;
    window.location.href = searchPath.value;
}
const autocompleteSearch = async (event) => {
    let query = event.query;
    let newFilteredNames = [];

    const response = await axios.get(`api/v1/people-web-search`, {
        params: {"keyword": query }
    });

    groupedNames.value = response.data;

    for (let gpname of groupedNames.value) {
        let filteredItems = FilterService.filter(gpname.items, ['label'], query, FilterMatchMode.CONTAINS);
        if (filteredItems && filteredItems.length) {
            newFilteredNames.push({...gpname, ...{items: filteredItems}});
        }
    }

    filteredNames.value = newFilteredNames;
}

onMounted(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has('name')) {
        selectedName.value = urlParams.get('name')
    }
});

const goToTop = () => {
   window.scrollTo({ top: 0, behavior: 'instant' });
}

</script>

<script>
import * as d3 from 'd3'
import Slider from "@vueform/slider"
import AnnouncementComponent from '../../components/advertisements/AnnouncementComponent.vue'
import headComponent from "../../components/inertiaHead/head.vue"
import ScreenLoader from '../../components/ScreenLoader.vue'
import img from '../../assets/img/watermark.png'

export default {
    name: 'zoomable',
    props: {
        nodesData: {
            type: Array,
            default: []
        },
        linkData: {
            type: Array,
            default: []
        },
        request_href: {
            type: String,
        },
        current_date: {
            type: String,
        },
        default_back_date: {
            type: String,
        },
        user: {
            type: Object
        },
        all_internal_comapnies_with_slug: {
            type: Array,
            default: [],
        },
    },
    components: {
        Paginator,
        Slider,
        AnnouncementComponent,
        headComponent,
        ScreenLoader
    },
    mounted() {

        if (typeof window === 'undefined') return '';
        this.is_visible_loader = true
        this.initSimulation();
        this.setSliderFromURLParams();
        // window.addEventListener('popstate', this.handlePopstate.bind(this));
        this.setDimensions();
        window.addEventListener('resize', this.setDimensions);

        // window.addEventListener('load', this.scrollToElement); // This will scroll to the PeopleWeb component initially
        window.addEventListener('filterApplied', this.scrollToElement);
        this.isMobile = this.checkIfMobile();
        window.addEventListener('resize', this.updateIsMobile);


        this.scrollPosition = window.scrollY || document.body.scrollTop;

        const urlParams = new URLSearchParams(window.location.search);

        if (urlParams.has('name')) {
            this.query = urlParams.get('name');
            console.log(this.query);
            // this.is_visible_loader = true
        }
        if (urlParams.has('startMonth') && urlParams.has('endMonth')) {
            this.duration[0] = urlParams.get('startMonth');
            this.duration[1] = urlParams.get('endMonth');
            if (!urlParams.has('name') && urlParams.has('startMonth') && urlParams.has('endMonth')) {
                setTimeout(() => {
                    this.is_visible_loader = false
                }, 3000);

            }
        }
        else if (urlParams.has('startYear') && urlParams.has('endYear')) {
            const startyear = urlParams.get('startYear')
            const endyear = urlParams.get('endYear')

            this.duration[0] = startyear + "-01-01";
            this.duration[1] = endyear + "-12-31";
            if (!urlParams.has('name') && urlParams.has('startYear') && urlParams.has('endYear')) {
                setTimeout(() => {
                    this.is_visible_loader = false
                }, 3000);

            }
        }
        else if (urlParams.has('startDate') && urlParams.has('endDate')) {
            this.duration[0] = urlParams.get('startDate');
            this.duration[1] = urlParams.get('endDate');
            if (!urlParams.has('name') && urlParams.has('startDate') && urlParams.has('endDate')) {
                setTimeout(() => {
                    this.is_visible_loader = false
                }, 3000);

            }
        }
        else if (urlParams.has('startQuarterDate') && urlParams.has('endQuarterDate')) {
            this.duration[0] = urlParams.get('startQuarterDate');
            this.duration[1] = urlParams.get('endQuarterDate');
            if (!urlParams.has('name') && urlParams.has('startQuarterDate') && urlParams.has('endQuarterDate')) {
                setTimeout(() => {
                    this.is_visible_loader = false
                }, 3000);

            }
        }
        else if (urlParams.has('startWeek') && urlParams.has('endWeek')) {
            this.duration[0] = urlParams.get('startWeek');
            this.duration[1] = urlParams.get('endWeek');
            if (!urlParams.has('name') && urlParams.has('startWeek') && urlParams.has('endWeek')) {
                setTimeout(() => {
                    this.is_visible_loader = false
                }, 3000);

            }
        }
        else {
            this.duration[0] = this.default_back_date
            this.duration[1] = this.current_date

            if (!urlParams.has('name')) {
                setTimeout(() => {
                    this.is_visible_loader = false
                }, 3000);
            }

            console.log(this.nodesData.length);
        }
    },
    beforeDestroy() {
        this.stopSimulation();
        // window.removeEventListener('popstate', this.handlePopState);
        window.removeEventListener('load', this.scrollToElement);
        window.removeEventListener('filterApplied', this.scrollToElement);
        window.removeEventListener('resize', this.updateIsMobile);
    },
    data() {
        return {
            img,
            currentPage: 1,
            itemsPerPage: 10,
            baseYear: 2017,
            currentYear: new Date().getFullYear(),
            selectedYears: [2017, new Date().getFullYear()],
            query: '',
            quarters: this.generateQuarters(2017, new Date().getFullYear()),
            selectedQuarters: [0, 0],
            selectedMonths: [0, 0],
            selectedWeeks: [0, 0],
            totalDays: this.calculateTotalDays(2017, new Date().getFullYear()),
            selectedDates: [0, 0],
            sliderInitialized: false,
            duration: [],
            is_visible_loader: false,
            searchQuery: '',
            scrollDirection: 'down',
            is_transition_first_call: false,
            width: null,
            height: null,
            scrollPosition: 0,
            isMobile: false,
            isCloseScroll: true

        };
    },
    computed: {
        nameCountArray() {
            const { linkData } = this;

            const uniqueSourcesMap = new Map();

            linkData.forEach(item => {
                const { source, target } = item;

                if (!uniqueSourcesMap.has(target)) {
                    uniqueSourcesMap.set(target, new Set([source]));
                } else {
                    uniqueSourcesMap.get(target).add(source);
                }
            });

            const nameCountArray = [];
            uniqueSourcesMap.forEach((sources, target) => {
                nameCountArray.push({ target, numberOfSources: sources.size });
            });

            nameCountArray.sort((a, b) => b.numberOfSources - a.numberOfSources);
            return nameCountArray
        },
        filteredNameCountArray() {
            const { nameCountArray, searchQuery } = this;

            if (!searchQuery) {
                return nameCountArray;
            }

            const lowerCaseSearch = searchQuery.toLowerCase();
            return nameCountArray.filter(data => data.target.name.toLowerCase().includes(lowerCaseSearch));
        },
        paginatedNameCountArray() {
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;

            return this.filteredNameCountArray.slice(startIndex, endIndex);
        },
        totalItems() {
            return this.filteredNameCountArray.length;
        },
        months() {
            const startYear = this.baseYear;
            const endYear = new Date().getFullYear();
            return this.generateMonths(startYear, endYear);
        },
        totalWeeks() {
            const startYear = this.baseYear;
            const currentYear = new Date().getFullYear();
            return this.calculateTotalWeeks(startYear, currentYear);
        },
        offSet() {
            return this.currentPage === 1 ? 0 : this.currentPage * 10 - 1;
        }

    },
    methods: {
        checkIfMobile() {
            return window.innerWidth <= 768;
        },
        updateIsMobile() {
            this.isMobile = this.checkIfMobile();
        },
        initSimulation() {

            const isMobileView = window.matchMedia('(max-width: 768px)').matches;
            let isTabletView = window.matchMedia('(min-width: 768px) and (max-width: 992px)').matches;
            
            const { nodesData, linkData } = this;
            
            if (nodesData.length > 0) {
                // this.is_visible_loader = true
                const currentUrl = window.location.href;
                localStorage.setItem('currentUrl', currentUrl);
            }
            const svg = d3.select('svg');
            const radius = 130

            const g = svg.append('g').attr('class', 'everything').attr("viewBox", [0, 0, this.width, this.height]).attr("style", "max-width: 100%; height: auto;");

            function circleColour(d) {
                if (d.company === 'C') {
                    return '#DDE6ED';
                } else {
                    return '#40B5AD';
                }
            }
            function nodeRadius(d) {
                if (d.company === 'C') {
                    return 195; // Increase the radius for company nodes
                } else {
                    return 40; // Use the default radius for other nodes
                }
            }
            function linkColour() {
                return 'gray';
            }

            const initialScale = isMobileView ? 0.14 : 0.15;

            const handleZoom = (e) => g.attr('transform', e.transform);
            const zoom = d3.zoom().on('zoom', handleZoom);

            zoom.scaleTo(svg, initialScale);
            svg.call(zoom);

            const simulation = d3.forceSimulation(nodesData)
                .force('links', d3.forceLink(linkData)
                    .id(d => d.name)
                    .distance(800)
                )
                .force('charge', d3.forceManyBody().strength(-1700))

            simulation.on('tick', tickActions);

            const link = g.append('g').attr('class', 'links')
                .selectAll('line')
                .data(linkData)
                .enter()
                .append('line')
                .attr('stroke-width', 2)
                .style('stroke', linkColour);

            const node = g.append('g').attr('class', 'nodes')
                .selectAll("circle")
                .data(nodesData)
                .enter()
                .append('g')
                .join("circle")
                .attr("r", 5)
                .call(d3.drag()
                    .on('start', dragstarted)
                    .on('drag', dragged)
                    .on('end', dragended));

            node.on('click', (event, d) => {
                if (d.url && d.is_active_company == true) {
                    window.open(d.url, '_blank');
                }
                else {
                    if (d.company == 'C') {
                        alert("Company profile not found!");
                    } else {
                        const newUrl = new URL(this.request_href);
                        newUrl.search = '';
                        newUrl.searchParams.set('name', d.name);
                        window.location.href = newUrl.toString()
                    }
                }
            });

            // Append a circle element to each node
            node.append('circle')
                .attr('r', d => nodeRadius(d))
                .attr('stroke', 'black') // Border color
                .attr('stroke-width', 1.5) // Border width
                .attr('fill', circleColour);

            // Append an image element to each node
            node.each(function (d) {
                var parentNode = this;
                if (d.company === 'C') {
                    d3.select(this)
                        .append('image')
                        .attr('xlink:href', d => d.image)
                        .attr('x', -radius) // Adjust the positioning as needed
                        .attr('y', -radius)
                        .attr('width', radius * 2)
                        .attr('height', radius * 2)
                        .on('error', function () {
                            d3.select(this).remove();
                            d3.select(parentNode)
                                .append('text')
                                .text(d => d.name)
                                .attr('x', 0) // Adjust positioning as needed
                                .attr('y', 0)
                                .style('text-anchor', 'middle')
                                .style('font-size', '25px')
                                .style('dominant-baseline', 'middle')
                                .style('font-weight', 'bold')
                                .attr('class', 'error-text');
                        })
                        .style('cursor', 'pointer');
                } else {
                    var textElement = d3.select(this).append('text');
                    var offset = Math.min(d3.select(this).attr('r') / 3, 5); // Adjust minimum offset if needed
                    textElement.text(d => d.name)
                        .style('text-anchor', 'start') // Align text to the left within the group
                        .style('dominant-baseline', 'middle')
                        .style('font-size', '45px')
                        .style('font-weight', 'bold')
                        .style('fill', 'black')
                        .style('cursor', 'pointer')
                        .attr('x', d3.select(this).attr('r') - offset + 40) // Position near node edge
                        .attr('y', 0); // Center vertically within the node
                }
            });

            function tickActions() {
                node.attr('transform', d => `translate(${d.x},${d.y})`);

                link
                    .attr('x1', d => d.source.x)
                    .attr('y1', d => d.source.y)
                    .attr('x2', d => d.target.x)
                    .attr('y2', d => d.target.y);

                node
                    .attr("cx", d => d.x)
                    .attr("cy", d => d.y);
            }

            node.append('title')
                .text(d => d.name);

            function dragstarted(event) {
                if (!event.active) simulation.alphaTarget(0.04).restart();
                event.subject.fx = event.subject.x;
                event.subject.fy = event.subject.y;
            }

            function dragged(event) {
                event.subject.fx = event.x;
                event.subject.fy = event.y;
            }

            function dragended(event) {
                if (!event.active) simulation.alphaTarget(0);
                event.subject.fx = null;
                event.subject.fy = null;
            }

            const urlParams = new URLSearchParams(window.location.search);

            simulation.on('end', () => {
                if (urlParams.has('name')) {
                    this.query = urlParams.get('name');
                    const targetNodeName = urlParams.get('name');

                    const targetNode = nodesData.find(node => node.name === targetNodeName);
                    let svgCenterX = null
                    let svgCenterY = null

                    if (targetNode) {
                        // Highlight the target node
                        const highlightDuration = 1200;
                        const highlightedFillColor = 'yellow';

                        // Get the current transformation
                        const transform = d3.zoomTransform(svg.node());

                        // Get the center coordinates of the SVG

                        if (isMobileView) {
                            svgCenterX = 400 / 2;
                            svgCenterY = this.height / 2;
                        } else if (isTabletView) {
                            svgCenterX = 700 / 2;
                            svgCenterY = this.height / 3;
                        } else {
                            svgCenterX = this.width / 2;
                            svgCenterY = this.height / 2;
                        }

                        // Get the center coordinates of the target node
                        const targetNodeX = targetNode.x * transform.k + transform.x;
                        const targetNodeY = targetNode.y * transform.k + transform.y;

                        // Calculate the offset for centering
                        const offsetX = (svgCenterX - targetNodeX * 0.2) / 2;
                        const offsetY = (svgCenterY - targetNodeY * 0.2) / 2;

                        // Apply the offset to the translation
                        const newTranslateX = transform.x + offsetX;
                        const newTranslateY = transform.y + offsetY;

                        // Apply a transition to the target node for highlighting
                        node.filter(d => d === targetNode)
                            .select('circle')
                            .transition()
                            .duration(highlightDuration)
                            .attr('fill', highlightedFillColor)

                        if (this.is_transition_first_call == false) {
                            if (isMobileView) {
                                svg.transition()
                                    .call(zoom.transform, d3.zoomIdentity.translate(newTranslateX, newTranslateY).scale(0.13))
                                    .on('end', () => {
                                        this.is_visible_loader = false;
                                        this.is_transition_first_call = true;

                                    });
                            } else if (isTabletView) {
                                svg.transition()
                                    .call(zoom.transform, d3.zoomIdentity.translate(newTranslateX, newTranslateY).scale(0.16))
                                    .on('end', () => {
                                        this.is_visible_loader = false;
                                        this.is_transition_first_call = true;

                                    });

                            }
                            else {
                                svg.transition()
                                    .on('end', () => {
                                        this.is_visible_loader = false;
                                        this.is_transition_first_call = true;
                                        zoom.scaleTo(svg, 0.12)
                                    });
                            }

                        }
                    }
                    else {
                        const targetNodeName = urlParams.get('name');
                        const matchingNodes = nodesData.filter(node => node.name.toLowerCase().includes(targetNodeName.toLowerCase()));

                        if (matchingNodes.length > 0) {
                            // Highlight all matching nodes
                            const highlightDuration = 1200;
                            const highlightedFillColor = 'yellow';

                            matchingNodes.forEach(matchingNode => {
                                node.filter(d => d === matchingNode)
                                    .select('circle')
                                    .transition()
                                    .duration(highlightDuration)
                                    .attr('fill', highlightedFillColor)

                            });

                            // Center the view on the first matching node
                            const targetNode = matchingNodes[0];
                            const targetX = targetNode.x;
                            const targetY = targetNode.y;

                            // Get the current transformation
                            const transform = d3.zoomTransform(svg.node());

                            // Get the center coordinates of the SVG
                            if (isMobileView) {
                                svgCenterX = 400 / 2;
                                svgCenterY = 1000 / 2;
                            } else if (isTabletView) {
                                svgCenterX = 750 / 2;
                                svgCenterY = this.height / 3;
                            } else {
                                svgCenterX = this.width / 2;
                                svgCenterY = this.height / 2;
                            }

                            // Get the center coordinates of the target node after transformation
                            const targetNodeX = targetX * transform.k + transform.x;
                            const targetNodeY = targetY * transform.k + transform.y;

                            // Calculate the new translation so that the target node remains at the center after centering
                            const newTranslateX = svgCenterX - targetNodeX * 0.2;
                            const newTranslateY = svgCenterY - targetNodeY * 0.2;
                            if (this.is_transition_first_call == false) {
                                if (isMobileView) {
                                    svg.transition()
                                        .on('end', () => {
                                            this.is_visible_loader = false;
                                            this.is_transition_first_call = true
                                            zoom.scaleTo(svg, 0.12)
                                        });

                                } else if (isTabletView) {
                                    svg.transition()
                                        .call(zoom.transform, d3.zoomIdentity.translate(newTranslateX, newTranslateY).scale(0.1))
                                        .on('end', () => {
                                            this.is_visible_loader = false;
                                            this.is_transition_first_call = true;

                                        });

                                }
                                else {
                                    svg.transition()
                                        .on('end', () => {
                                            this.is_visible_loader = false;
                                            this.is_transition_first_call = true
                                        });
                                }
                            }
                        }
                        else {
                            console.log('No matching nodes found');
                            // this.is_visible_loader = false;
                        }
                    }
                }
            });
            // this.is_visible_loader = false;
        },
        scrollToElement() {
            const frameSection = this.$refs.frameSection;
            if (frameSection.offsetTop > window.scrollY) {
                this.$nextTick(() => {
                    frameSection.scrollIntoView({ behavior: 'smooth' });
                });
            } else if (frameSection.offsetTop < window.scrollY - window.innerHeight) {
                frameSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
            this.scrollPosition = window.scrollY || document.body.scrollTop;
        },
        setDimensions() {
            const isMobile = window.matchMedia('(max-width: 768px)').matches;

            if (isMobile) {
                this.width = this.width = Math.min(window.innerWidth * 0.9, 300);
                this.height = 900;
            } else {
                if (this.nodesData.length > 0) {
                    this.width = this.$refs.mySvg.clientWidth;
                    this.height = this.$refs.mySvg.clientHeight;
                }
                else {
                    this.width = 0;
                    this.height = 0;
                }
            }
        },
        goBack() {
            const currentUrl = localStorage.getItem('currentUrl');
            if (currentUrl) {
                window.location.href = currentUrl;
            } else {
                window.history.back();
            }
        },
        getPreviousUrl() {
            const historyLength = window.history.length;
            if (historyLength > 1) {
                return document.referrer;
            }
            return null;
        },
        handleSearch() {
            this.currentPage = 1;
        },
        closeScroll() {
            this.isCloseScroll = false;
        },
        toggleScroll() {

            if (this.scrollDirection === 'down') {
                // Scroll to the bottom
                window.scrollTo({
                    top: 20000,
                    behavior: 'smooth'
                });
                // Scroll to the bottom of the page
                // document.body.scrollTop = document.body.scrollHeight;
                // document.documentElement.scrollTop = document.documentElement.scrollHeight;

                this.scrollDirection = 'up';
            } else {
                // Scroll to the top
                window.scrollTo({
                    top: 460,
                    behavior: 'smooth'
                });
                // document.body.scrollTop = 0;
                // document.documentElement.scrollTop = 0;

                this.scrollDirection = 'down';
            }
        },
        generateQuarters(startYear, endYear) {
            const quarters = [];
            for (let year = startYear; year <= endYear; year++) {
                quarters.push(
                    { year: year, name: 'Q1' },
                    { year: year, name: 'Q2' },
                    { year: year, name: 'Q3' },
                    { year: year, name: 'Q4' }
                );
            }
            return quarters;
        },
        format(value) {
            let roundedValue = Math.round(value);
            let Mathvalue = Math.floor(roundedValue);
            const quarter = this.quarters[Mathvalue];
            this.duration.push(quarter);

            return `${quarter.name} - ${quarter.year}`;
        },
        updateQueryParam(name) {
            // this.is_visible_loader = true
            const newUrl = new URL(this.request_href);
            newUrl.search = '';
            newUrl.searchParams.set('name', name);
            window.location.href = newUrl.toString();
        },
        search() {
            const urlParams = new URLSearchParams(window.location.search);
            if (this.query != '') {
                // // this.is_visible_loader = true
                const newUrl = new URL(this.request_href);
                newUrl.search = '';
                let trimSearchqury = this.query.trim()
                newUrl.searchParams.set('name', trimSearchqury);
                window.location.href = newUrl.toString()
            }
            else if (urlParams.has('name') && this.query == '') {
                const newUrl = new URL(this.request_href);
                newUrl.search = '';
                window.location.href = newUrl.toString();
            }
        },
        resetFilter() {
            // this.is_visible_loader = true
            const newUrl = new URL(this.request_href);
            newUrl.search = '';
            window.location.href = newUrl.toString()
        },
        formatWeek(value) {
            let roundedValue = Math.round(value);
            let Mathvalue = Math.floor(roundedValue);
            const year = this.getYearFromWeekIndex(Mathvalue);
            const week = Mathvalue - this.getWeekIndexFromYear(year) + 1;
            return `Week ${week} - ${year}`;
        },
        applyWeekFilter(values) {
            // this.is_visible_loader = true
            const startDate = this.getStartDateForWeek(values[0]);
            const endDate = this.getEndDateForWeek(values[1]);
            const formattedStartDate = this.formatDateForFilter(startDate);
            const formattedEndDate = this.formatDateForFilter(endDate);
            const currentUrl = this.request_href;
            const url = new URL(currentUrl);

            const nameParam = url.searchParams.get('name');
            if (nameParam) {
                url.search = '?' + new URLSearchParams({ name: nameParam }).toString();
                url.searchParams.set('startWeek', formattedStartDate);
                url.searchParams.set('endWeek', formattedEndDate);
                localStorage.setItem('startWeekIndex', values[0]);
                localStorage.setItem('endWeekIndex', values[1]);
                window.location.href = url.toString()
            } else {
                const newUrl = new URL(this.request_href);
                newUrl.search = '';
                newUrl.searchParams.set('startWeek', formattedStartDate);
                newUrl.searchParams.set('endWeek', formattedEndDate);
                localStorage.setItem('startWeekIndex', values[0]);
                localStorage.setItem('endWeekIndex', values[1]);
                window.location.href = newUrl.toString()
            }
            console.log(`Selected dates: ${startDate} - ${endDate}`);
            console.log(`Selected dates: ${formattedStartDate} - ${formattedEndDate}`);
        },
        calculateTotalWeeks(startYear, endYear) {
            let totalWeeks = 0;
            for (let year = startYear; year <= endYear; year++) {
                totalWeeks += this.getWeeksInYear(year);
            }
            return totalWeeks;
        },
        getWeeksInYear(year) {
            const lastDayOfYear = new Date(year, 11, 31);
            const weekNumber = this.getISOWeekNumber(lastDayOfYear);
            return weekNumber;
        },
        getISOWeekNumber(date) {
            const dayOne = new Date(date.getFullYear(), 0, 1);
            const dayOneDayOfWeek = dayOne.getDay();
            let startWeek = dayOne;
            if (dayOneDayOfWeek <= 4) {
                startWeek.setDate(dayOne.getDate() - dayOneDayOfWeek + 1);
            } else {
                startWeek.setDate(dayOne.getDate() + 8 - dayOneDayOfWeek);
            }
            const diff = date - startWeek;
            return Math.ceil(diff / (7 * 24 * 60 * 60 * 1000)) + 1;
        },
        getWeekIndexFromYear(year) {
            let weeks = 0;
            for (let y = this.baseYear; y < year; y++) {
                weeks += this.getWeeksInYear(y);
            }
            return weeks;
        },
        getYearFromWeekIndex(index) {
            let year = this.baseYear;
            let totalWeeks = 0;
            while (totalWeeks <= index) {
                totalWeeks += this.getWeeksInYear(year);
                if (totalWeeks > index) {
                    break;
                }
                year++;
            }
            return year;
        },
        getStartDateForWeek(value) {
            const startYear = this.getYearFromWeekIndex(value);
            const startWeek = value - this.getWeekIndexFromYear(startYear) + 1; // Adjust to match the week starting from 1
            return this.getDateOfISOWeek(startWeek, startYear);
        },
        getEndDateForWeek(value) {

            const endYear = this.getYearFromWeekIndex(value);
            const endWeek = value - this.getWeekIndexFromYear(endYear) + 1; // Adjust to match the week starting from 1
            return this.getDateOfISOWeek(endWeek, endYear);
        },
        getDateOfISOWeek(week, year) {
            const januarySecond = new Date(year, 0, 2);
            const daysToAdd = (week - 1) * 7;
            januarySecond.setDate(januarySecond.getDate() + daysToAdd);
            return januarySecond;
        },
        formatDate(value) {
            let roundedValue = Math.round(value);
            let Mathvalue = Math.floor(roundedValue);
            const date = this.getDateFromIndex(Mathvalue);
            const formattedDate = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: 'numeric' }).format(date);
            return formattedDate;
        },
        calculateTotalDays(startYear, endYear) {
            let totalDays = 0;
            for (let year = startYear; year <= endYear; year++) {
                for (let month = 0; month < 12; month++) {
                    totalDays += this.getDaysInMonth(year, month);
                }
            }
            return totalDays;
        },
        getDaysInMonth(year, month) {
            return new Date(year, month + 1, 0).getDate();
        },
        getDateFromIndex(index) {
            let year = this.baseYear;
            let month = 0;
            let day = index + 1;
            while (day > this.getDaysInMonth(year, month)) {
                day -= this.getDaysInMonth(year, month);
                month++;
                if (month > 11) {
                    month = 0;
                    year++;
                }
            }
            return new Date(year, month, day);
        },
        applyYearFilter() {
            // this.is_visible_loader = true
            const currentUrl = this.request_href;
            const url = new URL(currentUrl);
            const startYear = this.selectedYears[0]
            const endYear = this.selectedYears[1]

            const nameParam = url.searchParams.get('name');
            if (nameParam) {
                url.search = '?' + new URLSearchParams({ name: nameParam }).toString();
                url.searchParams.set('startYear', startYear);
                url.searchParams.set('endYear', endYear);
                window.location.href = url.toString()
            } else {
                const newUrl = new URL(window.location.href);
                newUrl.search = '';
                newUrl.searchParams.set('startYear', startYear);
                newUrl.searchParams.set('endYear', endYear);
                window.location.href = newUrl.toString()
            }
            console.log(`Selected dates: ${formattedStartDate} - ${formattedEndDate}`);
        },
        applyDateFilter() {
            // this.is_visible_loader = true
            const startDate = this.getDateFromIndex(this.selectedDates[0]);
            const endDate = this.getDateFromIndex(this.selectedDates[1]);
            const formattedStartDate = this.dateFilterFormat(startDate);
            const formattedEndDate = this.dateFilterFormat(endDate);

            const currentUrl = this.request_href;
            const url = new URL(currentUrl);

            const nameParam = url.searchParams.get('name');
            if (nameParam) {
                url.search = '?' + new URLSearchParams({ name: nameParam }).toString();
                url.searchParams.set('startDate', formattedStartDate);
                url.searchParams.set('endDate', formattedEndDate);
                window.location.href = url.toString()
            } else {
                const newUrl = new URL(window.location.href);
                newUrl.search = '';
                newUrl.searchParams.set('startDate', formattedStartDate);
                newUrl.searchParams.set('endDate', formattedEndDate);
                window.location.href = newUrl.toString()
            }

            console.log(`Selected dates: ${formattedStartDate} - ${formattedEndDate}`);
        },
        applyQuartersFilter(values) {
            // this.is_visible_loader = true
            const startDate = this.getStartDateForQuarter();
            const endDate = this.getEndDateForQuarter();
            const formattedStartDate = this.formatDateForFilter(startDate);
            const formattedEndDate = this.formatDateForFilter(endDate);

            const currentUrl = this.request_href;
            const url = new URL(currentUrl);
            const nameParam = url.searchParams.get('name');

            if (nameParam) {
                url.search = '?' + new URLSearchParams({ name: nameParam }).toString();
                url.searchParams.set('startQuarterDate', formattedStartDate);
                url.searchParams.set('endQuarterDate', formattedEndDate);
                localStorage.setItem('startQuarterIndex', values[0]);
                localStorage.setItem('endQuarterIndex', values[1]);
                window.location.href = url.toString()
            } else {
                const newUrl = new URL(window.location.href);
                newUrl.search = '';
                newUrl.searchParams.set('startQuarterDate', formattedStartDate);
                newUrl.searchParams.set('endQuarterDate', formattedEndDate);
                localStorage.setItem('startQuarterIndex', values[0]);
                localStorage.setItem('endQuarterIndex', values[1]);
                window.location.href = newUrl.toString()
            }
            console.log(`Selected dates: ${formattedStartDate} - ${formattedEndDate}`);
        },
        getStartDateForQuarter() {
            const startQuarter = this.quarters[this.selectedQuarters[0]];
            const startMonth = this.getQuarterStartMonth(startQuarter);
            const startDate = new Date(startQuarter.year, startMonth, 1);
            return startDate;
        },
        getEndDateForQuarter() {
            const endQuarter = this.quarters[this.selectedQuarters[1]];
            const endMonth = this.getQuarterEndMonth(endQuarter);
            const endDate = new Date(endQuarter.year, endMonth + 1, 0);
            return endDate;
        },
        getQuarterStartMonth(quarter) {
            switch (quarter.name) {
                case 'Q1':
                    return 0; // January
                case 'Q2':
                    return 3; // April
                case 'Q3':
                    return 6; // July
                case 'Q4':
                    return 9; // October
                default:
                    return 0; // Default to January
            }
        },
        getQuarterEndMonth(quarter) {
            switch (quarter.name) {
                case 'Q1':
                    return 2; // March
                case 'Q2':
                    return 5; // June
                case 'Q3':
                    return 8; // September
                case 'Q4':
                    return 11; // December
                default:
                    return 11; // Default to December
            }
        },
        setSliderFromURLParams() {
            const url = new URL(this.request_href);
            const startMonthParam = url.searchParams.get('startMonth');
            const endMonthParam = url.searchParams.get('endMonth');
            const startYearParam = url.searchParams.get('startYear');
            const endYearParam = url.searchParams.get('endYear');
            const startDateParam = url.searchParams.get('startDate');
            const endDateParam = url.searchParams.get('endDate');
            const startWeekParam = url.searchParams.get('startWeek');
            const endWeekParam = url.searchParams.get('endWeek');
            const startQuarterParam = url.searchParams.get('startQuarterDate');
            const endQuarterParam = url.searchParams.get('endQuarterDate');

            if (startMonthParam && endMonthParam) {

                const startMonthIndex = this.findMonthIndex(startMonthParam);
                const endMonthIndex = this.findMonthIndex(endMonthParam);

                if (startMonthIndex !== -1 && endMonthIndex !== -1) {
                    this.selectedMonths = [startMonthIndex, endMonthIndex];
                    this.sliderInitialized = true;
                }
            }
            else if (startYearParam && endYearParam) {
                this.selectedYears = [startYearParam, endYearParam];
                this.sliderInitialized = true;
            }
            else if (startDateParam && endDateParam) {
                const startDateIndex = this.getIndexFromDate(new Date(startDateParam));
                const endDateIndex = this.getIndexFromDate(new Date(endDateParam));
                this.selectedDates = [startDateIndex, endDateIndex];
                this.sliderInitialized = true;

            }
            else if (startWeekParam && endWeekParam) {
                const startWeekIndex = localStorage.getItem('startWeekIndex');
                const endWeekIndex = localStorage.getItem('endWeekIndex');
                this.selectedWeeks = [startWeekIndex, endWeekIndex];
                this.sliderInitialized = true;
            }
            else if (startQuarterParam && endQuarterParam !== null) {
                const startQuarterIndex = localStorage.getItem('startQuarterIndex');
                const endQuarterIndex = localStorage.getItem('endQuarterIndex');
                this.selectedQuarters = [startQuarterIndex, endQuarterIndex];
                this.sliderInitialized = true;
            }
            else {
                // Reset slider values to default
                this.selectedYears = [this.baseYear, this.currentYear];
                this.selectedDates = [0, 0];
                this.selectedMonths = [0, 0];
                this.selectedWeeks = [0, 0];
                this.selectedQuarters = [0, 0];
                this.sliderInitialized = false;
            }
        },
        getWeekIndexFromDate(date) {
            const referenceDate = new Date(this.baseYear, 0, 2); // January 2nd, 2017
            const millisecondsInDay = 24 * 60 * 60 * 1000; // Milliseconds in a day

            // Calculate the difference in days between the given date and the reference date
            const daysDifference = Math.floor((date - referenceDate) / millisecondsInDay);

            // Calculate the week index based on the mapping from the reference date
            const weekIndex = Math.floor(daysDifference / 7) + 1;

            return weekIndex
        },
        findMonthIndex(monthParam) {
            const month = new Date(monthParam);
            const year = month.getFullYear();
            const monthIndex = month.getMonth();
            return this.months.findIndex(
                m => m.year === year && m.month === monthIndex
            );
        },
        getIndexFromDate(date) {
            let year = date.getFullYear();
            let month = date.getMonth();
            let day = date.getDate() - 1;
            let totalDays = 0;

            for (let y = this.baseYear; y < year; y++) {
                for (let m = 0; m < 12; m++) {
                    totalDays += this.getDaysInMonth(y, m);
                }
            }

            for (let m = 0; m < month; m++) {
                totalDays += this.getDaysInMonth(year, m);
            }

            return totalDays + day;
        },
        applyMonthFilter() {
            // this.is_visible_loader = true
            const formattedStartDate = this.getStartDate();
            const formattedEndDate = this.getEndDate();
            console.log(`Selected dates: ${formattedStartDate} - ${formattedEndDate}`);

            const currentUrl = this.request_href;
            const url = new URL(currentUrl);

            const nameParam = url.searchParams.get('name');
            if (nameParam) {
                url.search = '?' + new URLSearchParams({ name: nameParam }).toString();
                url.searchParams.set('startMonth', formattedStartDate);
                url.searchParams.set('endMonth', formattedEndDate);
                window.location.href = url.toString()
            } else {
                const newUrl = new URL(window.location.href);
                newUrl.search = '';
                newUrl.searchParams.set('startMonth', formattedStartDate);
                newUrl.searchParams.set('endMonth', formattedEndDate);
                window.location.href = newUrl.toString()
            }
            if (!this.sliderInitialized) {
                this.$nextTick(() => {
                    this.setSliderFromURLParams();
                });
            }
        },
        generateMonths(startYear, endYear) {
            const allMonths = [];
            for (let year = startYear; year <= endYear; year++) {
                for (let month = 0; month < 12; month++) {
                    allMonths.push({ year: year, month: month });
                }
            }
            return allMonths;
        },
        getMonthName(monthIndex) {
            const monthNames = [
                'January', 'February', 'March', 'April', 'May', 'June',
                'July', 'August', 'September', 'October', 'November', 'December'
            ];
            return monthNames[monthIndex];
        },
        formatMonth(value) {
            let roundedValue = Math.round(value);
            let Mathvalue = Math.floor(roundedValue);
            const month = this.months[Mathvalue];
            return `${this.getMonthName(month.month)} - ${month.year}`;
        },

        getStartDate() {
            const startMonth = this.months[this.selectedMonths[0]];
            const formattedStartDate = `${startMonth.year}-${(startMonth.month + 1).toString().padStart(2, '0')}-01`;
            return formattedStartDate
        },
        getEndDate() {
            const endMonth = this.months[this.selectedMonths[1]];
            const lastDayOfMonth = new Date(endMonth.year, endMonth.month + 1, 0).getDate();
            const formattedEndDate = `${endMonth.year}-${(endMonth.month + 1).toString().padStart(2, '0')}-${lastDayOfMonth}`;
            return formattedEndDate;
        },
        formatDateForFilter(date) {
            return date.toISOString().split('T')[0];
        },
        dateFilterFormat(date) {
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
    }
}

</script>

<style src="@vueform/slider/themes/default.css"></style>

<style>
.slider-blue {
    --slider-connect-bg: #3B82F6;
    --slider-tooltip-bg: #3B82F6;
    --slider-tooltip-font-size: 12px;
    --slider-handle-ring-color: #3B82F630;

}

@media (max-width: 540px) {
    .slider-blue {
        margin: 0 auto;
        width: 100%;
    }

    .tab-pane.peoples-web {
        width: 50%;
        margin: 0 auto;
    }

    .slider-tooltip {
        font-size: 10px;
    }

    button.btn.btn-primary.text-light {
        margin: 0.5rem auto;
    }
}

.p-autocomplete-input {
    width: 100%;
    background: #f3f7fc;
    border: none;
}
.p-inputtext:focus {
    box-shadow: none !important;
}

.p-autocomplete-panel {
    z-index: 1 !important;
}
</style>