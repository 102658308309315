<template>
  <ul id="social-media-icons-bar" class="d-none d-md-block">
    <li class="hvr-grow">
      <a href="https://twitter.com/SearchSpaceAI" target="_blank" class="twitter"><img src="../../assets/icons/x-twitter.svg" ><span class="icon-overlay"></span></a>
    </li>
    <li class="hvr-grow">
      <a href="https://www.linkedin.com/company/search-space-ai" target="_blank" class="linkedin"><i class="fa fa-lg fa-linkedin"></i><span class="icon-overlay"></span></a>
    </li>
    <li class="hvr-grow">
      <a href="https://www.facebook.com/SearchSpaceGEO" target="_blank" class="facebook"><i class="fa fa-lg fa-facebook"></i><span class="icon-overlay"></span></a>
    </li>
    <li class="hvr-grow">
      <a href="https://www.youtube.com/@searchspaceAI/videos" target="_blank" class="youtube"><i class="fa fa-lg fa-youtube"></i><span class="icon-overlay"></span></a>
    </li>
  </ul>
</template>