<script setup>
import { ref, computed, onMounted } from 'vue'
import ListingCard from '../../components/listingCard/ListingCard.vue';
import { router, usePage, useForm, Link } from '@inertiajs/vue3'
import VueMultiselectCustom from '../../components/dropdownSelector/VueMultiselectCustom.vue'
import BsModal from '../../components/BsModal.vue'
import Gmap from "../../components/map/map.vue";
import _ from 'lodash'
import { projectFilterState } from './projectFilterState';
import Nav from '../../components/layout/Nav.vue'
import axios from "axios";
import ProjectsBanner from './ProjectsBanner.vue'
import headComponent from "../../components/inertiaHead/head.vue"

const props = defineProps({
  request_href: {
    type: String,
  },
  csrf_token: {
    type: String,
    required: ""
  },
  baseImageUrl: {
    type: String,
  },
  all_internal_comapnies_with_slug: {
    type: Array,
    default: [],
  },
  all_commodities: {
    type: Array,
  },
  all_countries: {
    type: Array
  },
  all_deals: {
    type: Array,
  },
  all_stages: {
    type: Array
  },
  listings: {
    type: Array,
  },
  listingsCount: {
    type: Number
  },
  heat_map_data: {
    type: Array,
  },
  location: {
    type: Array,
    default: [],
  },
  user: {
    type: Object,
    default: null,
  },
  captchaSiteKey: {
    type: String
  },
  is_deal_type_slug: {
    type: Boolean,
    default: false
  },
  is_country_slug: {
    type: Boolean,
    default: false
  },
  is_stage_slug: {
    type: Boolean,
    default: false
  },
  is_commodity_slug: {
    type: Boolean,
    default: false
  },
  is_home_page_map_view: {
    type: Boolean,
    default: false
  },
  country_name_in_slug:{
    type: String,
    required: ""
  },
  stage_name_in_slug:{
    type: String,
    required: ""
  },
  commodity_name_in_slug:{
    type: String,
    required: ""
  },
  deal_type_name_in_slug:{
    type: String,
    required: ""
  }
})

const currentUrl = computed(() => {
  return new URL(props.request_href);
})

const currentUrlPathName = computed(() => {
  const currentViewIsMap = !!currentUrl.value.searchParams.get('map-view')
  return `${currentUrl.value.pathname}${currentViewIsMap ? '?map-view=true' : ''}`
})

const showFilter = computed(() => projectFilterState.isOpen)

const toggleMapView = () => {
  const url = new URL(currentUrl.value.href)
  const currentViewIsMap = !!url.searchParams.get('map-view')
  if (currentViewIsMap) {
    url.searchParams.delete('map-view')
  } else {
    url.searchParams.set('map-view', true)
  }

  return router.visit(url.pathname + url.search, {
    method: 'get',
    replace: true,
    preserveScroll: true,
    onStart: () => {
      isLoading.value = true
    },
    onFinish: () => {
      isLoading.value = false

    }
  })
}

const listingsMapViewLocal = computed(() => props.listings)

const combinedFilters = computed(() => {

  const selectedPopUpFilter = (() => {
    if (typeof window === 'undefined') return '';
    const urlLocation = window.location;
    const queryString = urlLocation.search;
    const urlParams = new URLSearchParams(queryString);
    const urlString = urlLocation.href;
    const queryObj = [...new URLSearchParams(urlString.split('projects?')[1])].reduce(
      (a, [k, v]) => ((a[k] = v), a),
      {}
    );

    if (queryObj['map-view'] === 'true') {
      delete queryObj['map-view'];
    }

    const objSize = Object.keys(queryObj).length;

    return objSize === 1
      ? urlParams.get(Object.keys(queryObj)[0])
        ? urlParams.get(Object.keys(queryObj)[0]).split(',').length === 1
          ? urlParams.get(Object.keys(queryObj)[0])
          : 'projects'
        : []
      : 'projects';
  })();
  const selectedCategoryItem = (() => {
    const url = currentUrl.value.pathname;
    const parts = url.split('/projects');
    const lastParam = parts[parts.length - 1];
    console.log(lastParam);
    return lastParam;
  })();

  return {
    popUpFilter: selectedPopUpFilter,
    categoryItem: selectedCategoryItem,
  };
});

// === filter pop up =========================================================

const filterSelectedCommodities = ref(null)
const filterSelectedCountries = ref(null)
const filterKeyWord = ref('')
const filterComProName = ref('')
const filterSelectedDealType = ref([])
const filterSelectStage = ref([])
const selectedTopSearchCommoditiesLocal = ref('')
const selectedTopSearchCountryLocal = ref('')
const selectedTopSearchKeywordLocal = ref('')

const getQueryFilters = () => {
  const queryString = currentUrl.value.search
  const urlParams = new URLSearchParams(queryString);

  filterKeyWord.value = urlParams.get('keyword') ? urlParams.get('keyword') : ''
  filterComProName.value = urlParams.get('name-keyword') ? urlParams.get('name-keyword') : ''
  filterSelectedCommodities.value = urlParams.get('commodity') ? urlParams.get('commodity').split(",") : []
  filterSelectedCountries.value = urlParams.get('country') ? urlParams.get('country').split(",") : []

  const selectedDealType = urlParams.get('deal-type') ? urlParams.get('deal-type').split(",") : []
  urlParams.get('deal-type') && filterSelectedDealType.value.push(...urlParams.get('deal-type').split(","))

  selectedDealType.forEach(item => {
    document.querySelector(`[id="${item}"]`).checked = true
  })

  const selectedStatus = urlParams.get('stage') ? urlParams.get('stage').split(",") : []
  urlParams.get('stage') && filterSelectStage.value.push(...urlParams.get('stage').split(","))
  selectedStatus.forEach(item => {
    document.querySelector(`[id="${item}"]`).checked = true
  })
}

const dealTypeChecked = (dealType, checked) => {
  if (checked) {
    filterSelectedDealType.value.push(dealType)
  } else {
    const index = filterSelectedDealType.value.indexOf(dealType);
    if (index > -1) {
      filterSelectedDealType.value.splice(index, 1);
    }
  }
}

const stageChecked = (stage, checked) => {
  if (checked) {
    filterSelectStage.value.push(stage)
  } else {
    const index = filterSelectStage.value.indexOf(stage);
    if (index > -1) {
      filterSelectStage.value.splice(index, 1);
    }
  }
}

const isLoading = ref(false)

const applyFilter = (param) => {

  let searshParam = {}
  Object.assign(searshParam, filterKeyWord.value ? { keyword: filterKeyWord.value } : null);
  Object.assign(searshParam, filterComProName.value ? { 'name-keyword': filterComProName.value } : null);
  Object.assign(searshParam, filterSelectedCommodities.value?.length ? { commodity: filterSelectedCommodities.value.join(",") } : null);
  Object.assign(searshParam, filterSelectedCountries.value?.length ? { country: filterSelectedCountries.value.join(",") } : null);
  Object.assign(searshParam, filterSelectedDealType.value?.length ? { 'deal-type': filterSelectedDealType.value.join(",") } : null);
  Object.assign(searshParam, filterSelectStage.value?.length ? { 'stage': filterSelectStage.value.join(",") } : null);

  return router.visit(currentUrlPathName.value, {
    method: 'get',
    data: { ...searshParam },
    replace: true,
    preserveScroll: true,
    onStart: () => {
      isLoading.value = true
    },
    onFinish: () => {
      isLoading.value = false

    }
  })
}

const clearFilters = () => {
  filterKeyWord.value = ''
  filterComProName.value = ''
  filterSelectedCommodities.value = null
  filterSelectedCountries.value = null
  filterSelectedDealType.value = []
  filterSelectStage.value = []

  projectFilterState.setIsOpen(false)

  applyFilter()
}

// ===========================================================================

onMounted(() => {

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  }

  router.on('start', () => {
    isLoading.value = true
  })

  router.on('finish', () => {
    isLoading.value = false
  })


  let queryObject = {}
  const urlString = currentUrl.value.href
  queryObject.value = [...new URLSearchParams(urlString.split('?')[1])].reduce((a, [k, v]) => ((a[k] = v), a), {})

  selectedTopSearchCommoditiesLocal.value = queryObject.value.commodity
  selectedTopSearchKeywordLocal.value = queryObject.value.keyword
  selectedTopSearchCountryLocal.value = queryObject.value.country

  getQueryFilters()

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        if (props.listingsCount > currentCount.value) {
          fetchListings()
        }
      }
    });
  });
  observer.observe(gridMark.value);
})

const isMapView = computed(() => {
  return props.is_home_page_map_view
})


let queryObject = {}
const urlString = currentUrl.value.href
queryObject.value = [...new URLSearchParams(urlString.split('?')[1])].reduce((a, [k, v]) => ((a[k] = v), a), {})

selectedTopSearchCommoditiesLocal.value = queryObject.value.commodity
selectedTopSearchKeywordLocal.value = queryObject.value.keyword
selectedTopSearchCountryLocal.value = queryObject.value.country


const listing_limit_per_load = ref(18);
let shuffledListings = [];
let listingsShuffledLocal = ref([]);

const listingsShuffle = async (data) => {

  const priorityItems = data.filter(item => item.is_verified || item.is_new_property);
  const remainingItems = data.filter(item => !priorityItems.includes(item));

  for (let i = priorityItems.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [priorityItems[i], priorityItems[j]] = [priorityItems[j], priorityItems[i]];
  }
  for (let i = remainingItems.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [remainingItems[i], remainingItems[j]] = [remainingItems[j], remainingItems[i]];
  }


  const newShuffledListings = priorityItems.concat(remainingItems);

  shuffledListings = [...shuffledListings, ...newShuffledListings.filter(item => !shuffledListings.includes(item))];

  listingsShuffledLocal.value = shuffledListings;
};

listingsShuffle(props.listings || [])

const initialUrl = usePage().url
const currentCount = ref(listing_limit_per_load.value)

const fetchListings = async () => {

  isLoading.value = true

  const res = await axios.get(initialUrl, {
    params: {
      skip: currentCount.value
    },
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  })

  currentCount.value = currentCount.value + 18
  listingsShuffle(res.data.listings)
  isLoading.value = false

}


const gridMark = ref(null);

const goToTop = () => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}

const vistCatergoryURL = (url, data) => {
  const queryParams = props.is_home_page_map_view ? '?map-view=true' : '';
  if (url && data) {
    const targetURL = `/${url}/${data}${queryParams}`;
    router.visit(targetURL);
  }
  else {
    if (props.is_home_page_map_view) {
      const targetURL = `/${queryParams}`;
      router.visit(targetURL);
    }
    else {
      router.visit('/');
    }

  }
}
</script>

<template>
  <template v-if="is_country_slug == true">
       <headComponent :description="`Browse mining projects to invest in ${country_name_in_slug}. Dive into gold, silver, copper, junior mining, and more investments across various countries with SearchSpace AI.`"
          :title="`Mining Investment Projects in ${country_name_in_slug}`">
      </headComponent>
  </template>
  <template v-else-if="is_deal_type_slug == true">
       <headComponent :description="`Discover best mining ${deal_type_name_in_slug} projects to invest in USA, Canada, Mexico. From gold, silver, copper mass mining to junior mining, find opportunities that suit your investment strategy.`"
          :title="`Explore Mining ${deal_type_name_in_slug} Projects`">
      </headComponent>
  </template>
  <template v-else-if="is_stage_slug == true">
       <headComponent :description="`Learn about the ${stage_name_in_slug} stage in mining projects, a crucial phase of project development. Discover the methodologies, surveys, and resource evaluation techniques used during this phase`"
          :title="`Explore ${stage_name_in_slug} Stage in Mining Projects`">
      </headComponent>
  </template>
  <template v-else-if="is_commodity_slug == true">
       <headComponent :description="`Discover metal investment projects tailored to your preferred metals. Explore a diverse range of mining ventures, including those focused on ${commodity_name_in_slug}, all facilitated by SearchSpace AI.`"
          :title="`Explore ${commodity_name_in_slug} Mining Investment Projects`">
      </headComponent>
  </template>
  <template v-else>
       <headComponent :description="'SearchSpace AI brings you the latest mining news, best gold mines, Silver mining investments, mining news AI and a comprehensive mining network.'"
          :title="'Find Your Next Mining & Mining Investment'">
      </headComponent>
  </template>

  <section>
    <!-- Home banner starts -->
    <ProjectsBanner 
      :csrf_token="csrf_token" 
      :captcha_sitekey="captchaSiteKey"
      :all_commodities="all_commodities" 
      :all_countries="all_countries"
      :selectedTopSearchCommoditiesLocal="selectedTopSearchCommoditiesLocal"
      :selectedTopSearchKeywordLocal="selectedTopSearchKeywordLocal"
      :selectedTopSearchCountryLocal="selectedTopSearchCountryLocal" 
      :is_commodity_slug="is_commodity_slug" 
      :is_country_slug="is_country_slug" 
      :is_deal_type_slug="is_deal_type_slug" 
      :is_stage_slug="is_stage_slug" 
      :deal_type_name_in_slug="deal_type_name_in_slug" 
      :commodity_name_in_slug="commodity_name_in_slug" 
      :stage_name_in_slug="stage_name_in_slug" 
      :country_name_in_slug="country_name_in_slug"  />
    <!-- Home banner ends -->
  </section>

  <section>
    <a href="#" class="top-to-scroll scroll-on-top" @click="goToTop()">
      <i class="fa fa-chevron-up" aria-hidden="true"></i>
    </a>
  </section>

  <div class="banner-filter-for-home">
    <section style="position: absolute; width: 100%;">
      <!-- Home filter section starts -->
      <div class="home-filter-banner-background-width">
        <div class="container px-md-0 pt-3 home-filer-tab home-filter-banner-s d-flex justify-content-between">
          <div>
            <ul class="nav nav-pills flex-nowrap home-filer-section" role="tablist">
              <li class="nav-item">
               <Link :class="{
                  'nav-link': true,
                  'active': !combinedFilters.categoryItem ? combinedFilters.popUpFilter === '' : combinedFilters.categoryItem === ''
                }" data-bs-toggle="pill" href="#all" @click="vistCatergoryURL('', '')">
                All
                </Link>
              </li>
              <li class="nav-item">
                <Link :class="{
                  'nav-link': true,
                  'active': !combinedFilters.categoryItem ? combinedFilters.popUpFilter === 'Gold' : combinedFilters.categoryItem === 'gold'
                }" data-bs-toggle="pill" href="#" @click="vistCatergoryURL('commodities', 'gold')">
                Gold
                </Link>

              </li>
              <li class="nav-item">
                <Link :class="{
                  'nav-link': true,
                  'active': !combinedFilters.categoryItem ? combinedFilters.popUpFilter === 'Silver' : combinedFilters.categoryItem === 'silver'
                }" data-bs-toggle="pill" href="#" @click="vistCatergoryURL('commodities', 'silver')">
                Silver
                </Link>
              </li>
              <li class="nav-item">
                <Link :class="{
                  'nav-link': true,
                  'active': !combinedFilters.categoryItem ? combinedFilters.popUpFilter === 'Copper' : combinedFilters.categoryItem === 'copper'
                }" data-bs-toggle="pill" href="#" @click="vistCatergoryURL('commodities', 'copper')">
                Copper
                </Link>
              </li>
              <li class="nav-item">
                <Link :class="{
                  'nav-link': true,
                  'active': !combinedFilters.categoryItem ? combinedFilters.popUpFilter === 'United States of America' : combinedFilters.categoryItem === 'united-states-of-america'
                }" data-bs-toggle="pill" href="#" @click="vistCatergoryURL('countries', 'united-states-of-america')">
                USA
                </Link>
              </li>
              <li class="nav-item">
                <Link :class="{
                  'nav-link': true,
                  'active': !combinedFilters.categoryItem ? combinedFilters.popUpFilter === 'Canada' : combinedFilters.categoryItem === 'canada'
                }" data-bs-toggle="pill" href="#" @click="vistCatergoryURL('countries', 'canada')">
                Canada
                </Link>
              </li>
              <li class="nav-item">
                <Link :class="{
                  'nav-link': true,
                  'active': !combinedFilters.categoryItem ? combinedFilters.popUpFilter === 'Mexico' : combinedFilters.categoryItem === 'mexico'
                }" data-bs-toggle="pill" href="#" @click="vistCatergoryURL('countries', 'mexico')">
                Mexico
                </Link>
              </li>
              <li class="nav-item">
                <Link :class="{
                  'nav-link': true,
                  'active': !combinedFilters.categoryItem ? combinedFilters.popUpFilter === 'Investment' : combinedFilters.categoryItem === 'investment'
                }" data-bs-toggle="pill" href="#" @click="vistCatergoryURL('deal-type', 'investment')">
                Investments
                </Link>
              </li>
              <li class="nav-item">
                <Link :class="{
                  'nav-link': true,
                  'active': !combinedFilters.categoryItem ? combinedFilters.popUpFilter === 'Exploration' : combinedFilters.categoryItem === 'exploration'
                }" data-bs-toggle="pill" href="#" @click="vistCatergoryURL('project-stages', 'exploration')">
                Exploration
                </Link>
              </li>
              <li class="nav-item">
                <Link :class="{
                  'nav-link': true,
                  'active': !combinedFilters.categoryItem ? combinedFilters.popUpFilter === 'Joint-Venture' : combinedFilters.categoryItem === 'joint-venture'
                }" data-bs-toggle="pill" href="#" style="white-space: nowrap;"
                  @click="vistCatergoryURL('deal-type', 'joint-venture')">
                Joint Venture
                </Link>
              </li>
            </ul>
          </div>
          <div class="grid-map-toggle">
            <ul class="nav nav-pills flex-nowrap" role="tablist">
              <li class="nav-item">
                <button @click.prevent="toggleMapView()" :disabled="!isMapView" href="/" class="nav-link grid"
                  :class="{ 'active': !isMapView }" :preserve-scroll="true">
                  <i class="fa fa-th me-2" aria-hidden="true"></i>Grid
                </button>
              </li>
              <li class="nav-item">
                <button @click.prevent="toggleMapView()" :disabled="isMapView" class="nav-link map"
                  :preserve-scroll="true" :class="{ 'active': isMapView }">
                  <i class="fa fa-map me-2" aria-hidden="true"></i>Map
                </button>
              </li>
              <li>
                <button type="button" class="btn filter-section"
                  @click="projectFilterState.setIsOpen(!projectFilterState.isOpen)"><i class="fa fa-sliders me-2"
                    aria-hidden="true"></i>Filters</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- Home filter section ends -->

      <!-- Tab panes -->
      <div class="tab-content pt-0 home-page-map-content">

        <!-- Map View starts -->
        <div v-if="isMapView" id="map" class="container-fluid tab-pane active p-0 mb-5"
          :class="{ 'tw-animate-pulse': isLoading }">
          <br />
          <div class="row gx-4 gy-3">
            <Gmap :heat_map_data="heat_map_data" :listings="listingsMapViewLocal" :baseImageUrl="baseImageUrl"
              :map_view_current_page="'HomePage'" :count="listingsMapViewLocal.length" :location="location" />
          </div>
        </div>
        <!-- Map View ends -->

        <!-- Grid View All starts -->
        <div v-else id="all" class="container tab-pane active p-0" :class="{ 'tw-animate-pulse': isLoading }"><br>
          <div class="row gx-4 gy-3 pb-5 mb-5">
            <div class="col-xl-2 col-lg-3 col-md-4 col-sm-12 listing-card" v-for="item in listingsShuffledLocal">
              <ListingCard :item="item" :baseImageUrl="baseImageUrl" />
            </div>
            <div v-if="listingsShuffledLocal?.length === 0"
              class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-text-gray-400 tw-text-xl tw-mt-5 tw-h-28">
              <div>No results found. Please remove any filters applied.</div>
              <Link href="/">
              <div class="tw-text-sm tw-underline tw-cursor-pointer tw-text-gray-400">Click here to remove the filters
              </div>
              </Link>
            </div>
          </div>
        </div>
        <div ref="gridMark"></div>
        <!-- Grid View All ends -->
      </div>
    </section>

  </div>
  <!-- Filter Modal -->
  <form @submit.prevent="applyFilter" class="home-page-filter">

    <BsModal :show="showFilter">
      <template #modalHeader>
        <h5 class="modal-title" id="exampleModalLabel">Filters</h5>
        <button type="button" class="btn-close" aria-label="Close" @click="projectFilterState.setIsOpen(false)"></button>
      </template>
      <template #modalBody>
        <div class="row g-2">
          <div class="col-md" :class="{ 'disabled-div-home-list-view-filters': is_country_slug }">
            <div class="" :class="{ 'home-list-view-filter-pop': is_commodity_slug }">
              <label class="form-label">All Countries</label>
              <VueMultiselectCustom placeholder="Select" :options="all_countries" :modelValue="filterSelectedCountries"
                :multiple="true" @update:modelValue="filterSelectedCountries = $event" class="notranslate">
              </VueMultiselectCustom>
            </div>
          </div>
          <div class="col-md" :class="{ 'disabled-div-home-list-view-filters': is_commodity_slug }">
            <div class="" :class="{ 'home-list-view-filter-pop': is_country_slug }">
              <label class="form-label">All Commodities</label>
              <VueMultiselectCustom placeholder="Select" :options="all_commodities"
                :modelValue="filterSelectedCommodities" :multiple="true"
                @update:modelValue="filterSelectedCommodities = $event" class="notranslate">
              </VueMultiselectCustom>
            </div>
          </div>
        </div>

        <h6 class="mt-4 mb-2" :class="{ 'disabled-div-home-list-view-filters': is_deal_type_slug }">Deal Type</h6>
        <div class="row" :class="{ 'disabled-div-home-list-view-filters': is_deal_type_slug }">

          <div class="col-md-6 col-lg-4 ps-2" v-for="aDeal, index in all_deals">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" :id="aDeal.type"
                @change="(e) => dealTypeChecked(aDeal.type, e.target.checked)">
              <label class="form-check-label" :for="aDeal.type">
                {{ aDeal.type }}
              </label>
            </div>
          </div>
        </div>

        <h6 class="mt-4 mb-2" :class="{ 'disabled-div-home-list-view-filters': is_stage_slug }">Project Stage</h6>
        <div class="row" :class="{ 'disabled-div-home-list-view-filters': is_stage_slug }">

          <div class="col-md-6 col-lg-4 ps-2" v-for="aStage in all_stages">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" :id="aStage"
                @change="(e) => stageChecked(aStage, e.target.checked)">
              <label class="form-check-label" :for="aStage">
                {{ aStage }}
              </label>
            </div>
          </div>
        </div>

        <div class="row g-2 mt-3 home-filter-modal">
          <div class="col-md">
            <div class="">
              <label class="form-label">Project or Company Name</label>
              <input type="text" class="form-control" placeholder="" v-model="filterComProName">
            </div>
          </div>
          <div class="col-md">
            <div class="">
              <label class="form-label">Keyword</label>
              <input type="text" class="form-control" placeholder="" v-model="filterKeyWord">
            </div>
          </div>
        </div>

      </template>

      <template #modalFooter>
        <button type="button" class="btn btn-light clear-btn" @click="clearFilters">Clear</button>
        <button class="btn apply-btn custom-primary-btn" type="submit"
          @click="projectFilterState.setIsOpen(false)">Apply</button>
      </template>
    </BsModal>

  </form>
</template>