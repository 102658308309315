import { useMutation, useQueryClient } from "vue-query";
import axios from "axios";
import { ref, toRefs, computed, reactive } from "@vue/reactivity";
import _ from 'lodash'

const action = async (conversationId, message) => {
  console.log('here it is...')
  await axios.post(
    `/api/v1/conversations/${conversationId}/messages`,
    {
      conversation_id: conversationId,
      message
    }
  );
};
// ["conversation-messages", conversationId]
export default function useSendMessage() {
  const cache = useQueryClient();
  
  const mutation = useMutation(
    ({ conversationId, message }) => action(conversationId, message),
    {
      onMutate: async variables => {
        await cache.cancelQueries(["conversation-messages", variables.conversationId])
        
        const previousValue = cache.getQueryData(["conversation-messages", variables.conversationId])
        const newMessage = {
          is_new_message: true,
          is_owner: true,
          message: variables.message,
          sentDate: new Date().toLocaleDateString('en-gb'),
          sentTime: Intl.DateTimeFormat('en', { hour: "numeric", minute: "numeric", hour12: true }).format(new Date()),
          created_at: new Date().toISOString(),
          '_id': {
            '$oid': crypto.randomUUID()
          }
        }

        cache.setQueryData(["conversation-messages", variables.conversationId], data => {
          let pages = _.cloneDeep(data.pages)
          pages[0].data.push(newMessage)

          return {
            ...data,
            pages: pages
          }
        })

        return {previousValue, newMessage }
      },
      onSuccess: (data, variables, context) => {
        // cache.invalidateQueries([
        //   "conversation-messages",
        //   variables.conversationId
        // ]);

        cache.invalidateQueries('conversations')
      },
      onError: (err, newMessageItem, context) => {
        cache.setQueryData(["conversation-messages", newMessageItem.conversationId], data => {
          let pages = _.cloneDeep(data.pages)
          let failedItem = pages[0].data.find(x => x['_id']['$oid'] === context.newMessage['_id']['$oid'])
          failedItem.error = true

          return {
            ...data,
            pages: pages
          }
        })

      },
      onSettled: (data, error, variables, context) => {},
    }
  );

  return reactive({
    sendMessage: mutation.mutateAsync,
    sendMessageStatus: mutation,
  });
}
