import { useMutation, useQueryClient } from "vue-query";
import axios from "axios";
import { ref, toRefs, computed, reactive } from "@vue/reactivity";
import _ from 'lodash'

const action = async (data) => {
  
  const payload = {
        email: data.email,
        first_name: data.name,
  }

  const res = await axios.put(`/api/v1/marketing/contacts`, 
    payload,
    {
      headers: {
        'X-CSRF-Token': data.csrf_token
      }
    }
  );

  // const res = await axios.put(`/api/v1/marketing/contacts`, 
  //   payload,
  //   {
  //     headers: {
  //       'X-CSRF-Token': document.querySelector('[name=authenticity_token]').value
  //     }
  //   }
  // );

  return res
};

export default function useSubscribeNewsletter() {
  // const cache = useQueryClient();
  
  const mutation = useMutation(
    (data) => action(data),
    {
      
      onSuccess: (data, variables, context) => {
        // cache.invalidateQueries('conversations')
      },
      onError: (err, newMessageItem, context) => {
        console.error('Error while subscribing: ', err)

      },
      onSettled: (data, error, variables, context) => {},
    }
  );

  return reactive({
    subscribeNewsletter: mutation.mutateAsync,
    subscribeNewsletterStatus: mutation,
  });
}
