<script setup>
import { computed } from 'vue'
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

const props = defineProps({
    item: {
        type: Object,
        default: {}
    },
    baseImageUrl: {
        type: String,
    },

})

const dealTypeValue = computed(() => {
    const fieldName = `deal_${props.item.deal_type.replace('-', '_').toLowerCase()}_value`
    let index = props.item[fieldName] && props.item[fieldName].length
    let value = ''
    if (index != 0 && index != undefined) {
        if (fieldName === 'deal_sale_value', fieldName === 'deal_investment_value', fieldName === 'deal_lease_value') {
            value = props.item[fieldName][0].expectedValue
        }
        else {
            value = props.item[fieldName][index - 1].pecentageOfProjectPerStage
        }
    }
    return value
})

const imageUrl = computed(() => {
    let url = []
    if (props.item.images_s3) {
        // url = `${props.baseImageUrl}/${props.item.images_s3[0]}`
        for (let i = 0, len = props.item.images_s3.length; i < len; i++) {
            url.push(`${props.baseImageUrl}/${props.item.images_s3[i]}`)
        }
    }
    else {
        if (props.item.image_one != "") {
            url.push(props.item.image_one)
        }
        if (props.item.image_two != "") {
            url.push(props.item.image_two)
        }
        if (props.item.image_three != "") {
            url.push(props.item.image_three)
        }
        if (props.item.image_four != "") {
            url.push(props.item.image_four)
        }
        if (props.item.image_five != "") {
            url.push(props.item.image_five)
        }

    }
    return url
})
const modules = [Pagination, Navigation]


const urlSlugCreate = (data) => {
 // replace all special characters | symbols with a space
 let str = data.replace(/[`~!@#$%^&*()_\-+=\[\]{};:'"\\|\/,.<>?\s]/g, ' ').toLowerCase();

 // trim spaces at the start and end of the string
 str = str.replace(/^\s+|\s+$/gm, '');

 // replace space with dash/hyphen
 str = str.replace(/\s+/g, '-');

 return str;
};

const dealTypeSlug = props.item.deal_type? props.item.deal_type == "Unknown" ?"other":urlSlugCreate(props.item.deal_type):"none";
const commoditiSlug = props.item.primary_commodity? urlSlugCreate(props.item.primary_commodity): "none"
const countrySlug = props.item.country? urlSlugCreate(props.item.country): "none";
</script>

<template>
    <div class="card listingcardcard">
        <div class="card-body px-md-0 pt-0">
            <swiper class="swiper" slides-per-view="auto" :pagination="{ clickable: true  , dynamicBullets: true}" :modules="modules" :style="{
                    '--swiper-navigation-color': '#fff',
                    '--swiper-pagination-color': '#3882ff'
                }" :navigation="true">
                <template v-for="(image, index) in imageUrl" :key="index">
                    <swiper-slide>
                        <div class="card-img tw-overflow-hidden relative">
                            <img loading="lazy" :src="image" alt="Property image" class="tw-w-full tw-h-full tw-object-cover">
                            <div class="d-flex justify-content-end verified-new-icons absolute top-0 end-0 p-2 icons-verified-new">
                                <div class="click-to-top-new">
                                    <img loading="lazy" v-if="props.item.is_new_property" src="../../assets/img/new.png"
                                        alt="New Property">
                                    <span class="tooltip-text" v-if="props.item.is_new_property">New</span>
                                </div>
                                <div loading="lazy" class="click-to-top-verified">
                                    <img v-if="props.item.is_verified" class="ms-2" src="../../assets/img/verified.png"
                                        alt="Verified Property">
                                    <span class="tooltip-text" v-if="props.item.is_verified">Verified</span>
                                </div>
                            </div>
                        </div>
                    </swiper-slide>
                </template>
            </swiper>
            <div class="listingcard card-stats d-flex justify-content-around">
                <div class="card-stat views"><i class="fa fa-eye" style="color:#1D49A7"></i>&nbsp;{{ item.total_views }} View{{ item.total_views==1?"":"s" }}</div>
                <div v-if="false" class="card-stat rating"><i class="fa fa-star" style="color:#FCEB4F;"></i>&nbsp;4.5</div>
                <button v-if="false" class="card-stat save"><i class="fa fa-bookmark-o"></i>&nbsp;Save</button>
            </div>
            <a :href="'/' + dealTypeSlug + '/' + commoditiSlug + '/' + countrySlug + '/' + props.item.company_name_and_project_name_slug"
                style="text-decoration:none">

                <h6 class="">{{ props.item.name }}</h6>
                <h5 class="">{{ props.item.company_name }}</h5>
                <h5 class="mb-2">{{ props.item.country }}</h5>
                <div class="d-flex justify-content-between">
                    <p class="">{{ props.item.deal_type }}</p>
                    <p>{{ dealTypeValue }}</p>
                </div>
                <div class="d-flex justify-content-between">
                    <p class="">{{ props.item.stage }}</p>
                    <p class="">{{ props.item.claim_size }} &nbsp;{{ props.item.claim_unit_size }}</p>
                </div>
                <p class="commodities">{{ props.item.primary_commodity }} <span
                        v-if="props.item.secondary_commodity">|</span>
                    {{ props.item.secondary_commodity }}</p>
            </a>
        </div>
    </div>
</template>

<style>
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
    font-size: 0.5rem !important;

}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
    font-size: 0.5rem !important;

}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
    border-radius: 50%;
    background-color: #f1f1f1;
    color: black;
    height: 26px;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
    border-radius: 50%;
    background-color: #f1f1f1;
    color: black;
    height: 26px;
}

.swiper-wrapper {
    height: 58%;
}


@media (max-width: 429px) {
    .swiper-wrapper {
        height: 68%;
    }
}

@media (max-width: 1409px) {
    .swiper-wrapper {
        height: 55%;
    }
}

@media (max-width:1950px) and (min-width:1367px) {
    .listingcardcard .card-body {
        height: 20rem;
    }
}

@media (max-width:1200px) and (min-width:766px) {
    .listingcardcard .card-body {
        height: 17rem;
    }
}

@supports (-moz-appearance:none) {
    @media (max-width: 429px) {
        .listingcardcard .card-body {
            height: 22rem;
        }
    }
    
}
</style>