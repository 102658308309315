<script setup>

</script>

<template>
    <div class="container my-listings-pg mt-5">
        <div class="d-flex justify-content-between align-items-center title-section">
            <div class="d-flex flex-column">
                <h5>My Listings</h5>
                <p>You are subscribe with the <b>trial</b> plan.</p>
            </div>
            <div class="d-flex">
                <button class="btn custom-primary-btn py-1 px-4">Create Listing</button>
            </div>
        </div>
        <div class="d-flex sub-title-section mt-5">
            <h6 class="me-5">My Projects</h6>
            <p>1/3000 active listings</p>
        </div>
        <div class="horizontal-seperator"></div>
        <div class="my-lisitngs-section pb-5">
            <div class="d-flex flex-column justify-content-between align-items-start flex-md-row align-items-md-center">
                <h6>Donald D. Brown Mine - <span>Fry McVecan Claims</span></h6>
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked>
                </div>
            </div>
            <div class="d-flex align-items-start mt-2 flex-column flex-lg-row align-items-lg-center">
                <p class="me-lg-2">Privately Owned mine is a Canadian-listed junior gold exploration company focused on
                    exploring and
                    developing
                    projects in Mexico. The Company recently negotiated tenure and gained surface access to 6400ha of
                    contiguous
                    mineral concessions r the option…</p>
                <div class="d-flex flex-wrap flex-lg-nowrap mt-1 mt-lg-0">
                    <Button class="btn custom-primary-btn-outline me-2 mt-1 mt-lg-0">Remove</Button>
                    <Button class="btn custom-primary-btn-outline me-2 mt-1 mt-lg-0">Edit</Button>
                    <Button class="btn custom-primary-btn-outline me-2 mt-1 mt-lg-0">Duplicate</Button>
                    <Button class="btn custom-primary-btn-outline mt-1 mt-lg-0">Preview</Button>
                </div>
            </div>
            <div class="horizontal-seperator"></div>
            <div class="d-flex flex-column justify-content-between align-items-start flex-md-row align-items-md-center">
                <h6>Donald D. Brown Mine - <span>Fry McVecan Claims</span></h6>
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked>
                </div>
            </div>
            <div class="d-flex align-items-start mt-2 flex-column flex-lg-row align-items-lg-center">
                <p class="me-lg-2">Privately Owned mine is a Canadian-listed junior gold exploration company focused on
                    exploring and
                    developing
                    projects in Mexico. The Company recently negotiated tenure and gained surface access to 6400ha of
                    contiguous
                    mineral concessions r the option…</p>
                <div class="d-flex flex-wrap flex-lg-nowrap mt-1 mt-lg-0">
                    <Button class="btn custom-primary-btn-outline me-2 mt-1 mt-lg-0">Remove</Button>
                    <Button class="btn custom-primary-btn-outline me-2 mt-1 mt-lg-0">Edit</Button>
                    <Button class="btn custom-primary-btn-outline me-2 mt-1 mt-lg-0">Duplicate</Button>
                    <Button class="btn custom-primary-btn-outline mt-1 mt-lg-0">Preview</Button>
                </div>
            </div>
            <div class="horizontal-seperator"></div>
            <div class="d-flex flex-column justify-content-between align-items-start flex-md-row align-items-md-center">
                <h6>Donald D. Brown Mine - <span>Fry McVecan Claims</span></h6>
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked>
                </div>
            </div>
            <div class="d-flex align-items-start mt-2 flex-column flex-lg-row align-items-lg-center">
                <p class="me-lg-2">Privately Owned mine is a Canadian-listed junior gold exploration company focused on
                    exploring and
                    developing
                    projects in Mexico. The Company recently negotiated tenure and gained surface access to 6400ha of
                    contiguous
                    mineral concessions r the option…</p>
                <div class="d-flex flex-wrap flex-lg-nowrap mt-1 mt-lg-0">
                    <Button class="btn custom-primary-btn-outline me-2 mt-1 mt-lg-0">Remove</Button>
                    <Button class="btn custom-primary-btn-outline me-2 mt-1 mt-lg-0">Edit</Button>
                    <Button class="btn custom-primary-btn-outline me-2 mt-1 mt-lg-0">Duplicate</Button>
                    <Button class="btn custom-primary-btn-outline mt-1 mt-lg-0">Preview</Button>
                </div>
            </div>
            <div class="horizontal-seperator"></div>
            <div class="d-flex flex-column justify-content-between align-items-start flex-md-row align-items-md-center">
                <h6>Donald D. Brown Mine - <span>Fry McVecan Claims</span></h6>
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked>
                </div>
            </div>
            <div class="d-flex align-items-start mt-2 flex-column flex-lg-row align-items-lg-center">
                <p class="me-lg-2">Privately Owned mine is a Canadian-listed junior gold exploration company focused on
                    exploring and
                    developing
                    projects in Mexico. The Company recently negotiated tenure and gained surface access to 6400ha of
                    contiguous
                    mineral concessions r the option…</p>
                <div class="d-flex flex-wrap flex-lg-nowrap mt-1 mt-lg-0">
                    <Button class="btn custom-primary-btn-outline me-2 mt-1 mt-lg-0">Remove</Button>
                    <Button class="btn custom-primary-btn-outline me-2 mt-1 mt-lg-0">Edit</Button>
                    <Button class="btn custom-primary-btn-outline me-2 mt-1 mt-lg-0">Duplicate</Button>
                    <Button class="btn custom-primary-btn-outline mt-1 mt-lg-0">Preview</Button>
                </div>
            </div>
            <div class="horizontal-seperator"></div>
            <div class="d-flex flex-column justify-content-between align-items-start flex-md-row align-items-md-center">
                <h6>Donald D. Brown Mine - <span>Fry McVecan Claims</span></h6>
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked>
                </div>
            </div>
            <div class="d-flex align-items-start mt-2 flex-column flex-lg-row align-items-lg-center">
                <p class="me-lg-2">Privately Owned mine is a Canadian-listed junior gold exploration company focused on
                    exploring and
                    developing
                    projects in Mexico. The Company recently negotiated tenure and gained surface access to 6400ha of
                    contiguous
                    mineral concessions r the option…</p>
                <div class="d-flex flex-wrap flex-lg-nowrap mt-1 mt-lg-0">
                    <Button class="btn custom-primary-btn-outline me-2 mt-1 mt-lg-0">Remove</Button>
                    <Button class="btn custom-primary-btn-outline me-2 mt-1 mt-lg-0">Edit</Button>
                    <Button class="btn custom-primary-btn-outline me-2 mt-1 mt-lg-0">Duplicate</Button>
                    <Button class="btn custom-primary-btn-outline mt-1 mt-lg-0">Preview</Button>
                </div>
            </div>
            <div class="horizontal-seperator"></div>
        </div>
    </div>
</template>