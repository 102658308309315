<script setup>
import { computed, onBeforeUnmount, ref, onMounted } from 'vue'
import AwsS3 from '@uppy/aws-s3'
import Url from '@uppy/url'
import Webcam from '@uppy/webcam'
import { Dashboard, DashboardModal} from '@uppy/vue'
import axios from 'axios'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'


import '@uppy/drag-drop/dist/style.css'
import '@uppy/progress-bar/dist/style.css'


import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'

import Uppy from '@uppy/core'

const props = defineProps({
    csrf_token: {
        type: String,
        required: ""
    },
    uploadedImages: {
        type: Array,
        default: [],
    },
    remoteBaseUrl: {
        type: String,
        default: '',
    },
    resourceType: {
        type: String,
        required: true
    },
    config: {
        type: Object,
        default: {}
    }
})

const maxFileSizeMb = 5
const uppy = computed(() => new Uppy({
    autoProceed: false,
    restrictions: {
        allowedFileTypes: ['image/*','jpg', 'jpeg', 'png', 'gif'],
        maxFileSize: maxFileSizeMb * 1024 * 1024,
    },
    ...props.config
}))


uppy.value.use(AwsS3, {
    getUploadParameters (file) {
    // Send a request to our PHP signing endpoint.
    return fetch('/s3-sign', {
      method: 'post',
      // Send and receive JSON.
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        'X-CSRF-Token': props.csrf_token,
      },
      body: JSON.stringify({
        filename: file.name,
        contentType: file.type,
        resourceType: props.resourceType
      }),
    }).then((response) => {
      // Parse the JSON response.
      return response.json()
    }).then((data) => {
      // Return an object in the correct shape.
      return {
        method: data.method,
        url: data.url,
        fields: data.fields,
        // Provide content type header required by S3
        headers: {
          'Content-Type': file.type,
        },
      }
    })
  },
})


onBeforeUnmount(() => {
    uppy.value.close({ reason: 'unmount' })
})


uppy.value.on('upload', (res) => {
    console.log('On upload...', res)
})

uppy.value.on('upload-success', (file, data) => {
    console.log('Success!!!! File', file)
    console.log('Success!!!! Data', data)


})
uppy.value.on('complete', (res, ot) => {
    console.log('completes......111', res)
    console.log('completes......2222', ot)

    const urls =  res.successful.map(x => ({id: crypto.randomUUID(), img: x.response.uploadURL, url: x.response.uploadURL }))
    newlyUploadedFiles.value.push(...urls)

})

uppy.value.on('file-added', (res) => {
    console.log('File added', res)
})

const newlyUploadedFiles = ref([])


uppy.value.upload().then((result) => {
  console.info('zzzzzzzzzzzzzzzzSuccessful uploads:', result.successful)
  console.log('Result is here...', result)

  if (result.failed.length > 0) {
    console.error('zzzzzzErrors:')
    result.failed.forEach((file) => {
      console.error(file.error)
    })
  }
})


const showUploadModal = () => {
    uppy.value.getPlugin('vue:DashboardModal').openModal()
}

const deleteImage = async (file) => {
    console.log('Delete image....', file)


    const params = await fetch('/s3-sign-del', {
      method: 'post',
      // Send and receive JSON.
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        'X-CSRF-Token': props.csrf_token,
      },
      body: JSON.stringify({
        filename: file.img,
        resourceType: props.resourceType
        // contentType: 'image/jpeg',
      }),
    }).then((response) => {
      // Parse the JSON response.
      return response.json()
    }).then((data) => {
      // Return an object in the correct shape.
      return {
        method: data.method,
        url: data.url,
        fields: data.fields,
        // Provide content type header required by S3
        headers: {
        //   'Content-Type': 'image/jpeg',
        },
      }
    })

    console.log('Params....', params)
    const res = await axios({
        url: params.url,
        method: params.method,
        headers: params.headers,
        
    })

    uploadedImagesLocal.value = uploadedImagesLocal.value.filter(x => x.id !== file.id)
    newlyUploadedFiles.value = newlyUploadedFiles.value.filter(x => x.id !== file.id)
    console.log('Response.....', res)
}
console.log('Uploaded images::::', props.uploadedImages)

const uploadedImagesLocal = ref((props.uploadedImages || []).map((data, id) => ({id: crypto.randomUUID(), img: data})))

defineExpose({
  newlyUploadedFiles,
  uploadedImagesLocal,
})

</script>

<template>
    <div>
        <div class="upload-btn-wrapper">
            <slot name="trigger" :showUploadModal="showUploadModal" :newlyUploadedFiles="newlyUploadedFiles" :uploadedImagesLocal="uploadedImagesLocal">
                <button @click="showUploadModal" type="button" class="btn btn-primary w-25--"> 
                    Upload Image
                </button>
            </slot>
        </div>
         
         <div class="image-gallery-container"> 

            <div class="image-preview" v-for="file in uploadedImagesLocal" :key="file.id"> 
                <div class="image-wrapper">
                    <!-- <img :src="`${remoteBaseUrl}/${file.img}`"/> -->
                    <img
                        :src="`${remoteBaseUrl}/${file.img}`"
                        onerror="this.onerror=null; this.src='/img/press-article-icon.png'"
                    >
                </div>
                <button @click="deleteImage(file)" type="button" class="img-close-icon" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="image-preview" v-for="file in newlyUploadedFiles" :key="file.id"> 
                <div class="image-wrapper">
                    <!-- <img :src="file.img"/> -->
                    <img
                        :src="file.img"
                        onerror="this.onerror=null; this.src='/img/press-article-icon.png'"
                    >
                </div>
                <button @click="deleteImage(file)" type="button" class="img-close-icon" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
         </div>   
         <slot :uploadedImagesLocal="uploadedImagesLocal" :newlyUploadedFiles="newlyUploadedFiles">
         </slot>     
        <!-- <input v-for="file in uploadedImagesLocal" :key="file.id" :value="file.img" type="hidden" name="geo_listing[images_s3][]" />

        <input v-for="file in newlyUploadedFiles" :key="file.id" :value="file.img" type="hidden" name="geo_listing[images_s3][]" /> -->
       
        <DashboardModal :uppy="uppy" :plugins="[]" :open="false" :props="{theme: 'light', hideUploadButton: false}" />
    </div>
</template>

<style>
    
   

    @keyframes pulse {
        0%, 100% {
            opacity: 1;
        }
        50% {
            opacity: .5;
        }
    }

    .animate-pulse-new {
         	animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    }
</style>

<style lang="scss" scoped>
    

    .image-gallery-container {
        -moz-transition: .3s;
        -webkit-transition: .3s;
        transition: .3s;
        -moz-border-radius: 0;
        -webkit-border-radius: 0;
        border-radius: 0;
        background-color: transparent;
        border: 2px dashed rgba(0, 0, 0, 0.1);
        min-height: 180px;
        padding: 0;
        margin-bottom: 15px;
    }

    .upload-btn-wrapper {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 15px;
    }

    .image-preview {

        background: rgba(255, 255, 255, 0.8);
        position: relative;
        display: inline-block;
        margin: 17px;
        vertical-align: top;
        border: 1px solid #acacac;
        padding: 6px;

        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        border: none;
        background-color: #f4f4f2;
        margin: 10px;

        .image-wrapper {
            width: 100px;
            height: 100px;
            // position: relative;
            background: #ebebeb;
            // padding: 5px;
            // margin-bottom: 22px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .img-close-icon {
            position: absolute;
            top: -6px;
            right: -5px;
            background: white;
            border-radius: 100%;
            display: flex;
            opacity: 1;
            justify-content: center;
            align-items: center;
            color: gray;
            text-shadow: unset;
            font-weight: 400;
            font-size: 20px;
            border: 1px solid;
            width: 20px;
            height: 20px;
            &:focus {
                outline: none;   
            }
        }
    }
</style>>
