<script setup>
import { ref, computed } from "vue";
import Gmap from '../../components/map/map.vue';
import _ from 'lodash'
import Paginator from "primevue/paginator";
import PdfModal from "./../../components/modals/PdfModal.vue";
import YouTubeVideoGallery from "../../../src/components/YouTubeVideoGallery.vue";
import DocumentThumbnail from "../../components/documentThumbnail/DocumentThumbnail.vue";
import AnnouncementComponent from '../../components/advertisements/AnnouncementComponent.vue'
import Nav from '../../components/layout/Nav.vue'
const props = defineProps({
    request_href: {
        type: String,
    },
    company: {
        type: Object,
    },
    company_videos: {
        type: Array,
        default: [],
    },
    company_documents: {
        type: Array,
        default: [],
    },
    internal_projects: {
        type: Array,
    },
    news_releases: {
        type: Array,
    },
    stockdioKey: {
        type: String
    },
    baseImageUrl: {
        type: String,
    },
    user: {
        type: Object
    },
    all_internal_comapnies_with_slug: {
        type: Array,
        default: []
    },
    baseImageUrl: {
        type: String,
    },
});

const currentUrl = computed(() => {
    return new URL(props.request_href);
})
const is_all_videos_empty = computed(() => {
    if (!props.company || !props.company.videos) {
        return false;
    }
    let truecount = []
    props.company.videos.forEach((value, index) => {
        if (props.company.videos[index] == "") {
            truecount.push(true)
        }
    });

    return truecount.length == props.company.videos.length;
});

const internalCompanyNames = computed(() => {
    if (!props.all_internal_comapnies_with_slug) {
        return [];
    }

    return _.uniq(_.sortBy(props.all_internal_comapnies_with_slug.map(company => company.company_name), (o) => o.toLowerCase()));
});

const graph01_Url = props.company
    ? `https://api.stockdio.com/visualization/financial/charts/v1/HistoricalPrices?app-key=${props.stockdioKey}&stockExchange=${props.company.exchange}&symbol=${props.company.symbol}&palette=Financial-Light&font=Roboto&motif=Material&showCurrency=true&displayPrices=Area`
    : "";

const graph02_Url = props.company
    ? `https://api.stockdio.com/visualization/financial/charts/v1/PricePerformance?app-key=${props.stockdioKey}&stockExchange=${props.company.exchange}&symbol=${props.company.symbol}&palette=Financial-Light&Font=Roboto&template=aaa32567-c2d7-4cd8-bfbb-d4fec015dfd9&onload=priceperformance&amp;showLogo=no`
    : "";

const graph03_Url = props.company
    ? `https://api.stockdio.com/visualization/financial/charts/v1/StockOverview?app-key=${props.stockdioKey}&stockExchange=${props.company.exchange}&symbol=${props.company.symbol}&palette=Financial-Light&onload=st_0b05433b762e4910ab0480adfdbb2f99`
    : "";

const newsReleasesCount = computed(() => props.news_releases ? props.news_releases.length : 0);

const paginatedReleases = ref(props.news_releases ? props.news_releases.slice(0, 5) : []);

const s3BaseUrl = computed(() => props.baseImageUrl);
const page = ref(1);
const recordsPerPage = 5
const offSet = computed(() => {
    if (!props.news_releases) {
        return 0;
    }

    paginatedReleases.value = props.news_releases.slice((page.value - 1) * recordsPerPage, (page.value - 1) * recordsPerPage + recordsPerPage);
    return page.value === 1 ? 0 : page.value * recordsPerPage - 1;
});

const urlString = currentUrl.value.href;

var parts = urlString.split('/');
var lastSegment_slug_name = parts.pop() || parts.pop();

const selected_company_name = computed(() => {
    if (!lastSegment_slug_name || !props.all_internal_comapnies_with_slug) {
        return null;
    }

    const company = props.all_internal_comapnies_with_slug.find(obj => obj.slug === lastSegment_slug_name);

    return company ? company.company_name : null;
});

</script>

<template>
    <template v-if="company">
        <div class="container mt-4 px-md-0">

            <div class="container  tw-left-0 tw-right-0 tw-z-[1] tw-pointer-events-none sm:tw-flex"
                style="max-width: 1500px;margin-bottom: 15px;">
                <div id="announcement-dt"
                    class="tw-pointer-events-auto tw-w-[1358px] tw-mr-4  tw-mt-2 tw-mb-1 tw-hidden sm:tw-block">
                    <AnnouncementComponent type="desktop" :view="'CompanyProfile'" :user="user"
                        :listings_company_name_slug="company.slug" :baseImageUrl="baseImageUrl"
                        :companyName="company.company_name" />
                </div>
            </div>
            <div id="announcement-mobile" class="sm:tw-hidden  tw-left-0 tw-w-full tw-z-10 tw-p-3" style="height:16%;">
                <AnnouncementComponent type="mobile" :view="'CompanyProfile'" :user="user"
                    :listings_company_name_slug="company.slug" :baseImageUrl="baseImageUrl"
                    :companyName="company.company_name" />
            </div>
            <div class="d-flex justify-content-between advance-search-section">
                <div class="d-flex">
                    <h2>{{ company.company_name }}</h2>
                    <div class="company-ticker-symbols">
                        <p>{{ company.exchange }}&nbsp;:&nbsp;{{ company.symbol }}</p>
                    </div>
                </div>
                <div class="advance-search-parent mt-3">
                    <a class="advance-search-button" href="/searchspace-ai"> <i class="fa fa-search-plus pe-2"
                            aria-hidden="true"></i>Advance AI</a>
                </div>
            </div>
            <div class="inner-listing-top-section d-flex justify-content-between align-items-end">
                <div class="project-summary d-flex align-items-start align-items-lg-center flex-column flex-lg-row mt10">

                    <div class="d-flex align-items-center me-4 mt-1 mt-lg-0">
                        <img class="me-2" src="../../assets/img/address.png" alt="Company Address" />
                        <p class="bold">{{ company.street_address }}, {{ company.city }}, {{ company.company_country }}</p>
                    </div>
                    <div class="d-flex align-items-center me-4 mt-1 mt-lg-0">
                        <img class="me-2" src="../../assets/img/phone.png" alt="Company Phone Number" />
                        <p class="bold">{{ company.phone }}</p>
                    </div>
                    <div class="d-flex align-items-center me-4 mt-1 mt-lg-0">
                        <img class="me-2" src="../../assets/img/website.png" alt="company Website" />
                        <a :href="company.website" target="_blank">
                            <p class="project-website bold">Company Website</p>
                        </a>
                    </div>

                </div>
            </div>
            <div class="mt-2 alert alert-warning" role="alert">
                <span>
                    <span class="required-star">*</span> Please note that the availability of stock data depends on the
                    stock
                    market hours, national & other holidays etc.
                </span>
            </div>
        </div>
        <div class="container mt-4 px-md-0">

            <div class="row">
                <div class="col-lg-8 col-sm-12">

                    <!----------------------- Graph View ---------------------->
                    <div>
                        <iframe frameBorder='0' scrolling='no' width='100%' height='420' :src="graph01_Url"></iframe>
                    </div>


                    <!----------------------- Period Line View --------------------->
                    <div>
                        <iframe id="priceperformance" frameborder="0" scrolling="no" width="100%" height="285"
                            style="height: 285px;" :src="graph02_Url"></iframe>
                    </div>


                    <!------------------------------- Table View -------------------------------->
                    <div class="deal-details mobile-view-only">
                        <iframe id='st_0b05433b762e4910ab0480adfdbb2f99' frameBorder='0' scrolling='no' width='100%'
                            height='260' :src='graph03_Url'></iframe>
                    </div>



                    <!------------------------- News Releases ---------------------->
                    <div class="about-project">
                        <h5 class="mt-5 mb-3">News Releases</h5>

                        <div v-if="newsReleasesCount > 0">
                            <div v-for="(item, index) in paginatedReleases">
                                <div class="releas-card" :key="index">
                                    <p class="date">{{
                                        new Intl.DateTimeFormat('fr-CA', {
                                            year: 'numeric', month: 'numeric', day: 'numeric',
                                            hour12: true,
                                            timeZone: 'UTC'
                                        }).format(new Date(item.publish_date))

                                    }}</p>
                                    <h6 class="mb-1">
                                        <!-- Backward compatibility to view PDF Popup, if HTML is not available -->
                                        <PdfModal v-if="item.body==''" :link="`${s3BaseUrl}/${item.link_s3}`">
                                            <h6 class="mb-1">
                                                <a @click.prevent="" href="#" target="_blank">{{ item.title }}</a>
                                            </h6>
                                        </PdfModal>
                                       <a v-if="item.body" :href="`/press-releases/${item.provider}/${item.slug || item._id.$oid}?ref=company&backUrl=${currentUrl}`">{{ item.title }}</a>
                                    </h6>
                                </div>
                            </div>
                        </div>

                        <div v-else class="releas-card">
                            <p class="no-title">{{ "No News Releases." }}</p>
                        </div>

                        <div style="display: flex; margin: 10px 0 0 0 !important; ">
                            <Paginator :rows="recordsPerPage" :totalRecords="newsReleasesCount"
                                @page="(e) => (page = e.page + 1)" :first="offSet"
                                template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink  CurrentPageReport JumpToPageInput">
                            </Paginator>
                        </div>

                    </div>
                </div>

                <div class="col-lg-4 col-sm-12 ps-3 mt-1 mt-lg-0">

                    <!------------------------------- Table View -------------------------------->
                    <div class="deal-details desktop-view-only">
                        <iframe id='st_0b05433b762e4910ab0480adfdbb2f99' frameBorder='0' scrolling='no' width='100%'
                            height='260' :src='graph03_Url'></iframe>
                    </div>

                    <!------------------- About the company ----------------------->
                    <div class="about-project">
                        <h5 class="mt-5 mb-3">About the Company</h5>
                        <p>
                            {{ company.company_description }}
                        </p>
                    </div>

                    <div class="project-videos mt-5">
                        <h5 class="mb-3">Company Videos</h5>
                        <div class="videos row gx-3 gx-xxl-1 gy-3 gy-xxl-2"
                            v-if="company_videos.length > 0 && is_all_videos_empty == false">
                            <YouTubeVideoGallery :links="company_videos"></YouTubeVideoGallery>
                        </div>
                        <div class="videos row gx-3 gx-xxl-1 gy-3 gy-xxl-2" v-else>
                            <div class="col-xxl-4 col-md-6 col-sm-12">
                                <p>No videos added.</p>
                            </div>
                        </div>
                    </div>

                    <div class="project-documents mt-5">
                        <h5 class="mb-3">Company Documents</h5>
                        <div class="d-flex flex-wrap flex-wrap-column-gap">
                            <template v-if="company_documents.length > 0">
                                <div v-for="item, index in company_documents" class="thumbnail-wrapper">
                                    <DocumentThumbnail :key="index" :document="item" :baseImageUrl="baseImageUrl"
                                        :user="user" />
                                </div>
                            </template>
                            <template v-else>
                                <p>No documents added.</p>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container mt-5 mb80 px-md-0 location-mobile-view-bottom">
            <h5 class="mb-3">Project Locations</h5>

            <div class="row">
                <div class="col-lg-12 col-sm-12">
                    <Gmap :companylistings="internal_projects" :map_view_current_page="'Cmpany_Profile'"
                        :baseImageUrl="baseImageUrl" />
                </div>
            </div>
        </div>
    </template>
    <template v-else>
        <div class="d-flex align-items-center justify-content-center company-profile">
            <div class="text-center company-profile-not">
                <img class="info-img-company" src="../../assets/icons/info-svg.svg" alt="Info Image">
                <p class="mt-2">Company profile not found.</p>
                <a href="/" class="btn btn-primary text-white text-center  mt-2">Home</a>
            </div>
        </div>
    </template>
</template>

<style>
.p0 {
    padding: 0px !important;
}

.mt5 {
    margin-top: 5px;
}

.mt10 {
    margin-top: 10px;
}

.mb80 {
    margin-bottom: 80px;
}

.mb5 {
    margin-bottom: 5px;
}

.ml5 {
    margin-left: 5px;
}

.bold {
    font-weight: 500;
}

.round-btn {
    border: 1px solid #bfbfbf;
    padding: 9px;
    text-align: center;
    width: 65px;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.submt {
    color: #fff;
    background-color: #000;
}

.dash {
    margin: 7px 5px;
    font-weight: 700;
}

.date-picker {
    height: 38px;
}

.slider {
    width: 100%;
    height: 500px;
    overflow: hidden;
}

.slider img {
    width: 100%;
    height: 100%;
    display: none;
}

.slider img:first-child {
    display: block;
}

.thumbnail-wrapper {
    cursor: pointer;
    width: 21%;
    margin: 10px 10px 0 0;
}

@media (max-width: 420px) {
    .thumbnail-wrapper {
        width: 18%;
    }
}

.flex-wrap-column-gap {
    column-gap: 54px;
    overflow: hidden;
}

.company-ticker-symbols p {
    color: white !important;
    background-color: #3882ff;
    padding: 3px 5px 0px;
    margin-right: 8px;
    font-size: 11px !important;
    margin: 15 0 0 10 !important;
    font-weight: 500;
}

.cash-position {
    display: flex;
    justify-content: space-between;
    background-color: #000;
    padding: 10px;
}

.cash-position p {
    color: #fff !important;
    ;
}

.cash-position .date {
    color: #fff !important;
    font-weight: 100;
}

.releas-card {
    border-bottom: 1px solid #d9d9d9;
    margin-top: 15px;
}

.releas-card .date {
    color: #bfbfbf !important;
    margin-bottom: 5px !important;
}

.releas-card .title {
    color: #000 !important;
    margin-bottom: 5px !important;
}

.releas-card .no-title {
    color: #000 !important;
    margin-bottom: 5px !important;
    text-align: center;
    font-size: 14px;
}
</style>
