<script setup>
import { ref, computed, watch } from "vue";
import { useForm, router } from '@inertiajs/vue3'
import _ from 'lodash'
import VueMultiselectCustom from '../../components/dropdownSelector/VueMultiselectCustom.vue'
import BsContactModal from '../../components/modals/ConatcUsModal.vue'
import { VueRecaptcha } from 'vue-recaptcha';

const props = defineProps({

  all_commodities: {
    type: Array,
    default: []
  },
  all_countries: {
    type: Array,
    default: []
  },
  csrf_token: {
    type: String,
    required: ""
  },
  captcha_sitekey: {
    type: String
  },
  isTopSearchShow: {
    type: Boolean,
    default: true
  },
  isCompanyFilter: {
    type: Boolean,
    default: false
  },
  all_companies: {
      type: Array,
      default: []
  },
  all_internal_comapnies_with_slug: {
    type: Array,
    default: []
  },
  selectedTopSearchCommoditiesLocal: {
    type: String,
    default: ''
  },
  selectedTopSearchKeywordLocal: {
    type: String,
    default: ''
  },
  selectedTopSearchCountryLocal: {
    type: String,
    default: ''
  },
  selected_company_name: {
    type: String,
    default: null
  },
  country_name_in_slug:{
    type: String,
    required: ""
  },
  stage_name_in_slug:{
    type: String,
    required: ""
  },
  commodity_name_in_slug:{
    type: String,
    required: ""
  },
  deal_type_name_in_slug:{
    type: String,
    required: ""
  },
  is_deal_type_slug: {
    type: Boolean,
    default: false
  },
  is_stage_slug: {
    type: Boolean,
    default: false
  },
  is_commodity_slug: {
    type: Boolean,
    default: false
  },
  is_country_slug: {
    type: Boolean,
    default: false
  }
});

const searchKeyWord = ref(props.selectedTopSearchKeywordLocal)
const selectedCommoditiesLocal = ref(props.selectedTopSearchCommoditiesLocal)
const selectedInternalCompanyLocal = ref(props.selected_company_name)
const selectedCountriesLocal = ref(props.selectedTopSearchCountryLocal)

const clickglobelSearch = () => {
  if (props.isCompanyFilter == true) {
    if (selectedInternalCompanyLocal.value != null) {
        router.visit(`/company/${comapny_slug.value}`, {
        method: 'get',
        replace: true,
        preserveScroll: true,
      }
      )
    }
  }
  else {
    if (searchKeyWord.value != "" || selectedCommoditiesLocal.value != "" || selectedCountriesLocal.value != "") {
      let searshParam = {}
      Object.assign(searshParam, searchKeyWord.value ? { keyword: searchKeyWord.value } : null);
      Object.assign(searshParam, selectedCommoditiesLocal.value ? { commodity: selectedCommoditiesLocal.value } : null);
      Object.assign(searshParam, selectedCountriesLocal.value ? { country: selectedCountriesLocal.value } : null);

      router.visit('/projects', {
        method: 'get',
        data: { ...searshParam },
        replace: true,
        preserveScroll: true
      })
    }
  }
}

const comapny_slug = computed(() => {
  const company = props.all_internal_comapnies_with_slug.find(obj => obj.company_name === selectedInternalCompanyLocal.value);
  return company ? company.slug : null;
});

const showContactusmodal = ref(false);
const showThankyouMsg = ref(false);
const showModalbody = ref(true);

const form = useForm({
  email_contact: null,
  name_contact: null,
  message_contact: null,
  authenticity_token: props.csrf_token,
  reCaptchaToken: null
});

const submit = (e) => {
  form.post('/contact/process_contact_form', {
    preserveState: true, onSuccess: () => {
      showModalbody.value = false;
      showThankyouMsg.value = true;
      form.reset();

    }
  })
}

watch(showThankyouMsg, (val) => {
  if (val == true) {
    setTimeout(() => {

      showThankyouMsg.value = false;
      showContactusmodal.value = false;
    }, 5000);
  } else {
    setTimeout(() => {
      form.reset();
      showContactusmodal.value = false;
    }, 1000);
  }
});

const verifyMethod = (response) => {
  form.reCaptchaToken = response;
}

const expiredMethod = () => {
  form.reCaptchaToken = ""
}

</script>

<template>

  <!-- Project banner starts -->
  <div class="container-fluid banner-img project-banner tw-bg-gray-400 d-flex justify-content-center align-items-center flex-column px-3">
    <template v-if="is_country_slug == true"><h1 class="mb-3">Mining Investment Projects in {{country_name_in_slug}}</h1></template>
    <template v-else-if="is_stage_slug == true"><h1 class="mb-3">{{ stage_name_in_slug }} Stage in Mining Projects</h1></template>
    <template v-else-if="is_commodity_slug == true"><h1 class="mb-3">Explore {{commodity_name_in_slug}} Mining Investment Projects</h1></template>
    <template v-else-if="is_deal_type_slug == true"><h1 class="mb-3">Explore Mining {{deal_type_name_in_slug}} Projects</h1></template>
    <template v-else ><h1 class="mb-3">Find Your Next Mining Investment Projects</h1></template>
    <h3 class="mb-3 mb-sm-4">
      The resource for online mining project search, marketing and research
    </h3>
    <div class="container home-press-outer">
      <div class="d-flex flex-column mt-4 align-items-center">
        <form class="mb-0" @submit.prevent="clickglobelSearch">
          <div class="d-flex align-items-center justify-content-between mb-2 mb-lg-0 mt-2 mt-lg-0 search-bar bg-white">
            <div class="">
              <input type="text" class="form-control" id="floatingInput" placeholder="Search by Keyword"
                v-model="searchKeyWord">
            </div>
            
            <div class="vertical-seperator"></div>

            <div class="form-floating commodity-drop-down">
              <VueMultiselectCustom placeholder="All Commodities" :options="all_commodities"
                :modelValue="selectedCommoditiesLocal" :multiple="false"
                @update:modelValue="selectedCommoditiesLocal = $event" class="notranslate">

              </VueMultiselectCustom>
            </div>

            <div class="vertical-seperator "></div>

            <div class="form-floating country-drop-down"
              :class="{ 'disabled-div-home-list-view-filters': is_country_slug }">
              <VueMultiselectCustom placeholder="All Countries" :options="all_countries"
                :modelValue="selectedCountriesLocal" :multiple="false"
                @update:modelValue="selectedCountriesLocal = $event" class="notranslate">
              </VueMultiselectCustom>
            </div>

            <button type="submit" class="btn search-btn custom-primary-btn"><i class="fa fa-search"
                aria-hidden="true"></i>
            </button>
          </div>

          <p class="mt-2 me-1 add-project float-end">
            Not finding yours? <a href="#" class="help-link" data-bs-target="#contactModal" @click="showContactusmodal = !showContactusmodal">
            Learn how to add it here.</a>
          </p>
        </form>
      </div>
    </div>
  </div>
  <!-- Project banner ends -->

  <!-- Contact Modal -->
  <form :action="`/contact/process_contact_form`" method="POST" @submit.prevent="submit" class="mb-0">
    <BsContactModal :show="showContactusmodal" :modal_name="'ContactUS'">
      <template #modalHeader>
        <h5 class="modal-title" id="exampleModalLabel">Contact Us</h5>
        <button type="button" class="btn-close" aria-label="Close" @click="showContactusmodal = false"></button>
      </template>
      <template #modalBody>
        <div v-show="showModalbody">
          <h6 class="mt-1 mb-3">Send us a message</h6>
          <div class="mb-3">
            <label class="form-label">Name</label>
            <input type="text" class="form-control" v-model="form.name_contact" required>
          </div>
          <div class="mb-3">
            <label class="form-label">Email</label>
            <input type="email" class="form-control" v-model="form.email_contact" required>
          </div>
          <div class="mb-3">
            <label class="form-label">Message</label>
            <textarea class="form-control" v-model="form.message_contact" required></textarea>
          </div>

          <div class="mb-3">
            <vue-recaptcha ref="recaptcha" :sitekey="props.captcha_sitekey" @verify="verifyMethod"
              @expired="expiredMethod"></vue-recaptcha>

            <input type="hidden" class="form-control" v-model="form.reCaptchaToken" required>

            <div class="validation-msg">
              {{ errors?.message }}
            </div>
          </div>

          <button :disabled="form.reCaptchaToken === null" type="submit" class="btn apply-btn custom-primary-btn w-100">{{
            form.processing ? 'Please wait...' :
            'Submit' }} </button>
        </div>
        <div class="Thank-u-msg text-center mt-4" v-show="showThankyouMsg">
          <div class="success-icon"><i class="fa fa-check" aria-hidden="true"></i>
          </div>
          <h6 class="mb-2">Thank you!</h6>
          <p class="">Your message was delivered successfully. <br /> We will get back to you as quick as possible.</p>
        </div>
      </template>
      <template #modalFooter>
        <div class="d-flex align-items-center mb-2">
          <img src="../../assets/img/email.png" alt="Support Email" class="me-2">
          <p>support@searchspaceai.com</p>
        </div>
        <div class="d-flex align-items-center mb-2">
          <img src="../../assets/img/phone.png" alt="Support Phone Number" class="me-2">
          <p>+1 604 283 7679</p>
        </div>
        <div class="d-flex align-items-center mb-2">
          <img src="../../assets/img/address.png" alt="Support Address" class="me-2">
          <p>595 Burrard Street, Suit 1723, Vancouver, BC V7X 1J1.</p>
        </div>
      </template>
    </BsContactModal>
  </form>
  <!-- Contact Modal End-->

</template>

<style scoped lang="scss">
.apply-btn {
  --bs-btn-disabled-bg: #3882ff;
  padding: 5px;
}
</style>
