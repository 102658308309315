<script setup>

</script>

<template>
    <div class="container my-account-pg pb-5 pb-lg-0">
        <div class="d-flex align-items-center flex-column my-account-titles">
            <h5 class="mt-5 mb-0">My Account</h5>
            <h6 class="mt-1 mb-0 text-center">Manage your SearchSpaceGeo subscription</h6>
            <p class="mt-1 mb-0 text-center">Upgrade your trial account to a paid account by selecting a pricing plan</p>
        </div>
        <div class="row gx-5 gy-5 gy-lg-1 mt-4 d-flex justify-content-center align-items-end">
            <div class="col-md-4 pricing-plan">
                <div class="card pricing-plan-cards pt-3">
                    <h5 class="text-center">Basic</h5>
                    <div class="horizontal-seperator"></div>
                    <div class="card-body pt-0">
                        <h1 class="text-center"><sup>$</sup>50<sub>/Mo</sub></h1>
                        <div class="d-flex align-items-center mt-3">
                            <img src="../assets/img/access-true.png">
                            <p class="ms-2">Includes <b>1</b> listing</p>
                        </div>
                        <div class="horizontal-seperator"></div>
                        <div class="d-flex align-items-center">
                            <img src="../assets/img/access-true.png">
                            <p class="ms-2"><b>Create</b> and <b>Edit</b> your listing</p>
                        </div>
                        <div class="horizontal-seperator"></div>
                        <div class="d-flex align-items-center">
                            <img src="../assets/img/access-true.png">
                            <p class="ms-2"><b>Featured</b> listing</p>
                        </div>
                        <div class="horizontal-seperator"></div>
                        <div class="d-flex align-items-center">
                            <img src="../assets/img/access-false.png">
                            <p class="ms-2"><b>Viewer analytics</b></p>
                        </div>
                        <div class="horizontal-seperator"></div>
                        <div class="d-flex justify-content-center">
                            <button class="btn custom-primary-btn w-100">Select</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 pricing-plan">
                <div class="popular-plan">
                    <h5 class="text-center">POPULAR PLAN</h5>
                </div>
                <div class="card pricing-plan-cards popular-plan-section pt-3">
                    <h5 class="text-center">Operator</h5>
                    <div class="horizontal-seperator"></div>
                    <div class="card-body pt-0">
                        <h1 class="text-center"><sup>$</sup>100<sub>/Mo</sub></h1>
                        <div class="d-flex align-items-center mt-3">
                            <img src="../assets/img/access-true.png">
                            <p class="ms-2">Includes <b>3</b> listing</p>
                        </div>
                        <div class="horizontal-seperator"></div>
                        <div class="d-flex align-items-center">
                            <img src="../assets/img/access-true.png">
                            <p class="ms-2"><b>Create</b> and <b>Edit</b> your listing</p>
                        </div>
                        <div class="horizontal-seperator"></div>
                        <div class="d-flex align-items-center">
                            <img src="../assets/img/access-true.png">
                            <p class="ms-2"><b>Featured</b> listing</p>
                        </div>
                        <div class="horizontal-seperator"></div>
                        <div class="d-flex align-items-center">
                            <img src="../assets/img/access-false.png">
                            <p class="ms-2"><b>Viewer analytics</b></p>
                        </div>
                        <div class="horizontal-seperator"></div>
                        <div class="d-flex justify-content-center">
                            <button class="btn custom-primary-btn w-100">Select</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 pricing-plan">
                <div class="card pricing-plan-cards pt-3">
                    <h5 class="text-center">Company</h5>
                    <div class="horizontal-seperator"></div>
                    <div class="card-body pt-0">
                        <h1 class="text-center"><sup>$</sup>500<sub>/Mo</sub></h1>
                        <div class="d-flex align-items-center mt-3">
                            <img src="../assets/img/access-true.png">
                            <p class="ms-2">Includes <b>15</b> listing</p>
                        </div>
                        <div class="horizontal-seperator"></div>
                        <div class="d-flex align-items-center">
                            <img src="../assets/img/access-true.png">
                            <p class="ms-2"><b>Create</b> and <b>Edit</b> your listing</p>
                        </div>
                        <div class="horizontal-seperator"></div>
                        <div class="d-flex align-items-center">
                            <img src="../assets/img/access-true.png">
                            <p class="ms-2"><b>Featured</b> listing</p>
                        </div>
                        <div class="horizontal-seperator"></div>
                        <div class="d-flex align-items-center">
                            <img src="../assets/img/access-true.png">
                            <p class="ms-2"><b>Viewer analytics</b></p>
                        </div>
                        <div class="horizontal-seperator"></div>
                        <div class="d-flex justify-content-center">
                            <button class="btn custom-primary-btn w-100">Select</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>