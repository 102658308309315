<template>
  <h1 :style="{ 'text-align': 'center' }">Hellox {{name}}!</h1>
</template>

<script>
export default {
  props: {
    name: { type: String, required: true },
  },
  mounted() {
    console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx')
  }
}
</script>
