<script setup>
import { ref, computed } from 'vue'
import { router, useForm } from "@inertiajs/vue3";
import _ from 'lodash'
import VueMultiselectCustom from "../../components/dropdownSelector/VueMultiselectCustom.vue";
import useCreatePressReleasePreferenceAndRegistration from '../../components/hooks/useCreatePressReleaseSubscriptionAndRegistrationForSendGrid'
import { useToast } from "primevue/usetoast";
import Toast from 'primevue/toast';
import Nav from '../../components/layout/Nav.vue'

const props = defineProps({
    csrf_token: {
        type: String,
        required: "",
    },
    all_commodities: {
        type: Array,
    },
    all_countries: {
        type: Array,
    },
    user: {
        type: Object,
        default: null,
    },
    internal_external_companies: {
        type: Array,
        default: []
    },
    selected_companies: {
        type: Array,
        default: []
    },
    selected_commodities: {
        type: Array,
        default: []
    },
    company_selected_countries: {
        type: Array,
        default: []
    },

})

const toast = useToast();
const base_url = window.location.origin
// let url = 'login'
const selectedcompany = ref(props.selected_companies)
const selectedCommoditiesLocal = ref(props.selected_commodities)
const selectedCompanyCountriesLocal = ref(props.company_selected_countries)
const UserNameLocal = ref('')
const UserEmail = ref('')
const UserPassword = ref('')
const internal_external_companies_options = _.uniq(props.internal_external_companies.map(company => company.company_name))

const { createPressReleaseSubscriptionAndRegistration, createPressReleaseSubscriptionAndRegistrationStatus } = useCreatePressReleasePreferenceAndRegistration()

const submitForm = async () => {

    await createPressReleaseSubscriptionAndRegistration({
        company_name: selectedcompany.value,
        commodity: selectedCommoditiesLocal.value,
        country: selectedCompanyCountriesLocal.value,
        subscription_owner_email: UserEmail.value,
        user_name: UserNameLocal.value,
        password: UserPassword.value
    }).then(res => {
        toast.add({ severity: 'success', detail: 'Account and press release preference created successfully.', life: 3000 });
        window.localStorage.setItem('ssg-prs-sub-is-set', 'true')
        document.location = base_url

    }).catch(err => {
        window.scrollTo(0, 0)
        toast.add({ severity: 'error', summary: 'Error Message', detail: 'This email already exists. Please use a different email address to register.', life: 3000 });

    })


}
</script>

<template>
    <form v-cloak method="POST" @submit.prevent="submitForm">
        <div class="container subscription-pages">
            <h4 class="mt-3 mt-lg-5 mb-3">Subscription to News Releases</h4>
            <template v-if="createPressReleaseSubscriptionAndRegistrationStatus.status === 'error'">
                <div class="tw-mb-2">
                    <p class="error-link text-danger tw-mb-2 tw-font-bold">Could not save the news releases preferences and
                        account details.</p>

                    <p v-for="(e, index) in createPressReleaseSubscriptionAndRegistrationStatus.error.response.data.errors"
                        :key="index" class="error-link text-danger tw-mb-0">
                        {{ index }}: {{ e[0] }}
                    </p>
                </div>
            </template>
            <!------------------------------ Create your Account --------------------------------------------->
            <div class="row g-3 subscription-form">
                <h5 class="mt-5 mb-0">Create your Account</h5>
                <p class="mt-1 mb-0 ps-3 ms-1">Enter your account details.</p>
                <div class="col-md-6">
                    <label class="form-label">Name</label>
                    <input type="text" class="form-control" v-model="UserNameLocal" required>
                </div>
                <div class="col-md-6">
                    <label class="form-label">Email</label>
                    <input type="text" class="form-control" v-model="UserEmail" required>
                </div>
                <div class="col-md-6">
                    <label class="form-label">Password</label>
                    <input type="password" class="form-control" v-model="UserPassword" required>
                </div>
                <div class="col-12">
                    <div class="form-check d-flex align-items-center terms-n-conditions">
                        <input class="form-check-input mt-0" type="checkbox" required>
                        <label class="form-check-label">
                            Accept <a href="/terms_and_conditions">Terms and Conditions</a>
                        </label>
                    </div>
                </div>
            </div>

            <!------------------------------------ News Release Preferences  ---------------------------------------->
            <div class="row g-3 subscription-form mt-2">
                <h5 class="mt-5 mb-0">News Release Preferences</h5>
                <p class="mt-1 mb-0 ps-3 ms-1">Please select one or more options from the following to receive daily press
                    releases based on your preference.</p>
                <div class="col-md-12">
                    <label class="form-label">Companies</label>
                    <VueMultiselectCustom placeholder="Select" :options="internal_external_companies_options"
                        :modelValue="selectedcompany" :multiple="true" :max="10"
                        @update:modelValue="selectedcompany = $event" class="notranslate">
                    </VueMultiselectCustom>
                </div>
                <div class="col-md-12">
                    <label class="form-label">Project commodities associated with companies</label>
                    <VueMultiselectCustom placeholder="Select" :options="all_commodities"
                        :modelValue="selectedCommoditiesLocal" :multiple="true" :max="5"
                        @update:modelValue="selectedCommoditiesLocal = $event" class="notranslate">
                    </VueMultiselectCustom>
                </div>
                <div class="col-md-12">
                    <label class="form-label">Countries</label>
                    <VueMultiselectCustom placeholder="Select" :options="all_countries"
                        :modelValue="selectedCompanyCountriesLocal" :multiple="true" :max="5"
                        @update:modelValue="selectedCompanyCountriesLocal = $event" class="notranslate">
                    </VueMultiselectCustom>
                </div>
            </div>

            <div class="row g-3 listing-form mt-4 pb-5 d-flex justify-content-end">
                <div class="col-lg-3">
                    <button type="submit" class="btn custom-primary-btn w-100">Register and Submit Preferences</button>
                </div>
            </div>
        </div>
    </form>
</template>