<script setup>

import { ref, onMounted, computed } from 'vue'

const props = defineProps({
    type: {
        type: String, //mobile, desktop
    },
    view: {
        type: String,
    },
    img: {
        type: String,
        default: ""
    },
    user: {
        type: Object
    },
    is_banner_closed: {
        type: Boolean,
        default: false
    },
    listings_company_name_slug: {
        type: String,
        default: ""
    },
    baseImageUrl: {
        type: String,
    },
    companyName: {
        type: String,
        default: ""
    }

})

const pageLoaded = ref(false)
const showDt = ref(true)
const showMobile = ref(true)
let desktopImage = null
let mobileImage = null
let defultDesktopImage = null
let defultMobileImage = null
let callToAction = null



if (props.view == "AIpage") {
    desktopImage = "/img/HelioStarWeb.png"
    mobileImage = "/img/HelioStarMob.png"
    callToAction = "https://www.heliostarmetals.com"
}

if (props.view == "NewsReleaseListView") {
    desktopImage = "/img/Kitco_3.png"
    mobileImage = "/img/Kitco_mob.png"
    callToAction = "https://inventacapital.ca"
}

if (props.view == "CompanyProfile") {

    desktopImage = props.baseImageUrl + '/public/company/' + props.listings_company_name_slug + '/' + 'web.png'
    mobileImage = props.baseImageUrl + '/public/company/' + props.listings_company_name_slug + '/' + 'mobile.png'
    defultDesktopImage = "/img/AddSilverWeb.png"
    defultMobileImage = "/img/Ad_Vizsla_Silver_mob.png"

    if (props.companyName == "Heliostar Metals Ltd.") {
        callToAction = "https://www.heliostarmetals.com"
    }
    else if (props.companyName == "Northstar Gold Corp.") {
        callToAction = "https://northstargoldcorp.com"
    }
    else {
        callToAction = "https://vizslasilvercorp.com"
    }


}
if (props.view == "InnerListingView") {

    desktopImage = props.baseImageUrl + '/public/company/' + props.listings_company_name_slug + '/' + 'web.png'
    mobileImage = props.baseImageUrl + '/public/company/' + props.listings_company_name_slug + '/' + 'mobile.png'
    defultDesktopImage = "/img/TinOneNewWeb.png"
    defultMobileImage = "/img/Mobile_Tinone_new.png"

    if (props.companyName == "Heliostar Metals Ltd.") {
        callToAction = "https://www.heliostarmetals.com"
    }
    else if (props.companyName == "Northstar Gold Corp.") {
        callToAction = "https://northstargoldcorp.com"
    }
    else {
        callToAction = "https://tinone.ca"
    }
}

if (props.view == "MapViewForNewsReleases") {
    desktopImage = '/img/Map_view_dt.jpg'
    mobileImage = '/img/Map_view_mb.jpg'
    callToAction = " https://inventacapital.ca"
}

const closeDt = () => {
    showDt.value = false

    if (props.view == "MapViewForNewsReleases") {

        if (props.user == null) {
            window.sessionStorage.setItem('MapViewForNewsReleases-banner-dt', 'true')

        }
        else {
            window.sessionStorage.setItem('MapViewForNewsReleases-banner-dt-user', 'true')

        }
    }
}
const closeMobile = () => {
    showMobile.value = false

    if (props.view == "MapViewForNewsReleases") {

        if (props.user == null) {
            window.sessionStorage.setItem('MapViewForNewsReleases-banner-mobile', 'true')

        }
        else {
            window.sessionStorage.setItem('MapViewForNewsReleases-banner-mobile-user', 'true')

        }
    }

}
const isAlreadyBanerCloedDT = computed(() => {

    if (props.view == "MapViewForNewsReleases") {

        if (props.user == null) {
            return !!window.sessionStorage.getItem('MapViewForNewsReleases-banner-dt')
        }
        else {
            return !!window.sessionStorage.getItem('MapViewForNewsReleases-banner-dt-user')
        }
    }
})
const isAlreadyBanerCloedMobile = computed(() => {


    if (props.view == "MapViewForNewsReleases") {

        if (props.user == null) {
            return !!window.sessionStorage.getItem('MapViewForNewsReleases-banner-mobile')
        }
        else {
            return !!window.sessionStorage.getItem('MapViewForNewsReleases-banner-mobile-user')
        }
    }
})
const shouldShowDt = computed(() => {
    return !isAlreadyBanerCloedDT.value

})
const shouldShowMobile = computed(() => {
    return !isAlreadyBanerCloedMobile.value

})
</script>

<template>
    <template v-if="view != 'MapViewForNewsReleases'">

        <div v-if="type === 'desktop'" v-show="shouldShowDt ? showDt : shouldShowDt"
            class="tw-relative tw-h-full tw-w-full">
            <div class="tw-absolute tw-right-1 tw-top-1 tw-cursor-pointer hover:tw-opacity-80" @click="closeDt"
                v-show="is_banner_closed == true">
                <i class="fa fa-times-circle tw-text-lg" style="color: white;"></i>
            </div>
            <a :href="callToAction" target="_blank">
                <img :src="desktopImage" class="tw-w-full" alt="Desktop Annoucemenet"
                    :onerror="`this.onerror=null; this.src='${defultDesktopImage}'`">
            </a>
        </div>

        <div v-if="type === 'mobile'" v-show="shouldShowMobile ? showMobile : shouldShowMobile"
            class="tw-relative tw-h-full tw-w-full">
            <div class="tw-absolute tw-right-1 tw-top-1 tw-cursor-pointer hover:tw-opacity-80" @click="closeMobile"
                v-show="is_banner_closed == true">
                <i class="fa fa-times-circle tw-text-lg" style="color: white;"></i>
            </div>
            <a :href="callToAction" target="_blank"><img :src="mobileImage" class="tw-object-cover tw-w-full tw-h-full"
                    :onerror="`this.onerror=null; this.src='${defultMobileImage}'`"  alt="Mobile Annoucemenet"/></a>
        </div>
    </template>

    <template v-else>
        <div v-if="type === 'desktop'" v-show="shouldShowDt ? showDt : shouldShowDt"
            class="tw-relative tw-h-full tw-w-full">
            <div class="tw-absolute tw-right-1 tw-top-1 tw-cursor-pointer hover:tw-opacity-80" @click="closeDt"
                v-show="is_banner_closed == true">
                <i class="fa fa-times-circle tw-text-lg" style="color: white;"></i>
            </div>
            <a :href="callToAction" target="_blank">
                <img :src="desktopImage" class="tw-object-contain tw-w-full tw-h-full" alt="Desktop Annoucemenet">
            </a>
        </div>

        <div v-if="type === 'mobile'" v-show="shouldShowMobile ? showMobile : shouldShowMobile"
            class="tw-relative tw-h-full tw-w-full">
            <div class="tw-absolute tw-right-1 tw-top-1 tw-cursor-pointer hover:tw-opacity-80" @click="closeMobile"
                v-show="is_banner_closed == true">
                <i class="fa fa-times-circle tw-text-lg" style="color: white;"></i>
            </div>
            <a :href="callToAction" target="_blank"><img :src="mobileImage" alt="Mobile Annoucemenet"
                    class="tw-object-cover tw-w-full tw-h-full" /></a>
        </div>

    </template>
</template>

<style scoped></style>