<script setup>
import { ref, onMounted, watch } from 'vue'
import { useForm } from "@inertiajs/vue3";
import BsContactModal from '../../components/modals/ConatcUsModal.vue'
import { VueRecaptcha } from 'vue-recaptcha';

const props = defineProps({
  csrf_token: {
    type: String,
    default: ''
  },
  captchaSiteKey: {
    type: String,
  },
  errors: {
    type: Object,
  },
  is_home_page: {
    type: Boolean,
    default: false
  },
})

const footerContentHidden = ref(true)
const showMobileFooter = ref(true)

const currentYear = ref(null);
const showContactusmodal = ref(false)

const showThankyouMsg = ref(false)
const showModalboday = ref(true)
const emit = defineEmits(["onClose"]);

const form = useForm({
  email_contact: null,
  name_contact: null,
  message_contact: null,
  authenticity_token: props.csrf_token,
  reCaptchaToken: null
})
const submit = (e) => {
  form.post('/contact/process_contact_form', {
    preserveState: true, onSuccess: () => {
      showModalboday.value = false;
      showThankyouMsg.value = true;
      form.reset();

    }
  })

}
onMounted(() => {
    currentYear.value = new Date().getFullYear();
})


watch(showThankyouMsg, (val) => {

  if (val == true) {
    setTimeout(() => {

      showThankyouMsg.value = false;
      showContactusmodal.value = false
    }, 5000);
  } else {
    setTimeout(() => {
      form.reset();
      showContactusmodal.value = false
    }, 1000);
  }
})

const verifyMethod = (response) => {

  form.reCaptchaToken = response
}

const expiredMethod = () => {
  form.reCaptchaToken = ""
}


</script>

<template>
  <div class="footer-above-space"></div>

  <!-- Footer starts -->
  <div v-if="is_home_page" class="footer py-3">
    <div class="container px-md-0 d-flex flex-column flex-lg-row align-items-center justify-content-lg-between footer-max-width-tab">
      <div><span><i class="fa fa-copyright me-1 mt-3- mt-lg-0" aria-hidden="true"></i>{{currentYear}} SearchSpace&nbsp;AI</span></div>
      <div class="d-flex flex-column flex-lg-row align-items-center footer-max-width-tab">
        <div class="d-flex flex-wrap justify-content-right mt-2 mt-lg-0 footer-links">
          <a href="/about">
            <p class="me-4 mb-0">About</p>
          </a>
          <a style="display:none" href="/pricing">
            <p class="me-4 mb-0">Pricing</p>
          </a>
          <a type="button" data-bs-target="#contactModal"
          @click="showContactusmodal = !showContactusmodal">
          <p class="me-4 mb-0">Contact</p>
          </a>
          <a href="/privacy">
            <p class="me-4 mb-0">Privacy</p>
          </a>
          <a href="/faq">
            <p class="me-4 mb-0">FAQ</p>
          </a>
          <a href="/terms-and-conditions">
            <p class="me-4 mb-0">Terms and Conditions</p>
          </a>
          <a href="/commodities">
            <p class="me-4 mb-0">Commodities</p>
          </a>
          <a href="/countries">
            <p class="me-4 mb-0">Countries</p>
          </a>
          <a href="/deal-type">
            <p class="me-4 mb-0">Deal types</p>
          </a>
          <a href="/project-stages">
            <p class="me mb-0">Project stages</p>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div v-if="is_home_page && showMobileFooter" class="mobile-footer py-3">
    <div class="container px-md-0 d-flex flex-column footer-max-width-tab">
      <div class="d-flex flex-row justify-content-between">
        <span><i class="fa fa-copyright me-1 mt-3- mt-lg-0" aria-hidden="true"></i>{{currentYear}} SearchSpace&nbsp;AI</span>
        <span><i :class='["fa fa-2x", "fa-lg", footerContentHidden ? "fa-angle-down" : "fa-angle-up"]' aria-hidden="true" @click="footerContentHidden = !footerContentHidden"></i></span>
      </div>
      <div v-if="!footerContentHidden" class="d-flex flex-column flex-lg-row align-items-center footer-max-width-tab">
        <div class="d-flex flex-wrap justify-content-right mt-2 mt-lg-0 footer-links">
          <a href="/about">
            <p class="me-4 mb-0">About</p>
          </a>
          <a style="display:none" href="/pricing">
            <p class="me-4 mb-0">Pricing</p>
          </a>
          <a type="button" data-bs-target="#contactModal"
          @click="showContactusmodal = !showContactusmodal">
          <p class="me-4 mb-0">Contact</p>
          </a>
          <a href="/privacy">
            <p class="me-4 mb-0">Privacy</p>
          </a>
          <a href="/faq">
            <p class="me-4 mb-0">FAQ</p>
          </a>
          <a href="/terms-and-conditions">
            <p class="me-4 mb-0">Terms and Conditions</p>
          </a>
          <a href="/commodities">
            <p class="me-4 mb-0">Commodities</p>
          </a>
          <a href="/countries">
            <p class="me-4 mb-0">Countries</p>
          </a>
          <a href="/deal-type">
            <p class="me-4 mb-0">Deal types</p>
          </a>
          <a href="/project-stages">
            <p class="me mb-0">Project stages</p>
          </a>
        </div>
        <div class="footer-icons d-flex justify-content-start mt-2 mt-lg-0 w-100">
          <a class="flex-1" href="https://twitter.com/SearchSpaceAI" target="_blank"> <i class="fa fa-twitter me-3" style="font-weight:bolder;"
              aria-hidden="true"></i></a>
          <a class="flex-1" href="https://www.linkedin.com/company/search-space-ai"
            target="_blank"><i class="fa fa-linkedin me-3" aria-hidden="true"></i></a>
          <a class="flex-1" href="https://www.facebook.com/SearchSpaceGEO" target="_blank"><i class="fa fa-facebook me-3"
              aria-hidden="true"></i></a>
          <a class="flex-1" href="https://www.youtube.com/@searchspaceAI/videos" target="_blank"><i class="fa fa-youtube-play"
              aria-hidden="true"></i></a>
        </div>
      </div>
    </div>
  </div>
  <!-- Footer ends -->

  <!-- Contact Modal -->
  <form :action="`/contact/process_contact_form`" method="POST" @submit.prevent="submit" class="mb-0">
    <BsContactModal :show="showContactusmodal" :modal_name="'ContactUS'">
      <template #modalHeader>
        <h5 class="modal-title" id="exampleModalLabel">Contact Us</h5>
        <button type="button" class="btn-close" aria-label="Close" @click="showContactusmodal = false"></button>
      </template>
      <template #modalBody>
        <div v-show="showModalboday">
          <h6 class="mt-1 mb-3">Send us a message</h6>
          <div class="mb-3">
            <label class="form-label">Name</label>
            <input type="text" class="form-control" v-model="form.name_contact" required>
          </div>
          <div class="mb-3">
            <label class="form-label">Email</label>
            <input type="email" class="form-control" v-model="form.email_contact" required>
          </div>
          <div class="mb-3">
            <label class="form-label">Message</label>
            <textarea class="form-control" v-model="form.message_contact" required></textarea>
          </div>

          <div class="mb-3">
            <vue-recaptcha ref="recaptcha" :sitekey="props.captchaSiteKey" @verify="verifyMethod"
              @expired="expiredMethod"></vue-recaptcha>

            <input type="hidden" class="form-control" v-model="form.reCaptchaToken" required>

            <div class="validation-msg">
              {{ errors?.message }}
            </div>
          </div>

          <button :disabled="form.reCaptchaToken === null" type="submit" class="btn apply-btn custom-primary-btn w-100">{{
            form.processing ? 'Please wait...' :
            'Submit' }} </button>
        </div>
        <div class="Thank-u-msg text-center mt-4" v-show="showThankyouMsg">
          <div class="success-icon"><i class="fa fa-check" aria-hidden="true"></i>
          </div>
          <h6 class="mb-2">Thank you!</h6>
          <p class="">Your message was delivered successfully. <br /> We will get back to you as quick as possible.</p>
        </div>
      </template>
      <template #modalFooter>
        <div class="d-flex align-items-center mb-2">
          <img src="../../assets/img/email.png" alt="Support Email" class="me-2">
          <p>support@searchspaceai.com</p>
        </div>
        <div class="d-flex align-items-center mb-2">
          <img src="../../assets/img/phone.png" alt="Support Phone Number" class="me-2">
          <p>+1 604 283 7679</p>
        </div>
        <div class="d-flex align-items-center mb-2">
          <img src="../../assets/img/address.png" alt="Support Address" class="me-2">
          <p>595 Burrard Street, Suit 1723, Vancouver, BC V7X 1J1.</p>
        </div>
      </template>
    </BsContactModal>
  </form>
  <!-- Contact Modal End-->
</template>

<style scoped lang="scss">
.apply-btn {
  --bs-btn-disabled-bg: #3882ff;
  padding: 5px;
}

.footer-block {
  padding: 20px;
  background-color: #f0f0f0;
  text-align: center;
}

.fa.fa-twitter{
	font-family:sans-serif;
}
.fa.fa-twitter::before{
	content:"𝕏";
	font-size:1.2em;
}
</style>