<script setup>

import Paginator from 'primevue/paginator';
import { computed, ref, watch, onUpdated } from 'vue';
import useGetPressReleases from '../../hooks/press-releases/useGetPressReleases'
import useDeletePressRelease from '../../hooks/press-releases/useDeletePressRelease'
import PdfModal from '../../../frontend/components/modals/PdfModal.vue'
import Toast from 'primevue/toast';
import { useToast } from "primevue/usetoast";

const newsLetterUrl = new URLSearchParams(window.location.search)
const pressReleasId = newsLetterUrl.get('id')

const props = defineProps({
    filters: {
        type: Object,
        default: {
            companyName: undefined,
            keyword: undefined,
        }
    },
    deletable: {
        type: Boolean,
        default: false,
    },
    recordsPerPage: {
        type: Number,
        default: 5
    },
    baseImageUrl: {
        type: String
    }
})

const page = ref(1)
// const selectedCommoditiesLocal = ref( props.filters.commoditiesName)
const params = computed(() => {
    return {
        companyName: props.filters.companyName || undefined,
        commoditiesName: props.filters.commoditiesName || undefined,
        countryName: props.filters.countryName || undefined,
        keyword: props.filters.keyword || undefined,
        startDate: props.filters.startDate || undefined,
        endDate: props.filters.endDate || undefined,
        id: props.filters.id || undefined,
        page: page.value,
        recordsPerPage: props.recordsPerPage,
    }
})

const offSet = computed(() => {
    return page.value === 1 ? 0 : (page.value * props.recordsPerPage) - 1
})

const filters = computed(() => props.filters)

watch(filters, () => {
    page.value = 1
}, { deep: true })

const { data: pressReleaseData, isLoading: pressReleaseLoading, isSuccess: pressReleaseSuccess } = useGetPressReleases(params)
const { deletePressRelease, deletePressReleaseStatus } = useDeletePressRelease()

const deleteItem = async (item) => {
    console.log('DELETE', item)
    deletePressRelease(item._id.$oid).then(res => {
        toast.add({ severity: 'success', detail: 'Press release deleted successfully.', life: 3000 });
    }).catch(err => {
        toast.add({ severity: 'error', summary: 'Error Message', detail: 'We could not delete the press release.', life: 3000 });
    })
}
const s3BaseUrl = computed(() => props.baseImageUrl)
let pdfPopup = true



</script>


<template>
    <div v-if="pressReleaseLoading">
        Loading...
    </div>

    <template v-if="pressReleaseSuccess">
        <div v-if="!pressReleaseData.data.length">
            No press release found.
        </div>
        <div v-else v-for="(valueRow, index) in pressReleaseData.data" :key="JSON.stringify(index)">
            <div>
                <div class="d-flex justify-content-between align-items-center manage-news-release">
                    <div class="d-flex flex-column news-release-title">
                        <h6 class="mb-1 notranslate">
                            {{ valueRow.company_name }} - <span>{{ valueRow.publish_date }}</span></h6>
                        <p class="mb-0">
                            <PdfModal :link="`${s3BaseUrl}/${valueRow.link_s3}`"
                                :PressReleaseOpen="valueRow._id.$oid === pressReleasId ? true : false">
                                <!-- <PdfModal :link="`${s3BaseUrl}/${valueRow.link_s3}`" :PressReleaseOpen="false"> -->
                                <a @click.prevent="" href="#" target="_blank">{{ valueRow.title || 'No title' }}</a>
                            </PdfModal>
                        </p>
                        <!-- <p class="mb-0" style="width: 100%"> </p> -->
                    </div>
                    <div v-if="deletable" @click="deleteItem(valueRow)" class="tw-cursor-pointer">
                        <!-- <i class="pe-7s-close"></i>
                            x -->
                        <i class="fa fa-times" aria-hidden="true"></i>
                    </div>
                </div>
                <div class="horizontal-seperator w-100"></div>
            </div>
        </div>
    </template>
    <!-- <hr style="border-color: #EBEBEB; margin: 15px 0px;"> -->
    <template v-if="pressReleaseSuccess">
        <Paginator :rows="recordsPerPage" :totalRecords="pressReleaseData.pagination.count" @page="(e) => page = e.page + 1"
            :first="offSet"
            template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink  CurrentPageReport JumpToPageInput">
        </Paginator>

    </template>
    <Teleport to="#pressrelease_count" v-if="pressReleaseSuccess">
        {{ pressReleaseData.pagination.count }}
    </Teleport>
    <!----<Teleport to="#press_release_count_per_page"  v-if="pressReleaseSuccess">
                              <span v-if="pressReleaseData.pagination.current_page==1"> {{pressReleaseData.pagination.current_page}} to {{recordsPerPage}} of</span>
                              <span v-else-if="pressReleaseData.pagination.current_page * recordsPerPage > pressReleaseData.pagination.count"> {{(((pressReleaseData.pagination.current_page)-1) * recordsPerPage)+1}} to {{pressReleaseData.pagination.count}} of</span>
                              <span v-else>{{(((pressReleaseData.pagination.current_page)-1) * recordsPerPage)+1}} to {{ pressReleaseData.pagination.current_page *recordsPerPage}} of</span>
                           </Teleport>-->
</template>