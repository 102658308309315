<script setup>
import { ref, computed } from 'vue'
import _ from 'lodash'
import VueSingleselectCustom from '../VueMultiselectCustom.vue'
import useCreatePressRelease from '../../../src/hooks/press-releases/useCreatePressRelease'
import { useToast } from "primevue/usetoast";
import Toast from 'primevue/toast';
import Paginator from 'primevue/paginator';
import PressReleaseList from './PressReleaseList.vue'
import project_image_uploader from '../../components/geolistings/ProjectImageUploader.vue'

const props = defineProps({
    internal_external_companies: {
        type: Array,
        default: []
    },

    multiple: {
        type: Boolean,
        default: true,
    },
    selectedCompanies: {
        type: Array,
        default: []
    },
    primary_commodities: {
        type: Array,
        default: []
    },
    secondary_commodity: {
        type: Array,
        default: []
    },
    primary_selected_commodities: {
        type: Array,
        default: []
    },
    secondary_selected_commodities: {
        type: Array,
        default: []
    },
    pressrelases: {
        type: Array,
        default: []
    },
    baseImageUrl: {
        type: String,
        default: ''
    } ,
    user: {
        type: Object,
        default: null,
    },
})

const onChangePressRelase = (event) => {
    // let url = `?company_name=${event}` 
    // url = encodeURI(url)
    // document.location = url
    // return
}
const addTag = (index) => {
    props.internal_external_companies.push(index)
}

const toast = useToast();


const selectedcompany = ref(null)
const publishedDate = ref(null)
const primaryCommodity = ref(null)
const title = ref('')
const uploaderKey = ref(0)
const page = ref(1)
const selectedCompaniesLocal = ref(props.selectedCompanies)
const selectedPrimaryCommoditiesLocal = ref([])
const selectedSecondaryCommoditiesLocal = ref([])

const internal_external_companies = _.uniq(props.internal_external_companies.map(company => company.company_name))
//const internal_external_companies_options = _.sortBy(props.internal_external_companies,(o) => o.toLowerCase());
const internal_external_companies_options = _.sortBy(internal_external_companies, (o) => o.toLowerCase());

const { createPressRelease, createPressReleaseStatus } = useCreatePressRelease()
const uploader = ref(null)

const fileUploadErrorMessage = ref('')
const formErrors = ref({})
const isLoading = ref(false)

const submitForm = async () => {

    if (uploader.value.newlyUploadedFiles.length === 0) {
        fileUploadErrorMessage.value = "Please upload a file."
        return
    }

    fileUploadErrorMessage.value = ''
    const trimmedCompanyName = selectedcompany.value.trim();

    isLoading.value = true
    await createPressRelease({
        company_name: trimmedCompanyName,
        publish_date: publishedDate.value,
        title: title.value,
        created_by: props.user.email,
        link_s3: uploader.value.newlyUploadedFiles[0].url,
        primary_commodity: selectedPrimaryCommoditiesLocal.value,
        secondary_commodity: selectedSecondaryCommoditiesLocal.value
    }).then(res => {
        uploaderKey.value++
        toast.add({ severity: 'success', detail: 'Press release saved successfully.', life: 3000 });
        isLoading.value = false
    }).catch(err => {
        window.scrollTo(0, 0)
        toast.add({ severity: 'error', summary: 'Error Message', detail: 'We could not save the press release.', life: 3000 });
        isLoading.value = false
    })


}
const currentDate = computed(() => {
    const current = new Date();
    const date = `${current.getFullYear()}-${((current.getMonth() + 1) > 9 ? '' : '0') + (current.getMonth() + 1)}-${(current.getDate() > 9 ? '' : '0') + current.getDate()}`;
    return date;
})
</script>
    
<template>
    <form action="/press_release/manage_press_release" v-cloak method="POST" @submit.prevent="submitForm">
        <h5>Manage News Releases</h5>
        <template v-if="createPressReleaseStatus.status === 'error'">
            <div class="tw-mb-2">
                <p class="error-link text-danger tw-mb-2 tw-font-bold">Could not save the press release.</p>

                <p v-for="(e, index) in createPressReleaseStatus.error.response.data.errors" :key="index"
                    class="error-link text-danger tw-mb-0">
                    {{ index }}: {{ e[0] }}
                </p>
            </div>
        </template>
        <div class="pt-4">
            <div class="">
                <h6>Company Details</h6>
                <p class="mt-1">Enter the company details.</p>
            </div><br>
            <div class="row">
                <div class="col-sm-6">
                    <div class="field">
                        <label class="form-label">Company</label>
                        <VueSingleselectCustom class="notranslate" :options="internal_external_companies_options"
                            :modelValue="selectedcompany" :taggable="true"
                            tag-placeholder="Click here to create new company name" @tag="addTag(selectedcompany = $event)"
                            @update:modelValue="selectedcompany = $event" :multiple="false"
                            @select="onChangePressRelase(selectedCompaniesLocal = $event)"></VueSingleselectCustom>
                        <input :value="selectedcompany" type="hidden" name="company_name" />
                    </div>
                </div>
            </div>
        </div>
        <div class="pt-5">
            <div class="">
                <h6>Upload News Releases</h6>
                <p class="mb-2 mt-1">Upload news releases.</p>
                <p class="mb-0">File types: PDF</p>
                <p class="mb-0">Max size: 10MB</p>
                <!-- <button type ="button" class="btn btn-primary upload-file-btn">Upload File</button> -->
                <p v-if="fileUploadErrorMessage" class="error-link text-danger">{{ fileUploadErrorMessage }}</p>

                <project_image_uploader :key="uploaderKey" ref="uploader" resource-type="pressRelease" :config="{
                    restrictions: {
                        allowedFileTypes: ['application/pdf', 'pdf'],
                        maxFileSize: 10 * 1024 * 1024,
                        maxNumberOfFiles: 1,
                    }
                }" :uploaded-images="[]">
                    <template #trigger="triggerProps">
                        <button
                            :disabled="triggerProps.uploadedImagesLocal.length + triggerProps.newlyUploadedFiles.length > 0 || !selectedcompany"
                            @click="triggerProps.showUploadModal" type="button"
                            class="btn custom-primary-btn w-25--">
                            Upload File
                        </button>
                    </template>
                </project_image_uploader>
            </div>
            <div class="pt-5">
                <h6>News Release Details</h6>
                <p class="mt-1">Enter the news release details.</p> <br>
                <div class="row g-4 news-release-details-section">
                    <div class="col-sm-12">
                        <div class="field">
                            <label class="form-label">News release title</label>
                            <input class="form-control" v-model="title" name="title">
                        </div>
                    </div>

                    <div class="col-sm-4">
                        <div class="field">
                            <label class="form-label">Published date</label>
                            <input type="date" class="form-control" name="publish_date" v-model="publishedDate"
                                :max="currentDate">
                        </div>
                    </div>

                    <!---- <div class="col-sm-4">
                                <div class="field">
                                    <label>Primary commodity</label>
                                    <VueSingleselectCustom :options="primary_commodities"  :modelValue="selectedPrimaryCommoditiesLocal"  placeholder="Primary commodity"  @update:modelValue="selectedPrimaryCommoditiesLocal = $event" :multiple="multiple"></VueSingleselectCustom>
                                </div>
                            </div>

                            <div class="col-sm-4">
                                <div class="field">
                                    <label>Secondary commodity</label>
                                    <VueSingleselectCustom :options="secondary_commodity"  :modelValue="selectedSecondaryCommoditiesLocal" placeholder="Secondary commodity"  @update:modelValue="selectedSecondaryCommoditiesLocal = $event" :multiple="multiple"></VueSingleselectCustom>
                                </div>
                            </div>-->
                </div>
                <br><button :disabled="isLoading" type="submit" class="btn custom-primary-btn">Create News
                    Release</button>
            </div>
            <div class="press-releases mt-5">
                <h6 class="mb-4">News Releases</h6>
                <PressReleaseList :deletable="true" :filters="{ companyName: selectedcompany }"
                    :baseImageUrl="baseImageUrl" />
            </div>

    </div>
</form>

<Teleport to="body">
    <!-- <Toast /> -->
</Teleport></template>
