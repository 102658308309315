<script setup>

import {computed, ref} from 'vue'
import Dialog from 'primevue/dialog';

import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'

const props = defineProps({
    link: {
        type: String,
        required: true,
    }
})

const play = ref(false)

const videoId = computed(() => {
    let ID = '';
    let url = props.link.replace(/(>|<)/gi,'').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if(url[2] !== undefined) {
        ID = url[2].split(/[^0-9a-z_\-]/i);
        ID = ID[0];
    }
    else {
        ID = url;
    }
    return ID;
})

const thumb = computed(() => {
    return 'http://img.youtube.com/vi/' + videoId.value + '/mqdefault.jpg';
}) 

const playVideo = () => {
    play.value = !play.value
}

const close = () => {
    play.value = false
}
console.log(videoId)
</script>

<template>
    <div @click="playVideo" class="tw-cursor-pointer tw-shadow-md tw-rounded-md tw-overflow-hidden hover:tw-opacity-80 tw-transition-all">
        <img :src="thumb" class="tw-w-full tw-object-cover" alt='YouTube Video Thumb Image'/>
    </div>

    <Dialog :visible="play" header=" " :modal="true" :closable="true" @update:visible="close" :baseZIndex="100124001">
        <div class="tw-w-[22rem] xs:tw-w-[30rem] sm:tw-w-[40rem]">
            <div class="iframe-container">
                <iframe width="560" height="315" :src="`https://www.youtube.com/embed/${videoId}?start=1&autoplay=1`" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen ></iframe>
            </div>
        </div>
    </Dialog>
    
</template>