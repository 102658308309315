<script setup>

</script>

<template>
    <div class="container subscription-pages">
        <h4 class="mt-3 mt-lg-5 mb-3">Subscription to News Releases</h4>
        <!------------------------------ Create your Account --------------------------------------------->
        <div class="row g-3 subscription-form">
            <h5 class="mt-5 mb-0">Create your Account</h5>
            <p class="mt-1 mb-0 ps-3 ms-1">Enter your account details.</p>
            <div class="col-md-6">
                <label class="form-label">Name</label>
                <input type="text" class="form-control">
            </div>
            <div class="col-md-6">
                <label class="form-label">Email</label>
                <input type="text" class="form-control">
            </div>
            <div class="col-md-6">
                <label class="form-label">Password</label>
                <input type="text" class="form-control">
            </div>
            <div class="col-12">
                <div class="form-check d-flex align-items-center terms-n-conditions">
                    <input class="form-check-input mt-0" type="checkbox">
                    <label class="form-check-label">
                        Accept <a href="">Terms and Conditions</a>
                    </label>
                </div>
            </div>
        </div>

        <!------------------------------------ News Release Preferences  ---------------------------------------->
        <div class="row g-3 subscription-form mt-2">
            <h5 class="mt-5 mb-0">News Release Preferences</h5>
            <p class="mt-1 mb-0 ps-3 ms-1">Please select one or more options from the following to receive daily press
                releases based on your preference.</p>
            <div class="col-md-12">
                <label class="form-label">Companies</label>
                <select class="form-select">
                    <option selected>Select</option>
                    <option>...</option>
                    <option>...</option>
                    <option>...</option>
                </select>
            </div>
            <div class="col-md-12">
                <label class="form-label">Project commodities associated with companies</label>
                <select class="form-select">
                    <option selected>Select</option>
                    <option>...</option>
                    <option>...</option>
                    <option>...</option>
                </select>
            </div>
            <div class="col-md-12">
                <label class="form-label">Countries</label>
                <select class="form-select">
                    <option selected>Select</option>
                    <option>...</option>
                    <option>...</option>
                    <option>...</option>
                </select>
            </div>
        </div>

        <div class="row g-3 listing-form mt-4 pb-5 d-flex justify-content-end">
            <div class="col-lg-3">
                <button type="submit" class="btn custom-primary-btn w-100">Register and Submit Preferences</button>
            </div>
        </div>
    </div>
</template>