<script setup>
import { ref, onBeforeUnmount } from 'vue'
import PropertyConversationModal from './PropertyConversationModal.vue'

const props = defineProps({
  propertyId: {
    type: String,
    required: true,
  }
})



const showModal = ref(false)
</script>

<template>
    <template v-if="showModal">
      <PropertyConversationModal :propertyId="propertyId" @close="showModal = false"  />
    </template>
    <button type="button" @click="showModal = true" class="btn">
        <!-- <i class="fa fa-comment-o me-2" aria-hidden="true"></i> -->
        <img src="../../../frontend/assets/img/message-icon.png" alt="Massage With Owner"    class="msg-owner-icon">
        <img src="../../../frontend/assets/img/message-icon-white.png" alt=""   class="msg-owner-icon-white d-none">
        Message Owner
    </button>
</template>
