<script setup>
import { computed, watch, onMounted, ref } from 'vue'

    const props = defineProps({
        show: Boolean,
        blurBackdrop: false,
    })

    const isVisible = computed(() => props.show)

    const modalRef = ref(null)
    let thisModalObj = ref(null);
    // Watch
    watch([isVisible, thisModalObj], ([newIsVisible, newModalObj]) => {

        if(newIsVisible) {
            setTimeout(() => {                
                thisModalObj.value?.show();
            }, 0);
        }else {
            setTimeout(() => {                
                thisModalObj.value?.hide();
            }, 0);
        }

    }, {immediate: true})

    onMounted(() => {
        if (typeof window !== 'undefined') {
            import('bootstrap').then((bootstrap) => {
                const { Modal } = bootstrap;
                thisModalObj.value = new Modal(modalRef.value, {backdrop: "static"});
            });
        }
    })


</script>

<template>
    <div ref="modalRef" class="modal fade " tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" :class="[{'tw-bg-white/20': blurBackdrop}]"  :style="blurBackdrop? 'backdrop-filter: blur(3px)': ''" style="padding-right: 0px;">
        <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable pt-5">
          <div class="modal-content">
            <div class="modal-header">
                <slot name="modalHeader"></slot>
            </div>
            <div class="modal-body">
                 <slot name="modalBody"> </slot>
            </div>
            
            <slot name="modalFooterWrapper">
                <div class="modal-footer">
                    <slot name="modalFooter"> </slot>
                </div>
            </slot>
         
          </div>
        </div>
      </div>
</template>