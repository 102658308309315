<script setup>
import { useForm } from "@inertiajs/vue3";
import Nav from '../../components/layout/Nav.vue'
import headComponent from "../../components/inertiaHead/head.vue"

const props = defineProps({
    csrf_token: {
        type: String,
        default: ''
    }

})
const form = useForm({
    email: null,
    authenticity_token: props.csrf_token,
})

const submit = (e) => {
    form.post('/lost_password', {
        preserveState: true, onSuccess: () => {

            console.log('on successs...')
        }
    })

};
</script>

<template>
    <headComponent :description="'Reset your password and regain access to our comprehensive resource on mining listings, SearchSpace AI, and the mining projects network in usa, canada and worldwide.'"
          :title="'Forget Password'">
    </headComponent>
    <div class="container px-sm-0 d-flex justify-content-center mt-5">
        <div class="lost-pw">
            <h1 class="text-center h4" >Forget Password</h1>
            <p class="text-center mb-4">Enter your email to receive the reset link</p>
            <div class="row justify-content-center">
                <div class="col">
                    <form :action="`/lost_password`" method="POST" @submit.prevent="submit">
                    <div class="form-group">
                        <input required autofocus="autofocus" autocomplete="off" type="email" name="lost_password[email]"
                        v-model="form.email"  id="lost_email" class="form-control" placeholder="Email*">
                    </div>
                    <div class="horizontal-seperator"></div>
                    <button class="btn custom-primary-btn w-100" type="submit"> {{ form.processing ? 'Please wait...' : 'Send Link' }}
                    </button>
                </form>
                </div>
            </div>
        </div>
    </div>
</template>