<script setup>
import { computed } from "vue";
import YouTubeVideoGalleryItem from "./YouTubeVideoGalleryItem.vue";

const props = defineProps({
  links: {
    type: Array,
    default: [],
  },
});

const validLinks = computed(() => {
  return props.links.filter((i) => !!i);
});
</script>

<template>
  <div class="col-xxl-4 col-md-6 col-sm-12" v-for="link in validLinks" :key="link">
    <YouTubeVideoGalleryItem :link="link" />
  </div>
</template>
